<template>
  <div>
    <el-select
      v-model="currentValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :remote-method="fetchData"
      :filterable="true"
      :size="size"
      :clearable="clearable"
      remote
      reserve-keyword
      automatic-dropdown
      style="width: 100%"
      @change="handlerChange"
      @clear="fetchData()"
    >
      <!-- @blur="fetchData()" -->
      <!-- class="filter-item" -->
      <el-option
        v-for="item in dataList"
        :key="item.id"
        :label="item[config.label]"
        :value="item[config.value]"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'DicListSelectCommon',
  props: {
    placeholder: String,
    value: {
      type: String | Number
    },
    data: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    api: {
      type: Function,
      default: () => {}
    },
    searchNameKey: {
      type: String,
      default: 'title'
    },
    size: {
      type: String,
      default: 'small'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => {
        return {
          label: 'title',
          value: 'title'
        }
      }
    }
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData()
  },
  methods: {
    fetchData(name) {
      const q = {
        current: 1,
        size: 10000,
        params: {}
      }

      if (this.data) {
        q.params = this.data
      }

      if (name) {
        q.params[this.searchNameKey] = name
      }

      this.api(q).then((response) => {
        if (response && response.success) {
          const { records } = response.data
          if (records && records.length > 0) {
            this.dataList = records
          }
        }
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>
::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}
</style>
