<template>
  <div v-loading="loading" class="content">
    <div v-if="!isError">
      <div class="title">【{{ exam.title }}】 在线考试邀请</div>
      <div class="sub-title">
        <div class="icon-row">
          <div class="icon-content">
            <i class="el-icon-user-solid" />
          </div>
          <span>考生信息</span>
        </div>
      </div>
      <el-descriptions
        :column="1"
        :label-style="{
          'text-align': 'center',
          width: '2rem',
          border: '5px solid #fff'
        }"
        :content-style="{
          width: '4rem',
          border: '5px solid #fff',
          'background-color': '#fafafa'
        }"
        border
        style="margin-bottom: 0.35rem"
      >
        <el-descriptions-item label="姓名">
          <b>
            {{ info.name || '-' }}
          </b>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ info.mobile || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="E-mail">
          {{ info.email || '-' }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="sub-title">
        <div class="icon-row">
          <div class="icon-content">
            <i class="el-icon-s-order" />
          </div>
          <span>考试账号</span>
        </div>
      </div>
      <el-descriptions
        :column="1"
        :label-style="{
          'text-align': 'center',
          width: '2rem',
          border: '5px solid #fff'
        }"
        :content-style="{
          width: '4rem',
          border: '5px solid #fff',
          'background-color': '#fafafa'
        }"
        border
        style="margin-bottom: 0.35rem"
      >
        <el-descriptions-item label="开始时间">
          <b>
            {{ exam.startTime || '-' }}
          </b>
        </el-descriptions-item>
        <el-descriptions-item label="引导考试入口">
          <a
            :href="info.examEnterUrl"
            style="color: #cd2b1e; text-decoration: underline"
            target="_black"
          >
            {{ info.examEnterUrl || '-' }}
          </a>
          <el-link
            icon="el-icon-document-copy"
            size="mini"
            @click="copy($event, info.examEnterUrl)"
          >
            复制
          </el-link>
          <br >（请复制链接到电脑浏览器进行打开）
        </el-descriptions-item>
        <el-descriptions-item label="考试入口">
          <a
            :href="baseUrl"
            style="color: #cd2b1e; text-decoration: underline"
            target="_black"
          >
            {{ baseUrl }}
          </a>
          <el-link
            icon="el-icon-document-copy"
            size="mini"
            @click="copy($event, baseUrl)"
          >
            复制
          </el-link>
          <br >（请复制链接到电脑浏览器进行打开）
        </el-descriptions-item>
        <el-descriptions-item label="登录账号">
          {{ info.username || '-' }}
          <el-link
            icon="el-icon-document-copy"
            size="mini"
            @click="copy($event, info.username)"
          >
            复制
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item label="登录密码">
          {{ info.passwd || '-' }}
          <el-link
            class="color:blue"
            icon="el-icon-document-copy"
            size="mini"
            @click="copy($event, info.passwd)"
          >
            复制
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item label="确认情况">
          <span v-if="info.state === 1" class="red-text">（未确认）</span>
          <span v-if="info.state === 2" class="blue-text">（确认参加）</span>
          <span v-if="info.state === 3">（{{ info.state_dictText }}）</span>
        </el-descriptions-item>
      </el-descriptions>
      <div id="introduce" class="sub-title">
        <div class="icon-row">
          <div class="icon-content">
            <i class="el-icon-document" />
          </div>
          <span>考试须知</span>
        </div>
      </div>
      <el-card class="box-card">
        <div slot="header">
          <div class="box-header">考试须知：</div>
        </div>
        <div>
          <p class="indent-text">您好！</p>
          <!-- <p class="indent-text">
          感谢您的申请，经过综合评估，您已通过前期简历筛选，现邀请您参加
          <span class="red-text">
            {{ info.careerEnterpriseName }}{{ exam.title }}
          </span>
          在线考试。
        </p>
        <p class="indent-text">
          请您在收到通知信后仔细阅读考试须知，请您于<span class="red-text">
            {{ confirmEndTimeStr || '截止时间' }}</span
          >前登录考试系统进行设备测试，并完成人脸身份验证

          。本场考试要求必须开启电脑摄像头、手机视频监控及电脑桌面录屏监控，因此请确保设备正常运作并保障网络稳定。如未提前登录检测设备，出现设备故障、监控设备不能开启等问题，将会影响考生成绩。
        </p>
        <p class="indent-text">请您按通知时间准时登录进行作答！详情如下：</p> -->
          <p class="indent-text">
            现邀请您参加
            <span class="red-text">
              {{ info.careerEnterpriseName }}{{ exam.title }}</span
              >
            ，请您在收到通知信后仔细阅读考试须知， 请您于
            <span class="red-text"> {{ confirmEndTimeStr || '截止时间' }}</span>
            前登录考试系统进行设备测试，并完成人脸身份验证。
          </p>
          <p class="indent-text">
            本场考试要求必须开启电脑摄像头、手机视频监控及电脑桌面录屏监控，因此请确保设备正常运作并保障网络稳定。如未提前登录检测设备，出现设备故障、监控设备不能开启等问题，将会影响考生成绩。
          </p>
          <p class="indent-text">
            请您按通知时间准时登录进行作答！考试注意如下:
          </p>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <div class="box-header">考试要求：</div>
        </div>
        <div>
          <p class="indent-text">
            1. <span
              class="red-text"
            >设备要求：请自备电脑（建议用笔记本电脑）、高清摄像头（电脑自带摄像头即可），监控用手机（安装微信软件），电脑安装Chrome浏览器（版本80以上）</span
            >。禁止使用手机或平板电脑（如iPad、安卓系统平板等）作答。所有设备请确保电量充足，上网流量充足；
          </p>
          <p class="indent-text">
            2. <span
              class="red-text"
            >网速要求：作答网络的带宽需高于5M，或使用4G/5G手机分享热点上网</span
            >。关闭360等电脑管理软件，关闭下载软件，确保网速流畅；
          </p>
          <p class="indent-text">
            3. <span
              class="red-text"
            >环境要求：请选择独立、安静、封闭、光线明亮、不受打扰的空间独自参加考试</span
            >，例如在家、寝室、会议室均可，不允许在网吧、咖啡厅、教室等公共场所作答；
          </p>
          <p class="indent-text">
            4. 迟到5分钟后，将不能再登录系统作答，请提前登录考试系统；
          </p>
          <p class="indent-text">
            5、防作弊检测软件下载：本次考试严格要求考试期间电脑设备禁止开启与考试无关软件或程序，考生需要下载防作弊检测软件才可允许考试，软件下载:
            <router-link style="color: #cd2b1e; text-decoration: underline" to="/downtool/windows" tag="a" target="_blank">点此下载</router-link>
            <!-- <el-link
              icon="el-icon-document-copy"s
              style="font-size: 0.34rem; text-indent: 0"
              size="mini"
              @click="copy($event, antiCheatingSoftwareLink)"
            >
              复制
            </el-link> -->
          </p>
          <!-- <p class="indent-text">
          5. <span
            class="red-text"
          >融通人力在线考试系统使用视频，请务必仔细观看：点击查看>></span
          >
        </p>
        <p class="indent-text">
          6. <span
            class="red-text"
          >考试系统常见问题请务必查看：点击查看>></span
          >
        </p> -->
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <div class="box-header">考试监控：</div>
        </div>
        <div>
          <p class="indent-text">1. 手机监控：</p>
          <p class="indent-text">
            本次考试将开启手机视频监控，考生进入考试系统后使用微信扫码进入手机视频监控模式；
          </p>
          <p class="indent-text">
             <span class="red-text">
              请将手机放置于考生座位电脑正侧方（不要放后方或前方），间隔1.5米以上距离，架高手机，俯拍桌面；手机监控画面请包含完整的电脑屏幕、键盘、考生侧脸、上半身，双手处于监控范围内；手机请不要横着摆放，保持桌面整洁，不要有杂物，参考下图：
            </span>
          </p>
          <img src="@/assets/web/images/phone1.png" style="width: 6.8rem" >
          <p class="indent-text">2. 电脑视频监控：</p>
          <p class="indent-text">
            考试将开启电脑摄像头视频监控，请按系统要求打开电脑摄像头，确保在答题页面右上方可以清晰看到本人的上半身头像。
          </p>
          <p class="indent-text">3. 电脑桌面监控：</p>
          <p class="indent-text">
            考试将开启答题操作电脑录屏监控，正式考试时系统会要求共享屏幕，选择“您的整个屏幕”，点击“分享”即可开始录制，考试全程请不要停止共享。
          </p>
          <p class="indent-text">
            在正式考试前，您可以随时登录系统检测以上监控设备，体验操作，<span
              class="red-text"
            >请确保正式开考后，所有监控设备正常开启，否则会影响您的考试成绩</span
            >。
          </p>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <div class="box-header">考试纪律：</div>
        </div>
        <div>
          <p class="indent-text">
            1. <span
              class="red-text"
            >监考官会通过监控大屏全程观察每位考生的举止行为</span
            >，请严格遵守考场纪律，诚实作答，任意作弊行为一经核实，则考试成绩按0分计，并记录在融通人力诚信档案中；
          </p>
          <p class="indent-text">
            2. <span
              class="red-text"
            >考试中使用手机或平板电脑查阅信息，翻阅辅导书籍材料，查看小抄，接打电话，左顾右盼、外接显示器等行为，都将被认定为作弊</span
            >；
          </p>
          <p class="indent-text">
            3. <span
              class="red-text"
            >考试中严禁有其他人员陪同，例如家人、同事、朋友等，如有他人辅助作答，将被认定为作弊</span
            >；
          </p>
          <p class="indent-text">
            4.
            未按要求开启电脑摄像头监控、手机视频监控、电脑桌面监控，监控手机未按要求摆放，将被认定为违规；
          </p>
          <p class="indent-text">
            5.
            考试中禁止跳出考试页面，不要佩戴耳机、帽子，口罩，头发不能遮盖耳朵，不要遮挡电脑和手机摄像头，关闭有自动消息弹窗的软件，衣着要得体，作答期间不允许离开座位；
          </p>
          <p class="indent-text">
            6. <span
              class="red-text"
            >考中考官会随机抽查考生座位周围环境，请配合考官操作；</span
            >
          </p>
          <p class="indent-text">7. 可以使用草稿纸、笔，禁止使用电子计算器；</p>
          <p class="indent-text">
            8.
            举办方拥有全部试题的内容版权，请不要对试题内容做任何拍照、复制、传播。
          </p>
        </div>
      </el-card>
      <div v-if="info" class="work-btns">
        <el-button
          :disabled="info.state === 2"
          :loading="loading"
          :class="info.state === 2 ? 'work-btn-disabled' : 'work-btn'"
          type="primary"
          @click="joinTest"
        >
          确认参加
        </el-button>
        <el-button
          :disabled="info.state === 3"
          :loading="loading"
          type="default"
          @click="rejectTest"
        >
          放弃参加
        </el-button>
        <el-button :disabled="btnIsDisabled" type="default" @click="later">
          稍后确认
        </el-button>
      </div>
    </div>
    <div v-else>
      <div class="imgBody">
        <img class="img404" src="@/assets/404_images/404.png" alt="404" >
      </div>
      <h4 class="err-notice">
        信息错误，请确认当前页面地址是否和通知中的链接地址一致，并稍后重试。
      </h4>
    </div>
  </div>
</template>

<script>
import {
  fetchstudentconfirmconfirm,
  fetchstudentconfirmconfirmPrepare
} from '@/api/web/web'
import { modalConfig, modalTitle } from '../../../data/const'
import moment from 'moment'
import clip from '@/utils/clipboard'
import { antiCheatingSoftwareLink } from '@/data/const.js'

export default {
  name: 'ExamineeConfirm',
  data() {
    return {
      info: {},
      exam: {},
      loading: false,
      id: '',
      isTimeOut: false,
      isError: false,
      antiCheatingSoftwareLink: antiCheatingSoftwareLink,
      baseUrl: process.env.VUE_APP_URL_PREFIX
    }
  },
  computed: {
    btnIsDisabled() {
      let res = true
      // if (this.info && this.info.state) {
      //   if (this.info.state === 1 && !this.isTimeOut) {
      res = false
      //   }
      // }
      return res
    },
    confirmEndTimeStr() {
      let res = ''
      // console.log('confirmStr', this.exam)
      if (this.exam && this.exam.confirmEndTime) {
        const confirmEndTimestamp = moment(
          this.exam.confirmEndTime,
          'YYYY-MM-DD HH:mm'
        )
        res = `${confirmEndTimestamp.format('YYYY年MM月DD日')}（${this.getWeek(
          confirmEndTimestamp
        )}）${confirmEndTimestamp.format('HH:mm')}`
      }
      return res
    }
  },
  watch: {
    // exam: {
    //   handler(val) {
    //     this.isTimeOut = false
    //     if (val && val.confirmEndTime) {
    //       const confirmEndTimestamp = moment(
    //         this.exam.confirmEndTime,
    //         'YYYY-MM-DD HH:mm'
    //       )
    //       const now = moment()
    //       if (confirmEndTimestamp < now) {
    //         this.isTimeOut = true
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id
      this.fetchInfo(this.id)
    }
    (function(desW) {
      function computeFont() {
        const winW = document.documentElement.clientWidth
        if (winW >= 750) {
          document.documentElement.style.fontSize = '100px'
          return
        }
        document.documentElement.style.fontSize = (winW / desW) * 100 + 'px'
      }
      computeFont()
      window.addEventListener('resize', computeFont)
      window.addEventListener('orientationchange', computeFont)
    })(750)
  },
  methods: {
    // 复制考试链接
    copy(e, text) {
      clip(text, e)
    },
    // 到指定位置
    goAnchor(selector) {
      const anchor = document.getElementById(selector)
      const to = anchor.offsetTop
      scrollTo(to, 600)
    },
    getWeek(date) {
      // 参数时间戳
      const week = moment(date).day()
      switch (week) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 0:
          return '周日'
      }
    },
    async fetchInfo(id) {
      this.loading = true
      const res = await fetchstudentconfirmconfirmPrepare({ id })
      this.loading = false
      if (res && res.data) {
        this.info = res.data
        if (res.data.exam) {
          this.exam = res.data.exam
        }
      } else {
        this.isError = true
        // this.$notify({
        //   title: '失败',
        //   message: '发生了错误，请稍后重试',
        //   type: 'error'
        // })
      }
    },
    async submit(state) {
      const queryVal = {
        id: this.id,
        state
      }
      this.loading = true
      const res = await fetchstudentconfirmconfirm(queryVal)
      this.loading = false
      if (res) {
        this.fetchInfo(this.id)
      } else {
        this.$notify({
          title: '失败',
          message: '发生了错误，请稍后重试',
          type: 'error'
        })
      }
    },
    joinTest() {
      this.$confirm('确定是否要参加本次考试？', modalTitle, {
        ...modalConfig,
        center: true,
        showClose: false,
        customClass: 'message-box-dir',
        type: 'warning'
      }).then(() => {
        this.submit(2)
      })
    },
    rejectTest() {
      this.$confirm('您确定要放弃参加本次考试？', modalTitle, {
        ...modalConfig,
        showClose: false,
        customClass: 'message-box-dir',
        center: true,
        type: 'warning'
      }).then(() => {
        this.submit(3)
      })
    },
    later() {
      this.goAnchor('introduce')
    },
    changeCurrent() {
      if (this.currentStep < 3) {
        this.currentStep = this.currentStep + 1
      } else {
        this.currentStep = 0
      }
    },
    back() {
      const { id = '' } = this.$route.params
      this.$router.push({ name: 'LoginExam', params: { id }})
    }
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.34rem;
  max-width: 750px;
  padding: 0.35rem 0 1rem 0;
  margin: auto;
}

.title {
  margin-bottom: 0.35rem;
  text-align: center;
}
.sub-title {
  margin-bottom: 0.35rem;
}

.icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-content {
  background: #cd2b1e;
  color: white;
  border-radius: 3rem;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.1rem;
}

.el-icon-user-solid {
  margin-left: 0.09rem;
  margin-top: 0.05rem;
}
.el-icon-s-order {
  margin-left: 0.09rem;
  margin-top: 0.05rem;
}
.el-icon-document {
  margin-left: 0.09rem;
  margin-top: 0.05rem;
}

.blue-text {
  color: #125ab7;
}
.red-text {
  color: #cd2b1e;
}

.work-btns {
  height: 1rem;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgb(232 232 232 / 34%);
  text-align: center;
  padding: 0.1rem 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 750px;
}

.work-btn {
  background: #cd2b1e;
  border-color: #cd2b1e;
}
.work-btn:hover,
.work-btn:focus {
  background: #cd2b1e;
  border-color: #cd2b1e;
}
.work-btn-disabled {
  background: #e89d97;
  border-color: #e89d97;
}
.work-btn-disabled:focus,
.work-btn-disabled:hover {
  background: #e89d97;
  border-color: #e89d97;
}
.indent-text {
  text-indent: 2em;
}

::v-deep .el-card__body {
  padding: 0 20px;
}

.box-header {
  position: relative;
  padding-left: 0.14rem;
}

.box-header::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content: '';
  width: 0.05rem;
  height: 0.4rem;
  background-color: #cd2b1e;
}
.imgBody {
  padding: 1rem 0 0 1rem;
  width: 7rem;
}
.img404 {
  width: 6rem;
  height: 4rem;
}
.err-notice {
  padding: 1rem;
  text-indent: 2%;
}
</style>
<style>
.message-box-dir {
  width: 6rem;
}
</style>
