var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-descriptions",
        { attrs: { title: "基本信息", colon: false, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "版本" } }, [
            _vm._v(" V1.0.0 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "软件语言" } }, [
            _vm._v(" 简体中文 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "系统兼容" } }, [
            _vm._v(" 支持 Mac OS 11.0及以上版本操作系统 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "大小" } }, [
            _vm._v(" 185 KB "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "更新时间" } }, [
            _vm._v(" 2024/06/25 "),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "其他" } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c("el-tag", { staticClass: "right_10" }, [
                    _vm._v("安全无毒"),
                  ]),
                  _c("el-tag", { attrs: { type: "success" } }, [
                    _vm._v("无插件"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content_wrap" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/CheatChecker.zip",
              target: "_blank",
            },
          },
          [
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v("点此下载"),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content_wrap" }, [
        _c("div", { staticClass: "content_tit" }, [_vm._v("安装说明")]),
        _c("div", { staticClass: "content_item" }, [
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 1. 将压缩包下载到本地解压后，鼠标双击解压后的文件，即可完成安装并启动。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box" },
            [
              _c("el-image", {
                attrs: { src: _vm.caozuoImg.az_1, fit: "fill" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "content_item" }, [
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 2. 部分用户在安装后运行应用程序时可能会出现如下图所示的无法打开情况。这是MAC系统启用了新的安全机制，默认只信任 App Store 下载的软件和拥有开发者ID签名的应用程序。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                attrs: { src: _vm.caozuoImg.az_2, fit: "fill" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 您可以通过更改系统设置解决该问题：系统设置 -> 隐私与安全性 -> 安全性，然后选中在相关提示中选择 仍要打开 ，如下图： "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                attrs: { src: _vm.caozuoImg.az_3, fit: "fill" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 如果没有这个选项，打开终端,输入:sudo spctl --master-disable 后回车。然后在 password 钥匙图标后面输入本机电脑解锁密码（输入时不显示密码）， 回车后进入系统偏好设置进行相关设置即可。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                attrs: { src: _vm.caozuoImg.az_4, fit: "fill" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }