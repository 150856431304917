<template>
  <!-- <div class="wrap-bg"> -->
  <!-- <div class="line"/> -->
  <div class="wrap">
    <h2 class="title">试卷名称 ：{{ examInfo.data.title }} {{ current }}</h2>
    <el-row v-for="(item, index) in examList" :key="item.id" :gutter="20" class="grid-item">
      <el-col :span="8"><div class="grid-content bg-purple">{{ item.title }}</div></el-col>
      <el-col :span="8"><div class="grid-content bg-purple">总{{ item.quCount }}题/共{{ item.totalScore }}分</div></el-col>
      <el-col :span="8"><div class="grid-content bg-purple"><el-button :disabled="current<index" :type="current==index?'primary':''" @click="startExam(index)">{{ current > index ? '修改试题' : '开始答题' }}</el-button></div></el-col>
    </el-row>
    <h2 class="title"><el-button :type="current==examList.length?'primary':''" @click="handDirect">立即交卷</el-button></h2>
  </div>

  <!-- </div> -->
</template>

<script>
import { handExam, paperDetail } from '@/api/paper/exam'

import { mapGetters } from 'vuex'

export default {
  props: {
    examInfo: {
      type: Object,
      default: {}
    },
    examList: {
      type: Array,
      default: []
    },
    current: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageLoading: false
    }
  },

  computed: {
    ...mapGetters(['realName', 'name', 'mobile'])
  },

  watch: {
  },
  created(){
  },
  beforeDestroy() {
  },
  methods: {
    startExam(index) {
      const newArr = this.examList[index]
      const info = this.examInfo
      info.data.groupList = []
      info.data.groupList.push(newArr)
      const fillData = info
      // const g = JSON.stringify(fillData)
      this.$emit('fillSection',fillData,this.current)
      // this.$router.push(`/web/exam/start/${this.paperId}?group=${g}&gropcurrent=${this.current}`)
    },
    // 交卷操作
    handDirect() {
      const that = this

      const na = that.countNotAnswered()
      let msg = '确认要交卷吗？'

      if (na > 0) {
        msg = '您还有' + na + '题未作答，确认要交卷吗?'
      }

      that
        .$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          that.handWithEx(0)
        })
    },
    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered() {
      let na = 0
      // 判断全部未答
      this.examInfo.data.groupList.forEach(function(item) {
        item.quList.forEach(function(qu) {
          if (!qu.answered) {
            na += 1
          }
        })
      })
      return na
    },
    // 异常行为交卷
    handWithEx(exState) {
      // 打开
      this.pageLoading = true

      // 交卷
      handExam({ id: this.examInfo.data.id, exState: exState })
        .then((res) => {
          if (res.success) {
            setTimeout(() => {
              this.$router.push({
                name: 'ShowExam',
                params: { id: this.examInfo.data.id }
              })
            }, 500)
          }


          this.pageLoading = false
        })
        .catch(() => {
          this.pageLoading = false
        })
    }
  }
}
</script>
<style scoped>
.wrap-bg{
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to bottom, #4ea3f2 40%, #f7f7f1 60%);
}
.wrap{
  width: 100%;
  /* margin: 100px auto; */
  padding: 10px;
  /* box-shadow: 0 0 10px #4ea3f2;
  border-radius: 5px;
  background-color: #fff; */
}
.line{
  height: 1px;
}
.title{
  margin: 80px auto;
  width: 100%;
  text-align: center;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
      margin-bottom: 0;
  }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    /* background: #d3dce6; */
    text-align: center;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
