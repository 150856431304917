var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "选择考试",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        on: { "select-changed": _vm.handleSelected },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "搜索考试名称" },
                          model: {
                            value: _vm.listQuery.params.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery.params, "title", $$v)
                            },
                            expression: "listQuery.params.title",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            staticStyle: { float: "right" },
                            attrs: { type: "primary" },
                            on: { click: _vm.handleConfirm },
                          },
                          [_vm._v(_vm._s(_vm.selectedLabel))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "考试名称", align: "center", prop: "title" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "考试类型",
                    align: "center",
                    prop: "examType_dictText",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }