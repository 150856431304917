import { post } from '@/utils/request'

/**
 * 删除错题
 * @param data
 */
export function deleteQu(ids) {
  return post('/api/user/wrong-book/delete', { ids: ids })
}

