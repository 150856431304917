<template>
  <div>
    <div class="exam-ps">
      <!-- <el-alert
      title="错误提示的文案"
      type="error"
      style="margin-bottom: 10px;"
      effect="dark"/> -->
      <div v-if="paperData && paperData.id" class="user-box" style="margin-bottom: 10px;">
        {{ paperData.title }}
      </div>

      <el-row :gutter="20">
        <el-col :span="4">
          <el-card class="lay-box">
            <div v-if="isCam" style="height: 150px; margin-bottom: 10px">
              <exam-cam
                :height="150"
                :paper-id="paperId"
                :exam-id="paperData.examId"
                :check-tool-show="checkToolShow"
                :capture-p="captureP"
                @captureSucess="captureSucess"
                @started="onVideoStarted"
                @checkReady="checkReady"
              />
            </div>
            <div v-if="paperData && paperData.id && !isSection">
              <exam-cam-normal
                v-if="
                  paperData.examType === 1 &&
                    paperData.camOn &&
                    paperData.camInterval > 0
                "
                :cam-on="paperData.camOn"
                :cam-interval="paperData.camInterval"
                :paper-id="paperData.id"
                @break="handleBreak"
              />
              <template v-if="videoStarted">
                <div v-for="type in paperData.groupList" :key="type.id">
                  <div class="title">
                    {{ type.title }}
                    <span
                      style="float: right; font-size: 12px"
                    >总{{ type.quCount }}题/共{{ type.totalScore }}分</span
                    >
                  </div>

                  <div class="card-box">
                    <div
                      v-for="item in type.quList"
                      :key="item.id"
                      :class="{
                        'sub-card': item.subList && item.subList.length > 0,
                      }"
                    >
                      <div
                        v-if="!item.subList || item.subList.length === 0"
                        class="item"
                        @click="
                          fetchQuData(
                            item.quId,
                            '',
                            queryforbidBackOn == 1
                              ? false
                              : queryforbidBackOn == 2
                                ? item.answered
                                : true
                          )
                        "
                      >
                        <div
                          :class="{
                            num: true,
                            right: item.answered && currentId !== item.quId,
                            current: currentId === item.quId,
                          }"
                        >
                          {{ item.sort }}
                        </div>
                        <div class="flag">
                          <i
                            v-if="item.mark"
                            class="el-icon-s-flag"
                            style="color: #ff0000"
                          />
                        </div>
                      </div>
                      <div
                        v-for="sub in item.subList"
                        v-else
                        :key="sub.id"
                        class="item"
                        @click="
                          fetchQuData(
                            item.quId,
                            sub.quId,
                            queryforbidBackOn == 1
                              ? false
                              : queryforbidBackOn == 2
                                ? item.answered
                                : true
                          )
                        "
                      >
                        <div
                          :class="{
                            num: true,
                            right: sub.answered && currentSub !== sub.quId,
                            current: currentSub === sub.quId,
                          }"
                        >
                          {{ item.sort }}.{{ sub.sort }}
                        </div>
                        <div class="flag">
                          <i
                            v-if="sub.mark"
                            class="el-icon-s-flag"
                            style="color: #ff0000"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <el-divider />
              <div>
                <div class="Living-example-box">
                  <div class="tag-box">
                    <div class="tag" />
                    <div class="tag-tit">未做</div>
                  </div>
                  <div class="tag-box">
                    <div class="tag tblue" />
                    <div class="tag-tit">已做</div>
                  </div>
                  <div class="tag-box">
                    <div class="tag tyellow" />
                    <div class="tag-tit">选中</div>
                  </div>
                  <div class="tag-box">
                    <div><i class="el-icon-s-flag tred" /></div>
                    <div class="tag-tit">标记</div>
                  </div>
                </div>
                <!-- <exam-cam-rtc
              v-if="paperData.examType===2"
              :cam-on="paperData.camOn"
              :cam-interval="paperData.camInterval"
              :exam-id="paperData.examId"
              :paper-id="paperData.id"
              @break="handleBreak"
            /> -->

                <!-- <div style="height: 150px;">
              <exam-cam :height="150" :paper-id="paperId"/>
            </div> -->

                <!-- <div v-if="(paperData.examType===1 && paperData.camOn && paperData.camInterval > 0) || paperData.examType===2" class="cam-box">
              <strong>监控中</strong>
              确保您的正脸在摄像头范围内！
            </div> -->
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="17">
          <el-card
            v-if="videoStarted && isSection"
            id="exam-body"
            class="lay-box"
            @scroll="bodyScroll"
          >
            <div>
              <AnswerBySection
                :exam-info="examInfo"
                :exam-list="examList"
                :current="gropcurrent"
                @fillSection="fillSection"
              />
            </div>
          </el-card>
          <div v-else>
            <el-card
              v-if="paperData && paperData.id && videoStarted"
              id="exam-body"
              class="lay-box"
              @scroll="bodyScroll"
            >
              <div v-if="isRefresh">
                <ExamStepMode
                  v-if="
                    paperData.answerMode === 1 || paperData.answerMode === 3
                  "
                  ref="examStepMode"
                  :paper="paperData"
                  @fill="handleAnswer"
                />

                <ExamFullMode
                  v-else
                  ref="examFullMode"
                  :paper="paperData"
                  @fill="handleAnswer"
                />
              </div>
            </el-card>
            <el-card v-else class="lay-box">
              <div>
                请先按页面提示启动监考视频，如果页面没有提示，请刷新后重试！
              </div>
              <div class="loading">加载中.......</div>
            </el-card>
          </div>
        </el-col>

        <el-col :span="3">
          <el-card class="lay-box">
            <div v-if="paperData && paperData.id" class="user-box">
              {{ paperData.userId_dictText }}
            </div>
            <div v-if="isCam" style="margin: 10px 0">
              <div v-show="firstStatus == 3" class="check-box">
                <i class="el-icon-camera-solid check-log" />主摄像头监控中
              </div>
              <div v-show="firstStatus == 2" class="check-box2">
                <i class="el-icon-camera-solid check-log" />主摄像头监控失败
              </div>
              <div v-show="secondStatus == 3" class="check-box">
                <i class="el-icon-mobile-phone check-log" />手机监控中
              </div>
              <div v-show="secondStatus == 2" class="check-box2">
                <i class="el-icon-mobile-phone check-log" />手机监控失败
              </div>
              <div v-show="thirdStatus == 3" class="check-box">
                <i class="el-icon-s-platform check-log" />屏幕监控中
              </div>
              <div v-show="thirdStatus == 2" class="check-box2">
                <i class="el-icon-s-platform check-log" />屏幕监控失败
              </div>
              <div v-show="checkToolShow=='true'&&checkToolDialog == 1" class="check-box2">
                <i class="el-icon-s-platform check-log" />防作弊工具检测未启动
              </div>
              <div v-show="checkToolShow=='true'&&checkToolDialog == 2" class="check-box">
                <i class="el-icon-s-platform check-log" />防作弊工具检测通过
              </div>
              <div v-show="checkToolShow=='true'&&checkToolDialog == 3" class="check-box2">
                <i class="el-icon-s-platform check-log" />防作弊工具检测未通过
              </div>
            </div>
            <el-row
              v-if="paperData && paperData.id && !isSection"
              :gutter="10"
              style="text-align: center; line-height: 30px"
            >
              <el-col :span="24"> 剩余时间 </el-col>
              <el-col :span="24">
                <exam-timer
                  v-model="paperData.leftSeconds"
                  @timeout="handWithEx(0)"
                />
              </el-col>
              <el-col :span="24">
                <el-divider />
              </el-col>
              <el-col v-if="videoStarted" :span="24">
                <el-button
                  type="warning" @click="doHandler(0)"
                >提交试卷</el-button
                >
              </el-col>

              <el-col
                v-if="paperData.actionOn && paperData.actionInterval > 0"
                :span="24"
              >
                <el-divider />
                <action-checker
                  :action-on="paperData.actionOn"
                  :action-interval="paperData.actionInterval"
                  :trigger="actionTrigger"
                  @break="doHandler(4)"
                />
                秒内无任何操作将自动交卷！
              </el-col>

              <el-col v-if="videoStarted" :span="24">
                <el-divider />
              </el-col>

              <el-col
                v-if="
                  videoStarted &&
                    (queryforbidBackOn != 1 || queryforbidBackOn != '1')
                "
                :span="24"
              >
                <el-button
                  :disabled="!showPrevious"
                  icon="el-icon-back"
                  @click="handleNext(-1)"
                >上一题</el-button
                >
              </el-col>
              <el-col v-if="videoStarted" :span="24" style="padding-top: 20px">
                <el-button
                  :disabled="!showNext"
                  type="primary"
                  @click="handleNext(1)"
                >下一题<i
                  class="el-icon-right el-icon--right"
                /></el-button>
              </el-col>
              <el-col
                v-if="videoStarted && !showNext && groupShow"
                :span="24"
                style="padding-top: 20px"
              >
                <el-button
                  @click="nextchapter"
                >下一章<i
                  class="el-icon-right el-icon--right"
                /></el-button>
              </el-col>
            </el-row>
            <div class="tool-box">
              <el-popover placement="right" width="500" trigger="click">
                <div>
                  <calculator />
                </div>
                <el-button
                  slot="reference"
                  class="tool-btn"
                  size="mini"
                  icon="el-icon-mobile-phone"
                >计算</el-button
                >
              </el-popover>
              <el-button
                class="tool-btn"
                size="mini"
                icon="el-icon-chat-round"
                @click="handleConsultModal"
              >咨询</el-button
              >
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 页面离开检测 -->
      <leave-checker
        v-if="
          paperData &&
            paperData.id &&
            paperData.leaveOn &&
            paperData.leaveCount > 0
        "
        :leave-on="paperData.leaveOn"
        :leave-check="paperData.leaveCheck"
        :leave-count="paperData.leaveCount"
        :paper-id="paperData.id"
        :exam-id="paperData.examId"
        :check-tool-dialog="checkToolDialog"
        @break="handleBreak('因切屏次数超限，系统已强制交卷！')"
      />

      <!-- 在线状态 -->
      <!-- <exam-socket :exam-id="paperData.examId" platform="pc" /> -->

      <!-- 动作检测 -->
      <!--    <event-control />-->
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      title="考试须知"
      width="50%"
      center
    >
      <div class="notice-tit">
        <span>为保证考试公平公正，您务必遵守以下规则:</span>
      </div>
      <div class="notice-box">
        <span>
          1.监考官会通过监控大屏全程观察考生举止行为，严禁使用手机或平板电脑查阅信息，严禁查看考试辅助书籍等材料</span
          >
        <span>
          2.根据系统要求开启监控设备，考试中严禁有其他人员陪同，例如家人、同事、朋友等</span
          >
        <span>
          3.考试中禁止替考，跳出考试页面，接打电话，左顾右盼，离开座位，不要佩敷耳机、帽子，口罩，头发不能遮盖耳朵不要遮挡监控设备，衣着要得体</span
          >
        <span> 4.考官会随机抽查考生座位周围环境，请配合考官操作</span>
        <span>
          5.系统将在考试过程中进行抓拍验证，请保证脸部正面始终处于主摄像头中</span
          >
      </div>
      <div class="notice-tit">
        <span>重要提示:</span>
      </div>
      <div class="notice-box">
        <span> 1.任意作整行为一经核实，将取消资格</span>
        <span>
          2.考生的个人信息、抓拍照片、答题信息将受到严格保护，不会向任何第三方透露</span
          >
        <span> 3.禁止拍摄、记录试题，泄露考试试题内容属于侵权行为</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleOK">我知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="在线咨询"
      width="40%"
    >
      <div class="hot-number">热线电话：400-123-1234</div>
      <el-button
        class="common-problem-btn"
        type="text"
        @click="problemhandleOpene"
      >常见问题<i
        class="el-icon-question el-icon--right"
      /></el-button>
      <div class="chat-wrap">
        <div class="chat-box">
          <div v-for="(item, index) in newsList" :key="index">
            <div v-show="item.type == 1" class="chat-left">
              <el-avatar style="margin-right: 10px"> 管理 </el-avatar>
              <div class="chat-content">{{ item.news }}</div>
            </div>
            <div v-show="item.type == 2" class="chat-right">
              <el-avatar style="margin-left: 10px"> 我 </el-avatar>
              <div class="chat-content">{{ item.news }}</div>
            </div>
          </div>
        </div>
        <div class="chat-Controls">
          <!-- <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.title"
                :value="item.value"
              />
            </el-select>
          </div> -->
          <div class="chat-input">
            <el-input
              :rows="4"
              v-model="textarea"
              type="textarea"
              placeholder="请输入内容"
            />
            <el-button
              class="chat-btn" type="primary"
              @click="sendMsg"
            >发送</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="problemVisible"
      :before-close="problemhandleClose"
      title="常见问题回答"
      width="70%"
      center
    >
      <!-- noticeLoading -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-loading="noticeLoading"
          v-for="item in options"
          :key="item.value"
          :name="item.value"
          :label="item.title"
        >
          <div v-if="noticeList.length > 0" class="notice-wrap">
            <div
              v-for="(notice, index) in noticeList"
              :key="index"
              class="notice-item"
            >
              <div class="notice-q">
                <i
                  class="el-icon-question"
                  style="color: #d17979; margin-right: 10px; line-height: 25px"
                />
                <span>问:&nbsp;&nbsp;{{ notice.question }}</span>
              </div>
              <div class="notice-a">
                <i
                  class="el-icon-info"
                  style="color: #5c9df7; margin-right: 10px; line-height: 25px"
                />
                <span>答:&nbsp;&nbsp;{{ notice.answer }}</span>
              </div>
            </div>
          </div>
          <el-empty v-else :image-size="200" />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { getToken } from '@/utils/auth'
import { handExam, paperDetail, fillAnswer } from '@/api/paper/exam'
import { httpCreateById, httpQuestion } from '@/api/exam/exam'
import { fetchTree } from '@/api/sys/dict/value'
import watermark from '@/utils/watermark'
import screenfull from 'screenfull'
import { mapGetters } from 'vuex'
import { checkTool } from './mixin.js'

export default {
  mixins: [checkTool],
  data() {
    return {
      // 上下题
      showPrevious: false,
      showNext: true,
      allQuIds: [],
      // 试卷ID
      paperId: '',
      // 试卷信息
      paperData: {},
      // 动作检测
      actionTrigger: 0,
      currentId: null,
      currentSub: null,
      // 监考视频是否都已启动
      videoStarted: true,
      timer: null,
      isCam: false,
      examId: null,
      firstStatus: 1,
      secondStatus: 1,
      thirdStatus: 1,
      queryforbidBackOn: 0,
      countdownOn: true,
      centerDialogVisible: false,
      dialogVisible: false,
      problemVisible: false,
      options: [],
      value: '',
      textarea: null,
      newsList: [],
      chatList: [],
      activeName: '1',
      noticeList: [],
      noticeLoading: false,
      captureT: null,
      captureP: false,
      groupShow: false,
      gropcurrent: 0,
      isSection: false,
      examList: [],
      examInfo: {},
      isRefresh: false,
      checkToolShow: 'false'
    }
  },

  computed: {
    ...mapGetters(['realName', 'name', 'mobile'])
  },

  watch: {
    currentId: {
      handler(val) {
        // 刷新下题按钮
        this.refreshNext(val)
      }
    }
  },
  created() {
    // if (this.$route.query.countdownOn) {
    //   this.countdownOn = !!this.$route.query.countdownOn
    // }
    console.log(this.$route.query, '路由是')
    if (this.$route.query.isvideo) {
      this.paperId = this.$route.params.id
      this.isCam = this.$route.query.isvideo
      this.examId = this.$route.query.examId
      if (this.$route.query.checkToolShow) {
        this.checkToolShow = `${this.$route.query.checkToolShow}`
      }
    } else {
      this.paperId = this.$route.params.id
      this.fetchData(this.paperId)
    }
    // 启动聊天室
    this.startChat()
  },
  beforeDestroy() {
    // 清理页面
    this.clearPage()
    clearInterval(this.captureT)
    this.stopChat()
  },
  methods: {
    // 常见问题分类字典
    async getfetchTree() {
      const subData = {
        dicCode: 'question_category'
      }
      const res = await fetchTree(subData)

      if (res.success) {
        this.options = res.data
      } else {
        this.options = []
      }
    },
    // 常见问题列表
    async getQuestion(type) {
      this.noticeLoading = true
      const subData = {
        current: 1,
        size: 20,
        params: {
          categoryId: type
        }
      }
      const res = await httpQuestion(subData)
      this.noticeLoading = false
      console.log(res, '常见问题')
      if (res.success) {
        this.noticeList = res.data.records
      } else {
        this.noticeList = []
      }
    },
    // 填充页面试卷
    fillParper(res) {
      // 试卷内容
      this.isRefresh = false
      setTimeout(() => {
        this.isRefresh = true
      }, 100)
      this.paperData = res.data
      this.countdownOn = this.paperData.countdownOn
      // 顺序答题模式是 1 禁止回退,2 按试题顺序
      this.queryforbidBackOn = this.paperData.forbidBackOn
      // this.queryforbidBackOn = 2
      // 如果是三路视频监考，则 监考视频是否都已启动 设定为false，其他设定为true
      if (this.paperData.examType === 2) {
        this.videoStarted = false
      } else {
        this.videoStarted = true
      }

      // 已经考完了，不允许
      if (this.paperData.handState !== 0) {
        this.$router.push({
          name: 'ShowExam',
          params: { id: this.paperData.id }
        })
        return
      }

      this.allQuIds = []

      // 全部题目
      for (let i = 0; i < this.paperData.groupList.length; i++) {
        const quList = this.paperData.groupList[i].quList
        for (let j = 0; j < quList.length; j++) {
          this.allQuIds.push(quList[j].quId)
        }
      }
      // 是否异常
      this.emptyCheck()
      // 加载第一题
      this.$nextTick(() => {
        this.fetchQuData(this.allQuIds[0])
      })

      // 水印开关
      if (this.paperData.waterMark) {
        // 出现在题干部分
        const dom = document.querySelector('#exam-body')
        watermark.set(dom, `${this.realName} ${this.mobile || this.name}`)
      }
      this.videoStarted = true
      if (!this.$refs.examStepMode) {
        const modeTimer = setInterval(() => {
          if (this.$refs.examStepMode) {
            this.fetchQuData(this.allQuIds[0])
            clearInterval(modeTimer)
          }
        }, 500)
      }
    },
    // 试卷详情
    fetchData(id) {
      const params = { id: id }
      paperDetail(params).then((res) => {
        if (this.$route.query.isSection) {
          this.isSection = true
          this.isSection = true
          this.examInfo = res
          this.examList = res.data.groupList
        } else {
          this.fillParper(res)
        }
      })
    },
    async createParper() {
      const subData = {
        paperId: this.paperId,
        examId: this.examId
      }
      const res = await httpCreateById(subData)
      if (res.success) {
        console.log(res, res.data.camOn, 'res是什么')
        if (this.$route.query.isSection) {
          this.isSection = true
          this.examInfo = res
          this.examList = res.data.groupList
        } else {
          if (res.data.camOn) {
            this.captureT = setInterval(() => {
              this.captureP = true
            }, Number(res.data.camInterval) * 60000)
            console.log(res.data.faceCheck, res.data.camInterval, '我执行了么')
            // this.captureT = setInterval(() => {
            //   this.captureP = true
            // }, 10000)
          }
          this.fillParper(res)
        }
      }
    },
    captureSucess(flag) {
      this.captureP = false
    },
    onVideoStarted(e) {
      console.log(
        '@@@@@@@@@@@@@@ 收到视频启动的触发消息 connected',
        e.started,
        e.paperId
      )
      if (e && e.started) {
        this.centerDialogVisible = true
        // this.createParper()
      }
    },
    handleOK() {
      this.centerDialogVisible = false
      this.createParper()
    },
    handleCancel() {
      this.$router.go(-1)
      this.centerDialogVisible = false
    },

    // 填充答案回调
    handleAnswer(quData) {
      // 触发变化
      this.actionTrigger = new Date().getTime()

      this.currentId = quData.quId

      // 填充答案
      fillAnswer(quData).then((res) => {
        for (let i = 0; i < this.paperData.groupList.length; i++) {
          const quList = this.paperData.groupList[i].quList
          // 普通题目
          this.fillCard(quList, res.data)
          // 组合题目
          const subList = res.data.subList
          if (subList && subList.length > 0) {
            for (let j = 0; j < subList.length; j++) {
              this.fillCard(quList, subList[j])
            }
          }
        }
        if (!res.success && res.code == 1) {
          this.handleBreak(res.msg)
        }
      })
    },

    /**
     * 填充答题卡
     * @param quList
     * @param res
     */
    fillCard(quList, res) {
      if (!quList || quList.length === 0) {
        return
      }

      for (let i = 0; i < quList.length; i++) {
        const qu = quList[i]
        if (qu.quId === res.quId) {
          qu.answered = res.fill
          qu.mark = res.mark
          return
        }

        // 递归
        const subList = qu.subList
        this.fillCard(subList, res)
      }
    },
    checkAnswer(id) {
      let falg = false
      this.paperData.groupList.forEach((item) => {
        item.quList.forEach((quItem) => {
          // console.log(quItem, currentId, '试题是')
          if (id == quItem.quId) {
            console.log(quItem.answered, '作答了么')
            falg = quItem.answered
          }
        })
      })
      return falg
    },
    // 上一题下一题
    handleNext(num) {
      let index = 0
      for (let i = 0; i < this.allQuIds.length; i++) {
        if (this.allQuIds[i] === this.currentId) {
          index = i
        }
      }
      const currentId = this.allQuIds[index]
      index += num
      const nextId = this.allQuIds[index]

      // 开启顺序作答
      if (this.queryforbidBackOn > 0) {
        // 下一题前判断当前是否答题
        if (num > 0) {
          const falg = this.checkAnswer(currentId)
          if (falg) {
            this.fetchQuData(nextId)
          } else {
            this.$confirm('当前题还未作答，确定要跳到下一题？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.fetchQuData(nextId)
            })
          }
        } else {
          // 判断上一题是否答题,未达题不让点击
          const falg = this.checkAnswer(nextId)
          if (falg) {
            this.fetchQuData(nextId)
          }
        }
      } else {
        this.fetchQuData(nextId)
      }
    },

    // 刷新上下题按钮
    refreshNext(val) {
      if (val === this.allQuIds[0]) {
        this.showPrevious = false
      } else {
        this.showPrevious = true
      }
      // 最后一个索引
      const last = this.allQuIds.length - 1
      if (val === this.allQuIds[last]) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },

    // 强制交卷后操作
    handleBreak(msg) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'error'
      }).then(() => {
        // 出现异常情况
        this.$router.push({
          name: 'ShowExam',
          params: { id: this.paperData.id }
        })
      })
    },

    // 切换题目
    fetchQuData(quId, subId, isfetchQu = true) {
      if (isfetchQu) {
        this.currentId = quId
        this.currentSub = subId

        // 触发变化
        this.actionTrigger = new Date().getTime()

        // 加载新的题目
        if (
          (this.paperData.answerMode === 1 ||
            this.paperData.answerMode === 3) &&
          this.$refs.examStepMode
        ) {
          this.$refs.examStepMode.fetchQuData(quId, subId)
        }

        if (this.paperData.answerMode === 2 && this.$refs.examFullMode) {
          this.$refs.examFullMode.focus(subId || quId)
        }
      }
    },

    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered() {
      let na = 0
      // 判断全部未答
      this.paperData.groupList.forEach(function(item) {
        item.quList.forEach(function(qu) {
          if (!qu.answered) {
            na += 1
          }
        })
      })
      return na
    },

    // 异常行为交卷
    handWithEx(exState) {
      // 打开
      this.pageLoading = true

      // 交卷
      handExam({ id: this.paperId, exState: exState })
        .then((res) => {
          // 清理页面
          this.clearPage()
          if (res.success) {
            setTimeout(() => {
              this.$router.push({
                name: 'ShowExam',
                params: { id: this.paperId }
              })
            }, 500)
          }

          this.pageLoading = false
        })
        .catch(() => {
          this.pageLoading = false
          setTimeout(() => {
            this.$router.push({
              name: 'ShowExam',
              params: { id: this.paperId }
            })
          }, 500)
        })
    },

    // 交卷统一入口
    doHandler(exState) {
      console.log('异常信息...')
      if (!exState || exState === 0) {
        this.handDirect()
      } else {
        this.handWithEx(exState)
      }
    },

    // 交卷操作
    handDirect() {
      const that = this

      const na = that.countNotAnswered()
      let msg = '确认要交卷吗？'

      if (na > 0) {
        msg = '您还有' + na + '题未作答，确认要交卷吗?'
      }

      that
        .$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          that.handWithEx(0)
        })
    },

    // 校验试卷是否异常
    emptyCheck() {
      if (this.allQuIds.length === 0) {
        this.$confirm('试卷内容出现问题，无法继续考试！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          // 出现异常情况
          this.$router.push('/web/index?break')
        })
      }
    },

    clearPage() {
      // 移除水印
      watermark.remove()
      // 退出全屏
      if (screenfull.isFullscreen) {
        screenfull.toggle()
      }
    },
    bodyScroll() {
      console.log('滑动11')
    },
    checkReady(e) {
      if (e.id == 'pantResponse') {
        if (e.response == 'accepted') {
          this.firstStatus = e.facade ? 3 : 2
          this.secondStatus = e.side ? 3 : 2
          this.thirdStatus = e.desktop ? 3 : 2
        }
      } else {
        if (e.type == 'facade') {
          this.firstStatus = e.status
        }
        if (e.type == 'side') {
          this.secondStatus = e.status
        }
        if (e.type == 'desktop') {
          this.thirdStatus = e.status
        }
      }
    },
    handleConsultModal() {
      this.dialogVisible = true
      this.getfetchTree()
    },
    handleClose() {
      this.dialogVisible = false
    },
    problemhandleOpene() {
      this.problemVisible = true
      this.getQuestion('1')
    },
    problemhandleClose() {
      this.problemVisible = false
    },
    handleClick(tab, event) {
      console.log(tab.name, tab.index, event, 'jjjjjj')
      this.getQuestion(tab.name)
    },
    nextchapter() {
      const that = this

      const na = that.countNotAnswered()
      let msg = '确认要切换下一章吗？'

      if (na > 0) {
        msg = '您还有' + na + '题未作答，确认要切换下一章吗?'
      }

      that
        .$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          this.isSection = true
          this.gropcurrent = Number(this.gropcurrent) + 1
          console.log(this.gropcurrent, '页码是什么')

          // this.$router.push(`/web/answer/section/${this.paperId}?current=${num}`)
        })
    },
    fillSection(groupObj, gropcurrent) {
      this.groupShow = true
      this.isSection = false
      this.gropcurrent = gropcurrent
      console.log(groupObj, '要填充的题目是')
      this.fillParper(groupObj)
    },

    sendMsg() {
      if (this.textarea != '') {
        const content = this.textarea
        this.newsList.push({
          type: 2,
          news: content
        })
        this.textarea = ''
        const message = {
          content: content,
          examId: this.examId,
          paperId: this.paperId,
          type: 'CHAT'
        }
        this.stompClient.send(
          '/app/chat.send.to.invigilator',
          {},
          JSON.stringify(message)
        )
      }
    },
    onMessageReceived(message) {
      console.log('message received:', message.body)

      const { body } = message
      if (body) {
        const msg = JSON.parse(body)
        this.newsList.push({
          type: 1,
          news: msg.content,
          name: msg.senderName
        })
      }
    },
    onChatConnected(frame) {
      console.log('>>> chat connected: ', frame)
      this.subscription = this.stompClient.subscribe(
        '/user/topic/msg',
        this.onMessageReceived,
        (error) => {
          console.log(error)
        }
      )
    },

    startChat() {
      const wsUrl = `${process.env.VUE_APP_CHAT_BASE_API}/api/chat`
      // const wsUrl = `ws://localhost:9203/chat`;
      this.socket = new SockJS(wsUrl)
      // this.socket = new WebSocket(wsUrl);
      this.stompClient = Stomp.over(this.socket)

      const token = getToken()
      this.stompClient.connect({ token }, this.onChatConnected, (err) => {
        // 连接失败
        console.log(err)
      })
    },
    stopChat() {
      if (this.subscription) {
        this.subscription.unsubscribe()
      }
      if (this.stompClient) {
        this.stompClient.disconnect()
      }
    }
  }
}
</script>
<style scoped>
.Living-example-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tag-box {
  /* text-align: center; */
}
.tag {
  width: 10px;
  height: 8px;
  background-color: rgb(238, 238, 238);
  margin: 5px auto;
}
.tyellow {
  background-color: rgb(255, 180, 0);
}
.tred {
  /* background-color: red; */
  color: red;
  font-size: 18px;
}
.tblue {
  background-color: rgb(24, 144, 255);
}

.tag-tit {
  font-size: 12px;
}
.check-box {
  width: 100%;
  line-height: 20px;
  font-size: 12px;
  display: flex;
  color: rgb(79, 164, 79);
}
.check-box2 {
  width: 100%;
  line-height: 20px;
  font-size: 12px;
  display: flex;
  color: rgb(227, 103, 103);
}
.check-log {
  line-height: 20px;
  margin-right: 5px;
}
.loading {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 200px auto;
}
.user-box {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
@media print {
  body {
    display: none;
  }
  html::after {
    content: "禁止打印!";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    letter-spacing: 15px;
    color: brown;
  }
}
.notice-box {
  /* text-align: center; */
}
.lay-box {
  position: relative;
}
.tool-box {
  position: absolute;
  bottom: 10px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-self: center;
  align-items: center;
}
.tool-btn {
  margin: 5px auto;
}
.notice-tit {
  font-weight: bold;
  line-height: 32px;
}
.notice-box span {
  line-height: 32px;
  display: block;
}
.chat-wrap {
  width: 100%;
}
.chat-box {
  width: 100%;
  height: 300px;
  border-top: 1px solid #848181;
  border-bottom: 1px solid #848181;
  overflow: auto;
}
.chat-left,
.chat-right {
  width: 100%;
  display: flex;
  margin: 10px 0;
}
.chat-right {
  flex-direction: row-reverse;
}
.chat-content {
  max-width: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #939292;
}
.chat-input {
  position: relative;
}
.chat-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.hot-number {
  position: absolute;
  top: 18px;
  left: 110px;
  line-height: 32px;
  font-size: 14px;
  color: #939292;
}
.common-problem-btn {
  position: absolute;
  top: 3px;
  right: 50px;
  line-height: 32px;
  font-size: 14px;
  color: #939292;
}
.notice-wrap {
  width: 100%;
}
.notice-item {
  margin: 20px 0;
}
.notice-q,
.notice-a {
  display: flex;
  /* flex-wrap: wrap; */
  line-height: 25px;
}
</style>
