<template>
  <video v-preventright id="videoPlayer" ref="videoPlayer" class="video-js" />
</template>

<script>
import video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

export default {
  name: 'VideoPlayer',
  props: {
    value: String,
    refId: String,
    drag: Boolean
  },

  data() {
    return {
      player: null,
      duration: 0,
      options: {
        controls: true,
        loop: false,
        preload: true,
        aspectRatio: '16:9', // 显示比率
        fullscreen: {
          options: { navigationUI: 'hide' }
        },
        sources: [{
          src: '',
          type: 'video/mp4'
        }]
      },
      lastFile: '',
      lastProc: 0
    }
  },

  watch: {
    value: {
      handler(val) {
        // 停止播放
        this.$emit('pause')
        // 构建视频信息
        this.fillVideo(val)
        // 手动修改地址
        this.player.src(this.options.sources)
        this.$nextTick(() => {
          this.$refs.videoPlayer.load()
        })
      },
      deep: true
    }

  },
  mounted() {
    // 禁止下载
    if (this.$refs.videoPlayer) {
      this.$refs.videoPlayer.controlsList = 'nodownload'
    }

    this.initVideo()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {

    // 填充视频信息
    fillVideo(url) {
      // 清理
      this.lastProc = 0
      this.lastFile = ''
      // 初始化播放地址
      this.options.sources[0].src = url
      // 媒体类型
      let videoType = 'video/mp4'
      // 支持m3u8
      if (/^.+(\.m3u8)$/.test(url)) {
        videoType = 'application/x-mpegURL'
      }
      this.options.sources[0].type = videoType
    },

    // 初始化播放器
    initVideo() {
      const that = this
      const src = `${this.value}?rnd=${new Date()}`
      // 初始化视频播放信息
      this.fillVideo(this.value)

      // 初始化播放器
      this.player = video(this.$refs.videoPlayer, this.options, function onPlayerReady() {
        this.reloadSourceOnError({
          getSource: function(reload) {
            console.log(src, '视频播放错误重载')
            reload({
              src: src
            })
          }
        })
        this.on('loadedmetadata', function() {
          that.loaded(this)
        })
        // 保存进度
        this.on('timeupdate', function() {
          that.timeupdate(this)
        })
        this.on('play', function() {
          that.play()
        })
        this.on('ended', function() {
          that.ended(this)
        })
        this.on('pause', function() {
          console.log('pause....')
          that.$emit('pause')
        })
        this.on('contextmenu', function() {
          console.log('contextmenu....')
          return false
        })
      })
    },

    // 播放
    play() {
      console.log('playing....')
      this.$emit('play')
      this.lastFile = this.refId
    },

    // 结束播放
    ended(v) {
      console.log('播放完成....')
      this.$emit('ended')
      // 设置重新开始
      setTimeout(() => {
        v.currentTime(0)
        this.lastProc = 0
      }, 1000)
      return false
    },

    loaded(v) {
      console.log('+++++加载..')

      // 视频总长度
      this.duration = v.duration()
      // 发送长度
      this.$emit('loaded', this.duration)
      const lastTime = localStorage.getItem(this.refId)

      // 从上次记忆的时间播放
      if (lastTime != null && lastTime > 5 && lastTime< this.duration) {
        this.lastProc = lastTime
        v.currentTime(lastTime)
      }
    },

    // 进度变化
    timeupdate(v) {
      const curTime = v.currentTime()
      // 不能快进也不能后退
      if (this.drag && (curTime - this.lastProc > 2 || this.lastProc - curTime > 2)) {
        v.currentTime(this.lastProc)
        return
      }
      if (curTime > 0 && this.refId === this.lastFile) {
        localStorage.setItem(this.refId, curTime)
      }
      this.lastProc = curTime
    },

    pause() {
      this.player.pause()
    }
  }
}
</script>

