var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("user-select-dialog", {
        attrs: { "dialog-show": _vm.dialogShow, single: true },
        on: {
          "update:dialogShow": function ($event) {
            _vm.dialogShow = $event
          },
          "update:dialog-show": function ($event) {
            _vm.dialogShow = $event
          },
          select: _vm.handleSelected,
        },
      }),
      _vm.checkPermission()
        ? _c(
            "div",
            { staticStyle: { position: "relative", top: "2px" } },
            [
              _c(
                "el-input",
                _vm._b(
                  {
                    attrs: {
                      placeholder: "请选择" + _vm.placeholder,
                      clearable: "",
                    },
                    on: {
                      focus: function ($event) {
                        _vm.dialogShow = !_vm.dialogShow
                      },
                    },
                    model: {
                      value: _vm.currentValue,
                      callback: function ($$v) {
                        _vm.currentValue = $$v
                      },
                      expression: "currentValue",
                    },
                  },
                  "el-input",
                  _vm.$attrs,
                  false
                )
              ),
            ],
            1
          )
        : _c(
            "el-input",
            _vm._b(
              {
                attrs: { placeholder: "请输入" + _vm.placeholder + "编号" },
                model: {
                  value: _vm.currentId,
                  callback: function ($$v) {
                    _vm.currentId = $$v
                  },
                  expression: "currentId",
                },
              },
              "el-input",
              _vm.$attrs,
              false
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }