<template>
  <div>
    <!-- <TmplSelectDialog
      :dialog-show.sync="dialogShow"
      :single="true"
      @select="handleSelected"
    /> -->

    <!-- <RemoteExamSelectDialog
      :dialog-show.sync="dialogShow"
      :single="true"
      @select="handleSelected"
    /> -->

    <component
      :is="dialogName"
      :dialog-show.sync="dialogShow"
      :single="true"
      @select="handleSelected"
    />

    <div v-if="checkPermission()" style="position: relative; top: 2px">
      <el-input
        v-model="currentValue"
        v-bind="$attrs"
        :placeholder="`请选择${placeholder}`"
        clearable
        @focus="dialogShow = !dialogShow"
      />
    </div>

    <el-input
      v-else
      v-model="currentId"
      v-bind="$attrs"
      :placeholder="`请输入${placeholder}编号`"
    />
  </div>
</template>

<script>
import TmplSelectDialog from './components/TmplSelectDialog'
import RemoteExamSelectDialog from './components/RemoteExamSelectDialog'
import { PermissionMixin } from '../DicListSelectCommonP/mixin'

export default {
  name: 'SelectDialogDir',
  components: { TmplSelectDialog, RemoteExamSelectDialog },
  mixins: [PermissionMixin],
  props: {
    placeholder: String,
    value: {
      type: String | Number
    },
    data: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    searchNameKey: {
      type: String,
      default: 'title'
    },
    dialogName: {
      type: String,
      default: 'RemoteExamSelectDialog'
    },
    config: {
      type: Object,
      default: () => {
        return {
          label: 'title',
          value: 'title'
        }
      }
    }
  },
  data() {
    return {
      dialogShow: false,
      currentValue: '',
      currentId: ''
    }
  },
  watch: {
    // 回调数据
    currentId: {
      handler(val) {
        this.handlerChange(val)
      },
      deep: true
    },
    currentValue: {
      handler(val) {
        if (!val) {
          this.currentId = ''
        }
      }
    }
  },
  methods: {
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    },
    handleSelected(id, val) {
      if (val && val.length > 0) {
        this.currentValue = val[0].title
        this.currentId = val[0].id
      } else {
        this.currentValue = ''
        this.currentId = ''
      }
    }
  }
}
</script>
