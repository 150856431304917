<template>
  <div :id="quData.id" :class="{ 'sub-box': sub }" class="qu-box">

    <div class="qu-item">
      <div :class="sub ? 'sub' : 'num'">{{ index + 1 }}</div>
      <div class="content">
        <span v-if="showType">【{{ quData.quType_dictText }}】</span>
        <span v-html="$options.filters.formatRichText(quData.content)" />
      </div>
    </div>

    <!-- 单选多选判断 -->
    <div v-if="quData.quType === '1' || quData.quType === '2' || quData.quType === '3' || quData.quType === '5'"
      class="qu-answer">
      <div v-for="(item, index) in quData.answerList" :key="item.id" class="item">
        <div class="tag">{{ item.abc || item.tag }}</div>
        <div v-if="showAnswer || quData.quType !== '5'" class="content">
          <div>{{ item.content }}</div>
          <div v-if="item.image">
            <img :src="item.image" style="max-width: 40vw">
          </div>
        </div>
        <div v-if="item.isRight && showAnswer" class="right">
          <i class="el-icon-success" style="color: #1aac1a" />
          答案
        </div>
      </div>
    </div>

    <!-- 得分选项，组卷模式无需展示 -->
    <div v-if="showAnalysis && ((mode !== 3 && quData.quType !== '99') || quData.analysis)" class="as-box">
      <div v-if="mode !== 3 && quData.quType !== '99'">
        <!-- 简答题/组合题无对错显示 -->
        <div v-if="quData.quType != '4' && quData.quType != '99'">
          <div class="right-line">
            <i :class="quData.isRight ? 'right el-icon-success' : 'error el-icon-error'" />
            <span v-if="quData.isRight">答对了</span>
            <span v-else>答错了</span>
          </div>
        </div>

        <div>
          学员得分：
          <span v-if="quData.quType == '4' && paperState == 1">
            待阅卷后显示得分
          </span>
          <span v-else>
            {{ quData.actualScore }} <span v-if="!quData.isRight && quData.actualScore > 0">（部分得分）</span>
          </span>
        </div>

        <!-- 单选多选判断题 -->
        <div v-if="quData.quType == '1' || quData.quType == '2' || quData.quType == '3'">
          学员答案：
          <span>{{ userAnswer(quData.answerList) }}</span>
        </div>

        <!-- 简答题 -->
        <div v-if="quData.quType == '4'">
          学员答案：<span class="asp" v-html="$options.filters.formatRichText(quData.answer)" />
        </div>

        <div v-if="quData.media != null && quData.media !== ''">
          <audio :src="quData.media" controls />
        </div>

        <!-- 填空题 -->
        <div v-if="quData.quType == '5'">
          学员答案：
          <template v-for="a in quData.answerList">
            <div v-if="a.answer" :key="a.id">{{ a.answer }}</div>
          </template>
        </div>
      </div>

      <div v-if="quData.analysis">
        <div v-if="sub">
          题目解析：
        </div>
        <div v-else>
          大题解析：
        </div>
        <div class="asp" v-html="$options.filters.formatRichText(quData.analysis)" />
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'QuItemShow',
  components: {},
  props: {
    value: Object,
    index: Number,
    state: Number,
    // 视图模式，1学员视图，2批阅视图，3试卷视图
    mode: Number,
    showAnalysis: {
      type: Boolean,
      default: true
    },
    showAnswer: {
      type: Boolean,
      default: true
    },
    showType: {
      type: Boolean,
      default: false
    },
    sub: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quData: {},
      no: 1,
      paperState: 0
    }
  },

  watch: {

    value: {
      handler(val) {
        this.quData = val
      },
      deep: true
    },
    index: {
      handler(val) {
        this.no = val
      }
    },
    state: {
      handler(val) {
        this.paperState = val
      }
    }
  },

  created() {
    this.quData = this.value
    this.no = this.index
    this.paperState = this.state
  },

  methods: {

    // 判断是否回答
    userAnswer(answerList) {
      let answer = ''

      for (let i = 0; i < answerList.length; i++) {
        if (answerList[i].checked) {
          answer += answerList[i].abc
        }
      }

      if (answer == '') {
        answer = '未作答'
      }

      return answer
    }
  }
}
</script>

<style scoped>
/deep/ .asp p {
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  word-wrap: break-word
}

.right-line {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.right-line .right {
  color: #03DD6D;
  font-size: 22px;
  margin-right: 10px
}

.right-line .error {
  color: #FF4B50;
  font-size: 22px;
  margin-right: 10px
}
</style>
