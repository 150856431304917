<template>

  <span style="color: #ff0000; font-weight: 700">{{ leftSec }}</span>

</template>

<script>

export default {
  name: 'ActionChecker',
  props: {
    actionOn: Boolean,
    actionInterval: Number,
    trigger: Number
  },
  data() {
    return {
      timer: null,
      leftSec: 999
    }
  },

  watch: {

    trigger: {
      handler() {
        // 重启计时器
        this.startCalc()
      }
    }

  },

  created() {
    // 操作检测
    this.startCalc()
  },

  methods: {

    clearCalc() {
      // 重启计时器
      if (this.timer != null) {
        clearInterval(this.timer)
      }
    },

    // 开始倒计时
    startCalc() {
      this.clearCalc()

      // 操作检测
      if (this.actionOn && this.actionInterval > 0) {
        this.leftSec = this.actionInterval
        this.timer = setInterval(this.timeChecker, 1000)
      }
    },

    timeChecker() {
      this.leftSec -= 1
      if (this.leftSec <= 0) {
        this.$emit('break')
      }
    }
  }
}
</script>

