<template>
  <div class="wrap">
    <h2 class="hader_tit">在线考试客户端</h2>

    <div class="down_tool_wrap">
      <div class="down_tool_item">
        <div
          :class="active=='win'?'down_tool_btn_active':'down_tool_btn'"
          @click="goWin"
        >
          <i class="iconfont icon-windows"></i>
          <span>Windows系统</span>
        </div>
        <div
        :class="active=='mac'?'down_tool_btn_active':'down_tool_btn'"
          @click="goMac"
        >
          <i class="iconfont icon-macos"></i>
          <span>Mac OS 系统</span>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <div class="footer"></div>
  </div>
</template>

<script>
import az_1 from "../../../assets/web/caozuo_1.jpg";
import az_2 from "../../../assets/web/caozuo_2.jpg";
import az_3 from "../../../assets/web/caozuo_3.jpg";
import az_4 from "../../../assets/web/caozuo_4.jpg";
export default {
  data() {
    return {
      activeName: "win",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      caozuoImg: {
        az_1: az_1,
        az_2: az_2,
        az_3: az_3,
        az_4: az_4,
      },
      active:'win'
    };
  },
  mounted(){
    console.log(this.$route,'jjjjjjjjjj')
    if(this.$route.path.indexOf('mac')!=-1){
      this.active = 'mac';
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    goWin(){
      this.active = "win";
      this.$router.push('/downtool/windows')
    },
    goMac(){
      this.active = "mac";
      this.$router.push('/downtool/mac')
    }
  },
};
</script>

<style scoped>
.wrap {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
}
.hader_tit {
  text-align: center;
}

.content_wrap {
  margin-top: 30px;
}
.content_tit {
  line-height: 40px;
  font-weight: bold;
}
.content_item {
  margin: 20px auto;
}
.content_item_tit {
  line-height: 32px;
}
.content_item_box_2 {
  display: flex;
}
.footer {
  width: 100%;
  height: 5px;
}
.flex {
  display: flex;
}
.right_10 {
  margin-right: 10px;
}
.down_tool_wrap {
  width: 100%;
  padding: 10px;
}
.down_tool_item {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.down_tool_btn {
  width: 150px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #000;
}
.iconfont{
  font-size:25px;
}
.down_tool_btn_active{
  width: 150px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #339ae8;
  color:#339ae8;
}
</style>
