<template>

  <el-select
    v-model="currentValue"
    :multiple="multi"
    :remote-method="fetchData"
    filterable
    remote
    clearable
    placeholder="选择或搜索考试"
    class="filter-item"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>

</template>

<script>

import { fetchList } from '@/api/exam/exam'

export default {
  name: 'ExamSelect',
  props: {
    multi: Boolean,
    value: String,
    examType: String,
    openType: Number
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: String
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    },
    examType: {
      handler() {
        this.fetchData('')
      }
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData('')
  },
  methods: {

    fetchData(v) {
      const params = { current: 1, size: 100, params: { examType: this.examType, openType: this.openType, title: v }}
      fetchList(params).then(response => {
        this.dataList = response.data.records
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>
