<template>
  <div style="display: none">禁用F12/右键事件启用中</div>
</template>

<script>

export default {
  name: 'EventControl',
  data() {
    return {
      threshold: 300,
      checkTimer: null,
      pasteListener: function(e) {
        // 阻止
        e.stopPropagation()
        e.preventDefault()
      }
    }
  },
  mounted() {
    // 初始化阻止事件
    this.initPrevent()
  },
  beforeDestroy() {
    // 还原阻止事件
    this.resetPrevent()
  },
  methods: {

    // 注册阻止
    initPrevent() {
      // 禁用右键菜单、选定
      this.$nextTick(() => {
        document.oncontextmenu = function() {
          return false
        }
        document.onselectstart = function() {
          return false
        }
      })

      // 禁止粘贴
      document.addEventListener('paste', this.pasteListener)

      // 检查是否打开控制台
      this.checkTimer = setInterval(this.devCheck, 3000)
    },

    // 移除阻止
    resetPrevent() {
      // 还原右键
      document.oncontextmenu = function() {
        return true
      }
      // 还原选定
      document.onselectstart = function() {
        return true
      }
      // 还原按键功能
      document.removeEventListener('paste', this.pasteListener)

      // 清除定时器
      clearInterval(this.checkTimer)
    },

    // 校验是否打开了开发者
    devCheck() {
      if (window.outerWidth - window.innerWidth > this.threshold ||
        window.outerHeight - window.innerHeight > this.threshold) {
        // 如果打开控制台，则刷新页面
        this.$message.warning('不允许使用调试模式，系统将在3秒后跳转到首页！')
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }
    }

  }
}
</script>
