<template>
  <div class="wrap">
    <div class="step-box">
      <div class="step-item">
        <div class="step-tit">1.手机微信扫码</div>
        <div class="step-img">
          <div id="qrcodediv" ref="qrcodediv" />
        </div>
        <el-tooltip class="item" effect="dark" placement="top-start">
          <template #content>
            <p>扫码遇到问题怎么办?</p>
            <p>1、请检查你的手机网络连接是否正常;</p>
            <p>2、请更新手机微信APP至最新版，然后重新扫码;</p>
            <p>3、以上方法均不能解决，请点击右侧“客服”小窗联系我们;</p>
            <p>
              4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源
            </p>
          </template>
          <div class="step-tip">
            <i class="el-icon-question" />
            扫码遇故障?
          </div>
        </el-tooltip>
      </div>
      <div class="step-item">
        <div class="step-tit">2.竖立摆放手机</div>
        <div class="step-img">
          <el-image :src="url_1" :style="stp2_img_styleObj" fit="fill" />
        </div>
        <div class="step-tip">侧后方45度放置手机</div>
      </div>
      <div class="step-item">
        <div class="step-tit">3.开启视频监控</div>
        <div class="step-img">
          <el-image :src="url_2" :style="stp3_img_styleObj" fit="fill" />
        </div>
        <div class="step-tip">确认是否开始录制</div>
      </div>
    </div>
  </div>
</template>

<script>
// import kurentoUtils from 'kurento-utils'
import s1 from '../../assets/images/m1.jpg'
import s2 from '../../assets/images/m2.jpg'

import QRCode from 'qrcodejs2'
export default {
  name: 'MobileStep',
  props: {
    paperId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      stp2_img_styleObj: {
        width: '200px',
        height: '200px'
      },
      stp3_img_styleObj: {
        width: '130px',
        height: '200px'
      },
      qrCode: null,
      onlyOne: false,
      url_1: s1,
      url_2: s2
    }
  },
  watch: {
    // 检测查询变化
    dialogVisible: {
      handler() {
        if (this.dialogVisible) {
          const timer = setInterval(() => {
            this.createdQrCode()
            if (this.$refs.qrcodediv) {
              clearInterval(timer)
            }
          }, 500)
        }
      },
      deep: true
    }
  },
  created() {
    const clientWidth = document.body.clientWidth
    const clientHeight = document.body.clientHeight
    if (clientWidth <= 1024 || clientHeight <= 768) {
      this.stp2_img_styleObj = {
        width: '100px',
        height: '100px'
      }
      this.stp3_img_styleObj = {
        width: '80px',
        height: '100px'
      }
    } else {
      this.stp2_img_styleObj = {
        width: '200px',
        height: '200px'
      }
      this.stp3_img_styleObj = {
        width: '130px',
        height: '200px'
      }
    }
  },
  mounted() {
    this.showQrCode()
  },
  beforeDestroy() {},
  methods: {
    showQrCode() {
      const timer = setInterval(() => {
        if (this.$refs.qrcodediv) {
          this.createdQrCode()
          clearInterval(timer)
        }
      }, 500)
    },

    createdQrCode() {
      console.log(this.qrCode, 'lllll')
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      const text = `${process.env.VUE_APP_URL_PREFIX}/across/selectcamera?name=${this.paperId}&type=side`
      const clientWidth = document.body.clientWidth
      const clientHeight = document.body.clientHeight
      console.log(clientWidth, clientHeight)
      if (clientWidth <= 1024 || clientHeight <= 768) {
        this.qrCode = new QRCode(this.$refs.qrcodediv, {
          width: 100,
          height: 100,
          text: text
        })
      } else {
        this.qrCode = new QRCode(this.$refs.qrcodediv, {
          width: 200,
          height: 200,
          text: text
        })
      }

      console.log('二维码地址', text)
    }
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  padding: 10px;
}
.step-box {
  display: flex;
  justify-content: space-around;
}
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
.step-tit {
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}
.step-img {
  margin: 10px auto;
}
.step-tip {
  font-size: 12px;
  line-height: 32px;
  color: #949090;
  text-align: center;
}
</style>
