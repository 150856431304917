<template>
  <div v-if="checkData && checkData.id">
    <el-alert
      v-if="disabled"
      :title="checkMsg"
      type="error"
      style="margin-bottom: 20px"
    />
    <div>
      <div v-if="checkData.isBuy">
        <el-descriptions :column="1" title="考试信息" border>
          <el-descriptions-item label="考试名称">
            {{ checkData.title }}
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            {{ checkData.realName }}
          </el-descriptions-item>
          <el-descriptions-item label="身份证号">
            {{ checkData.idCard }}
          </el-descriptions-item>
          <el-descriptions-item label="考试总分">
            {{ checkData.totalScore }}
          </el-descriptions-item>
          <el-descriptions-item label="及格分数">
            {{ checkData.qualifyScore }}分
          </el-descriptions-item>
          <el-descriptions-item label="考试时间">
            {{ checkData.startTime }} ~ {{ checkData.endTime }}
          </el-descriptions-item>
          <el-descriptions-item label="考试时长">
            {{ checkData.totalTime }} 分钟
          </el-descriptions-item>
          <el-descriptions-item label="考试类型">
            {{ checkData.examType_dictText }}
          </el-descriptions-item>
          <el-descriptions-item v-if="checkData.desktopToolOn" label="辅助工具">
            <div>
              <ul>
                <li>
                  <span
                    style="margin-right: 10px"
                  >防作弊检测软件
                    <span
                      v-show="checkToolDialog == 1"
                      style="font-weight: bold; color: #ff3333"
                    >未启动</span
                    >
                    <span
                      v-show="checkToolDialog == 3"
                      style="font-weight: bold; color: #ff3333"
                    >检查不通过,请按防作弊软件提示关闭程序</span
                    >
                    <span
                      v-show="checkToolDialog == 2"
                      style="font-weight: bold; color: rgb(90, 225, 124)"
                    >检查通过</span
                    >
                  </span>
                </li>
                <li>
                  <span>如果没有安装防作弊软件，请先下载安装。</span>
                  <router-link
                    to="/downtool/windows" tag="a"
                    target="_blank"
                  >点此下载</router-link
                  >
                </li>
                <li>
                  <span>已下载，请先启动“考试系统防作弊检测工具。</span>
                </li>
              </ul>

              <!-- <a
                href="https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip"
                target="_blank"
              >
                下载地址:https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip</a
                > -->
            </div>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="考试城市">
            <el-cascader :options="options" v-model="value" clearable/>
          </el-descriptions-item> -->
          <el-descriptions-item v-if="checkData.content" label="注意事项">
            <div style="font-size: 14px; color: #ff3333">
              {{ checkData.content }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item
            v-if="checkData.openType === 9"
            label="输入密码"
          >
            <el-input
              v-model="postForm.password"
              show-password
              size="mini"
              type="password"
              style="width: 200px"
            />
          </el-descriptions-item>
          <el-descriptions-item
            v-if="checkData.camOn && checkData.examType === '1'"
            label="设备调试"
          >
            <el-checkbox v-model="deviceDebug"> 我已完成设备调试 </el-checkbox>
            <el-link
              style="margin-left: 20px"
              type="primary"
              icon="el-icon-camera"
              @click="showDeviceTest"
            >
              调试摄像头
            </el-link>
          </el-descriptions-item>
          <!-- 调试作用，暂时先放出来 -->
          <!-- <el-descriptions-item label="设备调试">
            <el-checkbox v-model="deviceDebug">
              我已完成设备调试
            </el-checkbox>
            <el-link style="margin-left: 20px" type="primary" icon="el-icon-camera" @click="showDeviceTest">
              调试摄像头
            </el-link>
          </el-descriptions-item> -->

          <el-descriptions-item
            v-if="checkData.examType === '2'"
            label="设备调试"
          >
            <el-checkbox v-model="deviceDebug"> 我已完成设备调试 </el-checkbox>
            <el-link
              style="margin-left: 20px"
              type="primary"
              icon="el-icon-camera"
              @click="showDeviceTest"
            >
              调试主摄像头&副摄像头&屏幕录制
            </el-link>
          </el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 20px">
          <el-button @click="goBack"> 返回 </el-button>
          <el-button
            v-if="checkData.examType === '2'"
            :loading="loading"
            :disabled="buttonNext()"
            type="primary"
            icon="el-icon-s-promotion"
            @click="readyExam"
          >
            开始考试
          </el-button>
          <el-button
            v-else
            :loading="loading"
            :disabled="buttonNext()"
            type="primary"
            icon="el-icon-s-promotion"
            @click="handleCreateBrfore"
          >
            开始考试
          </el-button>
          <!-- <el-button :loading="loading" :disabled="buttonNext()" type="primary" icon="el-icon-s-promotion" @click="handleShow">
            开始考试
          </el-button> -->
          <el-button type="danger" icon="el-icon-help" @click="handleApply">
            异常申请
          </el-button>
        </div>
      </div>
      <div v-else class="pay-box">
        <div>此考试需要购买，请付费后再进入!</div>
        <div class="price">￥{{ checkData.price }}</div>
        <div>
          <el-button type="primary" @click="handleBuy"> 购买此考试 </el-button>
        </div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <!-- 人脸识别 -->
    <!-- <face-check-dialog v-if="!disabled" :visible.sync="checkData.faceOn" :params="{examId: postForm.examId}" url="/api/paper/capture/face-check" @success="faceBack" /> -->
    <!-- <face-check-dialog
      v-if="!disabled"
      :visible.sync="faceCheck"
      :params="{ examId: postForm.examId }"
      url="/api/paper/capture/face-auth"
      @success="faceBack"
      @error="faceRrror"
    /> -->
    <el-dialog
      v-if="!disabled"
      :visible.sync="faceCheck"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="微信扫码，开启人脸识别"
      width="30%"
    >
      <div
        v-if="faceCheck"
        style="
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 10px;
        "
      >
        <div id="qrcodediv" ref="qrcodediv" />
      </div>
    </el-dialog>
    <!-- <face-check-dialog v-if="!disabled" :visible.sync="isSHow" :params="{ examId: postForm.examId }" url="/api/paper/capture/face-auth" @success="faceBack" /> -->
    <!-- 异常申请 -->
    <exam-apply-dialog
      v-if="!disabled"
      :exam-id="postForm.examId"
      :visible.sync="applyVisible"
    />
    <!--普通调试-->
    <simple-device-test
      v-if="checkData.examType === '1'"
      :visible.sync="deviceVisible"
    />
    <!-- 音视频调试 -->
    <more-device-test
      v-if="checkData.examType === '2'"
      :visible.sync="deviceVisible"
      :check-data="checkData"
    />
    <!-- 调试作用 -->
    <!-- <more-device-test
      v-if="checkData.examType === '1'"
      :visible.sync="deviceVisible"
      :check-data="checkData"
    /> -->
  </div>
</template>
<script>
import { checkProcess } from '@/api/paper/exam'
import { checkInfo, httpReadyParper } from '@/api/exam/exam'
import { createPaper } from '@/api/paper/exam'
import { Notification } from 'element-ui'
import { checkTool, faceQrCode } from './mixin.js'


export default {
  mixins: [checkTool, faceQrCode],
  data() {
    return {
      loading: false,
      payVisible: false,
      courseId: '',
      checkData: {
        answerDevice: 0,
        overTime: false,
        examCount: 0,
        lateMax: 0
      },
      postForm: {
        examId: null,
        faceToken: null
      },

      deviceDebug: false,

      rules: {
        password: [{ required: true, message: '考试密码不能为空！' }]
      },

      faceInfo: {},

      disabled: false,
      checkMsg: '',
      applyVisible: false,

      // 设备调试
      deviceVisible: false,
      isSHow: false,
      options: [
        {
          value: 'zhinan',
          label: '指南',
          children: [
            {
              value: 'shejiyuanze',
              label: '设计原则',
              children: [
                {
                  value: 'yizhi',
                  label: '一致'
                },
                {
                  value: 'fankui',
                  label: '反馈'
                },
                {
                  value: 'xiaolv',
                  label: '效率'
                },
                {
                  value: 'kekong',
                  label: '可控'
                }
              ]
            },
            {
              value: 'daohang',
              label: '导航',
              children: [
                {
                  value: 'cexiangdaohang',
                  label: '侧向导航'
                },
                {
                  value: 'dingbudaohang',
                  label: '顶部导航'
                }
              ]
            }
          ]
        },
        {
          value: 'zujian',
          label: '组件',
          children: [
            {
              value: 'basic',
              label: 'Basic',
              children: [
                {
                  value: 'layout',
                  label: 'Layout 布局'
                },
                {
                  value: 'color',
                  label: 'Color 色彩'
                },
                {
                  value: 'typography',
                  label: 'Typography 字体'
                },
                {
                  value: 'icon',
                  label: 'Icon 图标'
                },
                {
                  value: 'button',
                  label: 'Button 按钮'
                }
              ]
            },
            {
              value: 'form',
              label: 'Form',
              children: [
                {
                  value: 'radio',
                  label: 'Radio 单选框'
                },
                {
                  value: 'checkbox',
                  label: 'Checkbox 多选框'
                },
                {
                  value: 'input',
                  label: 'Input 输入框'
                },
                {
                  value: 'input-number',
                  label: 'InputNumber 计数器'
                },
                {
                  value: 'select',
                  label: 'Select 选择器'
                },
                {
                  value: 'cascader',
                  label: 'Cascader 级联选择器'
                },
                {
                  value: 'switch',
                  label: 'Switch 开关'
                },
                {
                  value: 'slider',
                  label: 'Slider 滑块'
                },
                {
                  value: 'time-picker',
                  label: 'TimePicker 时间选择器'
                },
                {
                  value: 'date-picker',
                  label: 'DatePicker 日期选择器'
                },
                {
                  value: 'datetime-picker',
                  label: 'DateTimePicker 日期时间选择器'
                },
                {
                  value: 'upload',
                  label: 'Upload 上传'
                },
                {
                  value: 'rate',
                  label: 'Rate 评分'
                },
                {
                  value: 'form',
                  label: 'Form 表单'
                }
              ]
            },
            {
              value: 'data',
              label: 'Data',
              children: [
                {
                  value: 'table',
                  label: 'Table 表格'
                },
                {
                  value: 'tag',
                  label: 'Tag 标签'
                },
                {
                  value: 'progress',
                  label: 'Progress 进度条'
                },
                {
                  value: 'tree',
                  label: 'Tree 树形控件'
                },
                {
                  value: 'pagination',
                  label: 'Pagination 分页'
                },
                {
                  value: 'badge',
                  label: 'Badge 标记'
                }
              ]
            },
            {
              value: 'notice',
              label: 'Notice',
              children: [
                {
                  value: 'alert',
                  label: 'Alert 警告'
                },
                {
                  value: 'loading',
                  label: 'Loading 加载'
                },
                {
                  value: 'message',
                  label: 'Message 消息提示'
                },
                {
                  value: 'message-box',
                  label: 'MessageBox 弹框'
                },
                {
                  value: 'notification',
                  label: 'Notification 通知'
                }
              ]
            },
            {
              value: 'navigation',
              label: 'Navigation',
              children: [
                {
                  value: 'menu',
                  label: 'NavMenu 导航菜单'
                },
                {
                  value: 'tabs',
                  label: 'Tabs 标签页'
                },
                {
                  value: 'breadcrumb',
                  label: 'Breadcrumb 面包屑'
                },
                {
                  value: 'dropdown',
                  label: 'Dropdown 下拉菜单'
                },
                {
                  value: 'steps',
                  label: 'Steps 步骤条'
                }
              ]
            },
            {
              value: 'others',
              label: 'Others',
              children: [
                {
                  value: 'dialog',
                  label: 'Dialog 对话框'
                },
                {
                  value: 'tooltip',
                  label: 'Tooltip 文字提示'
                },
                {
                  value: 'popover',
                  label: 'Popover 弹出框'
                },
                {
                  value: 'card',
                  label: 'Card 卡片'
                },
                {
                  value: 'carousel',
                  label: 'Carousel 走马灯'
                },
                {
                  value: 'collapse',
                  label: 'Collapse 折叠面板'
                }
              ]
            }
          ]
        },
        {
          value: 'ziyuan',
          label: '资源',
          children: [
            {
              value: 'axure',
              label: 'Axure Components'
            },
            {
              value: 'sketch',
              label: 'Sketch Templates'
            },
            {
              value: 'jiaohu',
              label: '组件交互文档'
            }
          ]
        }
      ],
      value: [],
      faceCheck: false
    }
  },
  watch: {
    // 检测查询变化
    faceCheck: {
      handler() {
        if (this.faceCheck) {
          this.getFaceId()
        } else {
          this.ws.close()
          clearInterval(this.timer)
        }
      },
      deep: true
    }
  },
  created() {
    const userAgent = window.navigator.userAgent
    let falg = false
    console.log(userAgent, '浏览器是', userAgent.indexOf('Firefox'))
    // 谷歌或火狐
    if (
      (userAgent.indexOf('Chrome') !== -1 &&
        userAgent.indexOf('Safari') !== -1 &&
        userAgent.indexOf('Mozilla') !== -1) ||
      userAgent.indexOf('Firefox') !== -1 ||
      userAgent.indexOf('Edg') !== -1 ||
      (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1)
    ) {
      console.log('谷歌浏览器或火狐浏览器或Edge浏览器或Safari浏览器')
      falg = true
    }
    // 是Edg
    // if (userAgent.indexOf('Edg') !== -1) {
    //   falg = false
    // }

    // 是搜狗
    if (userAgent.indexOf('SE 2.X MetaSr') !== -1) {
      falg = false
    }
    // 是360
    if (userAgent.indexOf('QIHU 360SE') !== -1) {
      falg = false
    }
    // 是支付宝
    if (userAgent.indexOf('AlipayClient') !== -1) {
      falg = false
    }
    // 是抖音
    if (userAgent.indexOf('DiDiPhone') !== -1) {
      falg = false
    }
    // 是QQ
    if (userAgent.indexOf('QQ') !== -1) {
      falg = false
    }
    // 是微信
    if (
      userAgent.indexOf('WindowsWechat') !== -1 ||
      userAgent.indexOf('MacWechat') !== -1 ||
      userAgent.indexOf('Weixin') !== -1 ||
      userAgent.indexOf('MicroMessenger') !== -1
    ) {
      falg = false
    }

    if (falg) {
      const id = this.$route.params.id
      this.courseId = this.$route.query.courseId
      this.activityId = this.$route.query.activityId

      if (id) {
        this.postForm.examId = id
        this.fetchData()
      }
    } else {
      this.$alert(
        `当前浏览器暂不支持考试，请更换为Chrome浏览器( https://www.google.cn/intl/zh-CN/chrome )或者Firefox浏览器( https://www.firefox.com.cn/ )或者Edge浏览器( https://www.microsoft.com/zh-cn/edge/download )或者Safari浏览器`,
        {
          confirmButtonText: '知道了',
          callback: (action) => {
            this.$router.go(-1)
          }
        }
      )
    }
  },
  methods: {
    buttonNext() {
      // 开启了视频但未勾选调试通过
      if (
        this.checkData.examType === '1' &&
        this.checkData.camOn &&
        !this.deviceDebug
      ) {
        return true
      }

      // 三路监控
      if (this.checkData.examType === '2' && !this.deviceDebug) {
        return true
      }

      if (this.disabled) {
        return true
      }

      return false
    },

    // 判断是否无法继续
    checkState() {
      // 不允许电脑考试
      if (this.checkData.answerDevice > 1) {
        this.checkMsg = '当前考试不支持使用电脑端答题，如有疑问请联系管理员！'
        this.disabled = true
        return
      }

      // 超过考试时间了
      if (this.checkData.overTime) {
        this.checkMsg = '考试未开始或已结束!'
        this.disabled = true
        return
      }

      // 迟到了
      if (this.checkData.late) {
        this.checkMsg = `您已经迟到超过${this.checkData.lateMax}分钟，不允许进入考试！`
        this.disabled = true
        return
      }

      // 没有考试机会了
      if (
        this.checkData.chance > 0 &&
        this.checkData.examCount >= this.checkData.chance
      ) {
        this.checkMsg = `考试次数超限，总共有${this.checkData.chance}次考试机会！`
        this.disabled = true
        return
      }

      this.disabled = false
    },

    // 查询考试详情
    fetchData() {
      checkInfo(this.postForm.examId).then((res) => {
        // 考试详情
        this.checkData = res.data
        this.checkState()
      })
    },
    handleCreateBrfore() {
      if (
        this.checkData.faceOn &&
        (this.postForm.faceToken === null || this.postForm.faceToken === '')
      ) {
        this.faceCheck = true
      } else {
        this.handleCreate()
      }
    },
    // 创建试卷
    handleCreate() {
      this.loading = true

      // 追加课程
      this.postForm.courseId = this.courseId
      this.postForm.activityId = this.activityId
      createPaper(this.postForm)
        .then((res) => {
          console.log('res', res)
          if (res.code === 0) {
            this.loading = false
            if (
              this.checkData.answerMode == 3 ||
              this.checkData.answerMode == '3'
            ) {
              this.$router.push({
                name: 'StartExam',
                params: { id: res.data.id },
                query: {
                  isSection: true,
                  checkToolShow: this.checkData.desktopToolOn
                }
              })
            } else {
              this.$router.push({
                name: 'StartExam',
                params: { id: res.data.id }
              })
            }
          } else {
            const err = { message: res.code.toString() }
            this.checkBack(err)
          }
        })
        .catch(this.checkBack)
    },
    async getParperId() {
      const subData = {
        id: this.postForm.examId
      }
      const res = await httpReadyParper(subData)
      if (res.success) {
        if (
          this.checkData.answerMode == 3 ||
          this.checkData.answerMode == '3'
        ) {
          this.$router.push({
            name: 'StartExam',
            params: { id: res.data.id },
            query: {
              isvideo: true,
              examId: this.postForm.examId,
              isSection: true,
              checkToolShow: this.checkData.desktopToolOn
            }
          })
        } else {
          this.$router.push({
            name: 'StartExam',
            params: { id: res.data.id },
            query: {
              isvideo: true,
              examId: this.postForm.examId,
              checkToolShow: this.checkData.desktopToolOn
            }
          })
        }
      } else {
        const err = { message: res.code.toString() }
        this.checkBack(err)
      }
    },

    checkToolSucess() {
      if (this.checkToolDialog == 2) {
        return true
      } else {
        Notification.error({
          title: '提示信息',
          message: '你的防作弊软件尚未通过检测，请先完成防作弊软件检测'
        })
        return false
      }
    },
    readyExam() {
      let pass = true
      if (this.checkData.desktopToolOn) {
        pass = this.checkToolSucess()
      }

      if (pass) {
        if (
          this.checkData.faceOn &&
        (this.postForm.faceToken === null || this.postForm.faceToken === '')
        ) {
          this.faceCheck = true
        } else {
          this.getParperId()
        }
      }
    },
    handleShow() {
      this.isSHow = true
    },
    faceRrror(msg) {
      this.faceCheck = false
      const news = `姓名：${this.checkData.realName}，身份证:${this.checkData.idCard}，${msg}`
      Notification.error({
        title: '提示信息',
        message: news
      })
    },
    faceBack(token) {
      this.postForm.faceToken = token
      if (this.checkData.examType === '2') {
        this.getParperId()
      } else {
        this.handleCreate()
      }
    },

    goBack() {
      this.$router.push({ name: 'ExamOnline' })
    },

    // 回到考试
    checkBack(err) {
      console.log('errr', err)
      this.loading = false
      // 有正在进行的考试
      if (
        err !== undefined &&
        err.message !== undefined &&
        err.message === '20010002'
      ) {
        // 删除
        this.$confirm(
          '您有正在进行的考试，点击确定按钮继续考试！',
          '提示信息',
          {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            type: 'warning'
          }
        ).then(() => {
          checkProcess().then((res) => {
            if (res.data && res.data.id) {
              if (res.data.examType && res.data.examType == '2') {
                const routeData = {
                  name: 'StartExam',
                  params: { id: res.data.id },
                  query: {
                    isvideo: true,
                    examId: res.data.examId,
                    checkToolShow: this.checkData.desktopToolOn
                  }
                }
                if (
                  this.checkData.answerMode == 3 ||
                  this.checkData.answerMode == '3'
                ) {
                  routeData.query.isSection = true
                  routeData.query.checkToolShow = this.checkData.desktopToolOn
                }
                console.log('>>>>countdownOn', res.data)
                if (res.data.countdownOn) {
                  // 是否显示倒计时
                  routeData.query.countdownOn = true
                  console.log('>>>>countdownOn true')
                }
                this.$router.push(routeData)
              } else {
                if (
                  this.checkData.answerMode == 3 ||
                  this.checkData.answerMode == '3'
                ) {
                  this.$router.push({
                    name: 'StartExam',
                    params: { id: res.data.id },
                    query: {
                      isSection: true,
                      checkToolShow: this.checkData.desktopToolOn
                    }
                  })
                } else {
                  this.$router.push({
                    name: 'StartExam',
                    params: { id: res.data.id }
                  })
                }
              }
            }
          })
        })
      }
    },

    handleBuy() {
      this.payVisible = true
    },
    handleApply() {
      this.applyVisible = true
    },
    showDeviceTest() {
      this.deviceVisible = true
    }
  }
}
</script>
<style scoped>
/deep/ .el-descriptions-item__label {
  width: 100px;
}

.pay-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 50px;
  align-items: center;
}

.pay-box .price {
  color: #ff0000;
  font-size: 28px;
  font-weight: 700;
}
</style>
