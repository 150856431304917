<template>

  <div v-if="siteData.props.wechatLogin || siteData.props.faceLogin || siteData.props.cropLogin || siteData.props.dingLogin" style="line-height: 35px; margin-top: 10px">
    <div class="title-line">其它方式登录</div>
    <div style="display: flex;">

      <div v-if="siteData.props.wechatLogin" class="third-item wechat-icon" @click="wechatLogin">
        <svg-icon icon-class="login-wx" />
        <div>微信登录</div>
      </div>

      <div v-if="siteData.props.cropLogin" class="third-item wx2-icon" @click="cropWechatLogin">
        <svg-icon icon-class="login-wx2" />
        <div>企业微信</div>
      </div>

      <div v-if="siteData.props.dingLogin" class="third-item dd-icon" @click="dingLogin">
        <svg-icon icon-class="login-dd"/>
        <div>钉钉登录</div>
      </div>

      <div v-if="siteData.props.faceLogin" class="third-item face-icon" @click="faceLogin">
        <svg-icon icon-class="login-face" />
        <div>人脸登录</div>
      </div>
    </div>

    <face-login-dialog :visible.sync="faceVisible"/>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { apiGetWechatUrl, apiGetCropWechatUrl, apiGetDingUrl } from '@/api/ability/login'
import FaceLoginDialog from '@/views/login/components/FaceLoginDialog.vue'

export default {
  name: 'ThirdLogin',
  components: { FaceLoginDialog },

  data() {
    return {
      loading1: false,
      loading2: false,
      loginForm: {},
      faceVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },
  methods: {

    wechatLogin() {
      // 获得跳转地址并跳转
      apiGetWechatUrl({ state: 'pc' }).then(res => {
        window.location = res.data.url
      })
    },

    cropWechatLogin() {
      // 获得跳转地址并跳转
      apiGetCropWechatUrl({ state: 'pc' }).then(res => {
        window.location = res.data.url
      })
    },

    dingLogin() {
      // 获得跳转地址并跳转
      apiGetDingUrl({ state: 'pc' }).then(res => {
        window.location = res.data.url
      })
    },

    faceLogin() {
      this.faceVisible = true
    }
  }
}
</script>

<style scoped>

.third-item{
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  color: #888;
  cursor: pointer;
}

.third-item .svg-icon{
  width: 28px; height: 28px;
  cursor: pointer;
}

.wechat-icon:hover{
  color: #1AAC1A;
  font-weight: 700;
}
.wx2-icon:hover{
  color: #0082EF;
  font-weight: 700;
}
.dd-icon:hover{
  color: #3296FA;
  font-weight: 700;
}
.face-icon:hover{
  color: #ff8000;
  font-weight: 700;
}
</style>
