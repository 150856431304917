import { render, staticRenderFns } from "./CheckContent.vue?vue&type=template&id=be7efb96&scoped=true"
import script from "./CheckContent.vue?vue&type=script&lang=js"
export * from "./CheckContent.vue?vue&type=script&lang=js"
import style0 from "./CheckContent.vue?vue&type=style&index=0&id=be7efb96&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be7efb96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\g\\rongtong2.0\\exam\\exam-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be7efb96')) {
      api.createRecord('be7efb96', component.options)
    } else {
      api.reload('be7efb96', component.options)
    }
    module.hot.accept("./CheckContent.vue?vue&type=template&id=be7efb96&scoped=true", function () {
      api.rerender('be7efb96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/web/devicecheck/component/CheckContent.vue"
export default component.exports