var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-box" }, [
    _c("div", { staticClass: "score" }, [
      _vm._v(" " + _vm._s(_vm.data.userScore)),
      _c("span", [_vm._v("分")]),
    ]),
    _c("div", [_vm._v(" " + _vm._s(_vm.data.thanks) + " ")]),
    _vm.data.reviewState === 0
      ? _c("div", { staticStyle: { color: "#ff8000", "font-weight": "700" } }, [
          _vm._v(
            " 该试卷还有主观题，主观题分数将在阅卷后累计到总成绩，请知晓！ "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }