var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 25 } },
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c(
                "el-card",
                [
                  _c("el-page-header", {
                    attrs: { content: "题库训练" },
                    on: { back: _vm.$navBack },
                  }),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "-25px", height: "35px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "danger" },
                          on: { click: _vm.handleFinish },
                        },
                        [_vm._v("结束训练")]
                      ),
                    ],
                    1
                  ),
                  _vm.mode !== 13
                    ? _c(
                        "el-row",
                        { staticStyle: { margin: "20px 0px 20px 0px" } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "guide-item", attrs: { span: 6 } },
                            [
                              _c("div", [_vm._v("答对")]),
                              _c("div", [_vm._v(_vm._s(_vm.trueCount) + "题")]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "guide-item", attrs: { span: 6 } },
                            [
                              _c("div", [_vm._v("答错")]),
                              _c("div", [
                                _vm._v(_vm._s(_vm.wrongCount) + "题"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "guide-item", attrs: { span: 6 } },
                            [
                              _c("div", [_vm._v("正确率")]),
                              _c("div", [_vm._v(_vm._s(_vm.rightRate) + "%")]),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "guide-item",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "align-content": "center",
                                "align-items": "center",
                              },
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: _vm.nextIfTrue,
                                        callback: function ($$v) {
                                          _vm.nextIfTrue = $$v
                                        },
                                        expression: "nextIfTrue",
                                      },
                                    },
                                    [_vm._v("答对继续下一题")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    { staticClass: "lky-exam" },
                    [
                      _c(
                        "el-col",
                        [
                          _c("qu-item-train", {
                            attrs: {
                              card: _vm.cardItem,
                              sub: false,
                              mode: _vm.mode,
                            },
                            on: { rest: _vm.handleRest },
                            model: {
                              value: _vm.quData,
                              callback: function ($$v) {
                                _vm.quData = $$v
                              },
                              expression: "quData",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.currentSort <= 1,
                                icon: "el-icon-back",
                              },
                              on: { click: _vm.fetchPrevious },
                            },
                            [_vm._v("上一题")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: {
                                disabled: _vm.currentSort >= _vm.maxSort,
                                type: "primary",
                              },
                              on: { click: _vm.fetchNext },
                            },
                            [
                              _vm._v("下一题"),
                              _c("i", {
                                staticClass: "el-icon-right el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "700",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v("答题卡")]
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "max-height": "600px",
                        "overflow-y": "scroll",
                      },
                    },
                    _vm._l(_vm.cardList, function (item) {
                      return _c(
                        "el-tag",
                        {
                          key: item.quId,
                          staticStyle: {
                            margin: "5px",
                            "font-size": "10px",
                            cursor: "pointer",
                          },
                          attrs: {
                            type: _vm.itemClass(
                              item.quId,
                              item.isRight,
                              item.answered
                            ),
                            st: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchQu(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.sort) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }