var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("device-header", { attrs: { "exam-info": _vm.examInfo } }),
      _c("check-content"),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "footer-inner" }, [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }