<template>

  <web-table
    ref="pagingTable"
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <el-date-picker
        v-model="listQuery.params.dateRange"
        class="filter-item"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="考试时间"
        prop="title"
        show-overflow-tooltip
      >

        <template v-slot="scope">
          <detail-link :id="scope.row.id" :title="scope.row.createTime" to="ShowExam" />
        </template>

      </el-table-column>

      <el-table-column
        label="考试用时"
        prop="userTime"
        align="center"
      />

      <el-table-column
        label="主观分"
        align="center">

        <template v-slot="scope">
          <div v-if="scope.row.resultType===1">
            不展示
          </div>
          <div v-else>
            {{ scope.row.userSubjScore }}
          </div>
        </template>

      </el-table-column>

      <el-table-column
        label="客观分"
        align="center">

        <template v-slot="scope">
          <div v-if="scope.row.resultType===1">
            不展示
          </div>
          <div v-else>
            {{ scope.row.userObjScore }}
          </div>
        </template>

      </el-table-column>

      <el-table-column
        label="总得分"
        align="center">

        <template v-slot="scope">
          <div v-if="scope.row.resultType===1">
            不展示
          </div>
          <div v-else>
            {{ scope.row.userScore }}
          </div>
        </template>

      </el-table-column>

      <el-table-column
        label="及格分"
        prop="qualifyScore"
        align="center"
      />

      <el-table-column
        label="阅卷状态"
        align="center"
        prop="reviewState_dictText"
      />

      <el-table-column
        label="是否通过"
        align="center">

        <template v-slot="scope">
          <div v-if="scope.row.resultType===1">
            <el-tag type="warning">
              不显示
            </el-tag>
          </div>
          <div v-else>
            <el-tag v-if="scope.row.state == 1">
              待阅卷
            </el-tag>

            <el-tag v-else-if="scope.row.passed" type="success">
              通过
            </el-tag>

            <el-tag v-else type="danger">
              未通过
            </el-tag>
          </div>
        </template>

      </el-table-column>

      <el-table-column
        label="操作"
        align="center"
      >

        <template v-slot="scope">
          <el-link v-if="scope.row.state===0" size="mini" type="primary" @click="continueExam(scope.row.id)">继续考试</el-link>
          <el-link v-else size="mini" type="primary" @click="showExam(scope.row.id)">试卷详情</el-link>
        </template>

      </el-table-column>

    </template>
  </web-table>

</template>

<script>

export default {
  components: { },
  data() {
    return {

      paperList: [],

      listQuery: {
        current: 1,
        size: 10,
        params: {
          examId: ''
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/paper/paper/my-paging'
      }

    }
  },
  created() {
    this.listQuery.params.examId = this.$route.params.examId
  },
  methods: {
    showExam(id) {
      this.$router.push({ name: 'ShowExam', params: { id: id }})
    },

    /**
     * 继续考试
     */
    continueExam(id) {
      this.$router.push({ name: 'StartExam', params: { id: id }})
    }
  }
}
</script>
