export default {
  install(Vue, options) {
    Vue.prototype.$navBack = function() {
      window.history.back()
    }

    // 当前环境
    Vue.prototype.$env = process.env.NODE_ENV

    // 演示环境
    Vue.prototype.$demo = process.env.NODE_ENV === 'demo'
  }
}
