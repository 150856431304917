<template>
  <div
    :class="{ fullscreen: fullscreen }"
    :style="{ width: containerWidth }"
    class="tinymce-container"
  >
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <editor-attach v-if="showUpload" @uploaded="handleAttr" />
    </div>
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import EditorAttach from '@/components/Tinymce/components/EditorAttach'
import { uploadFile } from '@/utils/upload'

export default {
  name: 'Tinymce',
  components: { EditorAttach },
  props: {
    id: {
      type: String,
      default: function() {
        return (
          'vue-tinymce-' +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + '')
        )
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    paste: {
      type: Boolean,
      default: true
    },
    showUpload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || '')
        )
      }
    }
  },
  mounted() {
    this.initTinymce()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    // 初始化
    initTinymce() {
      const that = this

      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        convert_urls: false,
        language: 'zh_CN',
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: false,
        branding: false,
        media_live_embeds: true,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'propmt',
        powerpaste_html_import: 'propmt',
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        wirisformulaeditorlang: 'zh',
        nonbreaking_force_tab: true,
        init_instance_callback: (editor) => {
          if (that.value) {
            editor.setContent(that.value)
          }
          that.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        // 屏蔽下载功能
        extended_valid_elements:
          'video[src|controlslist|controls|width|height],audio[src|controlslist|controls|width|height]',
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            that.fullscreen = e.state
          })
        },
        // 禁用粘贴
        paste_preprocess: function(plugin, args) {
          if (!that.paste) {
            that.$message.info('考试期间不能复制粘贴哦！')
            args.stopImmediatePropagation()
            args.stopPropagation()
            args.preventDefault()
            args.content = ''
          }
        },
        // 粘贴文件上传
        images_upload_handler: function(blobInfo, success, failure) {
          const file = blobInfo.blob()
          uploadFile(file)
            .then((url) => {
              // 回写URL地址
              success(url)
            })
            .catch(() => {
              failure()
            })
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    handleAttr(data) {
      const that = this

      const fileList = data.fileList
      const mediaType = data.mediaType

      console.log('fileList', fileList)

      // 插入内容
      let html = ''

      // 图片
      if (mediaType === 1) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><img class="wscnph" src="${fileList[i].url}" /></p>`
        }
      }

      // 视频
      if (mediaType === 2) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><video src="${fileList[i].url}" controls="controls" controlslist="nodownload" width="600px" height="auto" oncontextmenu="return false"></video></p>`
        }
      }

      // 音频
      if (mediaType === 3 || mediaType === 4) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><audio src="${fileList[i].url}" controls="controls" controlslist="nodownload" oncontextmenu="return false"></audio></p>`
        }
      }

      // 文件
      if (mediaType === 5) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><a href="${fileList[i].url}" target="_blank">${
            fileList[i].name || fileList[i].url
          }</a></p>`
        }
      }

      window.tinymce.get(that.tinymceId).insertContent(html)
    }
  }
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.editor-custom-btn-container {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 200;
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
