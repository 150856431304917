var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.checkToolDialog == 2 || _vm.checkToolDialog == 0
        ? _c(
            "div",
            { staticStyle: { height: "150px" } },
            [
              _c("video-presenter", {
                attrs: {
                  height: 150,
                  "cam-type": "facade",
                  paperid: _vm.paperId,
                  "exam-id": _vm.examId,
                  "capture-p": _vm.captureP,
                },
                on: {
                  alterError: _vm.alterError,
                  webjoin: _vm.nojion,
                  checkConnected: _vm.checkConnected,
                  captureSucess: _vm.captureSucess,
                  readyCall: _vm.readyCall,
                },
              }),
              _vm.isShowCall
                ? _c("VideoCallerViewer", {
                    attrs: {
                      "cam-type": "facade",
                      muted: !_vm.isShowCall,
                      "caller-info": _vm.callerInfo,
                      paperid: _vm.paperId,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("div", {
            staticStyle: { height: "150px", background: "#262626" },
          }),
      _c(
        "div",
        [
          (!_vm.started && _vm.videoCurrentStep >= 3) || _vm.started
            ? _c("screen-sharing", {
                attrs: { paperid: _vm.paperId, tracking: false, height: 0 },
                on: { checkConnected: _vm.checkConnected },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: !_vm.started && _vm.videoCurrentStep == 1,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "微信扫码，开启手机监控",
            width: "50%",
            center: "",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "/* display": "flex",
                "justify-content": "space-around",
                "*/\n        padding": "10px",
              },
            },
            [_c("mobile-step", { attrs: { "paper-id": _vm.paperId } })],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: !_vm.started && _vm.videoCurrentStep == 2,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "屏幕共享桌面提示",
            width: "50%",
            center: "",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "space-around",
                padding: "10px",
              },
            },
            [
              _c("el-image", {
                staticStyle: { width: "500px", height: "400px" },
                attrs: { src: _vm.url, fit: "fill" },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.startDesk } },
                [_vm._v("开启桌面共享")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.mobileVisible && _vm.started,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "请检查摄像头",
            width: "30%",
          },
        },
        [
          _c(
            "el-result",
            {
              attrs: {
                "sub-title": _vm.errMsg,
                title: "手机监控失效",
                icon: "error",
              },
            },
            [
              _vm.mobileVisible
                ? _c("template", { slot: "icon" }, [
                    _vm.mobileVisible
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              display: "flex",
                              "justify-content": "space-around",
                              padding: "10px",
                            },
                          },
                          [
                            _c("div", {
                              ref: "qrcodediv",
                              attrs: { id: "qrcodediv" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoVisible && _vm.started,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "请检查摄像头",
            width: "30%",
          },
        },
        [
          _vm.videoVisible
            ? _c("el-result", {
                attrs: {
                  icon: "error",
                  "sub-title": "请刷新页面，并重新检查视频或桌面监控失效",
                  title: "监控失效",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.checkToolDialog == 3 || _vm.checkToolDialog == 1,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "",
            width: "30%",
          },
        },
        [
          _c(
            "el-result",
            {
              attrs: {
                "sub-title": _vm.checkErrMsg,
                icon: "error",
                title: "温馨提示",
              },
            },
            [
              _vm.checkToolDialog == 1
                ? _c(
                    "template",
                    { slot: "extra" },
                    [
                      _c("el-button", { on: { click: _vm.goBack } }, [
                        _vm._v("点此退出考试"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }