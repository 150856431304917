<template>
  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <el-input
        v-model="listQuery.params.refRemark"
        class="filter-item"
        size="small"
        placeholder="请输入积分标题信息"
        prefix-icon="el-icon-search"
        style="width: 300px"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="姓名"
        prop="realName"
        show-overflow-tooltip
      />
      <el-table-column label="证书名称" align="center" prop="name" width="160px" />
      <el-table-column label="授予单位" align="center" prop="authority" width="160px" />
      <el-table-column label="取得时间" align="center" prop="createTime" width="160px" />
      <el-table-column label="到期时间" align="center" prop="endTime" width="160px" />
      <el-table-column
        label="操作项"
        align="center"
        width="100px"
      >
        <template v-slot="scope">

          <div>

            <a :href="scope.row.certificateFileUrl" target="_black">
              <el-button type="text">下载</el-button>
            </a>


          </div>

        </template>
      </el-table-column>

    </template>

  </web-table>
</template>

<script>

export default {
  name: 'MyPointsLog',
  components: { },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/usercert/my-paging'
      }
    }
  }
}
</script>

<style scoped>

  ::v-deep
  .search-area{
    display: none;
  }
</style>
