var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "300px" },
              attrs: {
                size: "small",
                placeholder: "请输入积分标题信息",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.refRemark,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "refRemark", $$v)
                },
                expression: "listQuery.params.refRemark",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: {
                label: "标题",
                prop: "refRemark",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "时间",
                align: "center",
                prop: "createTime",
                width: "180px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "积分",
                align: "right",
                prop: "points",
                width: "120px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.points > 0
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "700",
                                color: "orange",
                              },
                            },
                            [_vm._v(" + " + _vm._s(scope.row.points) + " ")]
                          )
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "700",
                                color: "red",
                              },
                            },
                            [_vm._v(" " + _vm._s(scope.row.points) + " ")]
                          ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }