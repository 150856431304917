<template>
  <div>
    <el-dialog :visible="isVisible" :before-close="handleClose" :title="datainfo.realName" width="1050px">
      <div v-if="isVisible" class="video-box">
        <div class="main-video-wrap">
          <div class="main-video">
            <video-viewer :height="300" :width="350" :cam-type="'facade'" :muted="!isVisible" :online="datainfo.online" :paperid="datainfo.id" />
          </div>
          <span>监控中（摄像头）</span>
        </div>
        <div class="second-video-wrap">
          <div class="second-video">
            <video-viewer :height="300" :width="250" :cam-type="'side'" :online="datainfo.online" :paperid="datainfo.id" />
          </div>
          <span>监控中（手机）</span>
        </div>
        <div class="third-video-wrap">
          <div class="third-video">
            <video-viewer :height="300" :width="350" :cam-type="'desktop'" :online="datainfo.online" :paperid="datainfo.id" />
          </div>
          <span>监控中（屏幕）</span>
        </div>
      </div>
      <VideoCaller v-if="isCall>0" :cam-type="'facade'" :paperid="datainfo.id" @connectSucess="connectSucess"/>
      <div v-show="isCall==1" class="caller-box">
        <div class="caller-loading">
          接通中...
        </div>
      </div>
      <div class="caller-box">
        <a v-show="isCall==0" class="caller-item" @click="isCall=1">
          <i class="el-icon-phone-outline"/>
        </a>
        <a v-show="isCall!=0" class="caller-item" @click="isCall=0">
          <i class="el-icon-phone"/>
        </a>
      </div>
      <div class="btn-box">
        <el-popover placement="bottom" trigger="click">
          <div class="forewarning-btn-box">
            <el-button v-if="isChief" class="forewarning-btn" @click="handleWarning(datainfo.id, 21)">确认作弊</el-button>
            <el-button v-if="!isChief" class="forewarning-btn" @click="handleWarning(datainfo.id, 33)">疑似作弊</el-button>
            <el-button v-if="isChief" class="forewarning-btn" @click="handleWarning(datainfo.id, 0)">取消作弊</el-button>
            <el-button class="forewarning-btn" @click="handleWarning(datainfo.id, 32)">红牌警告</el-button>
          </div>

          <el-button slot="reference">预警</el-button>
        </el-popover>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import VideoCaller from '../../components/VideoCaller'

export default {
  name: 'MonitoringDetails',
  components: { VideoCaller },
  props: {
    detailVisible: {
      default: false,
      type: Boolean
    },
    datainfo: {
      default: null,
      type: Object
    },
    isChief: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      isVisible: false,
      // 0 未接通  1 接通中  2 已接通
      isCall: 0
    }
  },


  watch: {
    detailVisible: {
      handler() {
        this.isVisible = this.detailVisible
      }
    }
  },
  beforeDestroy() {

  },

  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('detailClose', false)
      this.isVisible = false
      this.isCall = 0
    },
    handleWarning(id, level) {
      this.$emit('handleWarning', id, level)
    },
    connectSucess(status) {
      // 接通状态 - 0，未接通 1，接通中 2，已接通
      this.isCall = status
    }
  }
}
</script>

<style scoped>
.video-box {
  width: 90%;
  display: flex;
  /* justify-content: space-between; */
}

.main-video,
.second-video,
.third-video {
  width: 100%;
  height: 300px;
  margin: 20px 10px;
  display: flex;
  justify-content: space-around;
}

.main-video,
.third-video {
  width: 350px;
}

.second-video {
  width: 250px;
}

.main-video-wrap,
.second-video-wrap,
.third-video-wrap {
  text-align: center;
  color: rgb(125, 75, 95);
}
.btn-box{
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.caller-box{
  width: 100%;
  line-height: 32px;
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
}
.caller-item{
  width: 50px;
  line-height: 32px;
  display: block;
  font-size: 25px;
  text-align: center;
  border:1px solid rgb(111, 107, 108);
  border-radius:5px;
}
.el-icon-phone-outline{
  color: rgb(85, 237, 100);
}
.el-icon-phone{
  color: rgb(246, 84, 84);
}
.caller-loading{
  line-height: 32px;
  font-size: 20px;
  color: rgb(229, 198, 83);
}
</style>
