var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.checkPermission()
        ? _c(
            "DicListSelectCommon",
            _vm._b(
              {
                attrs: {
                  config: _vm.config,
                  placeholder: "请选择" + _vm.placeholder,
                },
                model: {
                  value: _vm.currentValue,
                  callback: function ($$v) {
                    _vm.currentValue = $$v
                  },
                  expression: "currentValue",
                },
              },
              "DicListSelectCommon",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "el-input",
            _vm._b(
              {
                attrs: { placeholder: "请输入" + _vm.placeholder + "编号" },
                model: {
                  value: _vm.currentValue,
                  callback: function ($$v) {
                    _vm.currentValue = $$v
                  },
                  expression: "currentValue",
                },
              },
              "el-input",
              _vm.$attrs,
              false
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }