var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-descriptions",
        { attrs: { colon: false, title: "基本信息", border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "版本" } }, [
            _vm._v(" V1.0.0 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "软件语言" } }, [
            _vm._v(" 简体中文 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "系统兼容" } }, [
            _vm._v(" 支持 64位的 Win10 / Win11 操作系统 "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "大小" } }, [
            _vm._v(" 185 KB "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "更新时间" } }, [
            _vm._v(" 2024/06/25 "),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "其他" } },
            [
              _c(
                "el-row",
                { attrs: { span: 20 } },
                [
                  _c("el-tag", { staticClass: "right_10" }, [
                    _vm._v("安全无毒"),
                  ]),
                  _c("el-tag", { attrs: { type: "success" } }, [
                    _vm._v("无插件"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content_wrap" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip",
              target: "_blank",
            },
          },
          [
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v("点此下载"),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content_wrap" }, [
        _c("div", { staticClass: "content_tit" }, [_vm._v("安装说明")]),
        _c("div", { staticClass: "content_item" }, [
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 1. 将压缩包下载到本地解压后，鼠标双击解压后的文件，即可完成安装并启动。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box" },
            [
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_1, fit: "fill" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "content_item" }, [
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 2. 部分用户在安装后运行应用程序时可能会出现如下图所示的无法打开情况。这是windows系统启用了安全机制 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_2, fit: "fill" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 您可以通过允许安装进行继续操作：更多信息 -> 仍要运行 如下图： "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_3, fit: "fill" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 3. 根据安装向导提示完成安装选项、安装位置的设置，点击 完成 即可完成安装并运行考试客户端程序。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_4, fit: "fill" },
              }),
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_5, fit: "fill" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content_item_tit" }, [
            _vm._v(
              " 注：软件安装时，若弹出如下提示，是由于电脑系统版本缺少必备文件导致，请下载vc runtime安装包（注：安装完成后需要重启电脑），下载地址： "
            ),
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "content_item_box_2" },
            [
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_6, fit: "fill" },
              }),
              _c("el-image", {
                staticStyle: { width: "48%" },
                attrs: { src: _vm.caozuoImg.az_7, fit: "fill" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticStyle: { color: "blue" },
          attrs: {
            href: "https://learn.microsoft.com/zh-cn/cpp/windows/latest-supported-vc-redist?view=msvc-170",
          },
        },
        [
          _vm._v(
            " https://learn.microsoft.com/zh-cn/cpp/windows/latest-supported-vc-redist?view=msvc-170 "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }