import moment from 'moment'
export const CountdownMixin = {
  data() {
    return {
      remainingTime: '', // 考试剩余时间
      timeInterval: '' // 计时器
    }
  },
  watch: {
    'examInfo.startTime': {
      handler(val) {
        if (val) {
          this.remainingTime = this.getRemainingTime()
        }
      }
    }
  },
  destroyed() {
    this.cleanTimeout()
  },
  methods: {
    cleanTimeout() {
      if (this.timeInterval) clearTimeout(this.timeInterval)
    },
    getRemainingTime() {
      this.cleanTimeout()
      let res = ''
      const now = moment()
      let startTime = ''
      let endTime = ''

      function zeroFill(v) {
        let _v = v
        if (_v < 10) {
          _v = '0' + _v
        }
        return _v
      }

      if (this.examInfo && this.examInfo.startTime) {
        startTime = moment(this.examInfo.startTime, 'YYYY-MM-DD HH:mm')
        endTime = moment(this.examInfo.endTime, 'YYYY-MM-DD HH:mm')
      }
      if (startTime > now) {
        let temp = moment(startTime).diff(now, 'minutes') + 1
        if (temp > 60) {
          temp = moment(startTime).diff(now, 'hours')
          if (temp <= 1) {
            const diff = moment(startTime).diff(now)

            res = `${zeroFill(moment.duration(diff).hours())}:${zeroFill(
              moment.duration(diff).minutes()
            )}:${zeroFill(moment.duration(diff).seconds())}`
          } else if (temp > 24) {
            temp = moment(startTime).diff(now, 'days')
            res = temp + '天'
          } else {
            res = temp + '小时'
          }
        } else {
          res = temp + '分钟'
        }
        this.timeInterval = setTimeout(() => {
          this.remainingTime = this.getRemainingTime()
        }, 1000)

        // console.log('剩余时间', res)
      } else if (endTime < now) {
        res = '考试已结束'
      } else {
        res = '考试已开始'
      }
      return res
    }
  }
}
