var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "body",
    },
    [
      _vm.deviceFlag
        ? _c(
            "div",
            [
              _vm.info
                ? _c("GuideHeader", {
                    attrs: { info: _vm.info, "exam-info": _vm.examInfo },
                  })
                : _vm._e(),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.exam.title)),
                  ]),
                ]),
                _c("div", { staticClass: "guide-content" }, [
                  _c("div", { staticClass: "guide-left" }, [
                    _c(
                      "div",
                      { staticClass: "guide" },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.active,
                              direction: "vertical",
                            },
                          },
                          _vm._l(_vm.stepList, function (item, i) {
                            return _c("el-step", {
                              key: i,
                              attrs: { title: item.title },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "guide-right" },
                    [
                      _c(
                        "el-card",
                        { staticClass: "card-content" },
                        [
                          _c(_vm.currentComp, {
                            tag: "component",
                            attrs: {
                              "go-last-step-handler": _vm.goLastStepHandler,
                              "go-next-step-handler": _vm.goNextStepHandler,
                              info: _vm.info,
                              "exam-info": _vm.examInfo,
                              "is-show": true,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "footer" }, [
                _c("div", { staticClass: "footer-inner" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
                  }),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }