<template>

  <div>
    <el-page-header content="公告详情" @back="$navBack" />

    <h3>{{ formData.title }}</h3>
    <small>发布时间：{{ formData.createTime }}</small>
    <el-divider />
    <div class="notice" v-html="$options.filters.formatRichText(formData.content)" />

  </div>

</template>

<script>
import { fetchDetail } from '@/api/sys/notice/notice'

export default {

  data() {
    return {

      formData: {
        title: '',
        content: ''
      }
    }
  },

  created() {
    const id = this.$route.params.id
    this.handleDetail(id)
  },

  methods: {

    handleDetail(id) {
      fetchDetail(id).then(res => {
        this.formData = res.data
      })
    }
  }
}
</script>

<style scoped>

::v-deep .notice img{
  max-width: 100%;
  border: #eee 1px solid;
  margin: 10px 0px;
}

::v-deep .notice div{
  line-height: 28px;
  font-size: 14px;
  color: #333;
}
</style>
