<template>
  <el-select
    v-model="roleIds"
    :remote-method="fetchData"
    :placeholder="placeholder"
    size="small"
    style="width: 100%"
    filterable
    remote
    multiple
    reserve-keyword
    clearable
    class="filter-item"
    automatic-dropdown
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="`${item.roleName}(${item.roleLevel})`"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { fetchList } from '@/api/sys/role/role'

export default {
  name: 'RoleSelect',
  props: {
    value: Array,
    placeholder: {
      type: String,
      default: '请选择角色'
    }
  },
  data() {
    return {
      // 下拉选项值
      list: [],
      roleIds: [],
      placeholderVal: ''
    }
  },

  watch: {
    placeholder: {
      handler() {
        this.placeholderVal = this.placeholder
      },
      immediate: true
    },
    // 检测查询变化
    value: {
      handler() {
        this.roleIds = this.value
      },
      deep: true
    }
  },
  created() {
    this.roleIds = this.value
    this.fetchData()
  },
  methods: {
    fetchData() {
      fetchList().then((response) => {
        this.list = response.data
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>
::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}
</style>
