<template>
  <div>
    <file-upload-default
      v-model="fileList"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType"
    />

    <!-- <file-upload-local
      v-if="conf.provider==='local'"
      v-model="fileList"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType" />

    <file-upload-oss
      v-if="conf.provider==='aliyun'"
      v-model="fileList"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType"
    />

    <file-upload-qiniu
      v-if="conf.provider==='qiniu'"
      v-model="fileList"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType" />

    <file-upload-cos
      v-if="conf.provider==='qcloud'"
      v-model="fileList"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType" /> -->
  </div>
</template>

<script>
import { fetchDetail } from '@/api/sys/config/prop'
// import FileUploadOss from './oss'
// import FileUploadLocal from './local'
// import FileUploadQiniu from './qiniu'
// import FileUploadCos from '@/components/FileUpload/cos'

import FileUploadDefault from './default'

export default {
  name: 'FileUpload',
  components: {
    // FileUploadCos,
    // FileUploadQiniu,
    // FileUploadLocal,
    FileUploadDefault
  },
  props: {
    // 接收一个值或列表
    value: [String, Array],
    accept: {
      type: String,
      default: '*'
    },
    tips: String,
    listType: {
      type: String,
      default: 'picture'
    },
    compress: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 1
    },
    showUrl: {
      type: String,
      default: ''
    },
    urlList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      conf: '',
      fileList: []
    }
  },

  watch: {
    // 初始化文件状态
    value: {
      handler(val) {
        this.fillValue(val)
      }
    },
    // 回调数据
    fileList: {
      handler(val) {
        this.successBack(val)
      },
      deep: true
    }
  },
  mounted() {
    // 查找配置
    this.fetchConfig()
  },

  created() {
    this.fillValue()
  },

  methods: {
    // 填充数据
    fillValue() {
      if (!this.limit || this.limit === 1) {
        this.fileList = this.value
          ? [{ url: this.showUrl, name: this.value }]
          : []
      } else {
        this.fileList = this.value || []
      }
    },

    // 查找配置情况
    fetchConfig() {
      fetchDetail('upload', '').then((res) => {
        this.conf = res.data
      })
    },

    // 上传成功回调
    successBack(data) {
      let res = null
      // 单文件返回URL
      if (!this.limit || this.limit === 1) {
        res = data.length > 0 ? data[0].url : ''
        const { url, name } = data.length > 0 ? data[0] : {}
        this.$emit('input', name)
        this.$emit('update:show-url', url)
      } else {
        res = data
        this.$emit('input', res)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  overflow: hidden;
  background-color: #969696;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  margin: 0 8px 8px 0;
  display: inline-block;
}

::v-deep .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  cursor: pointer;
  line-height: 90px;
  vertical-align: top;
}
</style>
