var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "exam-ps" },
        [
          _vm.paperData && _vm.paperData.id
            ? _c(
                "div",
                {
                  staticClass: "user-box",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [_vm._v(" " + _vm._s(_vm.paperData.title) + " ")]
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-card", { staticClass: "lay-box" }, [
                    _vm.isCam
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              height: "150px",
                              "margin-bottom": "10px",
                            },
                          },
                          [
                            _c("exam-cam", {
                              attrs: {
                                height: 150,
                                "paper-id": _vm.paperId,
                                "exam-id": _vm.paperData.examId,
                                "check-tool-show": _vm.checkToolShow,
                                "capture-p": _vm.captureP,
                              },
                              on: {
                                captureSucess: _vm.captureSucess,
                                started: _vm.onVideoStarted,
                                checkReady: _vm.checkReady,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.paperData && _vm.paperData.id && !_vm.isSection
                      ? _c(
                          "div",
                          [
                            _vm.paperData.examType === 1 &&
                            _vm.paperData.camOn &&
                            _vm.paperData.camInterval > 0
                              ? _c("exam-cam-normal", {
                                  attrs: {
                                    "cam-on": _vm.paperData.camOn,
                                    "cam-interval": _vm.paperData.camInterval,
                                    "paper-id": _vm.paperData.id,
                                  },
                                  on: { break: _vm.handleBreak },
                                })
                              : _vm._e(),
                            _vm.videoStarted
                              ? _vm._l(
                                  _vm.paperData.groupList,
                                  function (type) {
                                    return _c("div", { key: type.id }, [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(" " + _vm._s(type.title) + " "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "right",
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "总" +
                                                _vm._s(type.quCount) +
                                                "题/共" +
                                                _vm._s(type.totalScore) +
                                                "分"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "card-box" },
                                        _vm._l(type.quList, function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              class: {
                                                "sub-card":
                                                  item.subList &&
                                                  item.subList.length > 0,
                                              },
                                            },
                                            [
                                              !item.subList ||
                                              item.subList.length === 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.fetchQuData(
                                                            item.quId,
                                                            "",
                                                            _vm.queryforbidBackOn ==
                                                              1
                                                              ? false
                                                              : _vm.queryforbidBackOn ==
                                                                2
                                                              ? item.answered
                                                              : true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: {
                                                            num: true,
                                                            right:
                                                              item.answered &&
                                                              _vm.currentId !==
                                                                item.quId,
                                                            current:
                                                              _vm.currentId ===
                                                              item.quId,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.sort
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "flag" },
                                                        [
                                                          item.mark
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-s-flag",
                                                                staticStyle: {
                                                                  color:
                                                                    "#ff0000",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._l(
                                                    item.subList,
                                                    function (sub) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: sub.id,
                                                          staticClass: "item",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.fetchQuData(
                                                                item.quId,
                                                                sub.quId,
                                                                _vm.queryforbidBackOn ==
                                                                  1
                                                                  ? false
                                                                  : _vm.queryforbidBackOn ==
                                                                    2
                                                                  ? item.answered
                                                                  : true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: {
                                                                num: true,
                                                                right:
                                                                  sub.answered &&
                                                                  _vm.currentSub !==
                                                                    sub.quId,
                                                                current:
                                                                  _vm.currentSub ===
                                                                  sub.quId,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.sort
                                                                  ) +
                                                                  "." +
                                                                  _vm._s(
                                                                    sub.sort
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flag",
                                                            },
                                                            [
                                                              sub.mark
                                                                ? _c("i", {
                                                                    staticClass:
                                                                      "el-icon-s-flag",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#ff0000",
                                                                      },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                            ],
                                            2
                                          )
                                        }),
                                        0
                                      ),
                                    ])
                                  }
                                )
                              : _vm._e(),
                            _c("el-divider"),
                            _c("div", [
                              _c("div", { staticClass: "Living-example-box" }, [
                                _c("div", { staticClass: "tag-box" }, [
                                  _c("div", { staticClass: "tag" }),
                                  _c("div", { staticClass: "tag-tit" }, [
                                    _vm._v("未做"),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tag-box" }, [
                                  _c("div", { staticClass: "tag tblue" }),
                                  _c("div", { staticClass: "tag-tit" }, [
                                    _vm._v("已做"),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tag-box" }, [
                                  _c("div", { staticClass: "tag tyellow" }),
                                  _c("div", { staticClass: "tag-tit" }, [
                                    _vm._v("选中"),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tag-box" }, [
                                  _c("div", [
                                    _c("i", {
                                      staticClass: "el-icon-s-flag tred",
                                    }),
                                  ]),
                                  _c("div", { staticClass: "tag-tit" }, [
                                    _vm._v("标记"),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _vm.videoStarted && _vm.isSection
                    ? _c(
                        "el-card",
                        {
                          staticClass: "lay-box",
                          attrs: { id: "exam-body" },
                          on: { scroll: _vm.bodyScroll },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("AnswerBySection", {
                                attrs: {
                                  "exam-info": _vm.examInfo,
                                  "exam-list": _vm.examList,
                                  current: _vm.gropcurrent,
                                },
                                on: { fillSection: _vm.fillSection },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        [
                          _vm.paperData && _vm.paperData.id && _vm.videoStarted
                            ? _c(
                                "el-card",
                                {
                                  staticClass: "lay-box",
                                  attrs: { id: "exam-body" },
                                  on: { scroll: _vm.bodyScroll },
                                },
                                [
                                  _vm.isRefresh
                                    ? _c(
                                        "div",
                                        [
                                          _vm.paperData.answerMode === 1 ||
                                          _vm.paperData.answerMode === 3
                                            ? _c("ExamStepMode", {
                                                ref: "examStepMode",
                                                attrs: { paper: _vm.paperData },
                                                on: { fill: _vm.handleAnswer },
                                              })
                                            : _c("ExamFullMode", {
                                                ref: "examFullMode",
                                                attrs: { paper: _vm.paperData },
                                                on: { fill: _vm.handleAnswer },
                                              }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _c("el-card", { staticClass: "lay-box" }, [
                                _c("div", [
                                  _vm._v(
                                    " 请先按页面提示启动监考视频，如果页面没有提示，请刷新后重试！ "
                                  ),
                                ]),
                                _c("div", { staticClass: "loading" }, [
                                  _vm._v("加载中......."),
                                ]),
                              ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "lay-box" },
                    [
                      _vm.paperData && _vm.paperData.id
                        ? _c("div", { staticClass: "user-box" }, [
                            _vm._v(
                              " " + _vm._s(_vm.paperData.userId_dictText) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.isCam
                        ? _c("div", { staticStyle: { margin: "10px 0" } }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.firstStatus == 3,
                                    expression: "firstStatus == 3",
                                  },
                                ],
                                staticClass: "check-box",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-camera-solid check-log",
                                }),
                                _vm._v("主摄像头监控中 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.firstStatus == 2,
                                    expression: "firstStatus == 2",
                                  },
                                ],
                                staticClass: "check-box2",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-camera-solid check-log",
                                }),
                                _vm._v("主摄像头监控失败 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.secondStatus == 3,
                                    expression: "secondStatus == 3",
                                  },
                                ],
                                staticClass: "check-box",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-mobile-phone check-log",
                                }),
                                _vm._v("手机监控中 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.secondStatus == 2,
                                    expression: "secondStatus == 2",
                                  },
                                ],
                                staticClass: "check-box2",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-mobile-phone check-log",
                                }),
                                _vm._v("手机监控失败 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.thirdStatus == 3,
                                    expression: "thirdStatus == 3",
                                  },
                                ],
                                staticClass: "check-box",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-s-platform check-log",
                                }),
                                _vm._v("屏幕监控中 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.thirdStatus == 2,
                                    expression: "thirdStatus == 2",
                                  },
                                ],
                                staticClass: "check-box2",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-s-platform check-log",
                                }),
                                _vm._v("屏幕监控失败 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.checkToolShow == "true" &&
                                      _vm.checkToolDialog == 1,
                                    expression:
                                      "checkToolShow=='true'&&checkToolDialog == 1",
                                  },
                                ],
                                staticClass: "check-box2",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-s-platform check-log",
                                }),
                                _vm._v("防作弊工具检测未启动 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.checkToolShow == "true" &&
                                      _vm.checkToolDialog == 2,
                                    expression:
                                      "checkToolShow=='true'&&checkToolDialog == 2",
                                  },
                                ],
                                staticClass: "check-box",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-s-platform check-log",
                                }),
                                _vm._v("防作弊工具检测通过 "),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.checkToolShow == "true" &&
                                      _vm.checkToolDialog == 3,
                                    expression:
                                      "checkToolShow=='true'&&checkToolDialog == 3",
                                  },
                                ],
                                staticClass: "check-box2",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-s-platform check-log",
                                }),
                                _vm._v("防作弊工具检测未通过 "),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.paperData && _vm.paperData.id && !_vm.isSection
                        ? _c(
                            "el-row",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "line-height": "30px",
                              },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _vm._v(" 剩余时间 "),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c("exam-timer", {
                                    on: {
                                      timeout: function ($event) {
                                        return _vm.handWithEx(0)
                                      },
                                    },
                                    model: {
                                      value: _vm.paperData.leftSeconds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.paperData,
                                          "leftSeconds",
                                          $$v
                                        )
                                      },
                                      expression: "paperData.leftSeconds",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [_c("el-divider")],
                                1
                              ),
                              _vm.videoStarted
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "warning" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doHandler(0)
                                            },
                                          },
                                        },
                                        [_vm._v("提交试卷")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.paperData.actionOn &&
                              _vm.paperData.actionInterval > 0
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("el-divider"),
                                      _c("action-checker", {
                                        attrs: {
                                          "action-on": _vm.paperData.actionOn,
                                          "action-interval":
                                            _vm.paperData.actionInterval,
                                          trigger: _vm.actionTrigger,
                                        },
                                        on: {
                                          break: function ($event) {
                                            return _vm.doHandler(4)
                                          },
                                        },
                                      }),
                                      _vm._v(" 秒内无任何操作将自动交卷！ "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.videoStarted
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [_c("el-divider")],
                                    1
                                  )
                                : _vm._e(),
                              _vm.videoStarted &&
                              (_vm.queryforbidBackOn != 1 ||
                                _vm.queryforbidBackOn != "1")
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: !_vm.showPrevious,
                                            icon: "el-icon-back",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleNext(-1)
                                            },
                                          },
                                        },
                                        [_vm._v("上一题")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.videoStarted
                                ? _c(
                                    "el-col",
                                    {
                                      staticStyle: { "padding-top": "20px" },
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: !_vm.showNext,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleNext(1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("下一题"),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-right el-icon--right",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.videoStarted && !_vm.showNext && _vm.groupShow
                                ? _c(
                                    "el-col",
                                    {
                                      staticStyle: { "padding-top": "20px" },
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.nextchapter } },
                                        [
                                          _vm._v("下一章"),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-right el-icon--right",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "tool-box" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "500",
                                trigger: "click",
                              },
                            },
                            [
                              _c("div", [_c("calculator")], 1),
                              _c(
                                "el-button",
                                {
                                  staticClass: "tool-btn",
                                  attrs: {
                                    slot: "reference",
                                    size: "mini",
                                    icon: "el-icon-mobile-phone",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("计算")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "tool-btn",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-chat-round",
                              },
                              on: { click: _vm.handleConsultModal },
                            },
                            [_vm._v("咨询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.paperData &&
          _vm.paperData.id &&
          _vm.paperData.leaveOn &&
          _vm.paperData.leaveCount > 0
            ? _c("leave-checker", {
                attrs: {
                  "leave-on": _vm.paperData.leaveOn,
                  "leave-check": _vm.paperData.leaveCheck,
                  "leave-count": _vm.paperData.leaveCount,
                  "paper-id": _vm.paperData.id,
                  "exam-id": _vm.paperData.examId,
                  "check-tool-dialog": _vm.checkToolDialog,
                },
                on: {
                  break: function ($event) {
                    return _vm.handleBreak("因切屏次数超限，系统已强制交卷！")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.centerDialogVisible,
            title: "考试须知",
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "notice-tit" }, [
            _c("span", [_vm._v("为保证考试公平公正，您务必遵守以下规则:")]),
          ]),
          _c("div", { staticClass: "notice-box" }, [
            _c("span", [
              _vm._v(
                " 1.监考官会通过监控大屏全程观察考生举止行为，严禁使用手机或平板电脑查阅信息，严禁查看考试辅助书籍等材料"
              ),
            ]),
            _c("span", [
              _vm._v(
                " 2.根据系统要求开启监控设备，考试中严禁有其他人员陪同，例如家人、同事、朋友等"
              ),
            ]),
            _c("span", [
              _vm._v(
                " 3.考试中禁止替考，跳出考试页面，接打电话，左顾右盼，离开座位，不要佩敷耳机、帽子，口罩，头发不能遮盖耳朵不要遮挡监控设备，衣着要得体"
              ),
            ]),
            _c("span", [
              _vm._v(" 4.考官会随机抽查考生座位周围环境，请配合考官操作"),
            ]),
            _c("span", [
              _vm._v(
                " 5.系统将在考试过程中进行抓拍验证，请保证脸部正面始终处于主摄像头中"
              ),
            ]),
          ]),
          _c("div", { staticClass: "notice-tit" }, [
            _c("span", [_vm._v("重要提示:")]),
          ]),
          _c("div", { staticClass: "notice-box" }, [
            _c("span", [_vm._v(" 1.任意作整行为一经核实，将取消资格")]),
            _c("span", [
              _vm._v(
                " 2.考生的个人信息、抓拍照片、答题信息将受到严格保护，不会向任何第三方透露"
              ),
            ]),
            _c("span", [
              _vm._v(" 3.禁止拍摄、记录试题，泄露考试试题内容属于侵权行为"),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOK } },
                [_vm._v("我知道了")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            title: "在线咨询",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "hot-number" }, [
            _vm._v("热线电话：400-123-1234"),
          ]),
          _c(
            "el-button",
            {
              staticClass: "common-problem-btn",
              attrs: { type: "text" },
              on: { click: _vm.problemhandleOpene },
            },
            [
              _vm._v("常见问题"),
              _c("i", { staticClass: "el-icon-question el-icon--right" }),
            ]
          ),
          _c("div", { staticClass: "chat-wrap" }, [
            _c(
              "div",
              { staticClass: "chat-box" },
              _vm._l(_vm.newsList, function (item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.type == 1,
                          expression: "item.type == 1",
                        },
                      ],
                      staticClass: "chat-left",
                    },
                    [
                      _c(
                        "el-avatar",
                        { staticStyle: { "margin-right": "10px" } },
                        [_vm._v(" 管理 ")]
                      ),
                      _c("div", { staticClass: "chat-content" }, [
                        _vm._v(_vm._s(item.news)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.type == 2,
                          expression: "item.type == 2",
                        },
                      ],
                      staticClass: "chat-right",
                    },
                    [
                      _c(
                        "el-avatar",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v(" 我 ")]
                      ),
                      _c("div", { staticClass: "chat-content" }, [
                        _vm._v(_vm._s(item.news)),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "chat-Controls" }, [
              _c(
                "div",
                { staticClass: "chat-input" },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 4,
                      type: "textarea",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "chat-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.sendMsg },
                    },
                    [_vm._v("发送")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.problemVisible,
            "before-close": _vm.problemhandleClose,
            title: "常见问题回答",
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.problemVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "el-tab-pane",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.noticeLoading,
                      expression: "noticeLoading",
                    },
                  ],
                  key: item.value,
                  attrs: { name: item.value, label: item.title },
                },
                [
                  _vm.noticeList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "notice-wrap" },
                        _vm._l(_vm.noticeList, function (notice, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "notice-item" },
                            [
                              _c("div", { staticClass: "notice-q" }, [
                                _c("i", {
                                  staticClass: "el-icon-question",
                                  staticStyle: {
                                    color: "#d17979",
                                    "margin-right": "10px",
                                    "line-height": "25px",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("问: " + _vm._s(notice.question)),
                                ]),
                              ]),
                              _c("div", { staticClass: "notice-a" }, [
                                _c("i", {
                                  staticClass: "el-icon-info",
                                  staticStyle: {
                                    color: "#5c9df7",
                                    "margin-right": "10px",
                                    "line-height": "25px",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("答: " + _vm._s(notice.answer)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("el-empty", { attrs: { "image-size": 200 } }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }