<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="设备调试"
    width="800px"
    @close="handleClose"
  >
    <div>
      <div v-if="currentStep == 1">
        <div style="height: 430px">
          <video-presenter
            :height="430"
            :cam-type="'facade'"
            :paperid="checkData.studentVideoCheckName"
            :is-stop="!dialogVisible"
          />
        </div>
        <div class="tips-lg" @click="handleClose">
          能看到视频图像则表示摄像头正常，点此关闭
        </div>
        <div class="tips-sm">
          <div>1、网页必须基于https下才能调用摄像头和麦克风设备。</div>
          <div>
            2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器、Safari浏览器、Edge浏览器。
          </div>
          <div>3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。</div>
        </div>
      </div>
      <div v-if="currentStep == 2">
        <div
          style="height: 430px; display: flex; justify-content: space-between"
        >
          <div style="height: 430px; width: 550px">
            <video-viewer
              :height="430"
              :width="550"
              :cam-type="'side'"
              :online="true"
              :paperid="checkData.studentVideoCheckName"
              :is-stop="!dialogVisible"
            />
          </div>

          <div class="qrcode-box">
            <div class="qrcode-tit">副摄像头二维码</div>
            <div class="qrcode-body">
              <!-- <el-image :src="url" fit="fill" style="width: 100%; height: 100%" /> -->
              <div id="qrcodediv" ref="qrcodediv" />
            </div>
            <div class="qrcode-tip">请使用手机扫描上面二维码</div>
          </div>
        </div>
        <div class="tips-lg" @click="handleClose">
          能看到视频图像则表示摄像头正常，点此关闭
        </div>
        <div class="tips-sm">
          <div>1、调试成功后请退出手机端、正式考试需再次进行扫码。</div>
          <div>
            2、扫码成功后请将摄像头放置于电脑斜45度，确保能看到完整的视角。
          </div>
          <div>3、请保证摄像头开启直至考试结束、注意手机不要息屏。</div>
        </div>
      </div>
      <div v-if="currentStep == 3">
        <div style="height: 430px">
          <!-- 屏幕共享 -->
          <screen-sharing
            :paperid="checkData.studentVideoCheckName"
            :tracking="false"
            :height="430"
            :is-stop="!dialogVisible"
          />
        </div>
        <div class="tips-lg" @click="handleClose">
          能看到视频图像则表示摄像头正常，点此关闭
        </div>
        <div class="tips-sm">
          <div>1、点击【整个屏幕】选项卡 > 选中屏幕图像 > 点击【分享】按钮</div>
          <div>
            2、Mac电脑需要给浏览器授权才能进行屏幕录制，请在【隐私与安全】为浏览器开启录制权限。
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button
          :disabled="currentStep <= 1"
          style="width: 49%"
          type="primary"
          icon="el-icon-arrow-left"
          @click="next(-1)"
        >上一步</el-button
        >
        <el-button
          :disabled="currentStep >= 3"
          style="width: 49%"
          type="primary"
          @click="next(1)"
        >下一步<i
          class="el-icon-arrow-right el-icon--right"
        /></el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// import { getqrcode } from '@/api/exam/exam'
import QRCode from 'qrcodejs2'
export default {
  name: 'MoreDeviceTest',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkData: {
      type: Object
    }
  },
  data() {
    return {
      dialogVisible: false,
      camSuccess: false,
      currentStep: 1,
      // url: ''
      qrCode: null
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    },
    currentStep: {
      handler() {
        if (this.currentStep == 2) {
          const timer = setInterval(() => {
            this.createdQrCode()
            if (this.$refs.qrcodediv) {
              clearInterval(timer)
            }
          }, 500)
        }
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
    // getqrcode(this.checkData.tmplId).then(res => {
    //   console.log(res, 'kkkkkkkkkkkkkk')
    //   this.url = res.data
    // })
  },
  // mounted() {
  //   this.createdQrCode()
  // },

  methods: {
    createdQrCode() {
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      this.qrCode = new QRCode(this.$refs.qrcodediv, {
        width: 180,
        height: 180,
        text: `${process.env.VUE_APP_URL_PREFIX}/across/selectcamera?name=${this.checkData.studentVideoCheckName}&type=side`
      })
      console.log(
        `${process.env.VUE_APP_URL_PREFIX}/across/selectcamera?name=${this.checkData.studentVideoCheckName}&type=side`,
        '@@@@@@@@@@@@@@'
      )
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    next(number) {
      console.log(number, 'number')
      this.currentStep += number
    }
  }
}
</script>

<style scoped>
.tips-lg {
  background-color: #4377fb;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 10px 0;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
}

.tips-sm {
  line-height: 22px;
  margin-top: 10px;
  color: #888;
  font-size: 12px;
}

.btn-box {
  width: 100%;
  margin-top: 10px;
}

.qrcode-box {
  text-align: center;
}

.qrcode-tit {
  color: rgb(89, 135, 251);
}

.qrcode-tip {
  color: rgb(255, 171, 111);
}

.qrcode-body {
  margin: 10px 0;
  width: 180px;
  height: 180px;
}
</style>
