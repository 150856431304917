<template>
  <div>
    <user-select-dialog
      :dialog-show.sync="dialogShow"
      :single="true"
      @select="handleSelected"
    />
    <!-- :exclude-departs="deptCodes"
        :excludes="excludes" -->

    <div v-if="checkPermission()" style="position: relative; top: 2px">
      <el-input
        v-model="currentValue"
        v-bind="$attrs"
        :placeholder="`请选择${placeholder}`"
        clearable
        @focus="dialogShow = !dialogShow"
      />
    </div>

    <el-input
      v-else
      v-model="currentId"
      v-bind="$attrs"
      :placeholder="`请输入${placeholder}编号`"
    />
  </div>
</template>

<script>
import UserSelectDialog from '@/views/admin/sys/user/components/UserSelectDialog'
import { PermissionMixin } from '../DicListSelectCommonP/mixin'

export default {
  name: 'UserSelectDialogDir',
  components: { UserSelectDialog },
  mixins: [PermissionMixin],
  props: {
    placeholder: String,
    value: {
      type: String | Number
    },
    data: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    searchNameKey: {
      type: String,
      default: 'title'
    },
    config: {
      type: Object,
      default: () => {
        return {
          label: 'title',
          value: 'title'
        }
      }
    }
  },
  data() {
    return {
      dialogShow: false,
      currentValue: '',
      currentId: ''
    }
  },
  watch: {
    // 回调数据
    currentId: {
      handler(val) {
        this.handlerChange(val)
      },
      deep: true
    },
    currentValue: {
      handler(val) {
        if (!val) {
          this.currentId = ''
        }
      }
    }
  },
  methods: {
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    },
    handleSelected(id, val) {
      if (val && val.length > 0) {
        this.currentValue = val[0].realName
        this.currentId = val[0].id
      } else {
        this.currentValue = ''
        this.currentId = ''
      }
    }
  }
}
</script>
