var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择远程考试",
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c("data-table", {
            ref: "pagingTable",
            attrs: { options: _vm.options, "list-query": _vm.listQuery },
            on: { "select-changed": _vm.handleSelected },
            scopedSlots: _vm._u([
              {
                key: "filter-content",
                fn: function () {
                  return [
                    _c("dic-list-select", {
                      attrs: {
                        "dic-code": "remote_exam_status",
                        title: "考试项目状态",
                      },
                      model: {
                        value: _vm.listQuery.params.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "state", $$v)
                        },
                        expression: "listQuery.params.state",
                      },
                    }),
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "搜索关键字",
                        size: "small",
                        clearable: "",
                      },
                      model: {
                        value: _vm.listQuery.params.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "title", $$v)
                        },
                        expression: "listQuery.params.title",
                      },
                    }),
                    !_vm.single
                      ? _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleConfirm },
                          },
                          [_vm._v(_vm._s(_vm.selectedLabel))]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "data-columns",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50", align: "center" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "项目名称",
                        align: "left",
                        prop: "title",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属单位",
                        align: "left",
                        prop: "enterpriseName",
                        width: "160px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        align: "center",
                        width: "80px",
                        prop: "state_dictText",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "职位",
                        prop: "stats.careerNum",
                        align: "right",
                        width: "60px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.stats &&
                                      scope.row.stats.careerNum) ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "考生",
                        prop: "stats.studentNum",
                        align: "right",
                        width: "60px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.stats &&
                                      scope.row.stats.studentNum) ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "试卷", align: "right", width: "60px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.stats &&
                                      scope.row.stats.tmplNum) ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "监考",
                        prop: "stats.invigilatorNum",
                        align: "right",
                        width: "60px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.stats &&
                                      scope.row.stats.invigilatorNum) ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "阅卷",
                        prop: "stats.examinerNum",
                        align: "right",
                        width: "60px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.stats &&
                                      scope.row.stats.examinerNum) ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "考试时间",
                        align: "center",
                        width: "160px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.startTime) + " 至 "
                              ),
                              _c("br"),
                              _vm._v(" " + _vm._s(scope.row.endTime) + " "),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.single
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            width: "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRow(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 选择 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1903463731
                          ),
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }