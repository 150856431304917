<template>
  <div>
    <el-alert
      type="info"
      title="未认证或认证失败的用户，可手动指定用于人脸识别的用户照片记录！"
    />

    <data-table ref="pagingTable" :options="options" :list-query="listQuery">
      <template #data-columns>
        <!-- <el-table-column type="selection" width="55" /> -->

        <!-- <el-table-column align="center" label="日志ID" prop="id" /> -->

        <el-table-column align="center" label="供应商" prop="providerName" />

        <el-table-column align="center" label="调用接口" prop="interfaceName" />
        <!-- <el-table-column
          align="center"
          label="接口返回数据"
          prop="responseResult"
        /> -->
        <el-table-column
          align="center"
          label="认证结果"
          prop="responseSuccess"
          width="100px"
        >
          <template v-slot="scope">
            <span v-if="scope.row.responseSuccess">成功</span>
            <span v-else>失败</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="接口调用时间"
          prop="createTime"
        />
        <el-table-column label="人脸图片" align="center" width="100px">
          <template v-slot="scope">
            <el-image
              v-show="scope.row.faceImage"
              :src="`data:image/png;base64,${scope.row.faceImage}`"
              :preview-src-list="[
                `data:image/png;base64,${scope.row.faceImage}`
              ]"
              style="width: 60px; height: 60px"
              fit="fill"
            />
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="操作"
          prop="points"
          width="100px"
        >
          <template v-slot="scope">
            <el-link
              v-permission="['sys:user:update']"
              type="primary"
              icon="el-icon-document-checked"
              @click="handleUnbind(scope.row.id)"
            >指定</el-link
            >
          </template>
        </el-table-column>
      </template>
    </data-table>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { authByLog } from '@/api/sys/user/bind'

export default {
  name: 'UserPhotoLog',
  props: {
    userId: String
  },

  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          createBy: ''
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/sys/facelog/paging'
      }
    }
  },

  watch: {
    userId: {
      handler(val) {
        this.listQuery.params.createBy = val
      }
    }
  },

  created() {
    // 初始化赋值
    this.listQuery.params.createBy = this.userId

    // 刷新数据
    if (this.$refs.pagingTable) {
      this.$refs.pagingTable.getList()
    }
  },

  methods: {
    checkPermission,

    handleUnbind(id) {
      // 删除
      this.$confirm(
        // '请确定是否要要选中本次认证日志，本次认证照片将作为系统中人脸识别用户照片！',
        '该操作将使用此次认证记录中的人脸照片作为用户的实名认证照片，确定要继续吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const data = {
          id,
          force: false
        }
        authByLog(data).then((res) => {
          const { success } = res
          if (success) {
            this.$message({
              type: 'success',
              message: '设置成功!'
            })
          }
          this.$refs.pagingTable.getList()
        })
      })
    }
  }
}
</script>
