var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cam-box" }, [
    _c("div", [
      _c("video", {
        ref: "video",
        staticStyle: { background: "#262626" },
        attrs: {
          id: "video",
          width: _vm.width,
          height: _vm.height,
          playsinline: true,
          autoplay: true,
          muted: "",
        },
        domProps: { muted: true },
      }),
      _c("canvas", {
        ref: "canvas",
        attrs: { id: "canvas", width: _vm.width, height: _vm.height },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }