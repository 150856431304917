<template>
  <div class="app-container">
    <div class="face-box">
      <div v-if="errorcurrent > 0 && errorcurrent < 3" class="errorTip">
        <div>1、请核对您的姓名、身份证号信息是否正确。</div>
        <div>2、确保您的面部被足够的光线照亮，面部清晰，以便系统进行识别。</div>
        <div>
          3、请确保您的面部无遮挡，以便准确识别。摘下帽子、眼镜或其他可能遮挡脸部的物品。
        </div>
        <div>4、请保持面部处于画面中央位置，且面部表情稳定，以便准确识别。</div>
        <div>5、请调整您的识别角度，尽量与摄像头保持平视。</div>
      </div>

      <div v-if="errorcurrent >= 3" class="errorTip">
        <div>
          很抱歉，您连续几次识别都未通过，请再次尝试。如果仍然失败，请联系管理员或技术支持，核对系统录入信息是否正确。
        </div>
      </div>
      <tracking-cam
        ref="cam"
        :tracking="true"
        @tracking-error="showManual"
        @tracked="handleTracked"
      />

    </div>
    <div class="tips">请保持脸部正面在摄像框正中间，点击拍照识别进行人脸识别</div>
    <div class="footer">
      <el-button
        :loading="isloading"
        type="primary"
        @click="handleManual"
      >拍照识别</el-button
      >
    </div>
    <el-dialog
      :visible.sync="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="提示"
      width="80%"
    >
      <el-result
        v-if="show"
        :icon="tipState"
        :sub-title="tipText"
        title="温馨提示"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="show = false">知道了</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { post } from '@/utils/request'
import { setToken } from '@/utils/auth'

export default {
  name: 'MobileFace',
  components: { },
  data() {
    return {
      faceInfo: {
        id: '',
        realName: '',
        idCard: ''
      },
      manualVisible: true,
      errorcurrent: 0,
      isloading: false,
      examId: '',
      paperId: '',
      show: false,
      tipState: 'success',
      tipText: ''
    }
  },
  created() {
    console.log(this.$route, '路由是什么')
    this.examId = this.$route.query.examId
    const token = this.$route.query.token
    this.paperId = this.$route.query.paperId
    setToken(token)
  },
  methods: {
    // 关闭窗口
    handleClose() {
      this.$message.info('窗口已关闭，若要重新识别请刷新页面！')
      this.$emit('update:visible', false)
    },

    // 人工拍照
    showManual() {
      this.manualVisible = true
    },

    // 进行手工拍照
    handleManual() {
      this.$refs.cam.handleCapture()
    },

    // 拍照
    handleTracked(base64) {
      this.isloading = true
      // 附加base64图片
      const params = {
        examId: this.examId,
        useMoblie: true,
        paperId: this.paperId
      }
      this.$set(params, 'base64', base64)
      // if (this.errorcurrent<2) {
      post('/api/paper/capture/face-auth', params)
        .then((res) => {
          this.isloading = false
          this.faceInfo = res.data
          if (this.faceInfo.pass) {
            this.show = true
            this.successBack()
            this.tipState = 'success'
            this.tipText = '人脸识别成功，请继续在电脑端按步骤操作'
          } else {
            this.failBack(this.faceInfo.failMsg)
            this.tipState = 'error'
            this.tipText = '人脸识别失败，请重新拍照进行人脸识别'
          }
        })
        .catch(this.failBack)
      // } else {
      //   this.isloading = false
      //   // this.$message.error('人脸比对失败超过三次！')
      //   this.$emit('error', '人脸比对失败超过三次！')
      // }
    },

    // 成功回调
    successBack() {
      this.$message.success('人脸比对成功！')
      this.$emit('success', this.faceInfo)
      setTimeout(() => {
        this.$emit('update:visible', false)
      }, 2000)
    },

    // 识别失败
    failBack(msg) {
      this.$emit('fail')
      this.$refs.cam.reTrack()
      this.errorcurrent += 1
      // 显示消息
      if (msg) {
        // this.$message.error(msg)
        this.$emit('error', msg)
      }
    }
  }
}
</script>
<style scoped>
.app-container {
  width: 100%;
  height: 100vh;
  padding: 10px;
}
.footer{
  margin: 100px 0;
  display: flex;
  justify-content: space-around;
}
.tips  {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
</style>
