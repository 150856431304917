var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container-bg" },
    [
      _c(
        "el-header",
        { staticStyle: { padding: "0px", height: "60px" } },
        [_c("web-header")],
        1
      ),
      _c("el-main", { staticStyle: { padding: "0px" } }, [
        _c("div", { staticClass: "container main-inner" }, [_c("app-main")], 1),
      ]),
      _vm.siteData.copyRight
        ? _c("el-footer", { staticClass: "footer-copy" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }