<template>
  <!-- <el-select
    v-model="currentValue"
    :disabled="disabled"
    :placeholder="title"
    :remote-method="fetchData"
    size="small"
    filterable
    remote
    reserve-keyword
    clearable
    automatic-dropdown
    class="filter-item"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.value"
      :label="item.title"
      :value="item.value"
    />
  </el-select> -->

  <el-date-picker
    v-model="timeRange"
    :start-placeholder="title[0] || '开始时间'"
    :end-placeholder="title[1] || '结束时间'"
    size="small"
    format="yyyy-MM-dd HH:mm:ss"
    value-format="yyyy-MM-dd HH:mm:ss"
    type="datetimerange"
    range-separator="至"
    clearable
    @change="handlerChange"
  />
</template>

<script>
export default {
  name: 'DirListDatePicker',
  props: {
    title: Array,
    value: {
      type: String | Number
    },
    disabled: Boolean
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: [],
      startValue: '',
      endValue: '',
      timeRange: ['', '']
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.setDateToTimeComp()
      }
    },
    created() {
      this.setDateToTimeComp()
    }
  },
  methods: {
    setDateToTimeComp() {
      if (this.value && this.value.length > 0) {
        const [startTime, endTime] = this.value || []
        this.startValue = startTime
        this.endValue = endTime
        this.timeRange = [startTime, endTime]
      } else {
        console.error('时间组件传入起止时间两个字段')

        this.startValue = ''
        this.endValue = ''
        this.timeRange = ['', '']
      }
    },
    handlerChange(e) {
      let val = ['', '']
      if (e) {
        val = e
      }
      this.$emit('change', val)
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>
::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}
</style>
