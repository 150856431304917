var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { staticClass: "step-box" }, [
      _c(
        "div",
        { staticClass: "step-item" },
        [
          _c("div", { staticClass: "step-tit" }, [_vm._v("1.手机微信扫码")]),
          _c("div", { staticClass: "step-img" }, [
            _c("div", { ref: "qrcodediv", attrs: { id: "qrcodediv" } }),
          ]),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", placement: "top-start" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("p", [_vm._v("扫码遇到问题怎么办?")]),
                      _c("p", [_vm._v("1、请检查你的手机网络连接是否正常;")]),
                      _c("p", [
                        _vm._v("2、请更新手机微信APP至最新版，然后重新扫码;"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "3、以上方法均不能解决，请点击右侧“客服”小窗联系我们;"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源 "
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "step-tip" }, [
                _c("i", { staticClass: "el-icon-question" }),
                _vm._v(" 扫码遇故障? "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "step-item" }, [
        _c("div", { staticClass: "step-tit" }, [_vm._v("2.竖立摆放手机")]),
        _c(
          "div",
          { staticClass: "step-img" },
          [
            _c("el-image", {
              style: _vm.stp2_img_styleObj,
              attrs: { src: _vm.url_1, fit: "fill" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "step-tip" }, [_vm._v("侧后方45度放置手机")]),
      ]),
      _c("div", { staticClass: "step-item" }, [
        _c("div", { staticClass: "step-tit" }, [_vm._v("3.开启视频监控")]),
        _c(
          "div",
          { staticClass: "step-img" },
          [
            _c("el-image", {
              style: _vm.stp3_img_styleObj,
              attrs: { src: _vm.url_2, fit: "fill" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "step-tip" }, [_vm._v("确认是否开始录制")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }