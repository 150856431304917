var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.postForm.groupList, function (item) {
      return _c(
        "div",
        { key: item.id, attrs: { id: item.id } },
        _vm._l(item.quList, function (qu, index) {
          return _c(
            "el-card",
            { key: qu.id, staticClass: "content-card", attrs: { id: qu.id } },
            [
              _c("qu-item-show", {
                attrs: { value: qu, index: index, state: _vm.postForm.state },
              }),
              qu.quType === "99"
                ? _c(
                    "div",
                    _vm._l(qu.subList, function (sub, jj) {
                      return _c(
                        "div",
                        { key: sub.id },
                        [
                          _c("qu-item-show", {
                            attrs: {
                              value: sub,
                              index: jj,
                              state: _vm.postForm.state,
                              sub: true,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }