// 考试开始

const examRouteStart = {
  path: '/web/exam/start/:id',
  component: () => import('@/views/web/exam/exam'),
  name: 'StartExam',
  meta: { title: '开始考试', noCache: true, activeMenu: '/web/index' },
  hidden: true
}

export default examRouteStart
