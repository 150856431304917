var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-bg" }, [
    _c("div", { staticClass: "header-inner container" }, [
      _c("div", { staticClass: "col-logo" }, [
        _vm.siteData.frontLogoUrl
          ? _c("div", [
              _c("img", {
                staticStyle: { height: "40px" },
                attrs: {
                  src: _vm.siteData.frontLogoUrl,
                  alt: _vm.siteData.siteName,
                },
              }),
            ])
          : _c("div", { staticClass: "site-tt" }, [
              _vm._v(" " + _vm._s(_vm.siteData.siteName) + " "),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "col-menu" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                router: true,
                "default-active": _vm.activeIndex,
                mode: "horizontal",
                "background-color": "#4377fb",
                "text-color": "#fff",
                "active-text-color": "#FFD550",
              },
            },
            [
              _vm.siteData.props.moduleCourse || _vm.siteData.props.moduleExam
                ? _c(
                    "el-submenu",
                    {
                      attrs: { index: "1" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v(" 在线学习 ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3752743180
                      ),
                    },
                    [
                      _vm.siteData.props.moduleExam
                        ? _c(
                            "el-menu-item",
                            {
                              staticClass: "module-exam",
                              attrs: { index: "/web/repo" },
                            },
                            [_vm._v(" 题库训练 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.siteData.props.moduleExam
                ? _c(
                    "el-menu-item",
                    {
                      staticClass: "module-exam",
                      attrs: { index: "/web/index" },
                    },
                    [_vm._v(" 在线考试 ")]
                  )
                : _vm._e(),
              _c("el-menu-item", { attrs: { index: "/web/notice" } }, [
                _vm._v(" 系统公告 "),
              ]),
              _c("el-menu-item", { attrs: { index: "/web/uc" } }, [
                _vm._v(" 用户中心 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-user" },
        [
          _c(
            "el-badge",
            { attrs: { value: _vm.unread, max: 99, hidden: _vm.unread === 0 } },
            [
              _c(
                "div",
                { staticClass: "top-avatar", on: { click: _vm.msgClick } },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "38px" },
                      attrs: { src: _vm.avatarUrl, shape: "square" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "error" }, slot: "error" },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "38px" },
                              attrs: { src: _vm.faceUrl, shape: "square" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "error" }, slot: "error" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/web/avatar.png"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", [_vm._v(" " + _vm._s(_vm.realName) + " ")]),
          _vm.roleType === 2
            ? _c("a", { staticClass: "alink", on: { click: _vm.toAdmin } }, [
                _vm._v("管理"),
              ])
            : _vm._e(),
          _c("a", { on: { click: _vm.logout } }, [_vm._v("退出")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }