var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cam-box" },
    [
      _c("div", [
        _c("audio", {
          ref: "video",
          staticStyle: { background: "#262626" },
          attrs: {
            id: "video",
            width: 0,
            height: 0,
            autoplay: true,
            "muted:": _vm.muted,
          },
        }),
        _c("canvas", {
          ref: "canvas",
          attrs: { id: "canvas", width: 0, height: 0 },
        }),
      ]),
      _vm.noDevice
        ? _c("el-empty", {
            attrs: {
              description:
                "摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }