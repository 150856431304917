var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { height: "500px", margin: "'0 auto'" } },
      [
        _vm._v(" v1 "),
        _c("video-viewer", {
          attrs: {
            height: 430,
            width: 550,
            "cam-type": "facade",
            paperid: "1549684036545978370",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }