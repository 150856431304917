<template>

  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <dic-list-select
        v-model="listQuery.params.examType"
        dic-code="exam_type"
        class="filter-item"
        style="width: 200px"
        title="选择考试类型"
      />

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索考试名称"
        prefix-icon="el-icon-search"
        style="width: 200px"
      />

      <el-date-picker
        v-model="listQuery.params.dateRange"
        class="filter-item"
        size="small"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 380px"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="考试名称"
        show-overflow-tooltip
      >
        <template v-slot="scope">

          <detail-link :params="{ examId: scope.row.examId, userId: userId }" :title="scope.row.title" to="WebPaperList" />
        </template>
      </el-table-column>

      <el-table-column
        label="考试次数"
        align="center"
        prop="tryCount"
        width="100px"
      />

      <el-table-column
        label="最高得分"
        align="center"
        prop="maxScore"
        width="100px"
      />

      <el-table-column
        label="最近考试"
        align="center"
        prop="updateTime"
        width="200px"
      />

      <el-table-column
        label="是否通过"
        align="center"
        width="120px"
      >
        <template v-slot="scope">
          <div>
            <el-tag v-if="scope.row.passed" size="mini" type="success">通过</el-tag>
            <el-tag v-else size="mini" type="danger">未通过</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="操作项"
        align="center"
        width="100px"
      >
        <template v-slot="scope">
          <el-link type="primary" icon="el-icon-circle-close" @click="handlerExamBook(scope.row.examId)">考试错题</el-link>
        </template>
      </el-table-column>

    </template>

  </web-table>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MyPaper',
  components: { },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: ''
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/user/exam/my-paging'
      }
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },
  methods: {

    handlerExamBook(examId) {
      this.$router.push({ name: 'BookList', params: { examId: examId }})
    },

    handlePaperList(examId) {
      this.$router.push({ name: 'WebPaperList', params: { examId: examId, userId: this.userId }})
    }

  }
}
</script>
