export const modalTitle = '提示信息'

export const modalConfig = {
  confirmButtonText: '确定',
  cancelButtonText: '取消'
}

export const modalConfigConfirm = {
  confirmButtonText: '确定',
  showCancelButton: false
}

export const tableStyle = {
  background: '#f2f3f4',
  color: '#555',
  'font-weight': 'bold',
  'line-height': '32px'
}

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm'
export const DATE_FORMAT_WHOLE = 'yyyy-MM-dd HH:mm:ss'

export const antiCheatingSoftwareLink =
  'https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip'
