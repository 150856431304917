import { render, staticRenderFns } from "./bind.vue?vue&type=template&id=49de9a62"
import script from "./bind.vue?vue&type=script&lang=js"
export * from "./bind.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\g\\rongtong2.0\\exam\\exam-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49de9a62')) {
      api.createRecord('49de9a62', component.options)
    } else {
      api.reload('49de9a62', component.options)
    }
    module.hot.accept("./bind.vue?vue&type=template&id=49de9a62", function () {
      api.rerender('49de9a62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/sys/user/components/UserUpdateDialog/bind.vue"
export default component.exports