<template>
  <div>
    <div class="watch-box">
      <div class="watch-left">
        <el-card style="width: 100%; height: calc(100vh - 80px)">
          自动翻页：
          <div>
            <el-switch v-model="autoPage" active-text="开" inactive-text="关" />
          </div>

          <div>刷新时间：</div>

          <div>
            <el-input-number v-model="refreshTime" :min="5" :max="999" size="mini" />
            秒
          </div>

          <div style="font-size: 12px; color: #0a84ff">
            即将在{{ seconds }}秒后刷新
          </div>

          <el-divider />

          <div style="color: #555; line-height: 30px">

            <div>应考人数：{{ stat.requireCount }}人</div>
            <div>在考人数：{{ stat.processCount }}人</div>
            <div>交卷人数：{{ stat.handCount }}人</div>
            <div>缺考人数：{{ stat.missCount }}人</div>

          </div>

          <el-divider />

          <div v-if="stat.processCount > 0">
            <el-button
              type="primary"
              size="small"
              style="width: 100%"
              @click="batchDialog"
            >群发消息</el-button
            >
          </div>

          <!-- <div v-if="stat.processCount > 0" style="padding-top: 10px">
            <el-button
              type="danger"
              size="small"
              style="width: 100%"
              @click="showHandAll"
            >全部交卷</el-button
            >
          </div> -->

          <!-- <el-divider /> -->

        </el-card>

      </div>

      <div class="watch-right">

        <!-- <data-grid v-if="stat.processCount > 0" ref="dataGrid" :options="options" :list-query="listQuery"> -->
        <data-grid ref="dataGrid" :options="options" :list-query="listQuery">
          <template #filter-content>
            <div
              style="
                padding: 0px 0px 10px 0px;
                display: flex;
                align-items: center;
              ">
              <!-- <depart-tree-select v-model="listQuery.params.deptCode" width="200" />
              <el-input v-model="listQuery.params.realName" placeholder="搜索姓名" size="small" style="width: 200px; margin-left: 10px" /> -->
              <el-input
                v-model="listQuery.params.code" placeholder="搜索编号"
                size="small"
                style="width: 200px; margin-left: 10px" />
              <el-select v-model="jiweiValue" placeholder="请选择机位" style="margin-left: 10px">
                <el-option
                  v-for="item in jiweiOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"/>
              </el-select>
              <!-- <el-switch
                v-model="listQuery.params.online"
                style="margin-left: 10px"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="在线"
                inactive-text="离线"/> -->
              <el-radio
                v-model="listQuery.params.online" label=""
                style="margin-left: 10px;"
              >全部
              </el-radio>
              <el-radio v-model="listQuery.params.online" label="false">离线</el-radio>
              <el-radio v-model="listQuery.params.online" label="true">在线</el-radio>

              <el-radio
                v-model="listQuery.params.answerStatus" label=""
                style="margin-left: 10px;"
              >全部
              </el-radio>
              <el-radio v-model="listQuery.params.answerStatus" label="1">未答卷</el-radio>
              <el-radio v-model="listQuery.params.answerStatus" label="2">考试中</el-radio>
              <el-radio v-model="listQuery.params.answerStatus" label="3">已交卷</el-radio>
            </div>

          </template>

          <template v-slot:data-item="{ data }">
            <el-card class="user-card">
              <!-- <div style="display: flex; justify-content: center; background: #F5F7FA; padding: 2px">

                <el-image :src="data.image" fit="cover" style="width: 100%; height: 100px">

                  <div slot="error"
                    style="width: 100%; height: 100px; display: flex; align-items: center; justify-content: center">
                    <i class="el-icon-user" style="font-size: 36px; color: #ccc" />
                  </div>

                </el-image>

              </div> -->
              <div class="video-box">
                <div v-show="jiweiValue == '2'" class="main-video">
                  <video-viewer :online="data.online" :height="100" :cam-type="'desktop'" :paperid="data.id" />
                </div>
                <div class="sub-video">
                  <div class="second-video">
                    <video-viewer :online="data.online" :height="100" :cam-type="'side'" :paperid="data.id" />
                  </div>
                  <div class="third-video">
                    <video-viewer :online="data.online" :height="100" :cam-type="'facade'" :paperid="data.id" />
                  </div>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div class="user-name" @click="goDetail( data.studentId )"><a>{{ data.realName }}</a></div>
                <div>
                  <span
                    v-if="data.online"
                    class="online"
                  >
                    在线
                  </span>
                  <span v-else class="offline"> 离线 </span>
                </div>
                <div style="width: 120px; text-align: right">
                  <span v-if="data.paperState >= 0" :class="{ 'online': data.paperState == 0,'offwarn': data.paperState > 0 }">
                    {{ data.paperState_dictText }}
                  </span>
                  <span v-else class="offline">
                    未考试
                  </span>

                </div>
              </div>
              <div
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div style="font-size: 12px; color: #888">
                  答题进度:{{ data.answerCount }}/{{ data.quCount }}
                </div>
                <div>
                  <a><i class="el-icon-service" style="margin-right: 10px;" @click="handleConsultModal(data)"/></a>
                  <a><i class="el-icon-view" @click="watch(data)"/></a>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-around;
                  align-items: center;
                ">
                <!-- <el-button
                  :disabled="!data.online"
                  type="primary"
                  size="mini"
                  plain
                  @click="singleDialog(data.userId)"
                >发消息</el-button
                > -->
                <el-popover placement="right" trigger="click">
                  <div class="forewarning-btn-box">
                    <el-button size="mini" class="forewarning-btn" @click="handleWarning(data.id, 21)">确认作弊</el-button>
                    <el-button size="mini" class="forewarning-btn" @click="handleWarning(data.id, 0)">取消作弊</el-button>
                    <el-button size="mini" class="forewarning-btn" @click="handleWarning(data.id, 32)">红牌警告</el-button>
                  </div>

                  <el-button slot="reference" size="mini" style="margin-right: 5px;">预警</el-button>
                </el-popover>
                <!-- <el-button type="danger" size="mini" plain @click="chiefContinuingDialog(data.id)">继续考试</el-button> -->
                <el-button type="danger" size="mini" plain @click="chiefForceHandDialog(data.id)">强制交卷</el-button>
              </div>
            </el-card>

          </template>

        </data-grid>

        <!-- <div v-else style="display: flex; align-items: center; height: 60vh; justify-content: center">
          <el-empty description="没有正在考试的用户！" />
        </div> -->

      </div>
    </div>

    <el-dialog
      :visible.sync="handVisible" :close-on-click-modal="false"
      title="全部收卷" width="500px"
      @close="handVisible = false">

      <el-form ref="handForm" :model="handForm" :rules="handFormRules" label-position="left" label-width="100px">

        <el-form-item label="考卷状态" prop="exState">
          <dic-list-select v-model="handForm.exState" style="width: 100%" dic-code="paper_ex_state" />
        </el-form-item>

        <el-form-item label="收卷理由" prop="message">
          <el-input v-model="handForm.message" type="textarea" />
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handVisible = false">取 消</el-button>
        <el-button :loading="handLoading" type="primary" @click="doHandAll">确 定</el-button>
      </div>

    </el-dialog>
    <el-dialog
      :visible.sync="warningVisible" :close-on-click-modal="false"
      title="预警提示信息" width="500px"
      @close="handleWarningClose">
      <el-input v-model="warningmessage" type="textarea" />

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleWarningClose">取 消</el-button>
        <el-button :loading="warningLoading" type="primary" @click="forewarning">确 定</el-button>
      </div>
    </el-dialog>
    <monitoring-details :is-chief="true" :datainfo="detailInfo" :detail-visible="detailVisible" @detailClose="detailClose" @handleWarning="handleWarning"/>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :title="chatName"
      width="40%"
    >
      <div class="chat-wrap">
        <div class="chat-box">
          <div v-for="(item, index) in newsList" :key="index">
            <div v-show="item.type == 1" class="chat-left">
              <el-avatar style="margin-right: 10px"> 管理 </el-avatar>
              <div class="chat-content">{{ item.news }}</div>
            </div>
            <div v-show="item.type == 2" class="chat-right">
              <el-avatar style="margin-left: 10px"> 我 </el-avatar>
              <div class="chat-content">{{ item.news }}</div>
            </div>
          </div>
        </div>
        <div class="chat-Controls">
          <div class="chat-input">
            <el-input
              :rows="4"
              v-model="textarea"
              type="textarea"
              placeholder="请输入内容"
            />
            <el-button
              class="chat-btn" type="primary"
              @click="sendMsg"
            >发送</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { httpbatchMsg, singleMsg, httpchiefwatchStat, chiefmonitorForceHand, chiefMonitorContinuing, forceHandAll, httpchiefpaperlarm, httpchiefalarmpaging } from '@/api/exam/watch'

export default {
  name: 'ExaminationProcess',
  components: {},
  props: {
    examId: String
  },
  data() {
    return {
      autoPage: false,
      refreshTime: 10,
      seconds: 10,
      paused: false,

      // 统计信息
      stat: {
        requireCount: 0,
        processCount: 0,
        handCount: 0,
        missCount: 0
      },

      listQuery: {
        current: 1,
        size: 15,
        params: {
          remoteExamId: this.examId,
          answerStatus: '2',
          online: ''
          // invigilatorId: '1',
          // handState: 0
          // name: '',
          // code: '',
          // state: ''
        }
      },
      options: {
        lineCount: 8,
        // 列表请求URL
        // listUrl: '/api/monitor/paper/paging'
        listUrl: '/api/chiefmonitor/paper/watch-paging'
      },
      // 自动翻页
      pageTimer: null,
      showTimer: null,

      handVisible: false,
      handForm: {},
      handFormRules: {

        exState: [
          { required: true, message: '考卷状态必须选择！' }
        ],
        message: [
          { required: true, message: '收卷理由必须填写！' }
        ]
      },
      handLoading: false,
      warningVisible: false,
      warningmessage: null,
      warningLoading: false,
      detailVisible: false,
      detailInfo: {},
      jiweiOptions: [{
        value: '1',
        label: '双机位'
      }, {
        value: '2',
        label: '三机位'
      }],
      jiweiValue: '1',
      dialogVisible: false,
      newsList: [],
      textarea: null,
      chatName: null
    }
  },

  watch: {

    examId: {
      handler(val) {
        this.listQuery.params.examId = val
        this.fetchStat()
      }
    },

    jiweiValue: {
      handler(val) {
        if (val == '2') {
          this.listQuery.size = 10
        } else {
          this.listQuery.size = 15
        }
      }
    },

    // 自动翻页
    autoPage: {
      handler() {
        this.handleRefresh()
      }
    },
    refreshTime: {
      handler() {
        this.handleRefresh()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.pageTimer)
    clearInterval(this.showTimer)
  },

  created() {
    // 获取考试信息
    this.listQuery.params.examId = this.examId

    // 获取统计信息
    this.fetchStat()
  },
  methods: {
    // 获取统计信息
    fetchStat() {
      if (!this.examId) {
        return
      }
      httpchiefwatchStat({ id: this.examId }).then(res => {
        this.stat = res.data
        // 同步统计数据
        this.$emit('stat', this.stat)
        // 刷新列表
        this.handleRefresh()
      })
    },

    // 显示刷新
    showHandler() {
      if (this.showTimer) {
        clearInterval(this.showTimer)
      }

      this.showTimer = setInterval(() => {
        if (this.paused) {
          return
        }

        // 循环
        if (this.seconds <= 0) {
          this.seconds = this.refreshTime
        }

        this.seconds -= 1
      }, 1000)
    },

    // 自动翻页
    handleRefresh() {
      if (this.pageTimer) {
        clearInterval(this.pageTimer)
        this.seconds = this.refreshTime
      }
      // 自动翻页的
      if (this.autoPage) {
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()

          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.nextPage()
          }
        }, this.refreshTime * 1000)
      } else {
        // 普通刷新
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()

          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
        }, this.refreshTime * 1000)
      }

      this.showHandler()
    },

    // 单人消息
    singleDialog(userId) {
      this.paused = true

      this.$prompt('输入提醒消息', '单人消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        singleMsg({ examId: this.examId, userId: userId, message: value }).then(() => {
          this.paused = false
        })
      })
    },

    // 批量消息
    // 批量消息
    batchDialog() {
      this.paused = true

      this.$prompt('输入提醒消息', '批量消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        // batchMsg({ examId: this.examId, message: value }).then(() => {
        //   this.paused = false
        // })
        if (value.length < 2000) {
          const subData = {
            current: 1,
            size: 10000,
            params: {
              remoteExamId: this.examId,
              message: value
              // invigilatorId: '1',
              // handState: 0
              // name: '',
              // code: '',
              // state: ''
            }
          }
          httpbatchMsg(subData).then((response) => {
            console.log(response, '群发消息得结果是')
            Notification.success({
              title: '提示信息',
              message: '发送成功'
            })
          })
        } else {
          Notification.error({
            title: '提示信息',
            message: '发送消息过长'
          })
        }
      })
    },

    // 主考强制考生交卷
    chiefForceHandDialog(id) {
      this.paused = true

      this.$prompt('输入强制交卷理由', '强制交卷', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        chiefmonitorForceHand({ paperId: id, message: value }).then(() => {
          this.$message.success('操作成功！')
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
          this.fetchStat()

          this.paused = false
        })
      })
    },

    // 继续考试 主考消除疑似作弊报警，允许考生继续考试
    chiefContinuingDialog(id) {
      this.paused = true

      this.$prompt('输入继续考试理由', '继续考试', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        chiefMonitorContinuing({ paperId: id, message: value }).then(() => {
          this.$message.success('操作成功！')
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
          this.fetchStat()

          this.paused = false
        })
      })
    },

    // 批量消息
    showHandAll() {
      this.handVisible = true
      this.paused = true
    },

    doHandAll() {
      this.$refs.handForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.$confirm('交卷后考生将无法继续考试，确实要全部交卷吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const params = this.handForm
          params.examId = this.examId

          this.handLoading = true
          forceHandAll(params).then(() => {
            this.$message.success('操作成功！')
            this.fetchStat()

            this.paused = false
            this.handLoading = false
            this.handVisible = false
          })
        })
      })
    },
    async forewarning() {
      if (this.warningmessage) {
        this.warningLoading = true
        const subData = {
          remoteExamId: this.examId,
          paperId: this.paperId,
          alarmLevel: this.warningLevel,
          msg: {
            content: this.warningmessage
          }
        }
        const res = await httpchiefpaperlarm(subData)
        this.warningLoading = false
        if (res.success) {
          this.warningVisible = false
          this.$message.success('操作成功！')
        }
      } else {
        this.$message.error('预警提示信息未填写！')
      }
    },
    handleWarning(id, level) {
      this.warningVisible = true
      this.paperId = id
      this.warningLevel = level
    },
    handleWarningClose() {
      this.warningVisible = false
      this.warningmessage = null
    },
    watch(item) {
      this.detailInfo = item
      console.log(item, 'item是什么')
      this.detailVisible = true
    },
    detailClose(f) {
      this.detailVisible = f
    },
    handleConsultModal(info) {
      this.dialogVisible = true
      this.chatName = info.realName
      this.newsList = []
    },
    handleClose() {
      this.dialogVisible = false
    },
    sendMsg() {
      if (this.textarea != '') {
        this.newsList.push({
          type: 2,
          news: this.textarea
        })
        this.textarea = ''
      }
    },
    goDetail(id) {
      this.$router.push(`/web/user/info?id=${id}`)
    }
  }
}
</script>

<style scoped>
.watch-box {
  display: flex;
  height: calc(100vh - 70px);
}

.watch-left {
  width: 200px;
  flex-shrink: 0;
  line-height: 32px;
  font-weight: 700;
  font-size: 14px;
  flex-direction: column;
  border:1px red solid;
  overflow-y: scroll;
}

.watch-right {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 20px;
}

.online {
  color: #1aac1a;
  font-size: 12px;
  margin-left: 10px;
}

.offline {
  color: #ccc;
  font-size: 12px;
  margin-left: 10px;
}
.offwarn {
  color: #f5a3a3;
  font-size: 12px;
}
.user-card {
  width: calc((100vw - 200px) / 5 - 10);
  line-height: 28px;
  margin-right: 10px;
}

.user-name {
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
}

::v-deep .user-card .el-card__body {
  padding: 10px !important;
}

.main-video,
.sub-video,
.third-video {
  height: 100px;
}

.main-video {
  margin-bottom: 2px;
}

.sub-video {
  display: flex;
  justify-content: space-between;
}

.second-video {
  width: 29%;
}

.third-video {
  width: 70%;
}

.forewarning-btn-box {
  display: flex;
  flex-direction: column;
  /* max-width: 80px; */
}

.forewarning-btn {
  margin: 5px auto;
}

.img_list {
  width: 100%;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.img_item,
.wranImg_item {
  margin: 10px;
}

.wranImg_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.chat-wrap {
  width: 100%;
}
.chat-box {
  width: 100%;
  height: 300px;
  border-top: 1px solid #848181;
  border-bottom: 1px solid #848181;
  overflow: auto;
}
.chat-left,
.chat-right {
  width: 100%;
  display: flex;
  margin: 10px 0;
}
.chat-right {
  flex-direction: row-reverse;
}
.chat-content {
  max-width: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #939292;
}
.chat-input {
  position: relative;
}
.chat-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.hot-number{
  position: absolute;
  top: 18px;
  left: 110px;
  line-height: 32px;
  font-size: 14px;
  color: #939292;
}
</style>

