<template>
  <div>
    <el-row>
      <el-col :lg="16" :md="10" class="left-content">
        <div class="title-row">
          <div class="title">{{ examInfo.title }} 在线考试</div>
          <div class="time">
            考试时间: {{ examInfo.startTime }} - {{ examInfo.endTime }}
          </div>
        </div>
        <el-row>
          <el-col :span="8">
            <div class="info-title">欢迎你参加在线考试！</div>
            <div class="info-content">
              请使用最新版Chrome谷歌浏览器、Firefox火狐浏览器、Safari浏览器、Edge浏览器进行考试;<br >提前登录系统，检测调试电脑及手机设备;<br >请关闭QQ微信等软件，不要跳出考试页面;<br >注意考试纪律，诚信作答;<br >网络故障中断考试，重新登录继续作答即可.
            </div>
            <div class="count-down">
              距离开考还有:
              <!-- <div class="time-value">17 分钟</div> -->
              <div class="time-value">{{ remainingTime }}</div>
            </div>
          </el-col>
          <el-col :span="16">
            <img src="@/assets/web/images/login2.png" style="height: 45vh" >
          </el-col>
        </el-row>
      </el-col>

      <el-col :lg="8" :md="14" class="right">
        <div class="box">
          <el-tabs v-model="activeName">
            <el-tab-pane label="用户登录" name="account">
              <el-form
                v-if="activeName === 'account'"
                ref="postForm"
                :model="postForm"
                :rules="loginRules"
              >
                <el-form-item prop="username">
                  <el-input
                    v-model="postForm.username"
                    style="width: 100%"
                    placeholder="用户名"
                    prefix-icon="el-icon-user"
                  />
                </el-form-item>

                <el-form-item prop="password">
                  <el-input
                    v-model.trim="postForm.password"
                    style="width: 100%"
                    placeholder="密码"
                    type="password"
                    prefix-icon="el-icon-lock"
                    show-password
                  />
                </el-form-item>

                <el-form-item prop="captchaValue">
                  <qx-captcha
                    ref="captcha"
                    v-model="postForm"
                    :submit-form="keyDown"
                  />
                </el-form-item>
                <!-- <el-checkbox v-model="checked">隐私保护条例</el-checkbox> -->
                <el-form-item>
                  <el-button
                    :loading="loading"
                    type="primary"
                    style="width: 100%"
                    @click.native.prevent="accountLogin"
                  >
                    登录
                  </el-button>
                </el-form-item>
                <!-- <el-button
                  type="text"
                  icon="el-icon-s-opportunity"
                  @click="gotoDeviceCheck"
                >
                  设备网络检测
                </el-button> -->
              </el-form>
            </el-tab-pane>
          </el-tabs>

          <demo-account v-if="isDemo" />

          <div class="version">{{ version }}</div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :visible.sync="h5Visible"
      width="340px"
      style="text-align: center"
    >
      <div class="code-tips">扫码进入H5学员端</div>
      <div style="width: 300px; border: #ddd 1px solid">
        <vue-qr
          :size="298"
          :logo-src="siteData.backLogoUrl"
          :logo-scale="0.2"
          :text="siteData.h5Host"
        />
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="mpVisible"
      width="340px"
      style="text-align: center"
    >
      <div class="code-tips">扫码进入小程序学员端</div>
      <img
        :src="siteData.mpCode"
        style="width: 300px; border: #ddd 1px solid"
      >
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueQr from 'vue-qr'
import ThirdLogin from '@/views/login/components/ThirdLogin.vue'
import DemoAccount from '@/views/login/components/DemoAccount.vue'
import {
  fetchprepareexamInfo,
  fetchstudentconfirmconfirmPrepare
} from '../../api/web/web'
import { CountdownMixin } from '@/views/login/components/mixin'

export default {
  components: { DemoAccount, ThirdLogin, VueQr },
  mixins: [CountdownMixin],
  data() {
    return {
      examInfo: {},
      checked: false,
      isDemo: this.$demo,
      version: process.env.VUE_APP_VERSION || '',
      activeName: 'account',
      loading: false,
      h5Visible: false,
      mpVisible: false,
      wxVisible: false,
      postForm: {
        smsCode: '',
        captchaKey: '',
        captchaValue: ''
      },
      loginRules: {
        username: [{ required: true, message: '用户名不能为空' }],
        password: [{ required: true, message: '密码不能为空' }],
        captchaValue: [{ required: true, message: '验证码不能为空' }],
        smsCode: [{ required: true, message: '短信验证码不能为空' }],
        mobile: [{ required: true, message: '手机号不能为空' }]
      }
    }
  },

  computed: {
    ...mapGetters(['siteData'])
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id
      this.fetchInfo(this.id)
    }
  },
  methods: {
    async fetchInfo(id) {
      this.loading = true
      const res = await fetchprepareexamInfo({ id })
      this.loading = false
      if (res && res.data) {
        this.examInfo = res.data
      } else {
        this.$notify({
          title: '失败',
          message: '发生了错误，请稍后重试',
          type: 'error'
        })
      }
    },
    showH5Code() {
      this.h5Visible = true
    },
    showMpCode() {
      this.mpVisible = true
    },

    async mobileLogin() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        this.$store
          .dispatch('user/mobileLogin', this.postForm)
          .then(() => {
            this.$router.push('/')
          })
          .catch((err) => {
            if (err.message === '10010012') {
              this.$refs.captcha.changeCode()
            }
            console.log('错误信息为：', err)
            this.loading = false
          })
      })
    },

    accountLogin() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        this.$store
          .dispatch('user/login', this.postForm)
          .then(() => {
            this.$router.push('/examineeGuide/' + this.id)
            // this.$router.push('/')
          })
          .catch((err) => {
            this.$refs.captcha.changeCode()
            console.log('错误', err)
            // if (err.message === '10010012') {
            //   this.$refs.captcha.changeCode()
            // }
            this.loading = false
          })
      })
    },
    keyDown(e) {
      if (e.keyCode === 13) {
        if (this.activeName === 'account') {
          this.accountLogin()
        }

        if (this.activeName === 'mobile') {
          this.mobileLogin()
        }
      }
    },
    gotoDeviceCheck() {
      const { id = '' } = this.$route.params
      this.$router.push({
        name: 'DeviceCheck',
        params: { id }
      })
    }
  }
}
</script>
<style scoped>
.left-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.title-row {
  display: block;
  margin-top: 20px;
  margin-bottom: 60px;
}
.title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 30px;
}
.time {
  font-size: 16px;
  color: #575758;
}
.info-title {
  margin-bottom: 30px;
  color: #575758;
  font-size: 28px;
}
.info-content {
  color: #575758;
  margin-bottom: 30px;
}
.count-down {
  font-size: 18px;
}
.time-value {
  color: red;
  display: inline;
}
</style>
