<template>
  <div class="step2-content">
    <div class="title">在线考试须知</div>
    <div class="sub-title">* 为保障考试成绩真实有效，请遵守以下考场纪律</div>
    <div class="info-content">
      <p class="p-style">
        <b>为保证考试公平公正，您务必遵守以下规则:</b>
      </p>

      <p class="p-style">
        1.监考官会通过监控大屏全程观察考生举止行为，严禁使用手机或平板电脑查阅信息，严禁查看考试辅助书籍等材料
      </p>

      <p class="p-style">
        2.根据系统要求开启监控设备，考试中严禁有其他人员陪同，例如家人、同事、朋友等;
      </p>

      <p class="p-style">
        3.考试中禁止替考，跳出考试页面，接打电话，左顾右盼，离开座位，不要佩戴耳机、帽子，口罩，头发不能遮盖耳朵不要遮挡监控设备，衣着要得体;
      </p>

      <p class="p-style">4.考官会随机抽查考生座位周围环境，请配合考官操作。</p>

      <p class="p-style"><b>重要提示:</b></p>

      <p class="p-style">
        1.任意作弊行为一经核实，将取消资格，并记录在融通人力诚信档案中;
      </p>

      <p class="p-style">
        2.考生的个人信息、抓拍照片、答题信息将受到严格保护，不会向任何第三方透露;
      </p>

      <p class="p-style">
        3.禁止拍摄、记录试题，泄露考试试题内容属于侵权行为。
      </p>

      <p class="p-style">
        <el-checkbox v-model="checked" style="color: #f4585c">
          我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。
        </el-checkbox>
      </p>
    </div>
    <div class="btn-row">
      <el-button type="default" size="large" @click="last"> 上一步 </el-button>
      <el-button
        v-if="isPractice"
        :disabled="!(timestamp <= 0)"
        type="primary"
        size="large"
        class="next-btn"
        @click="practice"
      >
        开始练习{{ timestamp ? `(${timestamp})s` : '' }}
      </el-button>
      <el-button
        v-if="!isPractice"
        :disabled="!(timestamp <= 0)"
        type="primary"
        size="large"
        class="next-btn"
        @click="startExam"
      >
        开始考试{{ timestamp ? `(${timestamp})s` : '' }}
      </el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :center="true"
      title="考试纪律"
      width="70%"
    >
      <div
        v-for="(item, index) in confirmList"
        :key="item.id"
        style="margin-top: 15px"
      >
        <el-row>
          <el-col :span="18">
            <span>{{ index + 1 }}.</span>
            <span>{{ item.title }}</span>
          </el-col>
          <el-col :span="6">
            <el-radio-group
              v-model="confirms[item.id]"
              size="small"
              style="float: right"
            >
              <el-radio-button label="1">同意</el-radio-button>
              <el-radio-button label="0">不同意</el-radio-button>
          </el-radio-group></el-col
          >
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="!isConfirmAll"
          type="primary"
          class="next-btn"
          @click="confirmDiscipline"
        >
          开始作答
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { guideStepMixin } from './mixin'
import moment from 'moment'
import { fetchprepareexamInfo } from '../../../../api/web/web'
import { sysDicValueTree } from '@/api/remoteTest/remoteTest'
import { modalConfig, modalTitle } from '../../../../data/const'

const TIME = 5

export default {
  name: 'GuideStep4',
  mixins: [guideStepMixin],
  props: {
    id: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirms: {},
      confirmList: [],
      studentId: '',
      dialogVisible: false,
      timestamp: TIME,
      $countdown: '',
      checked: false
    }
  },
  computed: {
    isConfirmAll() {
      let _v = false
      if (this.confirms && this.confirmList && this.confirmList.length > 0) {
        _v = this.confirmList.every((confirmItem) => {
          const { id } = confirmItem
          if (this.confirms[id] && this.confirms[id] === '1') {
            return true
          } else {
            return false
          }
        })
      }
      return _v
    }
  },
  watch: {
    isShow: {
      handler(val) {
        // console.log('获取到第四部显示状态', val)
        if (val) {
          this.timestamp = TIME
          this.countDown(this.timestamp)
        } else {
          this.checked = false
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.studentId = this.$route.params.id
    }
    this.dialogTree()
  },
  beforeDestroy() {
    this.clearIntervalHandler()
  },
  methods: {
    async dialogTree() {
      const res = await sysDicValueTree({ dicCode: 'exam_discipline' })
      if (res && res.data) {
        // console.log("我看看获取了什么", res);
        this.confirmList = res.data
      }
    },
    practice() {
      if (!this.checked) {
        this.$confirm(
          '我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。',
          '考试须知',
          {
            ...modalConfig,
            type: 'warning'
          }
        ).then(() => {
          this.checked = true
          this.$router.push('/web/exam/check/practice-exam')
        })
      } else {
        this.$router.push('/web/exam/check/practice-exam')
      }
    },
    countDown() {
      this.clearIntervalHandler()
      this.$countdown = setInterval(() => {
        if (this.timestamp > 0) {
          this.timestamp = --this.timestamp
          // console.log('倒计时', this.timestamp)
        } else {
          this.clearIntervalHandler()
        }
      }, 1000)
    },
    handleClose() {
      this.dialogVisible = false
    },
    confirmDiscipline() {
      this.handleClose()
      let examId = ''
      if (this.examInfo && this.examInfo.executorId) {
        examId = this.examInfo.executorId
      } else if (this.exam.id) {
        examId = this.exam.id
      } else if (this.info && this.info.exam) {
        examId = this.info.exam.id
      }
      this.$router.push('/web/exam/check/' + examId)
    },
    startExam() {
      if (!this.checked) {
        this.confirmProtocol()
      } else {
        this.dialogVisible = true
      }
    },
    confirmProtocol() {
      this.$confirm(
        '我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。',
        '考试须知',
        {
          ...modalConfig,
          type: 'warning'
        }
      ).then(() => {
        this.checked = true
        this.dialogVisible = true
      })
    },
    clearIntervalHandler() {
      if (this.$countdown) clearInterval(this.$countdown)
    }
  }
}
</script>
<style scoped>
.step2-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 20px;
  margin-bottom: 15px;
}

.sub-title {
  color: #f36060;
  margin-bottom: 20px;
}

.info-content {
  /* display: flex;
  flex-direction: column; */
  margin-bottom: 40px;
  padding: 0 20px;
}

.p-style {
  /* text-indent: 2rem; */
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 40px;
}

.btn-row {
  display: flex;
  justify-content: center;
}

.next-btn {
  width: 400px;
  border-color: #f4585c;
  background-color: #f4585c;
}
::v-deep .is-disabled,
.is-disabled:hover {
  border-color: #fab8ba;
  background-color: #fab8ba;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #f4585c;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #f4585c;
  background-color: #f4585c;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f4585c;
}
</style>
