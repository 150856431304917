var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step2-content" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("身份验证")]),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(" * 人脸识别系统将验证考生身份，确保考试公平、公正 "),
      ]),
      _c("div", { staticClass: "identity-content" }, [
        _c("div", { staticClass: "video-box" }, [
          _c("div", { ref: "qrcodediv", attrs: { id: "qrcodediv" } }),
        ]),
        _c("div", { staticClass: "img-info" }, [
          _vm.isValidate
            ? _c("div", { staticClass: "img-state" }, [
                _vm._v(" 身份验证："),
                _c("span", { staticClass: "img-state-text" }, [_vm._v("成功")]),
              ])
            : _vm._e(),
          !_vm.isValidate
            ? _c("div", { staticClass: "img-sub-info" }, [
                _vm._v(" 微信扫码，开启人脸识别 "),
              ])
            : _vm._e(),
          _vm.isValidate
            ? _c("div", { staticClass: "img-sub-info" }, [
                _vm._v(" 考试全程请保持摄像头开启状态! "),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "btn-row" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "large" },
              on: { click: _vm.last },
            },
            [_vm._v(" 上一步 ")]
          ),
          _vm.isValidate
            ? _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: {
                    disabled: !_vm.isValidate,
                    type: "primary",
                    size: "large",
                  },
                  on: { click: _vm.nextHandler },
                },
                [_vm._v(" 保存，下一步 ")]
              )
            : _vm._e(),
          !_vm.isValidate
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "400px" },
                  attrs: { type: "default", size: "large" },
                  on: { click: _vm.nextHandler },
                },
                [_vm._v(" 跳过，下一步 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": true,
            title: "微信扫码，开启人脸识别",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _vm.show
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "space-around",
                    padding: "10px",
                  },
                },
                [_c("div", { ref: "qrcodediv", attrs: { id: "qrcodediv" } })]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }