<template>
  <div v-loading="loading" class="body">
    <div v-if="deviceFlag">
      <GuideHeader v-if="info" :info="info" :exam-info="examInfo" />
      <div class="content">
        <div class="top">
          <div class="title">{{ exam.title }}</div>
          <!-- <div class="title">{{ exam.title }} 综合类试卷</div>
          <div class="sub-title">在线考试</div> -->
        </div>
        <div class="guide-content">
          <div class="guide-left">
            <div class="guide">
              <el-steps :active="active" direction="vertical">
                <el-step
                  v-for="(item, i) in stepList"
                  :title="item.title"
                  :key="i"
                />
                <!-- <el-step title="完善信息" />
                <el-step title="身份验证" />
                <el-step title="手机监控" />
                <el-step v-show="exam.desktopToolOn" title="软件监控" />
                <el-step title="考试须知" />
                <el-step title="开始考试" /> -->
              </el-steps>
            </div>
          </div>
          <div class="guide-right">
            <el-card class="card-content">
              <component
                :is="currentComp"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
                :exam-info="examInfo"
                :is-show="true"
              />
              <!-- <guide-step-1
                v-show="active === 0"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
              />
              <guide-step-2
                v-show="active === 1"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
                :exam-info="examInfo"
                :is-show="active === 1"
              />
              <guide-step-3
                v-show="active === 2"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
              />
              <guide-step-anti-cheating
                v-show="exam.desktopToolOn && active === 3"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
              />
              <guide-step-4
                v-show="exam.desktopToolOn && active === 4"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
                :exam-info="examInfo"
                :is-show="active === 4"
              />
              <guide-step-4
                v-show="!exam.desktopToolOn && active === 3"
                :go-last-step-handler="goLastStepHandler"
                :go-next-step-handler="goNextStepHandler"
                :info="info"
                :exam-info="examInfo"
                :is-show="active === 3"
              /> -->
            </el-card>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footer-inner">
          <div v-html="siteData.copyRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchstudentconfirmconfirm,
  fetchstudentconfirmconfirmPrepare
} from '@/api/web/web'
import { modalConfig, modalTitle } from '../../../data/const'
import GuideHeader from './component/GuideHeader'
import { mapGetters } from 'vuex'
import GuideStep1 from './component/guidestep1.vue'
import GuideStep2 from './component/guidestep2.vue'
import GuideStep3 from './component/guidestep3.vue'
import GuideStep4 from './component/guidestep4.vue'
import GuideStepAntiCheating from './component/guidestepAntiCheating.vue'
import { fetchprepareexamInfo } from '../../../api/web/web'
import { deviceCheck } from '../../../mixins/deviceCheck'

export default {
  name: 'ExamineeConfirm',
  components: {
    GuideHeader,
    GuideStep1,
    GuideStep2,
    GuideStep3,
    GuideStep4,
    GuideStepAntiCheating
  },
  mixins: [deviceCheck],
  data() {
    return {
      active: 0,
      info: {},
      exam: {},
      loading: false,
      id: '',
      examInfo: {},
      isWin: false,
      baseInfo: {
        title: '完善信息',
        comp: 'guide-step-1'
      },
      identity: {
        title: '身份验证',
        comp: 'guide-step-2'
      },
      mobile: {
        title: '手机监控',
        comp: 'guide-step-3'
      },
      antiCheating: {
        title: '软件监控',
        comp: 'guide-step-anti-cheating'
      },
      notice: {
        title: '考试须知',
        comp: 'guide-step-4'
      },
      start: {
        title: '开始考试',
        comp: 'guide-step-4'
      },
      currentComp: '',
      stepList: []
    }
  },
  computed: {
    ...mapGetters(['siteData']),
    btnIsDisabled() {
      let res = true
      if (this.info && this.info.state) {
        if (this.info.state === 1) {
          res = false
        }
      }
      return res
    }
  },
  watch: {
    id: {
      handler(val, oldId) {
        if (val && val !== oldId) {
          this.fetchInfo(this.id)
        }
      },
      deep: true,
      immediate: true
    },
    stepList: {
      handler(val, old) {
        if (val && val.length > 0 && (this.active || this.active === 0)) {
          this.currentComp = this.stepList[this.active].comp
        }
      }
    },
    active: {
      handler(val, old) {
        if (this.stepList && this.stepList.length > 0 && (val || val === 0)) {
          this.currentComp = this.stepList[val].comp
        }
      }
    }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id
    }
    const agent = navigator.userAgent.toLowerCase()
    this.isWin = agent.indexOf('win') > -1 || agent.indexOf('wow') > -1
  },
  methods: {
    async fetchInfo(id) {
      this.loading = true
      const res = await fetchstudentconfirmconfirmPrepare({ id })
      this.loading = false
      this.fetchExamInfo(this.id)
      if (res && res.data) {
        this.info = res.data
        if (res.data.exam) {
          this.exam = res.data.exam
          const { desktopToolOn, examType } = this.exam
          this.stepList = [this.baseInfo]
          if (examType && examType === '2') {
            this.stepList.push(this.identity, this.mobile)
          }
          if (desktopToolOn) {
            this.stepList.push(this.antiCheating)
          }

          this.stepList.push(this.notice, this.start)
        }
      } else {
        // this.$notify({
        //   title: '失败',
        //   message: '发生了错误，请稍后重试',
        //   type: 'error'
        // })
      }
    },
    async fetchExamInfo(id) {
      this.loading = true
      const res = await fetchprepareexamInfo({ id })
      this.loading = false
      if (res && res.data) {
        this.examInfo = res.data
        const { examType } = this.examInfo
        if (examType && examType === '2') {
          this.stepList.splice(1, 0, this.identity, this.mobile)
          this.stepList = [...this.stepList]
        }
      } else {
        // this.$notify({
        //   title: '失败',
        //   message: '发生了错误，请稍后重试',
        //   type: 'error'
        // })
      }
    },
    goLastStepHandler() {
      this.active = --this.active
    },
    goNextStepHandler() {
      this.active = ++this.active
    }
  }
}
</script>

<style scoped>
.content {
  height: calc(100vh - 120px);
  overflow: auto;
  background: url('../../../assets/web/bg.png') #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}
.top {
  /* align-self: start; */
  text-align: center;
  width: 72vw;
  display: inline-block;
  margin: 0 auto 15px;
}
.title {
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  margin-right: 14px;
}
.sub-title {
  font-size: 10px;
  display: inline-block;
  color: #8d8d8d;
}
.guide-content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 220px);
}
.guide-left {
  height: 100%;
  max-width: 500px;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guide {
  max-height: 450px;
  height: 100%;
}
.guide-right {
  height: 100%;
  max-width: 1000px;
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-content {
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: auto;
}
.footer {
  color: #a3a8b0;
  background: #333;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
::v-deep .el-step__title.is-finish {
  color: #f35a5b;
}
::v-deep .el-step__head.is-finish {
  color: #f35a5b;
  border-color: #f35a5b;
}
::v-deep .el-step__head.is-finish > .el-step__icon.is-text {
  color: white;
  background: #f35a5b;
}
::v-deep .el-step__title.is-process {
  color: #f35a5b;
}
::v-deep .el-step__head.is-process {
  color: #f35a5b;
  border-color: #f35a5b;
}
::v-deep .el-step__head.is-process > .el-step__icon.is-text {
  color: white;
  background: #f35a5b;
}
::v-deep .el-step__title.is-wait {
  color: #d2d3d4;
}
::v-deep .el-step__head.is-wait {
  color: #d2d3d4;
  border-color: #d2d3d4;
}
::v-deep .el-step__head.is-finish > .el-step__line {
  background-color: #f35a5b;
}
::v-deep .el-step__head.is-process > .el-step__line {
  background-color: #f35a5b;
}

::v-deep .el-card__body {
  padding: 20px;
  height: 100%;
  /* display: flex;
  justify-content: center; */
}
</style>
