var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "考生编号", size: "small" },
              model: {
                value: _vm.listQuery.params.code,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "code", $$v)
                },
                expression: "listQuery.params.code",
              },
            }),
            _c("dic-list-select", {
              attrs: { title: "是否异常", "dic-code": "base_yes_no" },
              model: {
                value: _vm.listQuery.params.hasEx,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "hasEx", $$v)
                },
                expression: "listQuery.params.hasEx",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "考生编号", align: "center", prop: "code" },
            }),
            _c("el-table-column", {
              attrs: { label: "交卷时间", align: "center", prop: "handTime" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "考试用时/分钟",
                align: "center",
                prop: "userTime",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "考试状态", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            content:
                              scope.row.exMsg ||
                              scope.row.exState_dictText ||
                              "无异常信息",
                            placement: "top-start",
                            title: "异常信息",
                            width: "200",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "ex-ee": scope.row.exState !== 0,
                                "ex-normal": scope.row.exState === 0,
                              },
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [_vm._v(_vm._s(scope.row.exState_dictText))]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "切屏次数", align: "center", prop: "leaveTimes" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }