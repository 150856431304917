<template>
  <div class="step2-content">
    <div class="title">身份验证</div>
    <div class="sub-title">
      * 人脸识别系统将验证考生身份，确保考试公平、公正
    </div>
    <div class="identity-content">
      <!-- <el-image :src="''" style="width: 250px; height: 330px" fit="fill" /> -->
      <div class="video-box">
        <!-- <video-presenter
          v-if="isShow"
          :height="330"
          :width="250"
          :cam-type="'side'"
          :paperid="paperid"
          :device-id="deviceId"
        /> -->

        <div id="qrcodediv" ref="qrcodediv" />
      </div>
      <div class="img-info">
        <!-- <el-button
          v-if="!isValidate" @click="showFaceValidate"
        >开始身份验证</el-button
        > -->
        <div v-if="isValidate" class="img-state">
          身份验证：<span class="img-state-text">成功</span>
        </div>
        <div v-if="!isValidate" class="img-sub-info">
          微信扫码，开启人脸识别
        </div>
        <div v-if="isValidate" class="img-sub-info">
          考试全程请保持摄像头开启状态!
        </div>
      </div>
    </div>
    <div class="btn-row">
      <el-button type="default" size="large" @click="last"> 上一步 </el-button>
      <el-button
        v-if="isValidate"
        :disabled="!isValidate"
        type="primary"
        size="large"
        class="next-btn"
        @click="nextHandler"
      >
        保存，下一步
      </el-button>
      <!-- <el-button
        v-if="!isValidate"
        type="primary"
        size="large"
        class="next-btn"
        @click="showFaceValidate"
      >
        开始身份验证
      </el-button> -->
      <el-button
        v-if="!isValidate"
        style="width: 400px"
        type="default"
        size="large"
        @click="nextHandler"
      >
        跳过，下一步
      </el-button>
    </div>
    <!-- <face-check-dialog
      :visible.sync="show"
      :params="{ examId: examInfo.executorId }"
      url="/api/paper/capture/face-auth"
      @success="faceBack"
      @fail="failBack"
    /> -->
    <el-dialog
      :visible.sync="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="微信扫码，开启人脸识别"
      width="30%"
    >
      <div
        v-if="show"
        style="
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 10px;
        "
      >
        <div id="qrcodediv" ref="qrcodediv" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { guideStepMixin } from './mixin'
import { faceQrCode } from '../../exam/mixin'
import VideoPresenter from '@/components/VideoPresenter'

export default {
  name: 'GuideStep1',
  components: { VideoPresenter },
  mixins: [guideStepMixin, faceQrCode],
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deviceId: null,
      paperid: '',
      show: false,
      isValidate: false,
      postForm: {
        examId: ''
      }
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.postForm = val
          this.postForm.cardType = '身份证'
        }
      },
      deep: true,
      immediate: true
    },
    // 检测查询变化
    // show: {
    //   handler() {
    //     if (this.show) {
    //       this.postForm.examId = this.examInfo.executorId
    //       this.getFaceId()
    //     } else {
    //       this.ws.close()
    //       clearInterval(this.timer)
    //     }
    //   },
    //   deep: true
    // },
    examInfo: {
      handler(val) {
        if (val) {
          const { executorId } = val
          this.postForm.examId = executorId
          this.getFaceId()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    console.log(this.examInfo, '考试信息是')
    if (this.examInfo && this.examInfo.executorId) {
      console.log('考试id', this.examInfo.executorId)
      this.postForm.examId = this.examInfo.executorId
    }
  },
  methods: {
    nextHandler() {
      if (this.ws) {
        this.ws.close()
        clearInterval(this.timer)
      }
      this.next()
    },
    faceBack(a) {
      console.log('人脸验证成功')
      this.isValidate = true
      this.show = false
    },
    failBack() {
      console.log('人脸识别失败')
    },
    showFaceValidate() {
      this.show = true
    }
  }
}
</script>
<style scoped>
.step2-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 15px;
}
.sub-title {
  color: #f36060;
  margin-bottom: 50px;
}

.identity-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.img-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.video-box {
  /* min-height: 330px; */
  min-width: 250px;
}

.img-state {
  margin-bottom: 20px;
  font-size: 18px;
}

.img-state-text {
  /* margin-left: 20px; */
  color: #43a6ff;
  font-size: 20px;
}

.btn-row {
  display: flex;
  justify-content: center;
}
.next-btn {
  width: 400px;
  border-color: #f4585c;
  background-color: #f4585c;
}
</style>
