<template>
 <div>
  <div v-if="trainData && trainData.id" style="margin-bottom: 30px; cursor: pointer" @click="conTrain(trainData.repoId, trainData.mode)">
   <el-alert :closable="false" :title="`您上次训练的题库为：【${trainData.repoId_dictText}】，进度：${trainData.answerCount}/${trainData.totalCount}，点击此处快速继续训练！`" type="error" />
  </div><web-table :options="options" :list-query="listQuery">
   <template #filter-content>
    <dic-catalog-tree v-model="listQuery.params.catId" dic-code="repo_catalog" width="200" class="filter-item" /> <el-input v-model="listQuery.params.title" class="filter-item" size="small" placeholder="搜索题库名称" prefix-icon="el-icon-search" style="width: 200px" />
   </template>
   <template #data-columns>
    <el-table-column label="题库名称">
     <template v-slot="scope">
      <detail-link :params="{repoId: scope.row.id}" :title="scope.row.title" to="MyRepoView" />
     </template>
    </el-table-column> <el-table-column label="题库分类" align="center" prop="catId_dictText" /> <el-table-column label="试题数量" align="center" prop="quCount" /> <el-table-column label="创建时间" align="center" prop="createTime" /> <el-table-column label="更新时间" align="center" prop="updateTime" />
   </template>
  </web-table>
 </div>
</template>
<script>
import { checkProcess } from '@/api/repo/train'
import { mapGetters } from 'vuex'

export default {
  components: { },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          isTrain: true
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/repo/user-paging'
      },

      trainData: {}
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },
  mounted() {
    checkProcess().then(res => {
      this.trainData = res.data
    })
  },
  methods: {

    /**
     * 继续训练
     * @param repoId
     * @param mode
     */
    conTrain(repoId, mode) {
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: repoId, mode: mode }})
    }
  }
}
</script>