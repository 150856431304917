<template>
  <div>
    <!-- <video-presenter :height="430" :cam-type="'facade'" :paperid="'1549684036545978370'"/> -->

    <!-- <video-player v-model="videoUrl" :ref-id="refId" /> -->

    <!-- <video-web-player :height="430" :cam-type="'facade'" :paperid="'1727969108964364289'" :sort="1" viewer="'me" /> -->
  </div>
</template>

<script>

import { uuid } from 'vue-uuid'
// import videourl from './side1.webm'

export default {
  name: 'UserPaperList',
  components: { },
  props: {
    examId: {
      type: String,
      defaultValue: ''
    },
    userId: {
      type: String,
      defaultValue: ''
    }
  },

  data() {
    return {

      // videoUrl: videourl,
      refId: uuid.v4()
    }
  },

  watch: {
  },

  created() {

  },
  methods: {
  }
}
</script>
