var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.trainData && _vm.trainData.id
        ? _c(
            "div",
            {
              staticStyle: { "margin-bottom": "30px", cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.conTrain(_vm.trainData.repoId, _vm.trainData.mode)
                },
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  closable: false,
                  title:
                    "您上次训练的题库为：【" +
                    _vm.trainData.repoId_dictText +
                    "】，进度：" +
                    _vm.trainData.answerCount +
                    "/" +
                    _vm.trainData.totalCount +
                    "，点击此处快速继续训练！",
                  type: "error",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("web-table", {
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("dic-catalog-tree", {
                  staticClass: "filter-item",
                  attrs: { "dic-code": "repo_catalog", width: "200" },
                  model: {
                    value: _vm.listQuery.params.catId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "catId", $$v)
                    },
                    expression: "listQuery.params.catId",
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    size: "small",
                    placeholder: "搜索题库名称",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "题库名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("detail-link", {
                            attrs: {
                              params: { repoId: scope.row.id },
                              title: scope.row.title,
                              to: "MyRepoView",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "题库分类",
                    align: "center",
                    prop: "catId_dictText",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "试题数量",
                    align: "center",
                    prop: "quCount",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    align: "center",
                    prop: "createTime",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "更新时间",
                    align: "center",
                    prop: "updateTime",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }