<template>
 <div v-if="repoData && repoData.id">
  <el-row :gutter="20">
   <el-col :span="16">
    <el-card>
     <el-page-header content="题库详情" @back="goBack" />
     <h3>题库简介</h3>
     <p class="intro">{{ repoData.title }}</p>
     <el-divider />
     <div v-if="repoData.isBuy">
      <h3>自由练习</h3>
      <div class="practice-pattern">
       <a class="pattern" @click="startTrain(11)"> <i class="order"></i>顺序练习 </a> <a class="pattern" @click="startTrain(12)"> <i class="random"></i>随机练习 </a> <a class="pattern" @click="startTrain(13)"> <i class="bg"></i>背题模式 </a>
      </div><el-divider />
      <h3>题型练习</h3>
      <div>
       <div class="practice-pattern">
        <a v-for="item in repoData.typeList" :key="item.id" class="pattern" @click="startTrain('2'+item.quType)"> <i :class="'qtype'+item.quType"></i>{{ item.quType_dictText }}（{{ item.count }}题） </a>
       </div>
      </div>
     </div>
     <div v-else class="pay-box">
      <div>
       您需要购买此题库才能继续训练!
      </div>
      <div class="price">
       ￥{{ repoData.price }}
      </div>
      <div>
       <el-button type="primary" @click="handleBuy">
        购买题库
       </el-button>
      </div>
     </div>
    </el-card>
   </el-col>
   <el-col :span="8">
    <el-card>
     <div slot="header">
      <span style="font-weight: 700">最近训练</span> <el-button style="float: right; padding: 3px 0" type="text" @click="moreTrain">
       查看更多
      </el-button>
     </div>
     <div v-if="latestList.length==0">
      您还没有训练记录！
     </div>
     <el-row v-for="item in latestList" :key="item.id" class="his-item">
      <el-col :span="12">
       {{ item.mode_dictText }}
      </el-col>
      <el-col :span="12">
       <el-link v-if="item.state===0" type="primary" icon="el-icon-video-play" @click="conTrain(item.repoId, item.mode)">
        继续训练
       </el-link>
       <el-link v-else icon="el-icon-video-play" disabled>
        训练结束
       </el-link>
      </el-col>
      <el-col :span="12">
       进度：{{ item.answerCount }}/{{ item.totalCount }}
      </el-col>
      <el-col :span="12">
       正确率：{{ item.percent }}%
      </el-col>
     </el-row>
    </el-card>
   </el-col>
  </el-row> <!-- 支付弹窗 -->
 </div>
</template>
<script>

import { fetchDetail } from '@/api/repo/repo'
import { latestTrain } from '@/api/repo/train'

export default {
  data() {
    return {
      repoId: '',
      repoData: {},
      latestList: [],
      payVisible: false
    }
  },

  created() {
    this.repoId = this.$route.params.repoId

    fetchDetail({ id: this.repoId }).then(res => {
      this.repoData = res.data
    })

    // 最近训练
    this.fetchLatest()
  },
  methods: {

    fetchLatest() {
      latestTrain(this.repoId).then(res => {
        this.latestList = res.data.records
      })
    },

    goBack() {
      this.$router.replace({ name: 'MyRepoList' })
    },

    handleBuy() {
      this.payVisible = true
    },

    /**
     * 开始训练
     */
    startTrain(mode) {
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: this.repoId, mode: mode }})
    },

    /**
     * 继续训练
     * @param repoId
     * @param mode
     */
    conTrain(repoId, mode) {
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: repoId, mode: mode }})
    },

    /**
     * 更多训练
     */
    moreTrain() {
      // 记录缓存
      localStorage.setItem('uc:tab:active', 'third')
      // 强制跳转
      this.$router.push('/web/uc?to=train')
    }

  }
}
</script>
<style>
  .practice-pattern {
    padding: 0px;
    overflow: hidden
  }

  .practice-pattern .pattern {
    padding: 20px 30px;
    border: 1px solid #eaeaea;
    color: #4f4e58;
    font-size: 14px;
    display: flex;
    margin-right: 20px;
    float: left;
    align-items: center;
    margin-bottom: 20px
  }

  .practice-pattern .pattern:hover {
    border: 1px solid #5794f7;
    box-shadow: 0 5px 10px #d9e6fa
  }

  .practice-pattern .pattern i {
    display: inline-block;
    width: 24px;
    height: 24px;
    overflow: hidden;
    margin-right: 10px
  }

  .practice-pattern .pattern i.order {
    background: url(../../../assets/web/images/pattern1.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.random {
    background: url(../../../assets/web/images/pattern2.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.bg {
    background: url(../../../assets/web/images/pattern3.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype1 {
    background: url(../../../assets/web/images/qtype1.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype2 {
    background: url(../../../assets/web/images/qtype2.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype3 {
    background: url(../../../assets/web/images/qtype3.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype4 {
    background: url(../../../assets/web/images/qtype4.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype5 {
    background: url(../../../assets/web/images/qtype5.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype6 {
    background: url(../../../assets/web/images/pattern2.png) no-repeat center center;
    background-size: contain
  }

  .practice-pattern .pattern i.qtype99 {
    background: url(../../../assets/web/images/qtype99.png) no-repeat center center;
    background-size: contain
  }

  .his-item{
    background: #efefef;
    padding:10px 0px 10px 0px;
    margin-bottom: 10px;
  }

  .his-item :nth-child(3),.his-item :nth-child(4){
    font-size: 12px;
    color: #888;
  }

  .his-item :nth-child(1){
    font-weight: 700;
  }

  .his-item :nth-child(2),.his-item :nth-child(4){
    text-align: right;
    padding-right: 10px;
  }

  .his-item :nth-child(1),.his-item :nth-child(3){
    text-align: left;
    padding-left: 10px;
  }

  .pay-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 50px;
    align-items: center
  }

  .pay-box .price{
    color: #ff0000;
    font-size: 28px;
    font-weight: 700
  }
</style>