var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.warningStudentList,
            "header-cell-style": {
              background: "#f2f3f4",
              color: "#555",
              "font-weight": "bold",
              "line-height": "32px",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "studentCode", label: "编号", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "studentName", label: "姓名", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "发起时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invaligatorId",
              label: "预警等级",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.alarmLevel_dictText))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "invaligatorId", label: "描述", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.msg
                      ? _c("span", [_vm._v(_vm._s(scope.row.msg.content))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "invaligatorId", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.currentPage,
              "page-sizes": [100, 200, 300, 400, 500],
              "page-size": _vm.page.size,
              total: _vm.total,
              layout: "sizes, prev, pager, next",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.page, "currentPage", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.page, "currentPage", $event)
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.warningInfoVisible,
            title: "预警信息",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.warningInfoVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                  modifiers: { fullscreen: true, lock: true },
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    column: 4,
                    title: "",
                    direction: "vertical",
                    border: "",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "考生编号" } }, [
                    _vm._v(_vm._s(_vm.warnInfo.studentCode)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "预警等级" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.warnInfo.alarmLevel_dictText)),
                    ]),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2, label: "发起时间" } },
                    [_vm._v(_vm._s(_vm.warnInfo.createTime))]
                  ),
                  _vm.warnInfo.msg
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { span: 4, label: "描述" } },
                        [_vm._v(" " + _vm._s(_vm.warnInfo.msg.content) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-descriptions-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.warnInfo.pictureList &&
                            _vm.warnInfo.pictureList.length > 0,
                          expression:
                            "warnInfo.pictureList && warnInfo.pictureList.length > 0",
                        },
                      ],
                      attrs: { span: 4, label: "照片" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wranImg_list" },
                        _vm._l(_vm.warnInfo.pictureList, function (img, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "wranImg_item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src: img.facadeImageUrl,
                                  "preview-src-list": [img.facadeImageUrl],
                                  fit: "fill",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.warnInfo.snapPictureList &&
                            _vm.warnInfo.snapPictureList.length > 0,
                          expression:
                            "\n            warnInfo.snapPictureList && warnInfo.snapPictureList.length > 0\n          ",
                        },
                      ],
                      attrs: { span: 4, label: "人脸对比照片" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wranImg_list" },
                        _vm._l(
                          _vm.warnInfo.snapPictureList,
                          function (img, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "wranImg_item" },
                              [
                                _c("div", { staticClass: "wranImg_bd_wrap" }, [
                                  _c(
                                    "div",
                                    { staticClass: "wranImg_bd_item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "wranImg_bd_tit" },
                                        [_vm._v("抓拍")]
                                      ),
                                      img.facadeImageUrl
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  src: img.facadeImageUrl,
                                                  "preview-src-list": [
                                                    img.facadeImageUrl,
                                                  ],
                                                  fit: "fill",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "wranImg_bd_item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "wranImg_bd_tit" },
                                        [_vm._v("认证")]
                                      ),
                                      img.realImageUrl
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  src: img.realImageUrl,
                                                  "preview-src-list": [
                                                    img.realImageUrl,
                                                  ],
                                                  fit: "fill",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "wranImg_bd_noImg" },
                                            [_vm._v("暂无认证")]
                                          ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }