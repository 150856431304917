<template>
  <div class="cam-box">

    <div>
      <video id="video" ref="video" :width="width" :height="height" :playsinline="true" :autoplay="true" muted style="background: #262626" />

      <canvas id="canvas" ref="canvas" :width="width" :height="height" />
    </div>

  </div>
</template>

<script>
import kurentoUtils from 'kurento-utils'
// import { httpuploadPicture } from '@/api/exam/watch'
import { uuid } from 'vue-uuid'

export default {
  name: 'VideoWebPlayer',
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    // 发起监控的方式
    camType: {
      type: String,
      default: 'facade'
    },
    paperid: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Number,
      default: 1
    },
    viewer: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      canvas: null,
      context: null,
      // 警告定时器
      faceTimer: null,
      loading: false,

      // ice链接
      webRtcPeer: null,
      ws: null,
      timer: null,
      refId: uuid.v4(),
      videoUrl: ''

    }
  },
  watch: {

    // 检测查询变化
    isStop: {
      handler() {
        if (this.isStop) {
          this.stop()
        } else {
          this.start()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    // 关闭websocket
    if (this.ws) {
      this.ws.close()
    }
    this.dispose()
  },
  methods: {
    init() {
      if (!this.webRtcPeer) {
        // 添加自己的中继服务器的配置，否则会默认指向谷歌的服务器
        const iceservers = {
          'iceServers': [
            {
              urls: `${process.env.VUE_APP_ICESERVER_STUN_URL}`
            },
            {
              urls: [`${process.env.VUE_APP_ICESERVER_TURN_URL}`],
              username: `${process.env.VUE_APP_ICESERVER_TURN_USERNAME}`,
              credential: `${process.env.VUE_APP_ICESERVER_TURN_CREDENTIAL}`
            }
          ]
        }
        const options = {
          localVideo: this.$refs.video,
          configuration: iceservers,
          onicecandidate: this.onIceCandidate
          // mediaConstraints: getConstraints(),
        }
        this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
          (error) => {
            if (error) {
              return console.log(error, '错误信息是')
            }
            this.webRtcPeer.generateOffer(this.onOfferPresenter)
          })
      }

      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        // console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'playResponse':
            this.playerResponse(parsedMessage)
            break
          case 'iceCandidate':
            this.webRtcPeer.addIceCandidate(parsedMessage.candidate, (error) => {
              if (error) { return console.log('添加候选项错误', error) }
            })
            break
          case 'stopCommunication':
            this.dispose()
            break
          case 'recording':
            console.log('视频开始录制', parsedMessage)
            break
          case 'stopped':
            console.log('视频终止录制', parsedMessage)
            break
          case 'paused':
            console.log('视频暂停录制', parsedMessage)
            break
          default:
            console.log('无法识别的信息', parsedMessage)
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(`[websocket close]连接关闭, code=${event.code} reason=${event.reason}`)
          this.dispose()
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          console.log('[websocket close] Connection died', event)
          // this.start()
        }
      }

      this.ws.onerror = (error) => {
        console.log(`[websocket error] ${error.message}`)
        // this.start()
      }
    },
    playerResponse(message) {
      if (message.response != 'accepted') {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受' + errorMsg)
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) { return console.log(error, '错误是') }
        })
      }
    },
    errorResponse(message) {
      console.log(message, '连接错误信息是什么')
      this.$emit('alterError', message)
    },
    onOfferPresenter(error, offerSdp) {
      // facade ： 正面视频
      // side ： 侧面视频
      // desktop ： 桌面视频

      if (error) { return console.log('生成offer错误') }
      const message = {
        id: 'play',
        type: this.camType,
        name: this.paperid,
        sort: this.sort,
        viewer: this.viewer
      }
      if (this.camType != 'facade') {
        message.mode = 'video-only'
      } else {
        message.mode = ''
      }
      console.log(message, '视频发送')
      this.sendMessage(message)
    },
    onIceCandidate(candidate) {
      // console.log('当地的候选人', JSON.stringify(candidate))
      const message = {
        id: 'onIceCandidate',
        candidate: candidate,
        type: this.camType,
        name: this.paperid,
        sort: this.sort
      }
      this.sendMessage(message)
    },
    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },

    // fire 是否触发disconnected事件
    dispose(closeEmitter) {
      clearInterval(this.timer)
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose()
        this.webRtcPeer = null
      }
      if (!closeEmitter) {
        // 链路关闭，触发 关闭 消息 disconnected
        const e = {
          'id': 'disconnected',
          'camType': this.camType,
          'paperId': this.paperid,
          'deviceId': this.deviceId
        }
        this.$emit('disconnected', e)
      }
    },
    start() {
      console.log('>>> Player  start() >>>', this.ws)

      // WebSocket.readyState
      // 返回当前 WebSocket 的链接状态，只读。
      // 语法
      // var readyState = WebSocket.readyState;
      // 值
      // 以下其中之一
      // 0 (WebSocket.CONNECTING)
      // 正在链接中
      // 1 (WebSocket.OPEN)
      // 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING)
      // 连接正在关闭
      // 3 (WebSocket.CLOSED)
      // 连接已关闭或者没有链接成功

      // 检查是否需要重新创建 websocket
      let toCreate = true
      if (this.ws && (this.ws.readyState == WebSocket.CONNECTING || this.ws.readyState == WebSocket.OPEN)) {
        // 正在链接中 || 已经链接并且可以通讯
        toCreate = false
      }

      if (toCreate) {
        const wsUrl = `${process.env.VUE_APP_SIGNAL_BASE_API}/signal/player`
        // const wsUrl = 'wss://live.ntces.cn/tutorialcall'
        this.ws = new WebSocket(wsUrl)
        this.ws.onopen = (e) => {
          this.init()
          this.peng()
        }
      }


      // if (this.ws) {
      //   this.ws.close()
      // }
    },
    stop() {
      console.log('停止拍摄')
      const message = {
        id: 'stop',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
      this.dispose()
      if (this.ws) {
        this.ws.close()
      }
    },
    interruption() {
      console.log('中断')
      const message = {
        id: 'interruption',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        console.log('心跳 presenter:', this.camType, this.paperid)
        const message = {
          id: 'pant'
        }
        this.sendMessage(message)
      }, 30000)
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
</style>
