<template>
  <div class="header-bg">
    <div class="header-inner container">
      <div class="col-logo">
        <div v-if="siteData.frontLogoUrl">
          <img
            :src="siteData.frontLogoUrl"
            :alt="siteData.siteName"
            style="height: 40px"
          >
        </div>
        <div v-else class="site-tt">
          {{ siteData.siteName }}
        </div>
      </div>
      <div class="col-menu" />
      <div class="remaining-time">
        <span class="remaining-time-text"> 开考倒计时：</span>
        <span class="remaining-time-time">
          {{ remainingTime }}
        </span>
      </div>
      <div class="right-user">
        <el-badge :value="unread" :max="99" :hidden="unread === 0">
          <div class="top-avatar" @click="msgClick">
            <el-image :src="avatarUrl" shape="square" style="width: 38px">
              <div slot="error">
                <el-image :src="faceUrl" shape="square" style="width: 38px">
                  <div slot="error">
                    <img src="@/assets/web/avatar.png" >
                  </div>
                </el-image>
              </div>
            </el-image>
            <!-- <img v-if="avatar" :src="avatar"> <img v-else src="@/assets/web/avatar.png"> -->
          </div>
        </el-badge>
        <div>
          {{ realName }}
        </div>
        <a v-if="roleType === 2" class="alink" @click="toAdmin">管理</a>
        <a @click="logout">退出</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import { guideStepMixin } from './mixin'
import { CountdownMixin } from '@/views/login/components/mixin'

export default {
  name: 'GuideHeader',
  mixins: [guideStepMixin, CountdownMixin],
  data() {
    return {
      activeIndex: '/web/index',
      msgTimer: null,
      unread: 0,
      id: ''
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'faceUrl',
      'avatarUrl',
      'realName',
      'siteData',
      'roleType',
      'unreadMsg'
    ])
  },
  watch: {
    unreadMsg: {
      handler(val) {
        this.unread = val
      }
    }
  },
  created() {
    this.focusMenu()
    this.fetchMsg()

    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id
      // this.fetchInfo(this.id)
    }
  },

  methods: {
    // 选定菜单
    focusMenu() {
      const activeMenu = this.$route.meta.activeMenu

      if (activeMenu) {
        this.activeIndex = activeMenu
        return
      }
      const path = this.$route.path.split('/')
      const prefix = path[0] + '/' + path[1] + '/' + path[2]
      // console.log(prefix)
      this.activeIndex = prefix
    },

    isActive(url) {
      if (this.activeIndex === url) {
        return 'nav active'
      }
      return 'nav'
    },

    navClick(url) {
      this.activeIndex = url
      this.$router.push({ path: url })
    },

    msgClick() {
      // 指定消息
      localStorage.setItem('uc:tab:active', 'msg')
      this.activeIndex = '/web/uc'
      this.$router.push('/web/uc?to=msg')
    },

    async logout() {
      const that = this

      this.$confirm('确实要退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      })
        .then(() => {
          that.$store.dispatch('user/logout').then(() => {
            that.$router.push(`/login/${this.id}`)
          })
        })
        .catch(() => {})
    },

    toAdmin() {
      this.$router.push({ path: '/admin/dashboard' })
    },

    // 获取消息
    fetchMsg() {
      // 清理
      if (this.msgTimer) {
        clearInterval(this.msgTimer)
      }
      // 20秒读取一次消息
      this.msgTimer = setInterval(() => {
        store.dispatch('user/fetchMsg')
      }, 20000)

      // 立即读取一次
      store.dispatch('user/fetchMsg')
    }
  }
}
</script>
<style scoped>
.header-bg {
  height: 60px;
  background: #4377fb;
  display: flex;
  justify-content: center;
}

.header-inner {
  display: flex;
  align-items: center;
}

.right-user {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  line-height: 60px;
  align-items: center;
  max-width: 240px;
}

.site-tt {
  font-weight: 700;
  font-size: 22px;
  color: #eee;
  text-align: left;
}

.right-user a,
.right-user div {
  color: #efefef;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.right-user a:last-child {
  margin-right: 0px !important;
}

.right-user a:hover {
  color: #ffd550;
}

/deep/ .alink {
  color: #ffd550 !important;
}

/deep/ .alink:hover {
  color: #f94e3e !important;
}

.nav {
  color: #fff;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 10px 5px 10px;
}

.active {
  color: #000055;
  background: #ffd550;
}

.nav:hover {
  color: #000055;
  background: #ffd550;
}

.col-logo {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 250px;
}

.col-menu {
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/deep/ .top-avatar {
  text-align: right;
  display: flex;
  align-items: center;
  margin-right: 5px !important;
  cursor: pointer;
}

/deep/ .top-avatar div {
  display: flex;
  align-items: center;
  margin-right: -10px !important;
}

/deep/ .top-avatar img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  font-weight: 700;
  font-size: 16px;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-weight: 700;
    font-size: 16px;
    padding: 0px 5px;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
    font-weight: 700;
    padding: 0px 5px;
  }
}

.remaining-time {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.remaining-time-text {
  color: white;
}
.remaining-time-time {
  color: white;
  /* color: red; */
}
</style>
