<template>
  <div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">

      <el-form-item label="方案类型">
        <el-radio-group v-model="postForm.provider" size="small">
          <el-radio label="dahansantong">大汉三通</el-radio>
        </el-radio-group>
      </el-form-item>


      <div v-if="postForm.provider==='dahansantong'">

        <el-form-item label="接口地址" prop="data.server">
          <el-input v-model="postForm.data.server" size="small" placeholder="接口地址" />
        </el-form-item>
        <el-form-item label="用户名" prop="data.username">
          <el-input v-model="postForm.data.username" size="small" placeholder="用户名" />
        </el-form-item>
        <el-form-item label="密码" prop="data.password">
          <el-input v-model="postForm.data.password" size="small" placeholder="密码" />
        </el-form-item>
        <el-form-item label="签名" prop="data.sign">
          <el-input v-model="postForm.data.sign" size="sign" placeholder="签名" />
        </el-form-item>

      </div>

      <el-divider />

      <el-row style="margin-top: 20px">
        <el-button :loading="loading" icon="el-icon-refresh" type="warning" size="small" @click="changProvider">使用当前方案</el-button>
        <el-button :loading="loading" icon="el-icon-folder" type="primary" size="small" @click="submitForm">保存配置</el-button>
      </el-row>

    </el-form>

  </div>
</template>

<script>
import { fetchDetail, saveData, change } from '@/api/sys/config/prop'
import { isUrl2, isDirectory } from '@/utils/validate'

export default {
  name: 'SmsConfig',
  components: { },
  data() {
    return {
      postForm: {
        type: 'sms',
        provider: 'dahansantong',
        data: {
        },
        remark: ''
      },
      loading: false,

      rules: {
        'data.server': [{ required: true, message: '服务器地址不能为空' }],
        'data.username': [{ required: true, message: '用户名不能为空' }],
        'data.password': [{ required: true, message: '密码不能为空' }]
      }
    }
  },

  watch: {
    'postForm.provider': {
      handler(val) {
        this.fetchData(val)
      }
    }
  },
  created() {
    this.fetchData('')
  },
  methods: {

    fetchData(provider) {
      fetchDetail('sms', provider).then(res => {
        // 清空数据
        this.postForm = { type: 'sms', provider: provider, data: {}}

        // 赋值
        if (res.data && res.data.provider) {
          this.postForm = res.data
        }

        // 进行校验一下
        if (this.$refs.postForm) {
          this.$nextTick(() => {
            this.$refs.postForm.validate()
          })
        }
      })
    },
    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        // 参数转换
        this.loading = true

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '上传配置保存成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    },

    changProvider() {
      if (!this.postForm.type || !this.postForm.provider) {
        this.$message.warning('服务商必须选择！')
        return
      }

      // 参数转换
      this.loading = true

      change(this.postForm).then(() => {
        this.$notify({
          title: '成功',
          message: '服务商切换成功！',
          type: 'success',
          duration: 2000
        })

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
