<template>
  <div>
    <DicListSelectCommon
      v-if="checkPermission()"
      :config="config"
      :placeholder="`请选择${placeholder}`"
      v-bind="$attrs"
      v-model="currentValue"
    />

    <el-input
      v-else
      v-model="currentValue"
      v-bind="$attrs"
      :placeholder="`请输入${placeholder}编号`"
    />
  </div>
</template>

<script>
import { PermissionMixin } from './mixin'

export default {
  name: 'DicListSelectCommonP',
  mixins: [PermissionMixin],
  props: {
    placeholder: String,
    value: {
      type: String | Number
    },
    data: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    searchNameKey: {
      type: String,
      default: 'title'
    },
    config: {
      type: Object,
      default: () => {
        return {
          label: 'title',
          value: 'title'
        }
      }
    }
  },
  data() {
    return {
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    },
    // 回调数据
    currentValue: {
      handler(val) {
        this.handlerChange(val)
      },
      deep: true
    }
  },
  created() {
    this.currentValue = this.value
  },
  methods: {
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>
::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}
</style>
