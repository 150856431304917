var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step2-content" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("微信扫码，开启手机监控")]),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(
          " * 请用手机微信扫码，并保持考试全程手机屏幕处于常亮状态，关闭自动锁屏 "
        ),
      ]),
      _c("div", { staticClass: "monitor-content" }, [
        _c(
          "div",
          { staticClass: "monitor-item" },
          [
            _c("div", { staticClass: "monitor-sub" }, [
              _vm._v("1、手机微信扫码"),
            ]),
            _c("div", { staticClass: "monitor-img" }, [
              _c("div", { ref: "qrcodediv", attrs: { id: "qrcodediv" } }),
            ]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", placement: "top-start" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("p", [_vm._v("扫码遇到问题怎么办?")]),
                        _c("p", [_vm._v("1、请检查你的手机网络连接是否正常;")]),
                        _c("p", [
                          _vm._v("2、请更新手机微信APP至最新版，然后重新扫码;"),
                        ]),
                        _c("p", [
                          _vm._v(
                            "3、以上方法均不能解决，请点击右侧“客服”小窗联系我们;"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " 4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源 "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("div", { staticClass: "monitor-hint" }, [
                  _c("i", { staticClass: "el-icon-question" }),
                  _vm._v(" 扫码遇故障? "),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "monitor-item" },
          [
            _c("div", { staticClass: "monitor-sub" }, [
              _vm._v("2.侧后方45°竖立摆放手机"),
            ]),
            _c("el-image", {
              staticClass: "monitor-img",
              attrs: {
                src: require("../../../../assets/web/images/guide-2.jpg"),
                fit: "fill",
              },
            }),
            _c("div", { staticClass: "monitor-hint" }, [
              _vm._v("电脑正侧方放置手机"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "monitor-item" },
          [
            _c("div", { staticClass: "monitor-sub" }, [
              _vm._v("3.开启视频监控"),
            ]),
            _c("el-image", {
              staticClass: "monitor-img",
              staticStyle: { height: "300px", width: "170px" },
              attrs: {
                src: require("../../../../assets/web/images/guide-1.jpg"),
                fit: "fill",
              },
            }),
            _c("div", { staticClass: "monitor-hint" }, [
              _vm._v("确认是否开始录制"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "btn-row" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "large" },
              on: { click: _vm.last },
            },
            [_vm._v(" 上一步 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "next-btn",
              attrs: { type: "primary", size: "large" },
              on: { click: _vm.checkModal },
            },
            [_vm._v(" 我已扫码，开始检测手机 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "large" },
              on: { click: _vm.next },
            },
            [_vm._v(" 跳过，下一步 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            title: "手机监控检测结果",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-result", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isChecking,
                expression: "!isChecking",
              },
            ],
            attrs: {
              icon: "warning",
              title: "手机设备视频监控检测中...",
              "sub-title": "",
            },
          }),
          _c(
            "el-result",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isChecking,
                  expression: "isChecking",
                },
              ],
              attrs: {
                icon: "success",
                title: "您的手机设备检测:通过",
                "sub-title":
                  "考试全程请务必开启手机视频监控，并按图示放置合适位置，可以看到上半身和电脑全景，监控画面违规会影响到考试成绩。",
              },
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "next-btn",
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function () {
                          this$1.dialogVisible = false
                          _vm.next()
                        },
                      },
                    },
                    [_vm._v(" 下一步 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.checkStart
        ? _c("video-viewer", {
            attrs: {
              height: 0,
              width: 100,
              online: true,
              "cam-type": "side",
              paperid: _vm.paperId,
            },
            on: { acceptSuccess: _vm.acceptSuccess },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }