<template>

  <el-row :gutter="20">
    <el-col :span="16">

      <el-card>

        <web-table
          ref="pagingTable"
          :options="options"
          :list-query="listQuery"
          @select-changed="handleSelect"
        >

          <template #filter-content>

            <div style="display: flex">
              <div style="flex-grow: 1">
                <el-input
                  v-model="listQuery.params.title"
                  style="width: 250px"
                  placeholder="搜索错题"
                  prefix-icon="el-icon-search"
                />
              </div>
              <div style="display: flex; align-content: flex-end">

                <el-button type="primary" icon="el-icon-s-promotion" @click="startTrain">
                  错题训练
                </el-button>

                <el-button :disabled="selections.length === 0" type="danger" icon="el-icon-delete" @click="handelDelete">
                  删除
                </el-button>
              </div>
            </div>

          </template>

          <template #data-columns>

            <el-table-column
              label="题目"
              prop="title"
              show-overflow-tooltip
            >

              <template v-slot="scope">
                <detail-link :id="scope.row.quId" :title="scope.row.title" to="ViewQu" />
              </template>

            </el-table-column>

            <el-table-column
              label="错误次数"
              prop="wrongCount"
              align="center"
              width="100px"
            />

            <el-table-column
              label="错误时间"
              prop="updateTime"
              align="center"
              width="180px"
            />

          </template>
        </web-table>

      </el-card>

    </el-col>

    <el-col :span="8">
      <el-card>
        <h3>最近训练</h3>

        <div v-if="latestList.length==0">
          您还没有训练记录！
        </div>

        <el-row v-for="item in latestList" :key="item.id" class="his-item">
          <el-col :span="12">{{ item.createTime }}</el-col>
          <el-col :span="12">
            <el-link v-if="item.state===0" type="primary" icon="el-icon-video-play" @click="conTrain(item.repoId, item.mode)">继续训练</el-link>
            <el-link v-else icon="el-icon-video-play" disabled>训练结束</el-link>
          </el-col>
          <el-col :span="12">进度：{{ item.answerCount }}/{{ item.totalCount }}</el-col>
          <el-col :span="12">正确率：{{ item.percent }}%</el-col>
        </el-row>

      </el-card>
    </el-col>

  </el-row>

</template>

<script>
import { latestTrain } from '@/api/repo/train'
import { deleteQu } from '@/api/user/book'

export default {
  name: 'BookList',
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          title: '',
          examId: ''
        }
      },

      options: {
        multi: true,
        // 列表请求URL
        listUrl: '/api/user/wrong-book/paging',
        // 无数据查看
        noData: '没有错题或管理员未开启错题查看！'
      },

      latestList: [],
      selections: []
    }
  },
  created() {
    const id = this.$route.params.examId
    if (id !== undefined) {
      this.listQuery.params.examId = id

      // 最近训练
      this.fetchLatest(id)
    }
  },
  methods: {

    fetchLatest(id) {
      latestTrain(id).then(res => {
        this.latestList = res.data.records
      })
    },

    // 移除错题
    handelDelete() {
      this.$confirm('确实要将选定的题目从错题中移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteQu(this.selections).then(() => {
          this.$message.success('错题移除成功！')
          this.$refs.pagingTable.getList()
        })
      })
    },

    startTrain() {
      // 开始训练
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: this.listQuery.params.examId, mode: 0 }})
    },

    /**
     * 继续训练
     * @param repoId
     * @param mode
     */
    conTrain(repoId, mode) {
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: repoId, mode: mode }})
    },

    /**
     * 选定操作
     * @param obj
     */
    handleSelect(obj) {
      this.selections = obj.ids
    }

  }
}
</script>

<style scoped>

  .his-item{
    background: #efefef;
    padding:10px 0px 10px 0px;
    margin-bottom: 10px;
  }

  .his-item :nth-child(3),.his-item :nth-child(4){
    font-size: 12px;
    color: #888;
  }

  .his-item :nth-child(1){
    font-weight: 700;
  }

  .his-item :nth-child(2),.his-item :nth-child(4){
    text-align: right;
    padding-right: 10px;
  }

  .his-item :nth-child(1),.his-item :nth-child(3){
    text-align: left;
    padding-left: 10px;
  }
</style>
