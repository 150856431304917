<template>
  <div>
    <div
      class="filter-container"
      style="
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-wrap: wrap;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: -14px;
        "
      >
        <el-button
          v-if="options.addRoute"
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
        >添加</el-button
        >
        <el-button
          v-permission="[options.add.permission]"
          v-if="options.add && options.add.enable"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="optAdd"
        >{{ options.add.label || `添加` }}</el-button
        >
        <el-button
          v-permission="[options.edit.permission]"
          v-if="options.edit && options.edit.enable"
          :disabled="selectedIds.length === 0"
          size="small"
          type="warning"
          icon="el-icon-edit"
          @click="optEdit"
        >修改</el-button
        >
        <el-button
          v-permission="[options.delete.permission]"
          v-if="options.delete && options.delete.enable"
          :disabled="selectedIds.length === 0"
          size="small"
          type="danger"
          icon="el-icon-delete"
          @click="optDelete"
        >删除</el-button
        >

        <el-dropdown
          v-if="
            options.multiActions &&
              options.multiActions.length > 0 &&
              selectedIds.length > 0
          "
          size="small"
          style="margin-left: 10px"
          split-button
          @command="optAction"
        >
          批量操作
          <el-dropdown-menu slot="dropdown">
            <template v-for="(item, index) in options.multiActions">
              <el-dropdown-item
                v-permission="[item.permission]"
                :disabled="item.enable != null && !item.enable"
                :key="index"
                :command="item"
              >
                {{ item.label }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>

        <slot name="filter-content" />
      </div>
    </div>
    <el-table
      v-loading="listLoading"
      ref="table"
      :data="dataList.items"
      :row-key="getRowKeys"
      :header-cell-style="{
        background: '#f2f3f4',
        color: '#555',
        'font-weight': 'bold',
        'line-height': '32px'
      }"
      :expand-row-keys="expandKeys"
      border
      fit
      highlight-current-row
      @sort-change="tableSortChange"
      @select="select"
      @select-all="selectAll"
    >
      <el-table-column
        v-if="options.multi"
        :reserve-selection="false"
        align="center"
        type="selection"
        width="55"
      />

      <slot name="data-columns" />
    </el-table>

    <pagination
      v-show="dataList.totalLine > 0"
      :total="dataList.totalLine"
      :page.sync="listQuery.page.pageNo"
      :auto-scroll="autoScroll"
      :limit.sync="listQuery.page.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { postJobData } from '@/api/common'
import permission from '@/directive/permission/index.js' // 权限判断指令

export default {
  name: 'PagingTableJob',
  directives: { permission },
  components: {},
  // 组件入参
  props: {
    // 展开子组件
    expand: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {
          keyId: 'id',
          add: {
            enable: false,
            permission: '',
            router: {}
          },
          edit: {
            enable: false,
            permission: '',
            router: {}
          },
          delete: {
            enable: false,
            permission: '',
            url: ''
          },
          // 批量操作
          multiActions: [],
          // 列表请求URL
          listUrl: '',
          // 删除请求URL
          deleteUrl: '',
          // 启用禁用
          stateUrl: '',
          // 可批量操作
          multi: false
        }
      }
    },

    // 列表查询参数
    listQuery: {
      type: Object,
      default: () => {
        return {
          view: 'openCareerWrapper',
          filter: {
            projectId: '',
            name: '',
            recruitmentId: '',
            status: '',
            publishDateStart: '',
            publishDateEnd: ''
            // excludedCareerIds: [
            //   'a70c794d-cee3-4635-8cca-d6345a93941c',
            //   'rtyl22008'
            // ]
          },
          page: {
            pageNo: 1,
            pageSize: 100
          }
        }
      }
    },

    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      expandKeys: [],
      // 接口数据返回
      dataList: {
        items: [],
        totalLine: 0
      },
      // 数据加载标识
      listLoading: true,
      // 选定和批量操作
      selectedIds: [],
      selectedRows: [],
      // 显示批量操作
      multiShow: false
    }
  },
  watch: {
    // 检测查询变化
    listQuery: {
      handler() {
        this.getList()
      },
      deep: true
    },

    // 选定监听
    selectedRows: {
      handler(val) {
        // 没有待选定的
        this.selectedIds = val.map((item) => this.getRowKeys(item))
        this.$emit('select-changed', { ids: this.selectedIds, objs: val })
      },
      deep: true
    },

    // 展开行
    expand: {
      handler(val) {
        this.toggleExpand(val)
      }
    },
    dataList: {
      handler(val) {
        this.$emit('data-changed', val)
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取选定的ID列表
    getSelectedIds() {
      return this.selectedIds
    },

    // 获取选定的key
    getRowKeys(row) {
      if (this.options.keyId) {
        return row[this.options.keyId]
      }
      return row['id']
    },

    // 添加
    optAdd() {
      // 路由跳转
      if (this.options.add.router) {
        this.$router.push(this.options.add.router)
      }
      this.$emit('add')
    },

    // 修改数据
    optEdit() {
      // 修改最后选定的项目
      const last = this.selectedRows[this.selectedRows.length - 1]
      const id = this.getRowKeys(last)

      // 跳转的
      if (this.options.edit.router) {
        const router = this.options.edit.router
        const params = router.params
        // 追加id参数
        params.id = id
        router.params = params
        this.$router.push(router)
      }
      this.$emit('edit', id, last)
    },

    // 删除
    optDelete() {
      if (this.options.delete.url) {
        // 删除
        this.$confirm('确实要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          postJobData(this.options.delete.url, { ids: this.selectedIds }).then(
            () => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.clearSelection()
              this.getList()
            }
          )
        })
      }
      this.$emit('delete', this.selectedIds)
    },

    // 批处理回调
    optAction(item) {
      // 启用
      if (item.value === 'enable') {
        this.handleState(item.url, 0)
        return
      }

      // 禁用
      if (item.value === 'disable') {
        this.handleState(item.url, 1)
        return
      }

      // 改其他状态
      if (item.value === 'state') {
        this.handleState(item.url, item.state)
        return
      }

      // 向外回调的操作
      this.$emit('multi-actions', {
        opt: item.value,
        ids: this.selectedIds,
        objs: this.selectedRows
      })
    },

    // 启用、禁用、修改状态
    handleState(url, state) {
      postJobData(url, { ids: this.selectedIds, state: state }).then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '状态修改成功!'
          })
          this.clearSelection()
          // 重新搜索
          this.getList()
        }
      })
    },

    /**
     * 添加数据跳转
     */
    handleAdd() {
      if (this.options.addRoute) {
        this.$router.push({ name: this.options.addRoute, params: {}})
        return
      }
      console.log('未设置添加数据跳转路由！')
    },

    /**
     * 查询数据列表
     */
    getList() {
      this.listLoading = true
      // this.listQuery.t = new Date().getTime()
      postJobData(this.options.listUrl, this.listQuery).then((res) => {
        // console.log('查询数据列表', res)
        this.listLoading = false

        if (res && res.data) {
          this.dataList = res.data
        }

        // 延迟回填选定状态
        this.$nextTick(() => {
          this.toggleSelection()
          this.toggleExpand()
        })
      })
    },

    /**
     * 刷新表格
     */
    refresh() {
      this.clearSelection()
      this.getList()
    },

    // 选择一项时
    select(selection, row) {
      const selected = selection.length && selection.indexOf(row) !== -1
      if (!selected) {
        let index = -1
        this.selectedRows.some((item, i) => {
          if (this.getRowKeys(item) == this.getRowKeys(row)) {
            index = i
          }
        })
        if (index > -1) this.selectedRows.splice(index, 1)
      } else {
        this.selectedRows.push(row)
      }
    },

    // 全选或全不选
    selectAll(selection) {
      if (selection.length > 0) {
        // 全选
        this.dataList.items.forEach((item) => {
          if (
            !this.selectedRows.some(
              (items) => this.getRowKeys(items) == this.getRowKeys(item)
            )
          ) {
            this.selectedRows.push(item)
          }
        })
      } else {
        // 全不选
        this.dataList.items.forEach((item) => {
          let index = -1
          this.selectedRows.some((items, i) => {
            if (this.getRowKeys(items) == this.getRowKeys(item)) {
              index = i
            }
          })
          if (index > -1) this.selectedRows.splice(index, 1)
        })
      }
    },

    // 服务端数据排序
    tableSortChange(column) {
      this.listQuery.pageNo = 1
      if (column.order === 'descending') {
        this.listQuery.orders = [{ column: column.prop, asc: false }]
      } else {
        this.listQuery.orders = [{ column: column.prop, asc: true }]
      }
      this.getList()
    },

    // 清理勾选状态
    clearSelection() {
      this.$refs.table.clearSelection()
      this.selectedIds = []
      this.selectedRows = []
    },

    // 回填勾选状态
    toggleSelection() {
      const ids = this.selectedRows.map((item) => this.getRowKeys(item))
      if (ids && ids.length > 0) {
        this.dataList.items.forEach((row) => {
          const selected = ids.some((ele) => ele === this.getRowKeys(row))
          this.$refs.table.toggleRowSelection(row, selected)
        })
      }
    },

    // 展开或关闭行
    toggleExpand() {
      if (this.expand) {
        this.dataList.items.forEach((row) => {
          const key = this.getRowKeys(row)
          this.expandKeys.push(key)
        })
      } else {
        this.expandKeys = []
      }
    }
  }
}
</script>

<style scoped>
::v-deep .filter-container .filter-item {
  margin-left: 10px;
}

::v-deep .filter-container .filter-item:first-child {
  margin-left: 0px;
}

::v-deep .el-button--small {
  padding: 9px;
}
</style>
