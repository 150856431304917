var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.examId != null && _vm.examId !== ""
    ? _c("web-socket", {
        attrs: {
          url:
            "/api/socket/exam/" +
            _vm.userId +
            "/" +
            _vm.examId +
            "/" +
            _vm.platform,
        },
        on: { receive: _vm.onMessage },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }