import { post } from '@/utils/request'

/**
 * 保存截图
 * @param data
 */
export function saveCapture(data) {
  return post('/api/paper/capture/save', data)
}

