import { post, download } from '@/utils/request'

export function updateData(data) {
  return post('/api/sys/user/update', data)
}

export function saveData(data) {
  return post('/api/sys/user/save', data)
}

export function userReg(data) {
  return post('/api/sys/user/reg', data)
}

export function updatePass(data) {
  return post('/api/sys/user/update-pass', data)
}

export function findInfo(token) {
  return post('/api/sys/user/info', { token: token })
}

export function fetchDetail(id) {
  return post('/api/sys/user/detail', { id: id })
}

/**
 * 搜索用户列表
 * @param data
 * @returns {*}
 */
export function fetchPaging(data) {
  return post('/api/sys/user/paging', data)
}

/**
 * 导出
 * @param data
 */
export function exportExcel(data) {
  return download('/api/sys/user/export', data, '导出的用户.xlsx')
}

/**
 * 批量修改部门
 * @param data
 * @returns {Promise}
 */
export function apiBatchDept(data) {
  return post('/api/sys/user/batch-dept', data)
}

/**
 * 批量修改角色
 * @param data
 * @returns {Promise}
 */
export function apiBatchRole(data) {
  return post('/api/sys/user/batch-role', data)
}

export function login(data) {
  return post('/api/sys/user/login', data)
}

export function getInfo(token) {
  return post('/api/sys/user/info', { token: token })
}

export function logout() {
  return post('/api/sys/user/logout', {})
}

export function reg(data) {
  return post('/api/sys/user/reg', data)
}

export function mobileLogin(data) {
  return post('/api/sys/user/mobile-login', data)
}

export function faceLogin(data) {
  return post('/api/sys/user/face-login', data)
}

export function resetPass(data) {
  return post('/api/sys/user/reset-pass', data)
}

export function faceCheck(data) {
  return post('/api/sys/user/face-check', data)
}


/**
 * 删除监考任务
 * @param data
 * @returns {*}
 */
export function delJob(data) {
  return post('/api/invigilation/job/delete', data)
}


/**
 * 添加监考老师
 * @param data
 * @returns {*}
 */
export function addTeacher(data) {
  return post('/api/invigilation/teacher/attach', data)
}
/**
 * 移除监考老师
 * @param data
 * @returns {*}
 */
export function removeTeacher(data) {
  return post('/api/invigilation/teacher/detach', data)
}
/**
 * 清空监考老师
 * @param data
 * @returns {*}
 */
export function cleanTeacher(data) {
  return post('/api/invigilation/teacher/clean', data)
}

/**
 * 添加试卷
 * @param data
 * @returns {*}
 */
export function addExam(data) {
  return post('/api/invigilation/exam/attach', data)
}
/**
 * 移除试卷
 * @param data
 * @returns {*}
 */
export function removeExam(data) {
  return post('/api/invigilation/exam/detach', data)
}
/**
 * 清空试卷
 * @param data
 * @returns {*}
 */
export function cleanExam(data) {
  return post('/api/invigilation/exam/clean', data)
}
/**
 * 添加阅卷老师
 * @param data
 * @returns {*}
 */
export function addExaminer(data) {
  return post('/api/invigilation/examiner/attach', data)
}
/**
 * 移除阅卷老师
 * @param data
 * @returns {*}
 */
export function removeExaminer(data) {
  return post('/api/invigilation/examiner/detach', data)
}
/**
 * 清空阅卷老师
 * @param data
 * @returns {*}
 */
export function cleanExaminer(data) {
  return post('/api/invigilation/examiner/clean', data)
}
/**
 * 读取考生
 * @param data
 * @returns {*}
 */
export function genStudent(data) {
  return post('/api/invigilation/student/gen', data)
}
/**
 * 自动安排
 * @param data
 * @returns {*}
 */
export function assginStudent(data) {
  return post('/api/invigilation/student/assgin', data)
}