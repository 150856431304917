var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            "remote-method": _vm.fetchData,
            filterable: true,
            size: _vm.size,
            clearable: _vm.clearable,
            remote: "",
            "reserve-keyword": "",
            "automatic-dropdown": "",
          },
          on: {
            change: _vm.handlerChange,
            clear: function ($event) {
              return _vm.fetchData()
            },
          },
          model: {
            value: _vm.currentValue,
            callback: function ($$v) {
              _vm.currentValue = $$v
            },
            expression: "currentValue",
          },
        },
        _vm._l(_vm.dataList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item[_vm.config.label],
              value: item[_vm.config.value],
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }