import { post } from '@/utils/request'

/**
 * 开始训练
 * @param mode
 * @param repoId
 * @param userId
 * @param clear
 * @returns {*}
 */
export function startTrain(mode, repoId, userId, clear) {
  return post('/api/repo/train/start', { mode: mode, repoId: repoId, userId: userId, clear: clear })
}

/**
 * 训练结果详情
 * @param trainId
 * @returns {Promise}
 */
export function trainDetail(trainId) {
  return post('/api/repo/train/detail', { id: trainId })
}

/**
 * 填充答案
 * @param id
 * @param trainId
 * @param answers
 * @param isRight
 * @returns {*}
 */
export function fillResult(data) {
  return post('/api/repo/train/fill', data)
}

/**
 * 手动结束训练
 * @param trainId
 * @returns {*}
 */
export function finishTrain(trainId) {
  return post('/api/repo/train/finish', { id: trainId })
}

/**
 * 查找答题卡
 * @param trainId
 * @returns {*}
 */
export function listCard(trainId) {
  return post('/api/repo/train/card', { id: trainId })
}

/**
 * 查找训练题目详情
 * @param trainId
 * @param quId
 * @param mode
 * @returns {Promise}
 */
export function quDetail(trainId, quId, mode) {
  return post('/api/repo/train/qu-detail', { trainId: trainId, quId: quId, mode: mode })
}

/**
 * 下一题或者下一题
 * @param mode
 * @param repoId
 * @param userId
 * @param sequence
 * @returns {*}
 */
export function nextQu(mode, repoId, userId, sequence) {
  return post('/api/repo/train/next', { mode: mode, repoId: repoId, userId: userId, sequence: sequence })
}

/**
 * 最近训练
 * @param repoId
 * @returns {*}
 */
export function latestTrain(repoId) {
  return post('/api/repo/train/paging', { size: 5, current: 1, params: { repoId: repoId }})
}

/**
 * 查找最新的训练
 * @returns {*}
 */
export function checkProcess() {
  return post('/api/repo/train/check', {})
}
