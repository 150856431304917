<template>
  <div class="app-container">
    <div class="video-box">
      <video-presenter v-if="deviceId" :height="600" :cam-type="'side'" :paperid="paperid" :choose-device-id="deviceId" :is-cheat="isCheat" @unfocusWarning="unfocusWarning"/>
    </div>
    <div class="content">
      <el-card class="box-card">
        注意: 某些型号的手机上不显示画面，只要在电脑上能看到手机画面即可
      </el-card>
      <div class="tip-status">正在拍摄中..</div>
      <div class="tip">{{ tipMsg }}</div>
      <!-- <el-button type="danger" style="width: 100%;" @click="stop">结束摄像</el-button> -->
    </div>


  </div>
</template>

<script>
import VideoPresenter from '../../components/VideoPresenter'
import { Notification } from 'element-ui'

export default {
  name: 'MobileCamera',
  components: { VideoPresenter },
  data() {
    return {
      deviceId: null,
      paperid: '',
      // isStop: false
      isCheat: false,
      tipMsg: '请勿关闭手机，直至考试结束!'


    }
  },
  mounted() {
    const { deviceId, paperid, isTest } = this.$route.query
    this.deviceId = deviceId
    this.paperid = paperid
    if (isTest) {
      this.tipMsg = '完成测试后，请关闭监控'
    }
    this.noSleep()
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.isCheat = true
      } else {
        // setTimeout(()=>{
        Notification.info({
          title: '提示信息',
          message: '请不要离开监控页面，保持手机常亮'
        })
        // },1000)
      }
    })
  },
  methods: {
    stop() {
      if (navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome') != -1) {
        window.location.href = 'about:blank'
        window.close()
      } else {
        window.opener = null
        window.open('', 'self')
        window.close()
      }
    },
    noSleep() {
      const noSleep = new this.$NoSleep()
      document.addEventListener('click',
        function enableNoSleep() {
          noSleep.enable()
          document.removeEventListener('click', enableNoSleep, false)
        },
        false)
    },
    unfocusWarning() {
      console.log('我发送了一次警告')
      this.isCheat = false
    }
  }
}
</script>
<style scoped>
.app-container {
  width: 100%;
  padding: 0;
}

.video-box {
  width: 100%;
  height: 600px;
}

.content {
  width: 100%;
  padding: 10px;
}

.box-card {
  width: 100%;
  background-color: rgb(253, 244, 237);
  color: rgb(225, 184, 84);
  margin: 20px 0;
}

.tip,
.tip-status {
  width: 100%;
  margin: 10px 0;
  font-weight: bold;
}

.tip-status {
  color: rgb(105, 216, 163);
}

.tip {
  color: red;
}
</style>
