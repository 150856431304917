<template>
  <div>
    <el-table
      v-loading="listLoading"
      :data="warningStudentList"
      :header-cell-style="{
        background: '#f2f3f4',
        color: '#555',
        'font-weight': 'bold',
        'line-height': '32px',
      }"
      style="width: 100%"
    >
      <el-table-column prop="studentCode" label="编号" align="center" />
      <el-table-column prop="studentName" label="姓名" align="center" />
      <el-table-column prop="createTime" label="发起时间" align="center" />
      <el-table-column prop="invaligatorId" label="预警等级" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.alarmLevel_dictText }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invaligatorId" label="描述" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.msg">{{ scope.row.msg.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="processState" label="状态" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.processState == 1">已完成</span>
          <span v-show="scope.row.processState == 5">待复核</span>
          <span v-show="scope.row.processState == 6">已复核</span>
        </template>
      </el-table-column>
      <el-table-column prop="processResult" label="处理结果" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.processResult == 1">继续考试</span>
          <span v-show="scope.row.processResult == 2">严重警告</span>
          <span v-show="scope.row.processResult == 3">强制交卷</span>
        </template>
      </el-table-column>
      <el-table-column prop="processTime" label="处理时间" align="center" />
      <el-table-column prop="processMemo" label="处理说明" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.processMemo }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invaligatorId" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        :current-page.sync="page.currentPage"
        :page-sizes="[100, 200, 300, 400, 500]"
        :page-size="page.size"
        :total="total"
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :visible.sync="warningInfoVisible" title="预警信息" width="50%">
      <div v-loading.fullscreen.lock="fullscreenLoading">
        <el-descriptions
          :column="4"
          class="margin-top"
          title=""
          direction="vertical"
          border
        >
          <el-descriptions-item label="考生编号">{{
            warnInfo.studentCode
          }}</el-descriptions-item>
          <el-descriptions-item label="预警等级">
            <span>{{ warnInfo.alarmLevel_dictText }}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="发起时间">{{
            warnInfo.createTime
          }}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-show="warnInfo.processState == 1">已完成</span>
            <span v-show="warnInfo.processState == 5">待复核</span>
            <span v-show="warnInfo.processState == 6">已复核</span>
          </el-descriptions-item>
          <el-descriptions-item label="处理结果">
            <span v-show="warnInfo.processResult == 1">继续考试</span>
            <span v-show="warnInfo.processResult == 2">严重警告</span>
            <span v-show="warnInfo.processResult == 3">强制交卷</span>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="处理时间">{{
            warnInfo.processTime
          }}</el-descriptions-item>
          <el-descriptions-item v-if="warnInfo.msg" :span="4" label="描述">
            {{ warnInfo.msg.content }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="warnInfo.processMemo"
            :span="4"
            label="处理说明"
          >
            {{ warnInfo.msg.processMemo }}
          </el-descriptions-item>
          <el-descriptions-item
            v-show="warnInfo.pictureList && warnInfo.pictureList.length > 0"
            :span="4"
            label="照片"
          >
            <div class="wranImg_list">
              <div
                v-for="(img, index) in warnInfo.pictureList"
                :key="index"
                class="wranImg_item"
              >
                <el-image
                  :src="img.facadeImageUrl"
                  :preview-src-list="[img.facadeImageUrl]"
                  style="width: 100px; height: 100px"
                  fit="fill"
                />
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item
            v-show="
              warnInfo.snapPictureList && warnInfo.snapPictureList.length > 0
            "
            :span="4"
            label="人脸对比照片"
          >
            <div class="wranImg_list">
              <div
                v-for="(img, index) in warnInfo.snapPictureList"
                :key="index"
                class="wranImg_item"
              >
                <div class="wranImg_bd_wrap">
                  <div class="wranImg_bd_item">
                    <div class="wranImg_bd_tit">抓拍</div>
                    <div v-if="img.facadeImageUrl">
                      <el-image
                        :src="img.facadeImageUrl"
                        :preview-src-list="[img.facadeImageUrl]"
                        style="width: 100px; height: 100px"
                        fit="fill"
                      />
                    </div>
                  </div>
                  <div class="wranImg_bd_item">
                    <div class="wranImg_bd_tit">认证</div>
                    <div v-if="img.realImageUrl">
                      <el-image
                        :src="img.realImageUrl"
                        :preview-src-list="[img.realImageUrl]"
                        style="width: 100px; height: 100px"
                        fit="fill"
                      />
                    </div>
                    <div v-else class="wranImg_bd_noImg">暂无认证</div>
                  </div>
                </div>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="chiefContinuingDialog()"
          >继续考试</el-button
        >
        <el-button type="danger" @click="chiefForceHandDialog()"
          >强制交卷</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  httpchiefmonitoralarmpaging,
  chiefMonitorContinuing,
  chiefmonitorForceHand,
  httpalarmview,
} from "@/api/exam/watch";

export default {
  name: "ExaminationWarningList",
  components: {},
  props: {
    examId: String,
  },
  data() {
    return {
      warningStudentList: [],
      warningInfoVisible: false,
      warnInfo: {
        studentCode: "",
        alarmLevel: "",
        createTime: "",
        msg: {
          content: "",
        },
        pictureList: [],
      },
      listLoading: false,
      watchId: "",
      page: {
        currentPage: 1,
        size: 100,
      },
      total: 0,
      fullscreenLoading:false,
    };
  },
  watch: {
    warningInfoVisible(val) {
      if (!val) {
        this.getAlarmPaging();
        this.$emit("refreshCount");
      }
    },
    page: {
      handler(val) {
        this.getAlarmPaging();
      },
      deep: true,
    },
  },
  created() {
    // this.listQuery.params.examId = this.examId
    this.getAlarmPaging();
  },
  methods: {
    showDetail(info) {
      // this.warnInfo = info
      this.fullscreenLoading = true;
      if (info.id) {
        this.warningInfoVisible = true;
        this.lookAlarm(info.id);
      }
      this.watchId = info.id;
    },
    // 获取预警列表
    async getAlarmPaging() {
      this.listLoading = true;
      const subData = {
        current: 1,
        size: 100,
        params: {
          remoteExamId: this.examId,
          // invigilatorId: '1',
          // handState: 0
          // name: '',
          // code: '',
          // state: ''
        },
      };
      const res = await httpchiefmonitoralarmpaging(subData);
      this.listLoading = false;
      if (res.success) {
        this.warningStudentList = res.data.records;
        this.total = res.data.total;
      }
    },

    // 显示异常信息
    showMsg(msg) {
      if (msg) {
        this.$message.info(msg);
      }
    },

    handleReview(id) {
      this.$router.push({ name: "ReviewPaperDetail", params: { paperId: id } });
    },

    handleView(id) {
      this.$router.push({ name: "ShowExam", params: { id: id } });
    },
    // 主考强制考生交卷
    chiefForceHandDialog() {
      this.paused = true;

      this.$prompt("输入强制交卷理由", "强制交卷", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        chiefmonitorForceHand({ paperId: this.watchId, message: value }).then(
          () => {
            this.$message.success("操作成功！");
            if (this.$refs.dataGrid) {
              this.$refs.dataGrid.getList();
            }
            this.fetchStat();

            this.paused = false;
          }
        );
      });
    },

    // 继续考试 主考消除疑似作弊报警，允许考生继续考试
    chiefContinuingDialog() {
      this.paused = true;

      this.$prompt("输入继续考试理由", "继续考试", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        chiefMonitorContinuing({ paperId: this.watchId, message: value }).then(
          () => {
            this.$message.success("操作成功！");
            if (this.$refs.dataGrid) {
              this.$refs.dataGrid.getList();
            }
            this.fetchStat();

            this.paused = false;
          }
        );
      });
    },
    async lookAlarm(id) {
      const subdata = {
        id,
      };
      const res = await httpalarmview(subdata);
      console.log(res, "详情是");
      this.warnInfo = res.data;
      this.fullscreenLoading = false;
    },
    handleSizeChange(val) {
      this.page.size = val;
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
    },
  },
};
</script>

<style scoped>
.ex-ee {
  color: #ff0000;
}
.ex-normal {
  color: #0fda6d;
}
.wranImg_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.img_item,
.wranImg_item {
  margin: 10px;
}
.wranImg_bd_wrap {
  display: flex;
}
.wranImg_bd_item {
  width: 100px;
  text-align: center;
}
.wranImg_bd_tit {
  line-height: 32px;
  color: #8c8989;
}
.wranImg_bd_noImg {
  width: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #8c8989;
  color: #8c8989;
}
</style>
