var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qu-item-exam", {
    on: { fill: _vm.handleAnswer },
    model: {
      value: _vm.quData,
      callback: function ($$v) {
        _vm.quData = $$v
      },
      expression: "quData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }