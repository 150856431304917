var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step2-content" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("在线考试须知")]),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v("* 为保障考试成绩真实有效，请遵守以下考场纪律"),
      ]),
      _c("div", { staticClass: "info-content" }, [
        _vm._m(0),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(
            " 1.监考官会通过监控大屏全程观察考生举止行为，严禁使用手机或平板电脑查阅信息，严禁查看考试辅助书籍等材料 "
          ),
        ]),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(
            " 2.根据系统要求开启监控设备，考试中严禁有其他人员陪同，例如家人、同事、朋友等; "
          ),
        ]),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(
            " 3.考试中禁止替考，跳出考试页面，接打电话，左顾右盼，离开座位，不要佩戴耳机、帽子，口罩，头发不能遮盖耳朵不要遮挡监控设备，衣着要得体; "
          ),
        ]),
        _c("p", { staticClass: "p-style" }, [
          _vm._v("4.考官会随机抽查考生座位周围环境，请配合考官操作。"),
        ]),
        _vm._m(1),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(
            " 1.任意作弊行为一经核实，将取消资格，并记录在融通人力诚信档案中; "
          ),
        ]),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(
            " 2.考生的个人信息、抓拍照片、答题信息将受到严格保护，不会向任何第三方透露; "
          ),
        ]),
        _c("p", { staticClass: "p-style" }, [
          _vm._v(" 3.禁止拍摄、记录试题，泄露考试试题内容属于侵权行为。 "),
        ]),
        _c(
          "p",
          { staticClass: "p-style" },
          [
            _c(
              "el-checkbox",
              {
                staticStyle: { color: "#f4585c" },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [
                _vm._v(
                  " 我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。 "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "btn-row" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "large" },
              on: { click: _vm.last },
            },
            [_vm._v(" 上一步 ")]
          ),
          _vm.isPractice
            ? _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: {
                    disabled: !(_vm.timestamp <= 0),
                    type: "primary",
                    size: "large",
                  },
                  on: { click: _vm.practice },
                },
                [
                  _vm._v(
                    " 开始练习" +
                      _vm._s(_vm.timestamp ? "(" + _vm.timestamp + ")s" : "") +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isPractice
            ? _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: {
                    disabled: !(_vm.timestamp <= 0),
                    type: "primary",
                    size: "large",
                  },
                  on: { click: _vm.startExam },
                },
                [
                  _vm._v(
                    " 开始考试" +
                      _vm._s(_vm.timestamp ? "(" + _vm.timestamp + ")s" : "") +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            center: true,
            title: "考试纪律",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.confirmList, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticStyle: { "margin-top": "15px" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                      _c("span", [_vm._v(_vm._s(item.title))]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { float: "right" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.confirms[item.id],
                              callback: function ($$v) {
                                _vm.$set(_vm.confirms, item.id, $$v)
                              },
                              expression: "confirms[item.id]",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: { disabled: !_vm.isConfirmAll, type: "primary" },
                  on: { click: _vm.confirmDiscipline },
                },
                [_vm._v(" 开始作答 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p-style" }, [
      _c("b", [_vm._v("为保证考试公平公正，您务必遵守以下规则:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p-style" }, [_c("b", [_vm._v("重要提示:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }