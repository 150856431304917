<template>
  <div>
    <div class="content">
      <div class="title">基本信息确认</div>
      <div class="sub-title">* 为确保后续联系畅通，请认真填写您的信息</div>
      <el-form
        ref="postForm"
        :model="postForm"
        :rules="rules"
        label-suffix=" ："
        label-width="45%"
        label-position="right"
        size="mini"
      >
        <el-row>
          <el-col :span="20">
            <el-form-item
              label="姓名"
              prop="name"
              style="margin-bottom: 17px; margin-top: 22px"
            >
              <el-input
                v-model="postForm.name"
                style="width: 70%"
                maxlength="200"
                placeholder="请输入姓名"
                clearable
                size="large"
                disabled
              />
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item
              label="手机号码"
              prop="mobile"
              style="margin-bottom: 17px"
            >
              <el-input
                v-model="postForm.mobile"
                style="width: 70%"
                maxlength="200"
                clearable
                disabled
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="邮箱" prop="email" style="margin-bottom: 17px">
              <el-input
                v-model="postForm.email"
                style="width: 70%"
                placeholder="请输入邮箱"
                maxlength="200"
                clearable
                disabled
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="证件类型"
              prop="cardType"
              style="margin-bottom: 17px"
            >
              <el-input
                v-model="postForm.cardType"
                style="width: 70%"
                maxlength="200"
                clearable
                disabled
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="证件号码"
              prop="identifyId"
              style="margin-bottom: 17px"
            >
              <el-input
                v-model="postForm.identifyId"
                style="width: 70%"
                maxlength="200"
                clearable
                disabled
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" size="large" class="next-btn" @click="next">
        保存，下一步
      </el-button>
    </div>
  </div>
</template>
<script>
import { guideStepMixin } from './mixin'

export default {
  name: 'GuideStep1',
  mixins: [guideStepMixin],
  data() {
    return {
      postForm: {},
      rules: {
        name: [{ required: true, message: '姓名不能为空' }],
        mobile: [{ required: true, message: '手机号不能为空' }],
        // email: [{ required: false, message: '邮件不能为空' }],
        cardType: [{ required: true, message: '证件类型不能为空' }],
        identifyId: [{ required: true, message: '证件号码不能为空' }]
      }
    }
  },
  watch: {
    info: {
      handler(val) {
        // console.log('>>>> 组件内获取到详情', val)
        if (val) {
          this.postForm = val
          // const { name, identifyId, mobile, email } = val
          // this.postForm.name = name
          // this.postForm.identifyId = identifyId
          // this.postForm.mobile = mobile
          // this.postForm.email = email
          this.postForm.cardType = '身份证'
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  methods: {}
}
</script>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 15px;
}
.sub-title {
  color: #f36060;
  margin-bottom: 15px;
}
.next-btn {
  width: 40%;
  border-color: #f4585c;
  background-color: #f4585c;
}
</style>
