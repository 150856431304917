<template>

  <div>

    <el-dialog
      :visible.sync="leaveDialog"
      :before-close="leaveBack"
      title="注意"
      width="30%"
    >
      <span class="alert-text">您已经离开{{ leaveTimes }}次，离开{{ leaveCount }}次将自动交卷</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="leaveBack">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="msgDialog"
      :before-close="leaveBack"
      title="提示信息"
      width="30%"
    >
      <span class="alert-text">本场考试已开启切屏监控，离开{{ leaveCount }}次将会强制交卷，请诚信考试！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="leaveBack">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import { leaveIncr, httpclient } from '@/api/paper/exam'
import screenfull from 'screenfull'
import { Notification } from 'element-ui'
import lifecycle from 'page-lifecycle'

export default {
  name: 'LeaveChecker',
  props: {
    leaveOn: Boolean,
    leaveCheck: {
      type: Number,
      default: 0
    },
    leaveCount: {
      type: Number,
      default: 0
    },
    paperId: {
      type: String,
      default: ''
    },
    examId: {
      type: String,
      default: ''
    },
    checkToolDialog: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      msgDialog: false,
      timer: null,
      leaveTime: 0,
      leaveDialog: false,
      leaveTimes: 0,
      countTimer: null
    }
  },

  watch: {

    leaveOn: {
      handler(val) {
        if (val) {
          this.msgDialog = true
          // 离开检测
          this.startCalc()
        }
      }
    },
    checkToolDialog(val) {
      if (val != 2) {
        screenfull.exit()
        clearInterval(this.timer)
      } else {
        this.timer = setInterval(this.fullChecker, 1000)
      }
    }

  },

  created() {
    // 离开检测
    if (this.leaveOn) {
      this.startCalc()
      this.msgDialog = true
    }
  },

  mounted() {
    // 为窗口注册失去焦点事件
    window.onblur = () => {
      console.log('窗口失去交点')
      // Notification.error({
      //   title: '提示信息',
      //   message: '请规范考试，保持您的鼠标在答题页面上！'
      // })
      // this.sendAlterm('鼠标离开考试页面')
    }
    // 屏蔽鼠标右键
    // window.oncontextmenu((event) => {
    //   event.returnValue = false
    // })
    document.body.oncontextmenu = (event) => {
      console.log('鼠标右键')
      return false
    }
    // window.oncopy((event) => {
    //   event.returnValue = false
    // })

    document.body.oncopy = (event) => {
      console.log('打印')
      return false
    }
    const that = this
    if (that.countTimer) {
      clearTimeout(that.countTimer)
    }
    // 监听页面可见性
    // window.addEventListener('visibilitychange', this.visibleChange)
    console.log(lifecycle, 'lifecycle是什么')
    lifecycle.addEventListener('statechange', this.pageChange)
    window.addEventListener('keydown', this.keyDown)
  },
  beforeDestroy() {
    // 移除监听
    clearInterval(this.timer)
    // window.removeEventListener('visibilitychange', this.visibleChange)
    window.removeEventListener('keydown', this.keyDown, false)
    lifecycle.removeEventListener('statechange', this.pageChange)
  },

  methods: {

    clearCalc() {
      // 重启计时器
      if (this.timer != null) {
        clearInterval(this.timer)
      }
    },

    // 开始倒计时
    startCalc() {
      this.clearCalc()
      this.timer = setInterval(this.fullChecker, 1000)
    },

    // 切换到全屏模式
    toggleFull() {
      // console.log('切换全屏', screenfull)
      if (screenfull && !screenfull.isFullscreen) {
        console.log(screenfull, '全屏的方法')
        screenfull.exit()
        screenfull.request()
        this.leaveTime = 0
        this.startCalc()
      }
    },

    // 定时检查全屏状态
    fullChecker() {
      // console.log('是否全屏', screenfull)
      // 已经切出全屏状态
      if (screenfull && !screenfull.isFullscreen) {
        screenfull.exit()
        screenfull.request()
        // this.sendAlterm('esc退出全屏')
        // 切到全屏

        // 检查并保存
        // this.saveIncr()
      }
    },

    // 回到正常考试
    leaveBack() {
      this.leaveDialog = false
      this.msgDialog = false
      this.toggleFull()
    },

    // 视图发生变化
    // visibleChange() {
    //   const visible = document.visibilityState

    //   console.log('+++++change', visible)

    //   if (visible === 'hidden') {
    //     this.leaveTime = new Date().getTime()
    //   } else {
    //     this.saveIncr()
    //   }
    // },

    // saveIncr() {
    //   if (this.leaveTime === 0) {
    //     return
    //   }

    //   const that = this

    //   // 当前时间
    //   const nowTime = new Date().getTime()
    //   const leaveSec = (nowTime - this.leaveTime) / 1000

    //   if (leaveSec > this.leaveCheck) {
    //     // 暂停计数器
    //     this.clearCalc()
    //     this.sendAlterm('切换屏幕')
    //     // 同步到后台
    //     leaveIncr({ id: this.paperId }).then(res => {
    //       const data = res.data

    //       that.leaveTimes = data.leaveTimes
    //       that.leaveDialog = true
    //       that.leaveTime = 0

    //       // 已经违约
    //       if (data.overLimit) {
    //         that.$emit('break')
    //       }
    //     })
    //   }
    // },
    close() {
      if (navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome') != -1) {
        window.location.href = 'about:blank'
        window.close()
      } else {
        window.opener = null
        window.open('', 'self')
        window.close()
      }
    },
    keyDown() {
      const k = window.event.keyCode

      console.log(k, 'k是什么')

      // 屏蔽 F5 刷新键
      if (k == 116) {
        window.event.keyCode = 0
        window.event.returnValue = false
      }
      // Ctrl + c
      if (window.event.ctrlKey && k == 67) { window.event.returnValue = false }
      // Ctrl + v
      if (window.event.ctrlKey && k == 86) { window.event.returnValue = false }
      // Ctrl + p
      if (window.event.ctrlKey && k == 80) { window.event.returnValue = false }

      // Ctrl + R
      if (window.event.ctrlKey && k == 82) { window.event.returnValue = false }

      // 屏蔽Ctrl+n
      if (window.event.ctrlKey && k == 78) { window.event.returnValue = false }

      // 屏蔽Ctrl+w
      if (window.event.ctrlKey && k == 87) { window.event.returnValue = false }
      // 屏蔽 shift+F10
      if (event.shiftKey && k == 121) { window.event.returnValue = false }
      // 屏蔽 shift 加鼠标左键新开一网页
      if (window.event.srcElement.tagName == 'A' && window.event.shiftKey) {
        window.event.returnValue = false
      }
      // 屏蔽Alt+w
      if (window.event.altKey && k == 87) { window.event.returnValue = false }
      // 屏蔽Alt+F4
      if (window.event.altKey && k == 115) {
        window.showModelessDialog(
          'about:blank',
          '',
          'dialogWidth:1px;dialogheight:1px'
        )
        return false
      }
      // 屏蔽 Alt+方向键 ←
      // 屏蔽 Alt+ 方向键→
      if (window.event.altKey && (k == 37 || k == 39)) {
        // alert('不准你使用ALT+方向键前进或后退网页！')
        window.event.returnValue = false
      }
    },
    pageChange(event) {
      const that = this
      if (event.newState == 'hidden') {
        console.log('页面离开了')
        that.countTimer = setTimeout(() => {
          that.sendAlterm('切换屏幕')
          // 同步到后台
          leaveIncr({ id: that.paperId }).then(res => {
            const data = res.data

            that.leaveTimes = data.leaveTimes
            that.leaveDialog = true
            // that.leaveTime = 0

            // 已经违约
            if (data.overLimit) {
              that.$emit('break')
            }
          })
        }, that.leaveCheck * 1000)
      }
      if (event.oldState == 'active' && event.newState == 'passive') {
        console.log('鼠标离开了')
        // Notification.error({
        //   title: '提示信息',
        //   message: '请规范考试，保持您的鼠标在答题页面上！'
        // })
        that.sendAlterm('鼠标离开考试页面')
      }
    },
    async sendAlterm(msg) {
      const subData = {
        examId: this.examId,
        paperId: this.paperId,
        description: msg
      }
      const res = await httpclient(subData)
      if (res.success) {
        console.log(res, '操作成功')
      }
    }

  }
}
</script>

<style scoped>

  .alert-text{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
  }
</style>

