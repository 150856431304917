var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("web-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: "搜索考试名称",
                    size: "small",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticClass: "filter-item",
                  attrs: {
                    size: "small",
                    type: "datetimerange",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.listQuery.params.dateRange,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                    },
                    expression: "listQuery.params.dateRange",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "考试名称", "show-overflow-tooltip": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("detail-link", {
                            attrs: {
                              id: scope.row.id,
                              title: scope.row.title,
                              to: "CheckExam",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "时长",
                    align: "center",
                    prop: "totalTime",
                    width: "120px",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "总分",
                    align: "center",
                    prop: "totalScore",
                    width: "120px",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "及格分",
                    align: "center",
                    prop: "qualifyScore",
                    width: "120px",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "考试时间",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.startTime) +
                              " ~ " +
                              _vm._s(scope.row.endTime) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "考试状态", align: "center", width: "120px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.state === 0
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(" 进行中 "),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.state === 1
                            ? _c(
                                "el-tag",
                                { attrs: { type: "danger", disabled: "" } },
                                [_vm._v(" 已禁用 ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.state === 2
                            ? _c(
                                "el-tag",
                                { attrs: { type: "warning", disabled: "" } },
                                [_vm._v(" 未开始 ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.state === 3
                            ? _c("el-tag", { attrs: { disabled: "" } }, [
                                _vm._v(" 已结束 "),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }