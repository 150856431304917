<template>
  <div>
    <device-header :exam-info="examInfo" />
    <check-content />
    <div class="footer">
      <div class="footer-inner">
        <div v-html="siteData.copyRight" />
      </div>
    </div>
  </div>
</template>

<script>
import VideoPresenter from '@/components/VideoPresenter'
import DeviceHeader from './component/DeviceHeader.vue'
import CheckContent from './component/CheckContent.vue'
import { mapGetters } from 'vuex'
import { fetchprepareexamInfo } from '../../../api/web/web'
import moment from 'moment'

export default {
  components: { VideoPresenter, DeviceHeader, CheckContent },
  data() {
    return {
      deviceId: null,
      paperid: '',
      currentStep: 0,
      value: '0',
      imgUrl: '',
      voiceUrl: '',
      examInfo: {},
      loading: false,
      countDown: ''
    }
  },
  computed: {
    ...mapGetters(['siteData'])
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.getExamInfo(this.$route.params.id)
    }
  },
  methods: {
    async getExamInfo(id) {
      this.loading = true
      const res = await fetchprepareexamInfo({ id })
      this.loading = false
      if (res && res.data) {
        this.examInfo = res.data
      } else {
        console.log('接口错误', res)
      }
    },
    back() {
      const { id = '' } = this.$route.params
      this.$router.push({ name: 'LoginExam', params: { id }})
    }
  }
}
</script>
<style scoped>
.footer {
  color: #a3a8b0;
  background: #333;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
}
</style>
