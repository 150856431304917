<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="用户信息"
    width="60%"
    @close="handleClose"
  >
    <el-tabs v-model="tabActive">
      <el-tab-pane label="基本信息" name="base">
        <user-update-base
          :user-id="userId"
          :depart-id="departId"
          @close="handleClose"
        />
      </el-tab-pane>
      <el-tab-pane v-if="userId" label="账号绑定" name="bind">
        <user-update-bind :user-id="userId" />
      </el-tab-pane>
      <el-tab-pane label="人脸认证记录" name="photolog">
        <user-photo-log :user-id="userId" />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import UserUpdateBase from '@/views/admin/sys/user/components/UserUpdateDialog/base.vue'
import UserUpdateBind from '@/views/admin/sys/user/components/UserUpdateDialog/bind.vue'
import UserPhotoLog from '@/views/admin/sys/user/components/UserUpdateDialog/photoLog.vue'

export default {
  name: 'UserUpdateDialog',
  components: { UserUpdateBind, UserUpdateBase, UserPhotoLog },
  props: {
    userId: String,
    departId: String,
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialogVisible: false,
      loading: false,
      tabActive: 'base'
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {
    // 关闭选择框
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
