var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-bg" }, [
    _c("div", { staticClass: "header-inner container" }, [
      _c("div", { staticClass: "col-logo" }, [
        _vm.siteData.frontLogoUrl
          ? _c("div", [
              _c("img", {
                staticStyle: { height: "40px" },
                attrs: {
                  src: _vm.siteData.frontLogoUrl,
                  alt: _vm.siteData.siteName,
                },
              }),
            ])
          : _c("div", { staticClass: "site-tt" }, [
              _vm._v(" " + _vm._s(_vm.siteData.siteName) + " "),
            ]),
      ]),
      _c("div", { staticClass: "col-menu" }),
      _c("div", { staticClass: "remaining-time" }, [
        _c("span", { staticClass: "remaining-time-text" }, [
          _vm._v(" 开考倒计时："),
        ]),
        _c("span", { staticClass: "remaining-time-time" }, [
          _vm._v(" " + _vm._s(_vm.remainingTime) + " "),
        ]),
      ]),
      _c("div", { staticClass: "right-user" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }