var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isVisible,
            "before-close": _vm.handleClose,
            title: _vm.datainfo.realName,
            width: "1050px",
          },
        },
        [
          _vm.isVisible
            ? _c("div", { staticClass: "video-box" }, [
                _c("div", { staticClass: "main-video-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "main-video" },
                    [
                      _c("video-viewer", {
                        attrs: {
                          height: 300,
                          width: 350,
                          "cam-type": "facade",
                          muted: !_vm.isVisible,
                          online: _vm.datainfo.online,
                          paperid: _vm.datainfo.id,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("监控中（摄像头）")]),
                ]),
                _c("div", { staticClass: "second-video-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "second-video" },
                    [
                      _c("video-viewer", {
                        attrs: {
                          height: 300,
                          width: 250,
                          "cam-type": "side",
                          online: _vm.datainfo.online,
                          paperid: _vm.datainfo.id,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("监控中（手机）")]),
                ]),
                _c("div", { staticClass: "third-video-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "third-video" },
                    [
                      _c("video-viewer", {
                        attrs: {
                          height: 300,
                          width: 350,
                          "cam-type": "desktop",
                          online: _vm.datainfo.online,
                          paperid: _vm.datainfo.id,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("监控中（屏幕）")]),
                ]),
              ])
            : _vm._e(),
          _vm.isCall > 0
            ? _c("VideoCaller", {
                attrs: { "cam-type": "facade", paperid: _vm.datainfo.id },
                on: { connectSucess: _vm.connectSucess },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCall == 1,
                  expression: "isCall==1",
                },
              ],
              staticClass: "caller-box",
            },
            [
              _c("div", { staticClass: "caller-loading" }, [
                _vm._v(" 接通中... "),
              ]),
            ]
          ),
          _c("div", { staticClass: "caller-box" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCall == 0,
                    expression: "isCall==0",
                  },
                ],
                staticClass: "caller-item",
                on: {
                  click: function ($event) {
                    _vm.isCall = 1
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-phone-outline" })]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCall != 0,
                    expression: "isCall!=0",
                  },
                ],
                staticClass: "caller-item",
                on: {
                  click: function ($event) {
                    _vm.isCall = 0
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-phone" })]
            ),
          ]),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-popover",
                { attrs: { placement: "bottom", trigger: "click" } },
                [
                  _c(
                    "div",
                    { staticClass: "forewarning-btn-box" },
                    [
                      _vm.isChief
                        ? _c(
                            "el-button",
                            {
                              staticClass: "forewarning-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleWarning(_vm.datainfo.id, 21)
                                },
                              },
                            },
                            [_vm._v("确认作弊")]
                          )
                        : _vm._e(),
                      !_vm.isChief
                        ? _c(
                            "el-button",
                            {
                              staticClass: "forewarning-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleWarning(_vm.datainfo.id, 33)
                                },
                              },
                            },
                            [_vm._v("疑似作弊")]
                          )
                        : _vm._e(),
                      _vm.isChief
                        ? _c(
                            "el-button",
                            {
                              staticClass: "forewarning-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleWarning(_vm.datainfo.id, 0)
                                },
                              },
                            },
                            [_vm._v("取消作弊")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "forewarning-btn",
                          on: {
                            click: function ($event) {
                              return _vm.handleWarning(_vm.datainfo.id, 32)
                            },
                          },
                        },
                        [_vm._v("红牌警告")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [_vm._v("预警")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }