<template>

  <el-card>
    <el-page-header content="试题详情" @back="$navBack" />

    <section>
      <qu-item-show :value="quData" :show-analysis="true" :index="0" :mode="3" :state="1" :show-type="true" />

      <div v-if="quData.quType === '99'">
        <div v-for="(sub,jj) in quData.subList" :key="sub.id">
          <qu-item-show :value="sub" :index="jj" :mode="3" :state="1" :show-type="true" :sub="true" />
        </div>
      </div>

    </section>

  </el-card>

</template>

<script>
import { fetchDetail } from '@/api/qu/qu'
import QuItemShow from '@/views/admin/repo/qu/components/QuItemShow'

export default {
  name: 'QuView',
  components: { QuItemShow },
  data() {
    return {

      quData: {

      },

      radioValues: '',
      multiValues: [],
      analysisCount: 0

    }
  },
  created() {
    const id = this.$route.params.id
    if (id !== undefined) {
      this.fetchData(id)
    }
  },
  methods: {

    fetchData(id) {
      fetchDetail(id).then(response => {
        this.quData = response.data

        this.quData.answerList.forEach((an) => {
          // 解析数量
          if (an.analysis) {
            this.analysisCount += 1
          }

          // 用户选定的
          if (an.isRight) {
            if (this.quData.quType === '1') {
              this.radioValues = an.id
            } else {
              this.multiValues.push(an.id)
            }
          }
        })
      })
    },
    onCancel() {
      this.$router.push({ name: 'ListTran' })
    }

  }
}
</script>

<style scoped>

  .ques .option {
    padding: 20px 0 15px 0
  }

  .ques .option label {
    position: relative;
    padding: 10px;
    border: 1px solid #f6f6f8;
    color: #4f4e58;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 20px
  }

  .ques .option label.selected {
    padding: 10px;
    border: 1px solid #5794f7
  }

  .ques .option label.wrong {
    padding: 10px;
    border: 1px solid #ff0000;
  }

  .ques .option label span:first-child {
    margin: 0 10px 0 10px
  }

  .ques .option label span:nth-child(2), .lky-exam .ques .option label .option-img {
    flex: 1;
  }

  .ques .option label .ques-img {
    flex: 1;
  }

  .exam-result{
    margin-top: 30px;
  }
  .exam-result .ques .ques-title p i {
    color: #f01414;
    font-size: 14px;
    margin-left: 10px
  }

  .exam-result .ques .option label {
    position: relative
  }

</style>

