<template>
  <span>{{ str }}</span>
</template>

<script>

export default {
  name: 'SecFormat',
  props: {
    value: {
      type: Number,
      default: function() {
        return 0
      }
    }
  },
  data() {
    return {
      str: ''
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.calc(val)
      }
    }
  },
  created() {
    this.calc(this.value)
  },
  methods: {

    // 计算
    calc() {
      let sec = this.value
      let min = 0 // 分
      let hour = 0 // 小时

      if (sec > 60) {
        min = parseInt(sec / 60)
        sec = parseInt(sec % 60)
        if (min > 60) {
          hour = parseInt(min / 60)
          min = parseInt(min % 60)
        }
      }

      let res = ''
      if (hour > 0) {
        res += parseInt(hour) + '小时'
      }

      if (min > 0) {
        res += parseInt(min) + '分'
      }

      if (sec > 0 || (hour === 0 && min === 0)) {
        if (sec<10) {
          res += '0'
        }
        res += parseInt(sec) + '秒'
      }

      this.str = res
    }
  }
}
</script>
