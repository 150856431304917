<template>
  <div class="wrap-bg">
    <div class="line"/>
    <div class="wrap">
      <h2 class="title">{{ parperInfo.title }} - 考生详情查看</h2>
      <el-descriptions :column="4" class="margin-top" title="考生信息" direction="vertical" border>
        <template slot="extra">
          <el-button icon="el-icon-s-platform" type="primary" @click="goback">返回监控</el-button>
        </template>
        <el-descriptions-item label="考生编号">{{
          studentInfo.code
        }}</el-descriptions-item>
        <el-descriptions-item label="考生姓名">{{
          studentInfo.name
        }}</el-descriptions-item>
        <el-descriptions-item label="考试状态">
          <span>{{ parperInfo.exState_dictText }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="结束时间">
          <span>{{ parperInfo.handTime }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="考生账号">
          <span>{{ studentInfo.username }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="考试密码">
          <span>{{ studentInfo.passwd }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="考生手机">
          <span>{{ studentInfo.mobile }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="考生邮箱">
          <span>{{ studentInfo.email }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="考试名称" :span="2">
          <span>{{ parperInfo.title }}</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="登录方式">
          <span>{{ warnInfo.alarmLevel_dictText }}</span>
        </el-descriptions-item> -->
        <el-descriptions-item label="登录时间">
          <span>{{ studentInfo.createTime }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="作答进度">
          <span>{{ parperInfo.answerCount?parperInfo.answerCount:0 }}/{{ parperInfo.quCount }}</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item v-if="warnInfo.msg" :span="4" label="描述">
          {{ warnInfo.msg.content }}
        </el-descriptions-item> -->
      </el-descriptions>
      <el-descriptions :column="4" class="margin-top" title="考试监控" direction="vertical" border>
        <template slot="extra">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
        <el-descriptions-item :span="4" label="人脸对比抓拍图片">
          <div>
            <div class="wranImg_list">
              <div v-for="(img, index) in snapPList" :key="index" class="wranImg_item">
                <div class="wranImg_item_card">
                  <el-image :src="img.facadeImageUrl" style="width: 100px; height: 100px" fit="fill" />
                  <div>{{ img.createTime }}</div>
                </div>
                
              </div>
            </div>
            <div class="wranImg_fanye">
              <el-pagination :page-size="snapP.size" :pager-count="snapP.page" :total="snapP.total" layout="prev, pager, next" />
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item :span="4" label="告警图片">
          <div>
            <div class="wranImg_list">
              <div v-for="(img, index) in alemPList" :key="index" class="wranImg_item">
                <div class="wranImg_item_card">
                  <el-image :src="img.facadeImageUrl" style="width: 100px; height: 100px" fit="fill" />
                  <div>{{ img.createTime }}</div>
                </div>
                
              </div>
            </div>
            <div class="wranImg_fanye">
              <el-pagination :page-size="alemP.size" :pager-count="alemP.page" :total="alemP.total" layout="prev, pager, next"/>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>

  </div>
</template>

<script>
import {
  httpstudentDeatils,
  httpalarmpicture,
  httpsnappicture
} from '@/api/exam/watch'
export default {
  name: 'VideoRecords',
  components: {},
  props: {
  },
  data() {
    return {
      studentId: null,
      alemP: {
        page: 1,
        size: 20,
        total: 0
      },
      snapP: {
        page: 1,
        size: 20,
        total: 0
      },
      studentInfo: {},
      parperInfo: {},
      processInfo: {},
      listLoading: false,
      snapPList: [],
      alemPList: [],
      options: [
        {
          value: '1',
          label: '按抓拍时间正序'
        }, {
          value: '2',
          label: '按抓拍时间倒序'
        }
      ],
      value: '1'
    }
  },
  watch: {
    value: {
      handler(val) {
        this.getAlarmPicture()
        this.getSnapPicture()
      }
    }
  },
  mounted() {
    this.studentId = this.$route.query.id
    console.log(this.$route.query.id, 'studentid是')
    this.getStudentDetails()
    this.getAlarmPicture()
    this.getSnapPicture()
  },
  methods: {
    async getStudentDetails() {
      const subdata = {
        studentId: this.studentId
        // studentId: '1744267975927644161'
      }
      const res = await httpstudentDeatils(subdata)
      if (res.success) {
        this.studentInfo = res.data.student
        this.parperInfo = res.data.paper
        this.processInfo = res.data.remoteExam
      }
    },
    async getAlarmPicture() {
      const subdata = {
        current: this.alemP.page,
        size: this.alemP.size,
        params: {
          // studentId: '1744267975927644161'
          studentId: this.studentId
        },
        orders: [
          {
            column: 'create_time',
            asc: this.value == '1'
          }
        ]
      }
      const res = await httpalarmpicture(subdata)
      if (res.success) {
        this.alemPList = res.data.records
        this.alemP.page = res.data.current
        this.alemP.total = res.data.total
      }
    },
    async getSnapPicture() {
      const subdata = {
        current: this.snapP.page,
        size: this.snapP.size,
        params: {
          // studentId: '1744267975927644161'
          studentId: this.studentId
        },
        orders: [
          {
            column: 'create_time',
            asc: this.value == '1'
          }
        ]
      }
      const res = await httpsnappicture(subdata)
      if (res.success) {
        this.snapPList = res.data.records
        this.snapP.page = res.data.current
        this.snapP.total = res.data.total
      }
    },
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.wrap-bg{
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to bottom, #4ea3f2 40%, #f7f7f1 60%);
}
.wrap{
  width: 1200px;
  padding: 20px;
  margin: 10px auto;
  box-shadow: 0 0 10px #4ea3f2;
  border-radius: 5px;
  background-color: #fff;
}
.line{
  height: 1px;
}
.title{
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.margin-top{
  margin-top:20px;
}

.ex-ee{
  color: #ff0000;
}
.ex-normal{
  color: #0FDA6D;
}
.wranImg_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.img_item,
.wranImg_item {
  margin: 10px;
}
.wranImg_item_card{
  width: 100%;
  text-align: center;
}
.wranImg_bd_wrap{
  display: flex;
}
.wranImg_bd_item{
  width: 100px;
  text-align: center;
}
.wranImg_bd_tit{
  line-height: 32px;
  color: #8c8989;
}
.wranImg_fanye{
  display: flex;
  flex-direction: row-reverse;
}
.wranImg_bd_noImg{
  width:100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #8c8989;
  color: #8c8989;
}
</style>
