<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="选择试卷"
    width="60%"
    @close="handleClose"
  >
    <div class="app-container">
      <data-table
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
      >
        <template #filter-content>
          <dic-catalog-tree
            v-model="listQuery.params.catId"
            dic-code="tmpl_catalog"
            class="filter-item"
            title="选择试卷分类"
          />
          <dic-list-select
            v-model="listQuery.params.joinType"
            dic-code="join_type"
            class="filter-item"
            style="width: 200px"
          />
          <el-input
            v-model="listQuery.params.title"
            placeholder="试卷名称"
            class="filter-item"
            style="width: 200px"
            size="small"
          />

          <el-button
            v-if="!single"
            type="primary"
            class="filter-item"
            size="small"
            @click="handleConfirm"
          >{{ selectedLabel }}</el-button
          >
        </template>

        <template #data-columns>
          <el-table-column type="index" width="50" align="center" />

          <el-table-column label="试卷名称" prop="title" show-overflow-tooltip>
            <!-- <template v-slot="scope">
              <detail-link
                :id="scope.row.id"
                :title="scope.row.title"
                to="ExamTmplUpdate"
                permission="tmpl:update"
              />
            </template> -->
          </el-table-column>

          <el-table-column
            label="组卷方式"
            prop="joinType_dictText"
            align="center"
            width="100px"
          />

          <el-table-column
            label="试卷分类"
            show-overflow-tooltip
            prop="catId_dictText"
            align="center"
            width="120px"
          />

          <el-table-column
            label="总分"
            align="center"
            prop="totalScore"
            width="60px"
          />

          <el-table-column
            label="创建人"
            align="center"
            prop="createBy_dictText"
            width="100px"
          />

          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160px"
          />

          <el-table-column v-if="single" label="操作" align="center" width="80">
            <template v-slot="scope">
              <el-button type="text" @click="selectRow(scope.row)">
                选择
              </el-button>
            </template>
          </el-table-column>
        </template>
      </data-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TmplSelectDialog',
  props: {
    excludes: Array,
    excludeDeparts: Array,
    dialogShow: {
      type: Boolean,
      default: false
    },
    selections: Array,
    single: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      selectedLabel: '请选择试卷',
      listQuery: {
        current: 1,
        size: 10,
        params: {
          excludes: []
        }
      },
      // 选定的数据列表
      selectedList: [],
      selectedObjs: [],
      options: {
        // 可批量操作
        multi: !this.single,
        multiShow: this.single,
        keyId: 'id',
        // 列表请求URL
        listUrl: '/api/tmpl/tmpl/paging'
      }
    }
  },

  watch: {
    // 检测查询变化
    dialogShow: {
      handler(val) {
        this.dialogVisible = this.dialogShow
        if (val && this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    excludeDeparts: {
      handler() {
        this.listQuery.params.excludeDeparts = this.excludeDeparts
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    selectedList: {
      handler(val) {
        this.selectedLabel = '确认' + val.length + '项'
      }
    }
  },

  created() {
    this.dialogVisible = this.dialogShow
    this.listQuery.params.excludes = this.excludes
    this.listQuery.params.excludeDeparts = this.excludeDeparts
  },

  methods: {
    // 选定回调
    handleSelected(data) {
      if (data) {
        this.selectedList = data.ids
        this.selectedObjs = data.objs
      }
    },
    // 选中一行
    selectRow(row) {
      if (row && row.id) {
        this.selectedList = [row.id]
        this.selectedObjs = [row]
      }
      this.handleConfirm()
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:dialogShow', false)
      this.$refs.pagingTable.clearSelection()
      this.selectedList = []
      this.selectedObjs = []
    },

    handleConfirm() {
      if (this.selectedList === null || this.selectedList.length === 0) {
        this.$message({
          type: 'error',
          message: '请至少勾选一个用户！!'
        })
        return
      }

      this.$emit('select', this.selectedList, this.selectedObjs)

      // 关闭
      this.handleClose()
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
}
</style>
