<template>
  <div class="content">
    <el-upload
      ref="fileUpload"
      :action="uploadUrl"
      :accept="accept"
      :before-upload="beforeUpload"
      :on-change="handleChange"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :on-preview="handlePreview"
      :drag="listType !== 'picture-card'"
      :multiple="limit > 1"
      :limit="limit === 1 ? 2 : limit"
      :file-list="fileList"
      :list-type="listType"
      :headers="header"
    >
      <i v-if="listType === 'picture-card'" class="el-icon-plus" />

      <i v-if="listType !== 'picture-card'" class="el-icon-upload" />
      <div v-if="listType !== 'picture-card'" class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { compressImage } from '@/utils/upload'

export default {
  name: 'FileUploadDefault',
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    },
    compress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fileList: [],
      // uploadUrl: process.env.VUE_APP_BASE_API + '/api/common/file/upload',
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/file/upload',
      header: {}
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.fileList = val
      }
    }
  },

  created() {
    this.fillValue()
    this.header = { token: getToken() }
  },

  methods: {
    // 填充时间
    fillValue() {
      this.fileList = this.value
    },

    // 文件超出个数限制时的钩子
    handleExceed() {
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`)
    },
    // 删除文件之前的钩子
    beforeRemove() {
      return this.$confirm(`确定移除文件吗？`)
    },

    // 文件列表移除文件时的钩子
    handleRemove(file) {
      // 移除文件
      for (let i = 0; i < this.fileList.length; i++) {
        if (file.uid === this.fileList[i].uid) {
          this.fileList.splice(i, 1)
        }
      }
      this.$emit('input', this.fileList)
    },

    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      let storePath = ''
      let url = ''

      if (
        response &&
        response.data &&
        response.data.fileList &&
        response.data.fileList.length > 0
      ) {
        const { fileList: getFileList } = response.data
        storePath = getFileList[0].storePath
        url = getFileList[0].url
      }

      // 只能传一个的进行替换
      if (fileList.length >= 1) {
        if (this.limit === 1) {
          // 反转并保留一个
          fileList = [{ url: url, name: storePath }]
        } else {
          // 上传成功替换服务端URL
          for (let i = 0; i < fileList.length; i++) {
            if (file.uid === fileList[i].uid) {
              fileList[i].name = storePath
              fileList[i].url = url
            }
          }
        }
      }

      // console.log('文件上传后', fileList)
      this.$emit('input', fileList)
    },
    downloadFile(url) {
      const link = document.createElement('a')
      link.target = '_blank'
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
    },
    handlePreview(file) {
      if (file && file.url) {
        this.downloadFile(file.url)
      }
    },
    // 文件变化重新上传
    handleChange(file, fileList) {
      if (this.limit === 1 && fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },

    // 文件上传前的校验
    beforeUpload(file) {
      // 压缩上传
      return compressImage(file, this.compress)
    }
  }
}
</script>

<style scoped>
::v-deep .el-list-enter-active,
.el-list-leave-active {
  transition: none;
}
</style>
