var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-bg" }, [
    _c("div", { staticClass: "line" }),
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c("h2", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.parperInfo.title) + " - 考生详情查看"),
        ]),
        _c(
          "el-descriptions",
          {
            staticClass: "margin-top",
            attrs: {
              column: 4,
              title: "考生信息",
              direction: "vertical",
              border: "",
            },
          },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-s-platform", type: "primary" },
                    on: { click: _vm.goback },
                  },
                  [_vm._v("返回监控")]
                ),
              ],
              1
            ),
            _c("el-descriptions-item", { attrs: { label: "考生编号" } }, [
              _vm._v(_vm._s(_vm.studentInfo.code)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考生姓名" } }, [
              _vm._v(_vm._s(_vm.studentInfo.name)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考试状态" } }, [
              _c("span", [_vm._v(_vm._s(_vm.parperInfo.exState_dictText))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "结束时间" } }, [
              _c("span", [_vm._v(_vm._s(_vm.parperInfo.handTime))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考生账号" } }, [
              _c("span", [_vm._v(_vm._s(_vm.studentInfo.username))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考试密码" } }, [
              _c("span", [_vm._v(_vm._s(_vm.studentInfo.passwd))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考生手机" } }, [
              _c("span", [_vm._v(_vm._s(_vm.studentInfo.mobile))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "考生邮箱" } }, [
              _c("span", [_vm._v(_vm._s(_vm.studentInfo.email))]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "考试名称", span: 2 } },
              [_c("span", [_vm._v(_vm._s(_vm.parperInfo.title))])]
            ),
            _c("el-descriptions-item", { attrs: { label: "登录时间" } }, [
              _c("span", [_vm._v(_vm._s(_vm.studentInfo.createTime))]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "作答进度" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.parperInfo.answerCount ? _vm.parperInfo.answerCount : 0
                  ) +
                    "/" +
                    _vm._s(_vm.parperInfo.quCount)
                ),
              ]),
            ]),
          ],
          2
        ),
        _c(
          "el-descriptions",
          {
            staticClass: "margin-top",
            attrs: {
              column: 4,
              title: "考试监控",
              direction: "vertical",
              border: "",
            },
          },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-descriptions-item",
              { attrs: { span: 4, label: "人脸对比抓拍图片" } },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "wranImg_list" },
                    _vm._l(_vm.snapPList, function (img, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "wranImg_item" },
                        [
                          _c(
                            "div",
                            { staticClass: "wranImg_item_card" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: { src: img.facadeImageUrl, fit: "fill" },
                              }),
                              _c("div", [_vm._v(_vm._s(img.createTime))]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "wranImg_fanye" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.snapP.size,
                          "pager-count": _vm.snapP.page,
                          total: _vm.snapP.total,
                          layout: "prev, pager, next",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { span: 4, label: "告警图片" } },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "wranImg_list" },
                    _vm._l(_vm.alemPList, function (img, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "wranImg_item" },
                        [
                          _c(
                            "div",
                            { staticClass: "wranImg_item_card" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: { src: img.facadeImageUrl, fit: "fill" },
                              }),
                              _c("div", [_vm._v(_vm._s(img.createTime))]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "wranImg_fanye" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.alemP.size,
                          "pager-count": _vm.alemP.page,
                          total: _vm.alemP.total,
                          layout: "prev, pager, next",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }