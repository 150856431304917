import {
  login,
  reg,
  logout,
  getInfo,
  mobileLogin,
  faceLogin
} from '@/api/sys/user/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { fetchUnread } from '@/api/sys/user/im'

const state = {
  token: getToken(),
  userId: '',
  name: '',
  realName: '',
  avatar: '',
  avatarUrl: '',
  faceUrl: '',
  roleType: '',
  roles: [],
  permissions: [],
  unreadMsg: 0,
  mobile: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ROLE_TYPE: (state, roleType) => {
    state.roleType = roleType
  },
  SET_ID: (state, userId) => {
    state.userId = userId
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_REAL_NAME: (state, realName) => {
    state.realName = realName
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_AVATAR_URL: (state, avatarUrl) => {
    state.avatarUrl = avatarUrl
  },
  SET_FACE_URL: (state, faceUrl) => {
    state.faceUrl = faceUrl
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },

  SET_UNREAD_MSG: (state, count) => {
    state.unreadMsg = count
  },

  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile
  }
}

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sync({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },

  mobileLogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      mobileLogin(userInfo)
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  faceLogin({ commit }, faceData) {
    return new Promise((resolve, reject) => {
      faceLogin(faceData)
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  reg({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      reg(userInfo)
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((res) => {
          const data = res.data

          commit('SET_ID', data.id)
          commit('SET_ROLES', data.roles)
          commit('SET_REAL_NAME', data.realName)
          commit('SET_NAME', data.userName)
          commit('SET_AVATAR', data.avatar)
          commit('SET_AVATAR_URL', data.avatarUrl)
          commit('SET_FACE_URL', data.faceUrl)
          commit('SET_ROLE_TYPE', data.roleType)
          commit('SET_PERMISSIONS', data.permissions)
          commit('SET_MOBILE', data.mobile)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 退出登录
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resetRouter()
          dispatch('tagsView/delAllViews', null, { root: true })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 移除会话
  resetToken({ commit, dispatch }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // 获取未读消息
  fetchMsg({ commit }) {
    return new Promise((resolve, reject) => {
      // 重新获取
      fetchUnread()
        .then((res) => {
          const { data } = res
          commit('SET_UNREAD_MSG', data.count)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
