var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-box" }, [
    _c("div", { staticClass: "ps-box" }, [
      _c("div", [
        _c("span", { staticClass: "t1" }, [_vm._v("已答正确率：")]),
        _c("span", { staticClass: "t2" }, [_vm._v(_vm._s(_vm.answerRate))]),
        _c("span", { staticClass: "t3" }, [_vm._v("%")]),
      ]),
      _c("div", [
        _c("span", { staticClass: "t1" }, [_vm._v("总体正确率：")]),
        _c("span", { staticClass: "t2" }, [_vm._v(_vm._s(_vm.allRate))]),
        _c("span", { staticClass: "t3" }, [_vm._v("%")]),
      ]),
    ]),
    _c("div", { staticStyle: { display: "flex", "flex-wrap": "wrap" } }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "33%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-success dt-item",
              staticStyle: { color: "#1aac1a" },
            },
            [_vm._v("答对：" + _vm._s(_vm.detail.rightCount) + "题")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "34%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-error dt-item",
              staticStyle: { color: "#f0ad4e" },
            },
            [_vm._v("已答：" + _vm._s(_vm.detail.answerCount) + "题")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "33%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-circle-plus dt-item",
              staticStyle: { color: "#ff3333" },
            },
            [_vm._v("总共：" + _vm._s(_vm.detail.totalCount) + "题")]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-button",
          {
            staticStyle: { width: "200px" },
            attrs: { type: "danger" },
            on: { click: _vm.handleBack },
          },
          [_vm._v("返回题库")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }