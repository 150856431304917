<template>
  <el-form-item prop="captchaValue">
    <el-input v-model="postForm.captchaValue" placeholder="输入验证码" @keyup.enter.native="keyDown">
      <template slot="prepend">
        <div @click="changeCode()">
          <img :src="keyUrl" style="height: 30px; width: auto">
        </div>
      </template>
    </el-input>
  </el-form-item>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  name: 'QxCaptcha',
  props: {
    submitForm: {
      type: Function
    },
    value: {
      type: Object,
      default: function() {
        return {
          captchaValue: '',
          captchaKey: ''
        }
      }
    },
    id: String

  },
  data() {
    return {
      // 值
      postForm: {
        captchaValue: '',
        captchaKey: ''
      },
      keyUrl: '',
      rules: {
        captchaValue: [{ required: true, message: '验证码不能为空！' }]
      }
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.postForm = val
      },
      deep: true
    },

    // 只要变化就刷新
    id: {
      handler() {
        this.changeCode()
      }
    },

    // 传递参数
    postForm: {
      handler(val) {
        // 发送验证码
        this.$emit('input', val)
      },
      deep: true
    }
  },
  created() {
    this.postForm = this.value
    this.changeCode()
  },
  methods: {

    // 表单验证
    async validate() {
      // 返回结果
      return await this.$refs.postForm.validate().then((valid) => {
        return valid
      }).catch(() => {
        return false
      })
    },

    changeCode() {
      this.postForm.captchaKey = uuid.v4()
      this.postForm.captchaValue = ''
      this.keyUrl = `${process.env.VUE_APP_BASE_API}/api/common/captcha/gen?key=${this.postForm.captchaKey}`
    },

    keyDown(e) {
      this.submitForm(e)
    }

  }
}
</script>

<style scoped>

  ::v-deep
  .el-input-group__prepend{
    background: #fff;
    cursor: pointer;
  }

  .el-input-group__prepend div{
    display: flex;
    align-items: center;
    width: 80px
  }

</style>
