var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "参与部门范围", name: "first" } },
                [
                  _c("depart-refs", {
                    model: {
                      value: _vm.deptCodes,
                      callback: function ($$v) {
                        _vm.deptCodes = $$v
                      },
                      expression: "deptCodes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "参与人员范围", name: "second" } },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.handleAdd } },
                    [_vm._v("添加人员")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "mini" },
                      on: { click: _vm.handleClear },
                    },
                    [_vm._v(" 清除所有 ")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    _vm._l(_vm.userList, function (user, index) {
                      return _c(
                        "el-tag",
                        {
                          key: user.id,
                          staticStyle: {
                            "margin-right": "8px",
                            "margin-bottom": "8px",
                          },
                          attrs: { size: "small", closable: "" },
                          on: {
                            close: function ($event) {
                              return _vm.handleRemove(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(user.realName || user.userId_dictText) +
                              " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("user-select-dialog", {
        attrs: {
          "dialog-show": _vm.dialogShow,
          "exclude-departs": _vm.deptCodes,
          excludes: _vm.excludes,
        },
        on: {
          "update:dialogShow": function ($event) {
            _vm.dialogShow = $event
          },
          "update:dialog-show": function ($event) {
            _vm.dialogShow = $event
          },
          select: _vm.handleSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }