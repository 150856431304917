<template>
  <div class="cam-box">

    <div>
      <canvas id="canvas" ref="canvas" :width="width" :height="height" />
      <video v-if="!paperid" :width="width" :height="height" style="background: #262626"/>
      <video
        v-else
        id="video" ref="video"
        :width="width" :height="height"
        :playsinline="true" :autoplay="true"
        :muted="muted"
        controls
        style="background: #262626"
      />

    </div>

    <el-empty v-if="noDevice" description="摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！" />

  </div>
</template>

<script>
import kurentoUtils from 'kurento-utils'
import { mapGetters } from 'vuex'
import { httpuploadPicture } from '@/api/exam/watch'
import { Notification } from 'element-ui'

export default {
  name: 'VideoViewer',
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    },
    // 发起监控的方式
    camType: {
      type: String,
      default: 'facade'
    },
    paperid: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: true
    },
    online: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      source: null,
      canvas: null,
      context: null,
      camerasListEmitted: false,
      cameras: [],
      noDevice: false,
      // 警告定时器
      faceTimer: null,
      loading: false,

      // ice链接
      webRtcPeer: null,
      ws: null,
      offerSdp: null,
      timer: null

    }
  },
  computed: {
    ...mapGetters([
      'token',
      'siteData'
    ])
  },
  watch: {

    // 检测查询变化
    isStop: {
      handler() {
        if (this.isStop) {
          this.stop()
        } else {
          this.start()
        }
      },
      deep: true
    },
    online() {
      console.log(this.online, '是否在线')
      if (this.online) {
        this.start()
      }
    }
  },
  mounted() {
    console.log(this.online, '初始化是否在线')
    if (this.online) {
      this.start()
    }
  },
  beforeDestroy() {
    // 关闭websocket
    if (this.ws) {
      this.ws.close()
    }
    this.dispose()
  },
  methods: {
    init() {
      if (!this.webRtcPeer) {
        // 添加自己的中继服务器的配置，否则会默认指向谷歌的服务器
        const iceservers = {
          'iceServers': [
            {
              urls: `${process.env.VUE_APP_ICESERVER_STUN_URL}`
            },
            {
              urls: [`${process.env.VUE_APP_ICESERVER_TURN_URL}`],
              username: `${process.env.VUE_APP_ICESERVER_TURN_USERNAME}`,
              credential: `${process.env.VUE_APP_ICESERVER_TURN_CREDENTIAL}`
            }
          ]
        }
        const options = {
          remoteVideo: this.$refs.video,
          configuration: iceservers,
          onicecandidate: this.onIceCandidate
        }
        this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
          (error) => {
            if (error) {
              return console.log(error, '错误信息是')
            }
            this.webRtcPeer.generateOffer(this.onOfferViewer)
          })
      }
      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        // console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'presenterResponse':
            this.presenterResponse(parsedMessage)
            break
          case 'viewerResponse':
            this.viewerResponse(parsedMessage)
            break
          case 'iceCandidate':
            this.webRtcPeer.addIceCandidate(parsedMessage.candidate, (error) => {
              if (error) { return console.log('添加候选项错误', error) }
            })
            break
          case 'stopCommunication':
            this.dispose()
            break
          // case 'alarm':
          //   this.alarm(parsedMessage)
          //   break
          case 'launched':
            // console.log('接到launched消息，重新发起查看连接')
            this.init()
            break
          case 'recording':
            // console.log('视频开始录制', parsedMessage)
            break
          case 'stopped':
            // console.log('视频终止录制', parsedMessage)
            break
          case 'paused':
            // console.log('视频暂停录制', parsedMessage)
            break
          case 'pantResponse':
            this.pantResponse(parsedMessage)
            break
          default:
            // console.log('无法识别的信息', parsedMessage)
            // Notification.error({
            //   title: '提示信息',
            //   message: '系统出错请联系管理员'
            // })
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          // console.log(`[websocket close]连接关闭, code=${event.code} reason=${event.reason}`)
          this.dispose()
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          // console.log('[websocket close] Connection died', event)
          // this.start()
        }
      }

      this.ws.onerror = (error) => {
        console.log(`[websocket error] ${error.message}`)
        // this.start()
      }
    },
    presenterResponse(message) {
      if (message.response != 'accepted') {
        const errorMsg = message.message ? message.message : 'Unknow error'
        // console.log('由于以下原因不被接受' + errorMsg)
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) { return console.log(error, '错误是') }
        })
      }
    },
    viewerResponse(message) {
      if (message.response != 'accepted') {
        // this.$emit('acceptSuccess', false)
        const errorMsg = message.message ? message.message : 'Unknow error'
        // console.log('由于以下原因不被接受', errorMsg)
        this.dispose(true)
      } else {
        this.$emit('acceptSuccess', true)
        this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
          if (error) { return console.log(error, '错误是') }
        })
      }
    },
    pantResponse(message) {
      // console.log(message, '接收者 pantResponse信息是什么')
      // this.$emit('alterError', message)
    },
    alarm(message) {
      // console.log(message, '警告信息是什么')
      if (message.snap) {
        const newArr = []
        for (let i = 0; i <message.snapNum; i++) {
          const newObj = {
            'sort': i + 1,
            'imageData': this.capture()
          }
          newArr.push(newObj)
        }
        this.upLoadWarning(message.inspectAlarmId, newArr)
      }
    },
    onOfferViewer(error, offerSdp) {
      this.offerSdp = offerSdp
      if (error) { return console.log('生成offer错误') }
      const message = {
        id: 'viewer',
        sdpOffer: offerSdp,
        type: this.camType,
        name: this.paperid,
        viewer: this.token,
        kicked: true
      }
      if (this.camType != 'facade') {
        message.mode = 'video-only'
      } else {
        message.mode = ''
      }
      // console.log(message, '视频发送')
      this.sendMessage(message)
    },
    onIceCandidate(candidate) {
      // console.log('当地的候选人', JSON.stringify(candidate))

      const message = {
        id: 'onIceCandidate',
        candidate: candidate,
        type: this.camType,
        name: this.paperid,
        viewer: this.token
      }
      this.sendMessage(message)
    },

    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },
    dispose(closeEmitter) {
      clearInterval(this.timer)
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose()
        this.webRtcPeer = null
      }
    },
    start() {
      // console.log('>>> VideoViewer start() >>>', this.paperid, this.ws)

      // WebSocket.readyState
      // 返回当前 WebSocket 的链接状态，只读。
      // 语法
      // var readyState = WebSocket.readyState;
      // 值
      // 以下其中之一
      // 0 (WebSocket.CONNECTING)
      // 正在链接中
      // 1 (WebSocket.OPEN)
      // 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING)
      // 连接正在关闭
      // 3 (WebSocket.CLOSED)
      // 连接已关闭或者没有链接成功

      // 检查是否需要重新创建 websocket
      let toCreate = true
      if (this.ws && (this.ws.readyState == WebSocket.CONNECTING || this.ws.readyState == WebSocket.OPEN)) {
        // 正在链接中 || 已经链接并且可以通讯
        toCreate = false
      }

      if (toCreate) {
        const wsUrl = `${process.env.VUE_APP_SIGNAL_BASE_API}/signal/viewer`
        // const wsUrl = 'wss://live.ntces.cn/tutorialcall'
        this.ws = new WebSocket(wsUrl)
        this.ws.onopen = (e) => {
          // console.log('Viewer Websocket已连接', this.camType, this.paperid, this.deviceId)
          this.init()
          this.peng()
        }
      }
      // if (this.ws) {
      //   this.ws.close()
      // }
      // console.log('>>> start() >>>', toCreate, this.paperid, this.ws)
    },
    stop() {
      // console.log('停止接收')
      const message = {
        id: 'stop',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
      this.dispose()
      if (this.ws) {
        this.ws.close()
      }
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        // console.log('心跳 viewer: camType, paperid', this.camType, this.paperid)
        const message = {
          id: 'pant',
          type: this.camType,
          name: this.paperid
        }
        this.sendMessage(message)
      }, 30000)
      // console.log(this.timer, '进来了')
    },
    async upLoadWarning(base64) {
      const subData = {
        'inspectAlarmId': '1724681972630581250',
        'type': this.camType,
        'pictureList': [{
          'sort': 1,
          'imageData': base64
        }]
      }
      const res = await httpuploadPicture(subData)
      if (res.sucess) {
        // console.log('抓拍成功')
      }
    },
    // 静默截屏，截屏时候无感知
    capture() {
      const video = this.$refs.video
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.videoHeight
      canvas.width = video.videoWidth
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataImage = canvas.toDataURL(this.captureFormat)
      // 转换成base64去除头
      const base64 = dataImage.replace(/^data:image\/\w+;base64,/, '')
      this.upLoadWarning(base64)
      // this.$emit('capture', base64)
      // return base64
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
</style>
