import store from '@/store'

export const PermissionMixin = {
  props: {
    permission: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    checkPermission(value) {
      const _value = value || this.permission
      let hasPermission = false
      const permissions = store.getters && store.getters.permissions

      if (_value && _value instanceof Array && _value.length > 0) {
        const permissionRoles = _value

        hasPermission = permissions.some((role) => {
          return permissionRoles.includes(role)
        })
      }

      return hasPermission
    }
  }
}
