var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "300px" },
              attrs: {
                size: "small",
                placeholder: "搜索题库",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "所属题库", "show-overflow-tooltip": "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("detail-link", {
                        attrs: {
                          params: { repoId: scope.row.repoId },
                          title: scope.row.repoId_dictText,
                          to: "MyRepoView",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "练习模式",
                align: "center",
                prop: "mode_dictText",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "最近训练",
                align: "center",
                prop: "updateTime",
                width: "200px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "正确数量",
                align: "center",
                prop: "rightCount",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "训练进度", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.answerCount) +
                          "/" +
                          _vm._s(scope.row.totalCount) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "正确率", align: "left", width: "120px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-progress", {
                            attrs: {
                              width: 30,
                              percentage: scope.row.percent,
                              "stroke-width": 3,
                              "show-text": false,
                              type: "circle",
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "10px" } },
                            [_vm._v(_vm._s(scope.row.percent) + "%")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作项", align: "center", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        [
                          scope.row.state === 0
                            ? _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-video-play",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startTrain(
                                        scope.row.repoId,
                                        scope.row.mode
                                      )
                                    },
                                  },
                                },
                                [_vm._v("继续训练")]
                              )
                            : _c(
                                "el-link",
                                {
                                  attrs: {
                                    icon: "el-icon-video-play",
                                    disabled: "",
                                  },
                                },
                                [_vm._v("训练结束")]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }