import { post } from '@/utils/request'

/**
 * 提交实名认证
 * @param data
 * @returns {Promise}
 */
export function submit(data) {
  return post('/api/sys/user/real/submit', data)
}

/**
 * 查找实名认证
 * @returns {Promise}
 */
export function detail() {
  return post('/api/sys/user/real/detail', {})
}

/**
 * 认证审核：管理端
 * @returns {Promise}
 */
export function audit(data) {
  return post('/api/sys/user/real/audit', data)
}

/**
 * 自动审核
 * @returns {Promise}
 */
export function auto(data) {
  return post('/api/sys/user/real/auto-audit', data)
}
