import { post } from '@/utils/request'

/**
 * 题库详情
 * @param data
 */
export function fetchDetail(data) {
  return post('/api/repo/detail', data)
}
// 证书列表
export function fetchTmplList(data) {
  return post('/api/cert/tmpl/paging', data)
}
// 证书详情
export function fetchTmplDetail(data) {
  return post('/api/cert/tmpl/detail', data)
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/repo/save', data)
}

/**
 * 保存题库
 * @param data
 */
export function fetchList(data) {
  return post('/api/repo/paging', data)
}

/**
 * 题库批量操作
 * @param data
 */
export function batchAction(data) {
  return post('/api/repo/batch-action', data)
}

/**
 * 题型分组统计
 * @param data
 */
export function typeGroup(data) {
  return post('/api/repo/type-group', data)
}

/**
 * 题型分组统计
 * @param data
 */
export function chapterGroup(data) {
  return post('/api/repo/chapter-group', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(repoId) {
  return post('/api/repo/buy/init', { repoId: repoId })
}
