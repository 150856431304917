// 知识竞赛模块
import WebLayout from '@/layout/WebLayout'

const examRouter = {
  path: '/web',
  component: WebLayout,
  hidden: true,
  children: [
    {
      path: '/web/uc',
      component: () => import('@/views/web/ucenter'),
      name: 'UserCenter',
      meta: { title: '用户中心', noCache: true, activeMenu: '/web/uc' }
    },

    {
      path: '/web/notice',
      component: () => import('@/views/web/notice'),
      name: 'NoticeList',
      meta: { title: '系统公告', noCache: true }
    },
    {
      path: '/web/notice/detail/:id',
      component: () => import('@/views/web/notice/detail'),
      name: 'NoticeDetail',
      meta: { title: '公告详情', noCache: true, activeMenu: '/web/notice' }
    }
  ]
}
export default examRouter
