<template>
  <div class="wrap">
    <el-descriptions title="基本信息" :colon="false" border>
      <el-descriptions-item label="版本"> V1.0.0 </el-descriptions-item>
      <el-descriptions-item label="软件语言"> 简体中文 </el-descriptions-item>
      <el-descriptions-item label="系统兼容">
        支持 Mac OS 11.0及以上版本操作系统
      </el-descriptions-item>
      <el-descriptions-item label="大小"> 185 KB </el-descriptions-item>

      <el-descriptions-item label="更新时间"> 2024/06/25 </el-descriptions-item>

      <el-descriptions-item label="其他">
        <el-row :span="20">
          <el-tag class="right_10">安全无毒</el-tag>
          <el-tag type="success">无插件</el-tag>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>

    <div class="content_wrap">
      <a
        href="https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/CheatChecker.zip"
        target="_blank"
      >
        <el-button type="primary">点此下载</el-button>
      </a>
    </div>

    <div class="content_wrap">
      <div class="content_tit">安装说明</div>
      <div class="content_item">
        <div class="content_item_tit">
          1.
          将压缩包下载到本地解压后，鼠标双击解压后的文件，即可完成安装并启动。
        </div>
        <div class="content_item_box">
          <el-image :src="caozuoImg.az_1" :fit="'fill'"></el-image>
        </div>
      </div>
      <div class="content_item">
        <div class="content_item_tit">
          2.
          部分用户在安装后运行应用程序时可能会出现如下图所示的无法打开情况。这是MAC系统启用了新的安全机制，默认只信任
          App Store 下载的软件和拥有开发者ID签名的应用程序。
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_2" :fit="'fill'"></el-image>
        </div>
        <div class="content_item_tit">
          您可以通过更改系统设置解决该问题：系统设置 -> 隐私与安全性 ->
          安全性，然后选中在相关提示中选择 仍要打开 ，如下图：
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_3" :fit="'fill'"></el-image>
        </div>
        <div class="content_item_tit">
          如果没有这个选项，打开终端,输入:sudo spctl --master-disable
          后回车。然后在 password
          钥匙图标后面输入本机电脑解锁密码（输入时不显示密码），
          回车后进入系统偏好设置进行相关设置即可。
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_4" :fit="'fill'"></el-image>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import az_1 from "@/assets/web/caozuo_1.png";
import az_2 from "@/assets/web/caozuo_2.jpg";
import az_3 from "@/assets/web/caozuo_3.jpg";
import az_4 from "@/assets/web/caozuo_4.jpg";
export default {
  name: "downMac",
  data() {
    return {
      activeName: "win",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      caozuoImg: {
        az_1: az_1,
        az_2: az_2,
        az_3: az_3,
        az_4: az_4,
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.wrap {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
}
.hader_tit {
  text-align: center;
}

.content_wrap {
  margin-top: 30px;
}
.content_tit {
  line-height: 40px;
  font-weight: bold;
}
.content_item {
  margin: 20px auto;
}
.content_item_tit {
  line-height: 32px;
}
.content_item_box_2 {
  display: flex;
}
.footer {
  width: 100%;
  height: 5px;
}
.flex {
  display: flex;
}
.right_10 {
  margin-right: 10px;
}
.down_tool_wrap {
  width: 100%;
  padding: 10px;
}
.down_tool_item {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.down_tool_btn {
  width: 150px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #000;
}
.iconfont {
  font-size: 25px;
}
</style>
