<template>

  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索公告"
        prefix-icon="el-icon-search"
        style="width: 300px"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="公告名称"
      >
        <template v-slot="scope">
          <detail-link :id="scope.row.id" :title="scope.row.title" to="NoticeDetail" />
        </template>
      </el-table-column>

      <el-table-column
        label="发布人"
        align="center"
        prop="createBy_dictText"
      />

      <el-table-column
        label="发布时间"
        align="center"
        prop="createTime"
      />

    </template>

  </web-table>

</template>

<script>

export default {
  components: { },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: 0
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/sys/notice/paging'
      }
    }
  },

  methods: {

    handleDetail(id) {
      this.$router.push({ name: 'NoticeDetail', params: { id: id }})
    }
  }
}
</script>
