<template>

  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索题库"
        prefix-icon="el-icon-search"
        style="width: 300px"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="所属题库"
        show-overflow-tooltip
      >
        <template v-slot="scope">

          <detail-link :params="{repoId: scope.row.repoId}" :title="scope.row.repoId_dictText" to="MyRepoView" />
        </template>
      </el-table-column>

      <el-table-column
        label="练习模式"
        align="center"
        prop="mode_dictText"
        width="100px"
      />

      <el-table-column
        label="最近训练"
        align="center"
        prop="updateTime"
        width="200px"
      />

      <el-table-column
        label="正确数量"
        align="center"
        prop="rightCount"
        width="100px"
      />

      <el-table-column
        align="center"
        label="训练进度"
        width="100px"
      >
        <template v-slot="scope">

          {{ scope.row.answerCount }}/{{ scope.row.totalCount }}

        </template>
      </el-table-column>

      <el-table-column
        label="正确率"
        align="left"
        width="120px"
      >
        <template v-slot="scope">

          <div style="display: flex; align-items: center;">
            <el-progress :width="30" :percentage="scope.row.percent" :stroke-width="3" :show-text="false" type="circle" />
            <div style="padding-left: 10px">{{ scope.row.percent }}%</div>
          </div>

        </template>
      </el-table-column>

      <el-table-column
        label="操作项"
        align="center"
        width="100px"
      >
        <template v-slot="scope">

          <div>

            <el-link v-if="scope.row.state===0" type="primary" icon="el-icon-video-play" @click="startTrain(scope.row.repoId, scope.row.mode)">继续训练</el-link>
            <el-link v-else icon="el-icon-video-play" disabled>训练结束</el-link>

          </div>

        </template>
      </el-table-column>

    </template>

  </web-table>

</template>

<script>

export default {
  name: 'TrainHistory',
  components: { },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/repo/train/paging'
      }
    }
  },
  methods: {

    /**
     * 开始训练
     * @param mode
     */
    startTrain(repoId, mode) {
      this.$router.push({ name: 'MyRepoTraining', params: { repoId: repoId, mode: mode }})
    }
  }
}
</script>
