<template>
  <div class="anti-content">
    <div class="title">软件检测</div>
    <div class="monitor-content">
      <div class="monitor-item" style="width: 20%">
        <i style="font-size: 150px" class="el-icon-link" />
      </div>
      <div class="monitor-item" style="width: 60%">
        <div class="monitor-sub">
          <span>防作弊检测软件状态：</span>
          <span v-show="!noTool && !checkToolDialog && !checkLoading">
            <i
              class="el-icon-check"
              style="color: rgb(147, 240, 4); font-weight: bold"
            />
            检测通过
          </span>
          <span v-show="!noTool && checkToolDialog && !checkLoading">
            <i
              class="el-icon-close"
              style="color: rgb(213, 15, 38); font-weight: bold"
            />
            检测未通过
          </span>
          <span v-show="noTool && !checkLoading">
            <i class="el-icon-magic-stick" style="font-weight: bold" />
            <!-- 未开始检测 -->
            未启动
          </span>
          <span v-show="checkLoading">
            <i class="el-icon-loading" style="font-weight: bold" />
            检测中，请等待
          </span>
        </div>
        <br >
        <div class="monitor-sub">防作弊检测软件下载:</div>
        <div class="monitor-sub">
          <router-link
            to="/downtool/windows"
            tag="a"
            style="color: blue"
            target="_blank"
          >点此下载</router-link
          >
        </div>
        <div class="monitor-sub">
          已下载，请先启动“考试系统防作弊检测工具”软件
        </div>
      </div>
    </div>
    <div class="btn-row">
      <el-button type="default" size="large" @click="last"> 上一步 </el-button>
      <el-button
        type="primary"
        size="large"
        class="next-btn"
        @click="startCheck"
      >
        我已下载软件并启动，开始检测
      </el-button>
      <el-button type="default" size="large" @click="next">
        <span v-if="!noTool && !checkToolDialog && !checkLoading">下一步</span>
        <span v-else>跳过，下一步</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import { guideStepMixin } from './mixin'
import { checkTool } from './mixin.js'
import { antiCheatingSoftwareLink } from '@/data/const.js'

export default {
  name: 'GuideStepAntiCheating',
  mixins: [guideStepMixin, checkTool],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkLoading: true,
      timeStamp: '',
      antiCheatingSoftwareLink: antiCheatingSoftwareLink
    }
  },
  watch: {
    checkLoading: {
      handler(status) {
        if (status) {
          this.timeStamp = setTimeout(() => {
            this.checkLoading = false
          }, 1000)
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.timeStamp) {
      clearTimeout(this.timeStamp)
    }
  },
  methods: {
    startCheck() {
      this.checkLoading = true
      this.checkDetectionTool()
    }
  }
}
</script>
<style scoped>
.anti-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.title {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}

.btn-row {
  display: flex;
  justify-content: center;
}

.monitor-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: center;
}

.monitor-item {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-right: 25px;
}

.monitor-sub {
  margin-bottom: 15px;
  text-align: left;
}

.next-btn {
  width: 400px;
  border-color: #f4585c;
  background-color: #f4585c;
}
::v-deep .is-disabled,
.is-disabled:hover {
  border-color: #fab8ba;
  background-color: #fab8ba;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #f4585c;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #f4585c;
  background-color: #f4585c;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f4585c;
}
</style>
