var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "方案类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "local" } }, [
                    _vm._v("本地存储"),
                  ]),
                  _c("el-radio", { attrs: { label: "minio" } }, [
                    _vm._v("Minio"),
                  ]),
                  _c("el-radio", { attrs: { label: "huaweiyun" } }, [
                    _vm._v("华为云OBS"),
                  ]),
                  _c("el-radio", { attrs: { label: "aliyun" } }, [
                    _vm._v("阿里云OSS"),
                  ]),
                  _c("el-radio", { attrs: { label: "qcloud" } }, [
                    _vm._v("腾讯云"),
                  ]),
                  _c("el-radio", { attrs: { label: "qiniu" } }, [
                    _vm._v("七牛云"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "local"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "本地目录", prop: "data.localDir" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "本地目录地址，如：/data/upload/ 或 D:/data/file/",
                        },
                        model: {
                          value: _vm.postForm.data.localDir,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "localDir", $$v)
                          },
                          expression: "postForm.data.localDir",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            size: "small",
                            placeholder:
                              "以https://或http://开头的本地接口域名或CDN加速域名，以/结尾",
                          },
                          model: {
                            value: _vm.postForm.data.url,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm.data, "url", $$v)
                            },
                            expression: "postForm.data.url",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("upload/file/"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "aliyun"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c("el-alert", {
                        attrs: {
                          type: "error",
                          title:
                            "必须使用RAM账号，账号拥有AliyunSTSAssumeRoleAccess、AliyunMTSFullAccess、AliyunIMMFullAccess权限，文档：https://ram.console.aliyun.com/roles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "EndPoint", prop: "data.endpoint" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "aliyun_upload_endpoins" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bucket", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "阿里云存储容器" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "AccessKeyId", prop: "data.accessKeyId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "授权ID" },
                        model: {
                          value: _vm.postForm.data.accessKeyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeyId", $$v)
                          },
                          expression: "postForm.data.accessKeyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "AccessKeySecret",
                        prop: "data.accessKeySecret",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "授权秘钥" },
                        model: {
                          value: _vm.postForm.data.accessKeySecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeySecret", $$v)
                          },
                          expression: "postForm.data.accessKeySecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色ARN", prop: "data.arn" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "角色ARN，此角色应拥有写入新文件的权限：AliyunOSSFullAccess或oss:PutObject",
                        },
                        model: {
                          value: _vm.postForm.data.arn,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "arn", $$v)
                          },
                          expression: "postForm.data.arn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "OSS访问域名或CDN加速域名，必须以/结尾，如：https://xxxx.oss-cn-beijing.aliyuncs.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "智能媒体项目名称" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          size: "small",
                          placeholder: "智能媒体项目名称",
                        },
                        model: {
                          value: _vm.postForm.data.project,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "project", $$v)
                          },
                          expression: "postForm.data.project",
                        },
                      }),
                      _vm._v(" "),
                      _c("small", { staticStyle: { color: "#555" } }, [
                        _vm._v("用于文档转换 "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://help.aliyun.com/document_detail/99384.html?spm=5176.8465980.0.dexternal.32ac1450VWbBrl",
                            },
                          },
                          [_vm._v("参考文档")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "MPS管道ID" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { size: "small", placeholder: "MPS管道ID" },
                        model: {
                          value: _vm.postForm.data.pipeline,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "pipeline", $$v)
                          },
                          expression: "postForm.data.pipeline",
                        },
                      }),
                      _vm._v(" "),
                      _c("small", { staticStyle: { color: "#555" } }, [
                        _vm._v(" 用于视频转换 "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://mps.console.aliyun.com/pipeline/list",
                            },
                          },
                          [_vm._v("参考文档")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "qiniu"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储空间位置", prop: "data.endpoint" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "qiniu_upload_areas" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储空间名称", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "阿里云存储容器" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "AccessKeyId", prop: "data.accessKeyId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "AccessKeyId" },
                        model: {
                          value: _vm.postForm.data.accessKeyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeyId", $$v)
                          },
                          expression: "postForm.data.accessKeyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "AccessKeySecret",
                        prop: "data.accessKeySecret",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "AccessKeySecret",
                        },
                        model: {
                          value: _vm.postForm.data.accessKeySecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeySecret", $$v)
                          },
                          expression: "postForm.data.accessKeySecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "外链域名，必须以/结尾，如：http://qozlc75sb.hn-bkt.clouddn.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "qcloud"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属地域", prop: "data.region" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "qcloud_upload_endpoints" },
                        model: {
                          value: _vm.postForm.data.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "region", $$v)
                          },
                          expression: "postForm.data.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储桶名称", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "腾讯云存储桶" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretId", prop: "data.secretId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "secretId" },
                        model: {
                          value: _vm.postForm.data.secretId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretId", $$v)
                          },
                          expression: "postForm.data.secretId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretKey", prop: "data.secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "secretKey" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "COS访问域名或CDN加速域名，必须以/结尾，如：https://yfhl-1252868612.cos.ap-beijing.myqcloud.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒体处理列队ID" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "用于视频转换的媒体处理列队ID",
                        },
                        model: {
                          value: _vm.postForm.data.mediaQueue,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "mediaQueue", $$v)
                          },
                          expression: "postForm.data.mediaQueue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "minio"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Endpoint", prop: "data.endpoint" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Endpoint" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Access Key", prop: "data.accessKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Access Key" },
                        model: {
                          value: _vm.postForm.data.accessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKey", $$v)
                          },
                          expression: "postForm.data.accessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Secret Key", prop: "data.secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Secret Key" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Bucket Name", prop: "data.bucketName" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Bucket Name" },
                        model: {
                          value: _vm.postForm.data.bucketName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucketName", $$v)
                          },
                          expression: "postForm.data.bucketName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "huaweiyun"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Endpoint", prop: "data.endpoint" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Endpoint" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Access Key", prop: "data.accessKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Access Key" },
                        model: {
                          value: _vm.postForm.data.accessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKey", $$v)
                          },
                          expression: "postForm.data.accessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Secret Key", prop: "data.secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Secret Key" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Bucket Name", prop: "data.bucketName" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "Bucket Name" },
                        model: {
                          value: _vm.postForm.data.bucketName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucketName", $$v)
                          },
                          expression: "postForm.data.bucketName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-divider"),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-refresh",
                    type: "warning",
                    size: "small",
                  },
                  on: { click: _vm.changProvider },
                },
                [_vm._v("使用当前方案")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-folder",
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }