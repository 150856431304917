<template>

  <div v-if="userId">
    <span v-if="info && info.openId">
      <span>{{ info.openId }}</span> <el-button type="danger" size="mini" plain @click="saveUnBind">解绑</el-button>
    </span>

    <span v-else>
      <el-button type="primary" size="mini" plain @click="showBind">绑定</el-button>
    </span>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      title="绑定手机"
      width="30%"
      @close="dialogVisible = false"
    >

      <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="left" label-width="100px">

        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="dataForm.mobile"
          />
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="saveBind">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import { findBindInfo, directBind, directUnBind } from '@/api/sys/user/bind'
import { isMobile } from '@/utils/validate'

export default {
  name: 'MobileBinder',
  props: {
    userId: String
  },
  data() {
    return {
      info: {},
      dataForm: {},
      rules: {
        mobile: [{
          required: true,
          validator: isMobile
        }]
      },
      loading: false,
      dialogVisible: false
    }
  },

  watch: {
    userId: {
      handler(val) {
        // 获取用详情
        this.fetchBind()
      }
    }
  },

  created() {
    this.fetchBind()
  },

  methods: {

    // 查找绑定信息
    fetchBind() {
      if (!this.userId) {
        return
      }
      const params = {
        loginType: 'mobile',
        userId: this.userId
      }
      findBindInfo(params).then(res => {
        this.info = res.data
      }).catch(() => {
        this.loading = false
      })
    },

    saveBind() {
      // 表单验证
      this.$refs.dataForm.validate().then((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.dataForm.userId = this.userId
        directBind(this.dataForm).then(() => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '绑定成功!'
          })
          this.dialogVisible = false
          this.fetchBind()
        }).catch(() => {
          this.loading = false
        })
      })
    },

    showBind() {
      this.dialogVisible = true
    },

    saveUnBind() {
      // 删除
      this.$confirm('确实要解除用户的手机绑定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          mobile: this.info.openId,
          userId: this.userId
        }

        directUnBind(params).then(() => {
          this.$message({
            type: 'success',
            message: '解绑成功!'
          })
          this.fetchBind()
        })
      })
    }
  }
}
</script>
