var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "row" } },
    [
      _c(
        "div",
        { staticStyle: { width: "250px", flex: "0 0 250px" } },
        [_c("result-left-nav", { attrs: { data: _vm.postForm } })],
        1
      ),
      _c(
        "div",
        { staticStyle: { "flex-grow": "1", "padding-left": "20px" } },
        [
          _c("result-qu-list", {
            ref: "joinQuList",
            attrs: { data: _vm.postForm },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }