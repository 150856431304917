<template>
  <div class="step2-content">
    <div class="title">微信扫码，开启手机监控</div>
    <div class="sub-title">
      * 请用手机微信扫码，并保持考试全程手机屏幕处于常亮状态，关闭自动锁屏
    </div>
    <div class="monitor-content">
      <div class="monitor-item">
        <div class="monitor-sub">1、手机微信扫码</div>
        <div class="monitor-img">
          <div id="qrcodediv" ref="qrcodediv" />
        </div>
        <el-tooltip class="item" effect="dark" placement="top-start">
          <template #content>
            <p>扫码遇到问题怎么办?</p>
            <p>1、请检查你的手机网络连接是否正常;</p>
            <p>2、请更新手机微信APP至最新版，然后重新扫码;</p>
            <p>3、以上方法均不能解决，请点击右侧“客服”小窗联系我们;</p>
            <p>
              4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源
            </p>
          </template>
          <div class="monitor-hint">
            <i class="el-icon-question" />
            扫码遇故障?
          </div>
        </el-tooltip>
      </div>
      <div class="monitor-item">
        <div class="monitor-sub">2.侧后方45°竖立摆放手机</div>
        <el-image
          :src="require('../../../../assets/web/images/guide-2.jpg')"
          class="monitor-img"
          fit="fill"
        />
        <div class="monitor-hint">电脑正侧方放置手机</div>
      </div>
      <div class="monitor-item">
        <div class="monitor-sub">3.开启视频监控</div>
        <el-image
          :src="require('../../../../assets/web/images/guide-1.jpg')"
          class="monitor-img"
          style="height: 300px; width: 170px"
          fit="fill"
        />
        <div class="monitor-hint">确认是否开始录制</div>
      </div>
    </div>
    <div class="btn-row">
      <el-button type="default" size="large" @click="last"> 上一步 </el-button>
      <el-button
        type="primary"
        size="large"
        class="next-btn"
        @click="checkModal"
      >
        我已扫码，开始检测手机
      </el-button>
      <el-button type="default" size="large" @click="next">
        跳过，下一步
      </el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="手机监控检测结果"
      width="30%"
    >
      <el-result
        v-show="!isChecking"
        icon="warning"
        title="手机设备视频监控检测中..."
        sub-title=""
      />
      <el-result
        v-show="isChecking"
        icon="success"
        title="您的手机设备检测:通过"
        sub-title="考试全程请务必开启手机视频监控，并按图示放置合适位置，可以看到上半身和电脑全景，监控画面违规会影响到考试成绩。"
      >
        <template slot="extra">
          <el-button
            type="primary"
            size="medium"
            class="next-btn"
            @click="
              () => {
                this.dialogVisible = false
                next()
              }
            "
          >
            下一步
          </el-button>
        </template>
      </el-result>
    </el-dialog>
    <video-viewer
      v-if="checkStart"
      :height="0"
      :width="100"
      :online="true"
      :cam-type="'side'"
      :paperid="paperId"
      @acceptSuccess="acceptSuccess"
    />
  </div>
</template>
<script>
import { guideStepMixin } from './mixin'

import QRCode from 'qrcodejs2'

export default {
  name: 'GuideStep1',
  mixins: [guideStepMixin],
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      paperId: '',
      dialogVisible: false,
      checkStart: false,
      isChecking: false
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.postForm = val
          this.postForm.cardType = '身份证'
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.paperId = this.$route.params.id
    this.showQrCode()
  },
  methods: {
    showQrCode() {
      const timer = setInterval(() => {
        if (this.$refs.qrcodediv) {
          this.createdQrCode()
          clearInterval(timer)
        }
      }, 500)
    },

    createdQrCode() {
      // console.log(this.qrCode, 'lllll')
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      const text = `${process.env.VUE_APP_URL_PREFIX}/across/selectcamera?name=${this.paperId}&type=side&isTest=${true}`
      this.qrCode = new QRCode(this.$refs.qrcodediv, {
        width: 200,
        height: 200,
        text: text
      })
      console.log('二维码地址引导流程', text)
    },
    checkModal() {
      this.checkStart = true
      this.dialogVisible = true
    },
    acceptSuccess(falg) {
      console.log('检查成功', falg)
      this.isChecking = falg
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped>
.step2-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 15px;
}
.sub-title {
  color: #f36060;
  margin-bottom: 20px;
}

.monitor-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.monitor-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 25px;
}

.monitor-sub {
  margin-bottom: 15px;
  text-align: center;
}

.monitor-img {
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
}

.monitor-hint {
  color: #a5a5a5;
  text-align: center;
  font-size: 15px;
}

.btn-row {
  display: flex;
  justify-content: center;
}
.next-btn {
  width: 400px;
  border-color: #f4585c;
  background-color: #f4585c;
}
</style>
