var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "watch-out-box" },
    [
      _c(
        "div",
        { staticClass: "watch-out-header" },
        [
          _c("div", { staticClass: "watch-out-title" }, [
            _vm._v(_vm._s(_vm.stat.title)),
          ]),
          _c(
            "el-badge",
            {
              staticStyle: { "margin-right": "50px" },
              attrs: { value: _vm.alarm.unRead, hidden: _vm.alarm.unRead <= 0 },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "process" } }, [
                        _vm._v("监考 (" + _vm._s(_vm.stat.processCount) + ")"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "finished" } }, [
                        _vm._v("已交卷 (" + _vm._s(_vm.stat.handCount) + ")"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "apply" } }, [
                        _vm._v("预警列表(" + _vm._s(_vm.alarm.total) + ")"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.activeName === "process"
        ? _c("inspector-process", {
            attrs: {
              "exam-id": _vm.examId,
              "news-list": _vm.newsList,
              "send-message": _vm.sendMsg,
            },
            on: { stat: _vm.syncStat },
          })
        : _vm._e(),
      _vm.activeName === "finished"
        ? _c("inspector-hand", { attrs: { "exam-id": _vm.examId } })
        : _vm._e(),
      _vm.activeName === "apply"
        ? _c("inspector-warning-list", {
            attrs: { "exam-id": _vm.examId },
            on: { refreshCount: _vm.refreshCount },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }