import { post } from '@/utils/request'

/**
 * 考生确认信息接口
 * @param data
 * @returns {Promise}
 */
export function fetchstudentconfirmconfirmPrepare(data) {
  return post('/api/remoteexam/studentconfirm/confirmPrepare', data)
}

/**
 * 考生确认接口
 * @param data
 * @returns {Promise}
 */
export function fetchstudentconfirmconfirm(data) {
  return post('/api/remoteexam/studentconfirm/confirm', data)
}

/**
 * 考试登录，获取考试信息
 * @param data
 * @returns {Promise}
 */
export function fetchprepareexamInfo(data) {
  return post('/api/remoteexam/prepare/examInfo', data)
}
