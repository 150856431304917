import axios from 'axios'
import QRCode from 'qrcodejs2'
import { getToken } from '@/utils/auth'
import { httpGetFaceId } from '@/api/paper/exam'
export const checkTool = {
  data() {
    return {
      checkToolDialog: 0,
      checkErrMsg: '没有检测到防作弊检测工具启动,请先退出当前考试。重新启动防作弊检测工具，考试期间保持防作弊检测工具运行。'
    }
  },
  watch: {
    checkData: {
      handler(val) {
        console.log('设置检测工具是否开启', val.desktopToolOn)
        // 判断系统是否是windows
        const agent = navigator.userAgent.toLowerCase()
        const isWin = agent.indexOf('win') > -1 || agent.indexOf('wow') > -1
        const userAgent = navigator.userAgent
        const isMac = /Mac/.test(userAgent)
        if (val.desktopToolOn) {
          this.checkDetectionTool()
        }
      },
      deep: true
    }
  },
  mounted() {
    console.log('检测工具开启', this.checkToolShow)
    // 判断系统是否是windows
    const agent = navigator.userAgent.toLowerCase()
    const isWin = agent.indexOf('win') > -1 || agent.indexOf('wow') > -1
    const userAgent = navigator.userAgent
    const isMac = /Mac/.test(userAgent)
    if (this.checkToolShow != 'false') {
      this.timer2 = setTimeout(() => {
        this.checkDetectionTool()
      }, 0)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer2)
  },
  methods: {
    checkDetectionTool() {
      axios({
        baseURL: 'http://localhost:12622/loc',
        timeout: 30000,
        method: 'GET'
        // headers: {
        //   'Access-Control-Allow-Orgin': '*',
        //   'Content-Type': 'application/json;charset=utf-8'
        // }
      })
        .then((res) => {
          console.log(res, '结果是')
          if (res.data.result) {
            this.checkToolDialog = 3
            this.checkErrMsg = '请到到防作弊检测软件里面去检查错误'
          } else {
            this.checkToolDialog = 2
          }
          this.timer2 = setTimeout(() => {
            this.checkDetectionTool()
          }, 10000)
        })
        .catch((err) => {
          this.timer2 = setTimeout(() => {
            this.checkDetectionTool()
          }, 10000)
          this.checkToolDialog = 1
          this.checkErrMsg = '没有检测到防作弊检测工具启动,请先退出当前考试。重新启动防作弊检测工具，考试期间保持防作弊检测工具运行。'
          console.log(err, '错误是')
        })
    }
  }
}
export const faceQrCode = {
  data() {
    return {
      faceId: ''
    }
  },
  beforeDestroy() {
    this.ws.close()
    clearInterval(this.timer)
  },
  methods: {
    creactWs() {
      const wsUrl = `${process.env.VUE_APP_WS_PREFIX}/api/ws/faceauthstatus`
      // const wsUrl = 'ws://exam.ntces.cn/api/ws/faceauthstatus'
      this.ws = new WebSocket(wsUrl)
      this.ws.onopen = (e) => {
        const message = {
          id: 'register',
          examId: this.postForm.examId,
          paperId: this.faceId
        }
        this.sendMessage(message)
        this.peng()
      }
      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'registerResponse':
            console.log(parsedMessage, '收到的信息是')
            break
          case 'pantResponse':
            console.log(parsedMessage, 'parsedMessage收到的信息是')
            break
          case 'faceResponse':
            console.log(parsedMessage, 'faceResponse收到的信息是')
            this.faceResponse(parsedMessage)
            break
          default:
            console.log('无法识别的信息', parsedMessage)
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(
            `[websocket close]连接关闭, code=${event.code} reason=${event.reason}`
          )
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          console.log('[websocket close] Connection died')
          // this.start()
        }
      }

      this.ws.onerror = (error) => {
        // this.start()
        console.log(`[websocket error] ${error.message}`)
      }
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        const message = {
          id: 'pant'
        }
        this.sendMessage(message)
      }, 3000)
    },
    faceResponse(parsedMessage) {
      if (parsedMessage.success) {
        this.faceBack(parsedMessage.faceToken)
      }
    },

    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },
    async getFaceId() {
      const res = await httpGetFaceId()
      console.log(res, '获取的人脸识别id是')
      this.faceId = res.data
      this.creactWs()
      this.showQrCode()
    },
    showQrCode() {
      const timer = setInterval(() => {
        if (this.$refs.qrcodediv) {
          this.createdQrCode()
          clearInterval(timer)
        }
      }, 500)
    },

    createdQrCode() {
      console.log(this.qrCode, 'lllll')
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      const token = getToken()
      const text = `${process.env.VUE_APP_URL_PREFIX}/across/mobileface?examId=${this.postForm.examId}&token=${token}&paperId=${this.faceId}`
      this.qrCode = new QRCode(this.$refs.qrcodediv, {
        width: 200,
        height: 200,
        text: text
      })
      console.log('二维码地址', text)
    }
  }
}
