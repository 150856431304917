<template>
  <div>
    <div>
      <user-banner />
    </div>
    <div style="display: flex; margin-top: 50px">
      <div style="width: 200px; background: #f8f8f8">
        <el-menu
          :default-active="active"
          style="width: 200px"
          background-color="#f8f8f8"
          class="el-menu-vertical-demo"
          @select="menuSelect"
        >
          <el-submenu index="settings">
            <template #title>
              <i class="el-icon-user"/> <span>用户设置</span>
            </template>
            <el-menu-item index="user"> 个人资料 </el-menu-item>
            <el-menu-item index="pass"> 密码安全 </el-menu-item>
            <el-menu-item index="connect"> 账号绑定 </el-menu-item>
            <el-menu-item v-if="siteData.props.faceLogin" index="real-person">
              实名认证
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-if="siteData.props.moduleExam"
            class="module-exam"
            index="exam"
          >
            <i class="el-icon-document"/>
            <span slot="title">考试成绩</span>
          </el-menu-item>
          <el-menu-item
            v-if="siteData.props.moduleExam"
            class="module-exam"
            index="train"
          >
            <i class="el-icon-data-line"/>
            <span slot="title">训练记录</span>
          </el-menu-item>
          <el-menu-item index="msg">
            <i class="el-icon-chat-round"/>
            <span slot="title">站内信息</span>
          </el-menu-item>
          <el-menu-item index="points">
            <i class="el-icon-coin"/>
            <span slot="title">积分日志</span>
          </el-menu-item>
          <el-menu-item index="myCertificate">
            <i class="el-icon-coin"/>
            <span slot="title">我的证书</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div style="flex-grow: 1; border: #f1f1f1 1px solid; padding: 30px">
        <update-info v-if="active === 'user'" />
        <update-pass v-if="active === 'pass'" />
        <connect-bind v-if="active === 'connect'" />
        <real-person v-if="active === 'real-person'" />
        <my-paper v-if="active === 'exam'" />
        <train-history v-if="active === 'train'" />
        <msg v-if="active === 'msg'" />
        <points-log v-if="active === 'points'" />
        <MyCertificate v-if="active === 'myCertificate'" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import MyPaper from '@/views/web/paper/total'
import ConnectBind from '@/views/web/ucenter/components/ConnectBind/index.vue'
import RealPerson from '@/views/web/ucenter/components/RealPerson/index.vue'
import MyCertificate from '@/views/web/ucenter/components/MyCertificate/index.vue'
export default {
  components: {
    RealPerson,
    ConnectBind,
    MyPaper,
    MyCertificate
  },
  data() {
    return {
      active: 'user',
      state: ''
    }
  },
  computed: {
    ...mapGetters(['siteData', 'token', 'realName'])
  },
  watch: {
    '$route.query.to': {
      handler(val) {
        this.menuSelect(val)
      },
      deep: true
    },
    '$route.query.state': {
      handler(val) {
        this.state = val
      },
      deep: true
    }
  },

  created() {
    const to = this.$route.query.to
    const state = this.$route.query.state
    if (state) {
      this.state = state
    }
    if (to) {
      this.menuSelect(to)
      return
    }

    const cache = localStorage.getItem('uc:menu:active')
    if (cache) {
      this.menuSelect(cache)
    }
  },
  methods: {
    // 菜单选择
    menuSelect(index) {
      if (!index) {
        index = 'user'
      }

      this.active = index
      this.$route.query.to = index
      // 保存状态
      localStorage.setItem('uc:menu:active', index)
    }
  }
}
</script>
<style scoped>
::v-deep .el-menu {
  border-right: none;
}
</style>
