var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm._m(0),
      _vm.currentStep === 0
        ? _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "title" }, [_vm._v("摄像头检测")]),
            _c(
              "div",
              { staticClass: "box-img" },
              [
                _vm.currentStep === 0
                  ? _c("video-presenter", {
                      attrs: {
                        height: 280,
                        width: 375,
                        "cam-type": "side",
                        paperid: _vm.paperid,
                        "device-id": _vm.deviceId,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "title-explain" }, [
              _vm._v("是否可以清楚的看到自己？"),
            ]),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrent(false)
                      },
                    },
                  },
                  [_vm._v("看不见")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrent(true)
                      },
                    },
                  },
                  [_vm._v("看得见")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.currentStep === 1
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("麦克风检测")]),
              _c(
                "div",
                {
                  staticClass: "title-explain",
                  staticStyle: {
                    "text-align": "left",
                    "margin-bottom": "20px",
                  },
                },
                [_vm._v(" 对着麦克风说 哈喽 试试~ ")]
              ),
              _c("recorder", {
                ref: "record",
                on: { success: _vm.recordBack },
                model: {
                  value: _vm.recordUrl,
                  callback: function ($$v) {
                    _vm.recordUrl = $$v
                  },
                  expression: "recordUrl",
                },
              }),
              _c("div", { staticClass: "micro-content" }),
              _c(
                "div",
                { staticClass: "btn-row" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeCurrent(false)
                        },
                      },
                    },
                    [_vm._v("不能录制")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changeCurrent(true)
                        },
                      },
                    },
                    [_vm._v(" 能录制 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === 2
        ? _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "title" }, [_vm._v("扬声器检测")]),
            _vm.recordUrl
              ? _c("audio", {
                  staticClass: "audio-content",
                  attrs: { src: _vm.recordUrl, controls: "" },
                })
              : _c("audio", {
                  staticClass: "audio-content",
                  attrs: { src: _vm.voiceUrl, controls: "" },
                }),
            _c(
              "div",
              {
                staticClass: "title-explain",
                staticStyle: { "margin-bottom": "40px" },
              },
              [_vm._v(" 请点击上方播放按钮，打开电脑声音 ")]
            ),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrent(false)
                      },
                    },
                  },
                  [_vm._v("听不见")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.changeCurrent(true)
                      },
                    },
                  },
                  [_vm._v("听得见")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.currentStep === 3
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("检测结果")]),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: {
                    column: 1,
                    "label-style": { "text-align": "right", width: "100px" },
                    "content-style": { width: "280px", "margin-left": "20px" },
                    border: "",
                  },
                },
                [
                  _vm.config.osName
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "系统 " } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.config.osName) +
                              " " +
                              _vm._s(_vm.config.osVersion) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.config.browserName
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "浏览器 " } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.config.browserName) +
                              " " +
                              _vm._s(_vm.config.browserVersion) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("el-descriptions-item", { attrs: { label: "摄像头 " } }, [
                    _vm.checkList.camera
                      ? _c("span", { staticClass: "blue-text" }, [_vm._v(" ✔")])
                      : _c("span", { staticClass: "red-text" }, [_vm._v(" X")]),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "麦克风 " } }, [
                    _vm.checkList.microphone
                      ? _c("span", { staticClass: "blue-text" }, [_vm._v(" ✔")])
                      : _c("span", { staticClass: "red-text" }, [_vm._v(" X")]),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "扬声器 " } }, [
                    _vm.checkList.speaker
                      ? _c("span", { staticClass: "blue-text" }, [_vm._v(" ✔")])
                      : _c("span", { staticClass: "red-text" }, [_vm._v(" X")]),
                  ]),
                  _vm.config.downlink
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "上行网络 " } },
                        [
                          _c("span", { staticClass: "blue-text" }, [
                            _vm._v(_vm._s(_vm.config.downlink) + "（Mbps）"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.config.effectiveType
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "网络类型 " } },
                        [
                          _c("span", { staticClass: "blue-text" }, [
                            _vm._v(_vm._s(_vm.config.effectiveType)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn-row" },
                [
                  _c("el-button", { on: { click: _vm.changeCurrent } }, [
                    _vm._v("重新检测"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.back } },
                    [_vm._v("返回登录页")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-row" }, [
      _c("div", { staticClass: "title" }, [_vm._v("在线考试 设备检测")]),
      _c("div", { staticClass: "title-explain" }, [
        _vm._v(" 为保证在线考试顺利进行，请按系统提示进行设备检测 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }