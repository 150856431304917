import { post } from '@/utils/request'

/**
 * 课程详情
 * @param id
 * @returns {*}
 */
export function fetchDetail(id) {
  return post('/api/notify/tmpl/detail', { id: id })
}

/**
 * 保存课程
 * @param data
 */
export function saveData(data) {
  return post('/api/notify/tmpl/save', data)
}

/**
 * 模板分页
 * @param id
 * @returns {*}
 */
export function fetchPaging(data) {
  return post('/api/notify/tmpl/paging', data)
}

