<template>
  <div class="watch-out-box">
    <div class="watch-out-header">
      <div class="watch-out-title">{{ stat.title }}</div>
      <el-badge :value="alarm.unRead" :hidden="alarm.unRead <= 0" style="margin-right: 50px;">
        <div>
          <el-radio-group v-model="activeName" size="mini">
            <el-radio-button
              label="process"
            >监考 ({{ stat.processCount }})</el-radio-button
            >
            <el-radio-button
              label="finished"
            >已交卷 ({{ stat.handCount }})</el-radio-button
            >
            <el-radio-button label="apply">预警列表{{ alarm.total }}</el-radio-button>
            <el-radio-button label="processed">待处理预警列表{{ alarm.unRead }}</el-radio-button>
          </el-radio-group>
        </div>
      </el-badge>
    </div>

    <examination-process
      v-if="activeName === 'process'"
      :exam-id="examId"
      @stat="syncStat"
    />
    <examination-hand
      v-if="activeName === 'finished'"
      :exam-id="examId"
    />
    <examination-warning-list v-if="activeName === 'apply'" :exam-id="examId" @refreshCount="refreshCount"/>
    <examinationProcessedList v-if="activeName === 'processed'" :exam-id="examId" @refreshCount="refreshCount"/>
  </div>
</template>

<script>
import examinationProcess from '@/views/admin/remoteTest/examination/components/examinationProcess'
import examinationHand from '@/views/admin/remoteTest/examination/components/examinationHand'
import examinationWarningList from '@/views/admin/remoteTest/examination/components/examinationWarningList'
import examinationProcessedList from '@/views/admin/remoteTest/examination/components/examinationProcessedList'
import { httpchiefwatchStat, httpalarmcount } from '@/api/exam/watch'
import { mapGetters } from 'vuex'

export default {
  components: {
    examinationProcess,
    examinationHand,
    examinationWarningList,
    examinationProcessedList
  },
  data() {
    return {
      examId: '',
      cacheKey: 'inspector-tab-active',
      activeName: 'process',
      stat: {
        requireCount: 0,
        processCount: 0,
        handCount: 0,
        missCount: 0,
        applyCount: 0
      },
      statTimer: null,
      isMain: false,
      alarm: {
        total: 0,
        unRead: 0
      }
    }
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'roles'])
  },
  watch: {
    activeName: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)

        // 统计数据从里面传出
        if (val === 'process') {
          this.clearTimer()
        } else {
          // 初始化统计数据
          this.initTimer()
        }
      }
    }
  },

  created() {
    console.log('@@@@@@@@@@@@', this.roles)
    this.roles.forEach((role) => {
      if (role == 'invigilatorChief') {
        this.isMain = true
      }
    })
    // 获取考试信息
    this.examId = this.$route.params.examId

    // 获取缓存
    const active = localStorage.getItem(this.cacheKey)
    if (active) {
      this.activeName = active
    }
    this.getAlarmCount()
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    // 获取统计信息
    fetchStat() {
      if (!this.examId) {
        return
      }
      httpchiefwatchStat({ id: this.examId }).then((res) => {
        this.stat = res.data
      })
    },

    initTimer() {
      this.clearTimer()
      // 10秒获取一次
      this.statTimer = setInterval(this.fetchStat, 10000)
      // 获取一次信息
      this.fetchStat()
    },

    clearTimer() {
      if (this.statTimer) {
        clearInterval(this.statTimer)
      }
    },

    // 同步统计状态
    syncStat(data) {
      this.stat = data
    },
    async getAlarmCount() {
      const subdata = {
        id: this.examId
      }
      const res = await httpalarmcount(subdata)
      const { total, unRead } = res.data
      this.alarm = {
        total,
        unRead
      }
    },
    refreshCount() {
      this.getAlarmCount()
    }
  }
}
</script>

<style scoped>
.watch-out-box {
  background: #1e2c3c;
  padding: 10px;
  height: 100vh;
  border-radius: 5px;
  overflow: auto;
  margin: -10px;
}

.watch-out-header {
  display: flex;
  align-items: center;
  color: #fff;
  border-bottom: #888 1px solid;
  margin-bottom: 10px;
  height: 50px;
}

.watch-out-title {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
}
</style>
