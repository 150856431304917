var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "video-box" },
      [
        _c("el-image", {
          staticStyle: { width: "100%", height: "400px" },
          attrs: { src: _vm.url, fit: "fill" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "tip-status" }, [
          _vm._v(
            "参考上图所示，手机架设在您斜后方，确保可以同时拍到您的上半身及电脑屏幕。"
          ),
        ]),
        _c("div", { staticClass: "tip-status" }, [
          _vm._v("点击[开始摄像]按钮后，如出现授权提示，请选择允许授权。"),
        ]),
        _c("div", { staticClass: "tip-status" }, [
          _vm._v("如果[开始摄像]不成功，点击右上角3个点，选择在浏览器中打开。"),
        ]),
        _c("div", { staticClass: "tip" }, [
          _vm._v("请设置手机的休眠时间足够长，避免自动息屏"),
        ]),
        _c(
          "el-select",
          {
            staticStyle: { width: "100%" },
            attrs: { size: "mini" },
            model: {
              value: _vm.deviceId,
              callback: function ($$v) {
                _vm.deviceId = $$v
              },
              expression: "deviceId",
            },
          },
          _vm._l(_vm.cameras, function (item) {
            return _c("el-option", {
              key: item.deviceId,
              attrs: { value: item.deviceId, label: item.label },
            })
          }),
          1
        ),
        _c(
          "el-button",
          {
            staticStyle: { width: "100%" },
            attrs: { type: "primary" },
            on: { click: _vm.goStart },
          },
          [_vm._v("开始摄像")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }