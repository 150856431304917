<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="参与部门范围" name="first">
          <depart-refs v-model="deptCodes" />
        </el-tab-pane>
        <el-tab-pane label="参与人员范围" name="second">
          <el-button size="mini" @click="handleAdd">添加人员</el-button>
          <el-button type="danger" size="mini" @click="handleClear">
            清除所有
          </el-button>
          <div style="margin-top: 10px">
            <el-tag
              v-for="(user, index) in userList"
              :key="user.id"
              size="small"
              style="margin-right: 8px; margin-bottom: 8px"
              closable
              @close="handleRemove(index)"
            >
              {{ user.realName || user.userId_dictText }}
            </el-tag>
          </div>
          <!-- <div style="margin-top: 10px">
            <el-table :data="userList" stripe style="width: 100%">
              <el-table-column prop="userId_dictText" label="姓名">
                <template v-slot="scope">
                  {{ scope.row.realName || scope.row.userId_dictText }}
                </template>
              </el-table-column>
              <el-table-column prop="date" label="证件号" />
              <el-table-column prop="date" label="手机号" />
              <el-table-column prop="date" label="订单号" />
              <el-table-column prop="address" label="下单时间 " width="180" />
              <el-table-column label="操作" align="center" width="80">
                <template v-slot="scope">
                  <el-button
                    type="text"
                    icon="el-icon-delete-solid"
                    @click="removeItem(scope.row.id)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div> -->
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- <el-card>
      <div slot="header" class="clearfix">
        <span>参与部门范围</span>
      </div>
      <depart-refs v-model="deptCodes"/>
    </el-card>

    <el-card style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>参与人员范围</span>
      </div>
      <el-button size="mini" @click="handleAdd">选择人员</el-button>
      <el-button type="danger" size="mini" @click="handleClear">清除所有</el-button>
      <div style="margin-top: 10px">
        <el-tag
          v-for="(user,index) in userList"
          :key="user.id"
          size="small"
          style="margin-right: 8px; margin-bottom: 8px"
          closable
          @close="handleRemove(index)">
          {{ user.realName || user.userId_dictText }}
        </el-tag>
      </div>
    </el-card> -->

    <user-select-dialog
      :dialog-show.sync="dialogShow"
      :exclude-departs="deptCodes"
      :excludes="excludes"
      @select="handleSelected"
    />
  </div>
</template>

<script>
import UserSelectDialog from '@/views/admin/sys/user/components/UserSelectDialog'

export default {
  name: 'UserRange',
  components: { UserSelectDialog },
  props: {
    departs: Array,
    users: Array
  },
  data() {
    return {
      dialogShow: false,
      // 弹窗排除
      excludes: [],
      // 已选择
      userList: [],
      activeName: 'first',
      deptCodes: []
    }
  },
  watch: {
    departs: {
      handler(val) {
        this.deptCodes = val
      }
    },
    users: {
      handler() {
        this.fillUsers()
      }
    },
    deptCodes: {
      handler(val) {
        this.$emit('update:departs', val)
      }
    }
  },

  created() {
    this.deptCodes = this.departs
    this.fillUsers()
  },

  methods: {
    // 填充用户信息
    fillUsers() {
      const excludes = []
      let userList = []

      if (this.users && this.users.length > 0) {
        userList = this.users
        for (let i = 0; i < this.users.length; i++) {
          excludes.push(this.users[i].userId)
        }
      }
      this.excludes = excludes
      this.userList = userList
    },

    // 添加窗口
    handleAdd() {
      this.dialogShow = true
    },

    // 移除数据
    handleRemove(index) {
      this.userList.splice(index, 1)
      this.excludes.splice(index, 1)
    },

    // 选定用户
    handleSelected(ids, objs) {
      const userList = this.userList
      const excludes = this.excludes

      for (let i = 0; i < objs.length; i++) {
        const userId = objs[i].id

        if (!this.excludes.includes(userId)) {
          userList.push({ userId: userId, realName: objs[i].realName })
          excludes.push(userId)
        }
      }

      this.userList = userList
      this.excludes = excludes

      this.$emit('update:users', this.userList)
    },

    handleClear() {
      this.userList = []
      this.excludes = []
      this.$emit('update:users', this.userList)
    },
    removeItem(id) {
      if (
        this.userList &&
        this.userList.length > 0 &&
        this.userList.some((userItem) => {
          return userItem.id === id
        })
      ) {
        this.userList = this.userList.filter((userItem) => {
          return userItem.id !== id
        })
      }
      this.$emit('update:users', this.userList)
    }
  }
}
</script>
