<template>
  <div class="cam-box">
    <div v-show="!videoLock">
      <video
        id="video"
        ref="video"
        :width="width"
        :height="height"
        :playsinline="true"
        :autoplay="true"
        style="background: #262626"
      />

      <canvas id="canvas" ref="canvas" :width="width" :height="height" />
    </div>

    <div v-show="videoLock">
      <img :src="captureImage" >
    </div>

    <div v-if="paddingTop > 0" :style="`padding-top:${paddingTop}px`">
      <el-select v-model="deviceId" size="mini" style="width: 100%">
        <el-option
          v-for="item in cameras"
          :key="item.deviceId"
          :value="item.deviceId"
          :label="item.label"
        />
      </el-select>

      <div v-if="tracking && showTrackTips && trackingMsg" class="tips">
        {{ trackingMsg }}
      </div>
    </div>

    <!-- <el-empty v-if="noDevice" description="屏幕共享载入失败!请同意共享屏幕" /> -->
  </div>
</template>

<script>
// 引入tracking
import 'tracking/build/tracking-min.js'
import 'tracking/build/data/face-min.js'

import kurentoUtils from 'kurento-utils'
import { httpuploadPicture } from '@/api/exam/watch'
import { Notification } from 'element-ui'

export default {
  name: 'ScreenSharing',
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    captureFormat: {
      type: String,
      default: 'image/jpeg'
    },
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    },
    paperid: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      source: null,
      canvas: null,
      context: null,
      camerasListEmitted: false,
      cameras: [],
      noDevice: false,
      // 摄像头ID
      deviceId: null,
      videoLock: false,
      // 内容偏移
      paddingTop: 0,
      tracks: null,
      trackingMsg: '',
      captureLock: false,
      captureImage: null,
      // 警告定时器
      faceTimer: null,
      loading: false,
      ws: '',
      timer: null,
      camType: 'desktop'
    }
  },
  watch: {
    // 检测查询变化
    isStop: {
      handler() {
        if (this.isStop) {
          this.stop()
        } else {
          this.start()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.start()
    this.peng()
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close()
    }
    this.dispose()
  },
  methods: {
    init() {
      if (!this.webRtcPeer) {
        // const constraints = { video: true, preferCurrentTab: true }
        const constraints = {
          audio: false,
          video: { displaySurface: 'monitor' }
        }
        navigator.mediaDevices
          .getDisplayMedia(constraints)
          .then((stream) => {
            // console.log(stream, '媒体流')
            this.tracks = stream.getTracks()
            stream.getVideoTracks()[0].addEventListener('ended', () => {
              console.log('点击了关闭共享桌面', this.tracks)
              this.stop()
            })
            // 添加自己的中继服务器的配置，否则会默认指向谷歌的服务器
            const iceservers = {
              iceServers: [
                {
                  urls: `${process.env.VUE_APP_ICESERVER_STUN_URL}`
                },
                {
                  urls: [`${process.env.VUE_APP_ICESERVER_TURN_URL}`],
                  username: `${process.env.VUE_APP_ICESERVER_TURN_USERNAME}`,
                  credential: `${process.env.VUE_APP_ICESERVER_TURN_CREDENTIAL}`
                }
              ]
            }

            const options = {
              localVideo: this.$refs.video,
              configuration: iceservers,
              onicecandidate: this.onIceCandidate,
              videoStream: stream
            }
            this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
              options,
              (error) => {
                if (error) {
                  Notification.error({
                    title: '提示信息',
                    message: '屏幕共享无法使用，请检查是否禁止该权限'
                  })
                  return console.log(error, '错误信息是')
                }
                this.webRtcPeer.generateOffer(this.onOfferPresenter)
              }
            )
          })
          .catch((error) => {
            // console.log(error, '失败了')
            this.noDevice = true
            this.$emit('error', error)
          })
      }
      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        // console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'presenterResponse':
            this.presenterResponse(parsedMessage)
            break
          case 'viewerResponse':
            this.viewerResponse(parsedMessage)
            break
          case 'iceCandidate':
            this.webRtcPeer.addIceCandidate(
              parsedMessage.candidate,
              function(error) {
                if (error) {
                  return console.log('添加候选项错误', error)
                }
              }
            )
            break
          case 'alarm':
            this.alarm(parsedMessage)
            break
          case 'stopCommunication':
            this.dispose()
            break
          case 'pantResponse':
            this.pantResponse(parsedMessage)
            break
          case 'recording':
            console.log('视频开始录制', parsedMessage)
            break
          case 'stopped':
            console.log('视频终止录制', parsedMessage)
            break
          case 'paused':
            console.log('视频暂停录制', parsedMessage)
            break
          default:
            console.log('无法识别的信息', parsedMessage)
            // Notification.error({
            //   title: '提示信息',
            //   message: '系统出错请联系管理员'
            // })
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(
            `[websocket close]连接关闭, code=${event.code} reason=${event.reason}`
          )
          this.dispose()
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          console.log('[websocket close] Connection died')
          // this.dispose()
        }
      }

      this.ws.onerror = (error) => {
        console.log(`[websocket error] ${error.message}`)
        this.dispose()
      }
    },
    presenterResponse(message) {
      // console.log('presenterResponse desktop', message)
      // this.$emit('startShooting', true)
      if (message.response == 'accepted') {
        this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
          if (error) {
            return console.log(error, '错误是')
          }
        })
      } else {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受', errorMsg)

        this.dispose(true)
      }
    },
    viewerResponse(message) {
      if (message.response != 'accepted') {
        const errorMsg = message.message ? message.message : 'Unknow error'
        // console.log('由于以下原因不被接受' + errorMsg)
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
          if (error) {
            return console.log(error, '错误是')
          }
        })
      }
    },
    pantResponse(message) {
      // console.log(message, '屏幕分享 pantResponse信息是什么')
      this.$emit('checkConnected', message)
    },
    alarm(message) {
      // console.log(message, '警告信息是什么')
      if (message.snap) {
        const newArr = []
        for (let i = 0; i < message.snapNum; i++) {
          const newObj = {
            sort: i + 1,
            imageData: this.capture()
          }
          newArr.push(newObj)
        }
        this.upLoadWarning(message.inspectAlarmId, newArr)
      }
    },
    onOfferPresenter(error, offerSdp) {
      // facade ： 正面视频
      // side ： 侧面视频
      // desktop ： 桌面视频

      if (error) {
        return console.log('生成offer发生错误', error)
      }
      const message = {
        id: 'presenter',
        sdpOffer: offerSdp,
        type: 'desktop',
        name: this.paperid,
        mode: 'video-only',
        kicked: true
      }
      // console.log('presenter desktop 发送offer消息', message)
      this.sendMessage(message)
    },
    onIceCandidate(candidate) {
      // console.log('当地的候选人', JSON.stringify(candidate))
      const message = {
        id: 'onIceCandidate',
        candidate: candidate,
        type: 'desktop',
        name: this.paperid
      }
      this.sendMessage(message)
    },
    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },
    dispose(closeEmitter) {
      // console.log('desktop dispose', closeEmitter)

      clearInterval(this.timer)
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose()
        this.webRtcPeer = null
      }
    },
    start() {
      // console.log(
      //   '>>> Presenter Desktop start() >>>',
      //   this.camType,
      //   this.paperid
      // )

      // WebSocket.readyState
      // 返回当前 WebSocket 的链接状态，只读。
      // 语法
      // var readyState = WebSocket.readyState;
      // 值
      // 以下其中之一
      // 0 (WebSocket.CONNECTING)
      // 正在链接中
      // 1 (WebSocket.OPEN)
      // 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING)
      // 连接正在关闭
      // 3 (WebSocket.CLOSED)
      // 连接已关闭或者没有链接成功

      // 检查是否需要重新创建 websocket
      let toCreate = true
      if (
        this.ws &&
        (this.ws.readyState == WebSocket.CONNECTING ||
          this.ws.readyState == WebSocket.OPEN)
      ) {
        // 正在链接中 || 已经链接并且可以通讯
        toCreate = false
      }
      if (toCreate) {
        const wsUrl = `${process.env.VUE_APP_SIGNAL_BASE_API}/signal/invigilation`
        this.ws = new WebSocket(wsUrl)
        this.ws.onopen = (e) => {
          // console.log('Presenter Desktop Websocket已连接', this.paperid)
          this.init()
        }
      }
    },
    stop() {
      // console.log('停止分享')
      const message = {
        id: 'stop',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
      this.dispose()
      if (this.ws) {
        this.ws.close()
      }
    },
    interruption() {
      // console.log('停止拍摄')
      const message = {
        id: 'interruption',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        // console.log('心跳 desktop:', this.camType, this.paperid)
        const message = {
          id: 'pant',
          type: this.camType,
          name: this.paperid
        }
        this.sendMessage(message)
      }, 30000)
      // console.log(this.timer, '进来了')
    },
    async upLoadWarning(base64) {
      const subData = {
        inspectAlarmId: '1724681972630581250',
        type: this.camType,
        pictureList: [
          {
            sort: 1,
            imageData: base64
          }
        ]
      }
      const res = await httpuploadPicture(subData)
      if (res.sucess) {
        // console.log('抓拍成功')
      }
    },
    // 静默截屏，截屏时候无感知
    capture() {
      const video = this.$refs.video
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.videoHeight
      canvas.width = video.videoWidth
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataImage = canvas.toDataURL(this.captureFormat)
      // 转换成base64去除头
      const base64 = dataImage.replace(/^data:image\/\w+;base64,/, '')
      this.upLoadWarning(base64)
      // this.$emit('capture', base64)
      // return base64
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
</style>
