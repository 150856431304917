var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("基本信息确认")]),
        _c("div", { staticClass: "sub-title" }, [
          _vm._v("* 为确保后续联系畅通，请认真填写您的信息"),
        ]),
        _c(
          "el-form",
          {
            ref: "postForm",
            attrs: {
              model: _vm.postForm,
              rules: _vm.rules,
              "label-suffix": " ：",
              "label-width": "45%",
              "label-position": "right",
              size: "mini",
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          "margin-bottom": "17px",
                          "margin-top": "22px",
                        },
                        attrs: { label: "姓名", prop: "name" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "70%" },
                          attrs: {
                            maxlength: "200",
                            placeholder: "请输入姓名",
                            clearable: "",
                            size: "large",
                            disabled: "",
                          },
                          model: {
                            value: _vm.postForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "name", $$v)
                            },
                            expression: "postForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "17px" },
                        attrs: { label: "手机号码", prop: "mobile" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "70%" },
                          attrs: {
                            maxlength: "200",
                            clearable: "",
                            disabled: "",
                            size: "large",
                          },
                          model: {
                            value: _vm.postForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "mobile", $$v)
                            },
                            expression: "postForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "17px" },
                        attrs: { label: "邮箱", prop: "email" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "70%" },
                          attrs: {
                            placeholder: "请输入邮箱",
                            maxlength: "200",
                            clearable: "",
                            disabled: "",
                            size: "large",
                          },
                          model: {
                            value: _vm.postForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "email", $$v)
                            },
                            expression: "postForm.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "17px" },
                        attrs: { label: "证件类型", prop: "cardType" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "70%" },
                          attrs: {
                            maxlength: "200",
                            clearable: "",
                            disabled: "",
                            size: "large",
                          },
                          model: {
                            value: _vm.postForm.cardType,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "cardType", $$v)
                            },
                            expression: "postForm.cardType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "17px" },
                        attrs: { label: "证件号码", prop: "identifyId" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "70%" },
                          attrs: {
                            maxlength: "200",
                            clearable: "",
                            disabled: "",
                            size: "large",
                          },
                          model: {
                            value: _vm.postForm.identifyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "identifyId", $$v)
                            },
                            expression: "postForm.identifyId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "next-btn",
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.next },
          },
          [_vm._v(" 保存，下一步 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }