var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "anti-content" }, [
    _c("div", { staticClass: "title" }, [_vm._v("软件检测")]),
    _c("div", { staticClass: "monitor-content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "monitor-item", staticStyle: { width: "60%" } },
        [
          _c("div", { staticClass: "monitor-sub" }, [
            _c("span", [_vm._v("防作弊检测软件状态：")]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.noTool && !_vm.checkToolDialog && !_vm.checkLoading,
                    expression: "!noTool && !checkToolDialog && !checkLoading",
                  },
                ],
              },
              [
                _c("i", {
                  staticClass: "el-icon-check",
                  staticStyle: {
                    color: "rgb(147, 240, 4)",
                    "font-weight": "bold",
                  },
                }),
                _vm._v(" 检测通过 "),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.noTool && _vm.checkToolDialog && !_vm.checkLoading,
                    expression: "!noTool && checkToolDialog && !checkLoading",
                  },
                ],
              },
              [
                _c("i", {
                  staticClass: "el-icon-close",
                  staticStyle: {
                    color: "rgb(213, 15, 38)",
                    "font-weight": "bold",
                  },
                }),
                _vm._v(" 检测未通过 "),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.noTool && !_vm.checkLoading,
                    expression: "noTool && !checkLoading",
                  },
                ],
              },
              [
                _c("i", {
                  staticClass: "el-icon-magic-stick",
                  staticStyle: { "font-weight": "bold" },
                }),
                _vm._v(" 未启动 "),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkLoading,
                    expression: "checkLoading",
                  },
                ],
              },
              [
                _c("i", {
                  staticClass: "el-icon-loading",
                  staticStyle: { "font-weight": "bold" },
                }),
                _vm._v(" 检测中，请等待 "),
              ]
            ),
          ]),
          _c("br"),
          _c("div", { staticClass: "monitor-sub" }, [
            _vm._v("防作弊检测软件下载:"),
          ]),
          _c(
            "div",
            { staticClass: "monitor-sub" },
            [
              _c(
                "router-link",
                {
                  staticStyle: { color: "blue" },
                  attrs: {
                    to: "/downtool/windows",
                    tag: "a",
                    target: "_blank",
                  },
                },
                [_vm._v("点此下载")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "monitor-sub" }, [
            _vm._v(" 已下载，请先启动“考试系统防作弊检测工具”软件 "),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "btn-row" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "default", size: "large" },
            on: { click: _vm.last },
          },
          [_vm._v(" 上一步 ")]
        ),
        _c(
          "el-button",
          {
            staticClass: "next-btn",
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.startCheck },
          },
          [_vm._v(" 我已下载软件并启动，开始检测 ")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "default", size: "large" },
            on: { click: _vm.next },
          },
          [
            !_vm.noTool && !_vm.checkToolDialog && !_vm.checkLoading
              ? _c("span", [_vm._v("下一步")])
              : _c("span", [_vm._v("跳过，下一步")]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "monitor-item", staticStyle: { width: "20%" } },
      [
        _c("i", {
          staticClass: "el-icon-link",
          staticStyle: { "font-size": "150px" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }