<template>
  <div class="header-bg">
    <div class="header-inner container">
      <div class="col-logo">
        <div v-if="siteData.frontLogoUrl">
          <img
            :src="siteData.frontLogoUrl"
            :alt="siteData.siteName"
            style="height: 40px"
          >
        </div>
        <div v-else class="site-tt">
          {{ siteData.siteName }}
        </div>
      </div>
      <div class="col-menu" />
      <div class="remaining-time">
        <span class="remaining-time-text"> 开考倒计时：</span>
        <span class="remaining-time-time">
          {{ remainingTime }}
        </span>
      </div>
      <div class="right-user" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { CountdownMixin } from '@/views/login/components/mixin'

export default {
  name: 'DeviceHeader',
  mixins: [CountdownMixin],
  props: {
    examInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeIndex: '/web/index',
      msgTimer: null,
      unread: 0,
      id: ''
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'faceUrl',
      'avatarUrl',
      'realName',
      'siteData',
      'roleType',
      'unreadMsg'
    ])
  },
  watch: {},
  created() {},

  methods: {}
}
</script>
<style scoped>
.header-bg {
  height: 60px;
  background: #4377fb;
  display: flex;
  justify-content: center;
}

.header-inner {
  display: flex;
  align-items: center;
}

.right-user {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  line-height: 60px;
  align-items: center;
  max-width: 240px;
}

.site-tt {
  font-weight: 700;
  font-size: 22px;
  color: #eee;
  text-align: left;
}

.right-user a,
.right-user div {
  color: #efefef;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.right-user a:last-child {
  margin-right: 0px !important;
}

.right-user a:hover {
  color: #ffd550;
}

/deep/ .alink {
  color: #ffd550 !important;
}

/deep/ .alink:hover {
  color: #f94e3e !important;
}

.nav {
  color: #fff;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 10px 5px 10px;
}

.active {
  color: #000055;
  background: #ffd550;
}

.nav:hover {
  color: #000055;
  background: #ffd550;
}

.col-logo {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 250px;
}

.col-menu {
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/deep/ .top-avatar {
  text-align: right;
  display: flex;
  align-items: center;
  margin-right: 5px !important;
  cursor: pointer;
}

/deep/ .top-avatar div {
  display: flex;
  align-items: center;
  margin-right: -10px !important;
}

/deep/ .top-avatar img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  font-weight: 700;
  font-size: 16px;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-weight: 700;
    font-size: 16px;
    padding: 0px 5px;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
    font-weight: 700;
    padding: 0px 5px;
  }
}

.remaining-time {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.remaining-time-text {
  color: white;
}
.remaining-time-time {
  color: white;
  /* color: red; */
}
</style>
