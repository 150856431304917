var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "300px" },
              attrs: {
                size: "small",
                placeholder: "搜索公告",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "公告名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("detail-link", {
                        attrs: {
                          id: scope.row.id,
                          title: scope.row.title,
                          to: "NoticeDetail",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "发布人",
                align: "center",
                prop: "createBy_dictText",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "发布时间", align: "center", prop: "createTime" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }