<template>
  <div class="content">
    <div class="title-row">
      <div class="title">在线考试 设备检测</div>
      <div class="title-explain">
        为保证在线考试顺利进行，请按系统提示进行设备检测
      </div>
    </div>

    <el-card v-if="currentStep === 0" class="box-card">
      <div class="title">摄像头检测</div>
      <!-- <div class="select-camera">
        <div class="name">摄像头选择</div>
        <el-select
          v-model="value"
          placeholder="请选择摄像头"
          style="width: 70%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div> -->
      <div class="box-img">
        <video-presenter
          v-if="currentStep === 0"
          :height="280"
          :width="375"
          :cam-type="'side'"
          :paperid="paperid"
          :device-id="deviceId"
        />
      </div>
      <!-- <el-image :src="imgUrl" :fit="'scale-down'" class="box-img" /> -->
      <div class="title-explain">是否可以清楚的看到自己？</div>
      <div class="btn-row">
        <el-button @click="changeCurrent(false)">看不见</el-button>
        <el-button
          type="primary" @click="changeCurrent(true)"
        >看得见</el-button
        >
      </div>
    </el-card>
    <el-card v-if="currentStep === 1" class="box-card">
      <div class="title">麦克风检测</div>
      <!-- <div class="select-camera" style="margin-bottom: 20px">
        <div class="name">麦克风选择</div>
        <el-select
          v-model="value"
          placeholder="请选择摄像头"
          style="width: 70%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div> -->
      <div class="title-explain" style="text-align: left; margin-bottom: 20px">
        对着麦克风说 哈喽 试试~
      </div>
      <recorder ref="record" v-model="recordUrl" @success="recordBack" />
      <div class="micro-content">
        <!-- <el-slider
          v-for="i in 15"
          :key="i"
          v-model="value"
          vertical
          height="40px"
          disabled
          style="width: 10px"
        /> -->
      </div>
      <!-- <div class="title-explain" style="margin-bottom: 50px">
        是否可以看到音量图标跳动？
      </div> -->
      <div class="btn-row">
        <el-button @click="changeCurrent(false)">不能录制</el-button>
        <el-button type="primary" @click="changeCurrent(true)">
          能录制
        </el-button>
      </div>
    </el-card>
    <el-card v-if="currentStep === 2" class="box-card">
      <div class="title">扬声器检测</div>
      <!-- <div class="select-camera" style="margin-bottom: 20px">
        <div class="name">扬声器选择</div>
        <el-select
          v-model="value"
          placeholder="请选择扬声器"
          style="width: 70%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div> -->

      <audio v-if="recordUrl" :src="recordUrl" controls class="audio-content" />
      <audio v-else :src="voiceUrl" controls class="audio-content" />

      <div class="title-explain" style="margin-bottom: 40px">
        请点击上方播放按钮，打开电脑声音
      </div>
      <div class="btn-row">
        <el-button @click="changeCurrent(false)">听不见</el-button>
        <el-button
          type="primary" @click="changeCurrent(true)"
        >听得见</el-button
        >
      </div>
    </el-card>
    <el-card v-if="currentStep === 3" class="box-card">
      <!-- <div class="title">网络检测</div> -->
      <div class="title">检测结果</div>

      <el-descriptions
        :column="1"
        :label-style="{ 'text-align': 'right', width: '100px' }"
        :content-style="{ width: '280px', 'margin-left': '20px' }"
        border
        style="margin-bottom: 30px"
      >
        <el-descriptions-item v-if="config.osName" label="系统 ">
          {{ config.osName }}
          {{ config.osVersion }}
        </el-descriptions-item>
        <el-descriptions-item v-if="config.browserName" label="浏览器 ">
          <!-- Chrome 119.0.0.0 -->
          {{ config.browserName }}
          {{ config.browserVersion }}
        </el-descriptions-item>
        <el-descriptions-item label="摄像头 ">
          <span v-if="checkList.camera" class="blue-text"> ✔</span>
          <span v-else class="red-text"> X</span>
        </el-descriptions-item>
        <el-descriptions-item label="麦克风 ">
          <span v-if="checkList.microphone" class="blue-text"> ✔</span>
          <span v-else class="red-text"> X</span>
        </el-descriptions-item>
        <el-descriptions-item label="扬声器 ">
          <span v-if="checkList.speaker" class="blue-text"> ✔</span>
          <span v-else class="red-text"> X</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="视频监控 ">
          <span class="blue-text"> ✔</span>
        </el-descriptions-item>
        <el-descriptions-item label="屏幕共享 ">
          <span class="blue-text"> ✔</span>
        </el-descriptions-item> -->
        <!-- <el-descriptions-item label="网络延时 "> 10ms </el-descriptions-item> -->
        <el-descriptions-item v-if="config.downlink" label="上行网络 ">
          <span class="blue-text">{{ config.downlink }}（Mbps）</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="下行网络 ">
          <span class="blue-text">极佳</span>
        </el-descriptions-item> -->
        <el-descriptions-item v-if="config.effectiveType" label="网络类型 ">
          <span class="blue-text">{{ config.effectiveType }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <div class="btn-row">
        <el-button @click="changeCurrent">重新检测</el-button>
        <el-button type="primary" @click="back">返回登录页</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import VideoPresenter from '@/components/VideoPresenter'
export default {
  name: 'CheckContent',
  components: { VideoPresenter },
  data() {
    return {
      deviceId: null,
      paperid: '',
      currentStep: 0,
      options: [
        {
          value: 0,
          label: '默认'
        },
        {
          value: '1',
          label: '设备1'
        },
        {
          value: '2',
          label: '设备2'
        }
      ],
      value: 0,
      imgUrl: '',
      voiceUrl: '',
      recordUrl: '',
      checkList: {
        camera: false,
        microphone: false,
        speaker: false
      },
      config: {
        browserName: '', // 浏览器类型
        browserVersion: '', // 浏览器版本
        osName: '', // 系統类型
        osVersion: '', // 系統版本
        downlink: '',
        effectiveType: ''
      }
    }
  },
  created() {
    this.initConfig()
  },
  methods: {
    resetCheckList() {
      this.checkList = {
        camera: false,
        microphone: false,
        speaker: false
      }
    },
    // 获取配置
    initConfig() {
      const userAgentStr = navigator.userAgent
      // console.log('获取设备信息', userAgentStr)
      // console.log('获取网络信息', navigator.connection)

      const { downlink = '', effectiveType = '' } = navigator.connection || {}
      this.config.downlink = downlink
      this.config.effectiveType = effectiveType

      const browserReg = {
        Chrome: /Chrome/,
        IE: /MSIE/,
        Firefox: /Firefox/,
        Opera: /Presto/,
        Safari: /Version\/([\d.]+).*Safari/,
        360: /360SE/,
        QQBrowswe: /QQ/
      }
      for (const key in browserReg) {
        if (browserReg[key].test(userAgentStr)) {
          this.config.browserName = key
          if (key === 'Chrome') {
            this.config.browserVersion = userAgentStr
              .split('Chrome/')[1]
              .split(' ')[0]
          } else if (key === 'IE') {
            this.config.browserVersion = userAgentStr
              .split('MSIE ')[1]
              .split(' ')[1]
          } else if (key === 'Firefox') {
            this.config.browserVersion = userAgentStr.split('Firefox/')[1]
          } else if (key === 'Opera') {
            this.config.browserVersion = userAgentStr.split('Version/')[1]
          } else if (key === 'Safari') {
            this.config.browserVersion = userAgentStr
              .split('Version/')[1]
              .split(' ')[0]
          } else if (key === '360') {
            this.config.browserVersion = ''
          } else if (key === 'QQBrowswe') {
            this.config.browserVersion = userAgentStr
              .split('Version/')[1]
              .split(' ')[0]
          }
        }
      }

      const deviceReg = {
        iPhone: /iPhone/,
        iPad: /iPad/,
        Android: /Android/,
        Windows: /Windows/,
        Mac: /Macintosh/
      }

      for (const key in deviceReg) {
        if (deviceReg[key].test(userAgentStr)) {
          this.config.osName = key
          if (key === 'Windows') {
            this.config.osVersion = userAgentStr
              .split('Windows NT ')[1]
              .split(';')[0]
          } else if (key === 'Mac') {
            this.config.osVersion = userAgentStr
              .split('Mac OS X ')[1]
              .split(')')[0]
          } else if (key === 'iPhone') {
            this.config.osVersion = userAgentStr
              .split('iPhone OS ')[1]
              .split(' ')[0]
          } else if (key === 'iPad') {
            this.config.osVersion = userAgentStr
              .split('iPad; CPU OS ')[1]
              .split(' ')[0]
          } else if (key === 'Android') {
            this.config.osVersion = userAgentStr
              .split('Android ')[1]
              .split(';')[0]
            this.config.deviceName = userAgentStr
              .split('(Linux; Android ')[1]
              .split('; ')[1]
              .split(' Build')[0]
          }
        }
      }
    },
    // 录音回调
    recordBack() {
      console.log(this.recordUrl)
    },
    changeCurrent(isWork = false) {
      if (this.currentStep < 3) {
        if (isWork) {
          switch (this.currentStep) {
            case 0:
              this.checkList.camera = true
              break
            case 1:
              this.checkList.microphone = true
              break
            case 2:
              this.checkList.speaker = true
              break
            default:
              console.log('错误')
          }
        }
        this.currentStep = this.currentStep + 1
      } else {
        this.resetCheckList()
        this.currentStep = 0
      }
    },
    back() {
      const { id = '' } = this.$route.params
      this.resetCheckList()
      this.$router.push({ name: 'LoginExam', params: { id }})
    }
  }
}
</script>
<style scoped>
/* .content {
  height: 100%;
  margin: 60px auto 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
} */

.content {
  height: calc(100vh - 120px);
  min-height: 700px;
  overflow: auto;
  background: url('../../../../assets/web/bg.png') #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* width: 100vw; */
}

.title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 25px;
}

.box-card {
  min-width: 835px;
  min-height: 575px;
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.select-camera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.box-img {
  width: 375px;
  height: 280px;
  margin-bottom: 20px;
}

.title-explain {
  color: #7e6f72;
  text-align: center;
  margin-bottom: 30px;
}

.micro-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-left: -10px;
}

.audio-content {
  margin-bottom: 10px;
}

.blue-text {
  font-weight: 600;
  color: #7ca5ed;
}
.red-text {
  font-weight: 600;
  color: #dc362e;
}

.btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}
</style>
