<template>
  <div class="app-container">
    <div class="video-box">
      <el-image :src="url" :fit="'fill'" style="width: 100%; height: 400px"/>
    </div>
    <div class="content">
      <div class="tip-status">参考上图所示，手机架设在您斜后方，确保可以同时拍到您的上半身及电脑屏幕。</div>
      <div class="tip-status">点击[开始摄像]按钮后，如出现授权提示，请选择允许授权。</div>
      <div class="tip-status">如果[开始摄像]不成功，点击右上角3个点，选择在浏览器中打开。</div>
      <div class="tip">请设置手机的休眠时间足够长，避免自动息屏</div>
      <el-select v-model="deviceId" size="mini" style="width: 100%;">
        <el-option v-for="item in cameras" :key="item.deviceId" :value="item.deviceId" :label="item.label" />
      </el-select>
      <el-button type="primary" style="width: 100%;" @click="goStart">开始摄像</el-button>
    </div>
  </div>
</template>

<script>
import VideoPresenter from '../../components/VideoPresenter'
import imgUrl from '../../assets/images/cam-bg.png'
import { Notification } from 'element-ui'

export default {
  name: 'SelectCamera',
  components: { VideoPresenter },
  data() {
    return {
      camerasListEmitted: false,
      cameras: [],
      // 摄像头ID
      deviceId: null,
      noDevice: false,
      name: '',
      url: imgUrl,
      isTest: false
    }
  },
  mounted() {
    // this.deviceId = '111'
    const { name, isTest } = this.$route.query
    console.log(isTest, 'isTest')
    this.isTest = isTest
    this.name = name
    this.loadCameras()
  },
  methods: {
    loadCameras() {
      if (navigator.mediaDevices || navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {
            navigator.mediaDevices
              .enumerateDevices()
              .then(deviceInfos => {
                console.log(deviceInfos, '@@@@')
                for (let i = 0; i !== deviceInfos.length; ++i) {
                  const deviceInfo = deviceInfos[i]
                  if (deviceInfo.kind === 'videoinput') {
                    this.cameras.push(deviceInfo)
                  }
                }

                if (this.cameras.length === 0) {
                  this.noDevice = true
                }
              })
              .then(() => {
                if (!this.camerasListEmitted) {
                  if (this.cameras.length > 0) {
                    this.deviceId = this.cameras[0].deviceId
                  } else {
                    this.noDevice = true
                  }
                  // this.$emit('cameras', this.cameras)
                  this.camerasListEmitted = true
                }
              })
              .catch(error => this.$emit('notsupported', error))
          }).catch((e) => {
            Notification.error({
              title: '提示信息',
              message: '请允许访问设备的视频音频权限'
            })
          })
      } else {
        Notification.error({
          title: '提示信息',
          message: '浏览器不支持视频和音频，请更换浏览器'
        })
      }
    },
    goStart() {
      if (this.deviceId) {
        if (this.isTest) {
          this.$router.push(`/across/mobilecamera?deviceId=${this.deviceId}&paperid=${this.name}&isTest=${true}`)
        } else {
          this.$router.push(`/across/mobilecamera?deviceId=${this.deviceId}&paperid=${this.name}`)
        }
      } else {
        Notification.error({
          title: '提示信息',
          message: '请先选择可用的手机摄像头再进行摄像'
        })
      }
    }
  }
}
</script>
<style scoped>
.app-container {
    width: 100%;
    padding: 0;
}

.video-box {
    width: 100%;
    height: 400px;
}

.content {
    width: 100%;
    padding: 10px;
}

.box-card {
    width: 100%;
    background-color: rgb(253, 244, 237);
    color: rgb(225, 184, 84);
    margin: 20px 0;
}

.tip,
.tip-status {
    width: 100%;
    margin: 10px 0;
    /* font-weight: bold; */
}

.tip-status {
    /* color: rgb(105, 216, 163); */
}

.tip {
    color: red;
}
</style>
