var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.leaveDialog,
            "before-close": _vm.leaveBack,
            title: "注意",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.leaveDialog = $event
            },
          },
        },
        [
          _c("span", { staticClass: "alert-text" }, [
            _vm._v(
              "您已经离开" +
                _vm._s(_vm.leaveTimes) +
                "次，离开" +
                _vm._s(_vm.leaveCount) +
                "次将自动交卷"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.leaveBack } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.msgDialog,
            "before-close": _vm.leaveBack,
            title: "提示信息",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.msgDialog = $event
            },
          },
        },
        [
          _c("span", { staticClass: "alert-text" }, [
            _vm._v(
              "本场考试已开启切屏监控，离开" +
                _vm._s(_vm.leaveCount) +
                "次将会强制交卷，请诚信考试！"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.leaveBack } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }