var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        "destory-on-close": true,
        title: "Excel 模板导入",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.dialogVisible
        ? _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-alert",
                {
                  staticClass: "alert-body",
                  attrs: {
                    closable: false,
                    title: "从Excel 模板导入的考生将覆盖相同的考生",
                    type: "info",
                    "show-icon": "",
                  },
                },
                [
                  _c("div", { attrs: { slob: "description" } }, [
                    _c("span", { staticStyle: { display: "inline" } }, [
                      _vm._v(" 只能上传 xls / xlsx 文件，且不超过 5MB "),
                    ]),
                    _c(
                      "span",
                      { staticClass: "link-text" },
                      [
                        _vm._v(" 模板下载地址："),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/template/考生导入模板.xlsx",
                                  target: "_black",
                                },
                              },
                              [_vm._v(" 《 考生导入模板.xlsx 》 ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.actionUrl,
                    "auto-upload": false,
                    limit: 10,
                    data: _vm.uploadData,
                    headers: _vm.header,
                    "on-exceed": _vm.onExceed,
                    "before-upload": _vm.onBeforeUpload,
                    "on-success": _vm.onUploadSuccess,
                    "on-error": _vm.onUploadError,
                    multiple: "",
                    accept: ".xls,.xlsx",
                    drag: "",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmToUpload } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }