var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "qu-box",
      class: { "sub-box": _vm.sub },
      attrs: { id: _vm.quData.id },
    },
    [
      _c("div", { staticClass: "qu-item" }, [
        _c("div", { class: _vm.sub ? "sub" : "num" }, [
          _vm._v(_vm._s(_vm.index + 1)),
        ]),
        _c("div", { staticClass: "content" }, [
          _vm.showType
            ? _c("span", [
                _vm._v("【" + _vm._s(_vm.quData.quType_dictText) + "】"),
              ])
            : _vm._e(),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.formatRichText(_vm.quData.content)
              ),
            },
          }),
        ]),
      ]),
      _vm.quData.quType === "1" ||
      _vm.quData.quType === "2" ||
      _vm.quData.quType === "3" ||
      _vm.quData.quType === "5"
        ? _c(
            "div",
            { staticClass: "qu-answer" },
            _vm._l(_vm.quData.answerList, function (item, index) {
              return _c("div", { key: item.id, staticClass: "item" }, [
                _c("div", { staticClass: "tag" }, [
                  _vm._v(_vm._s(item.abc || item.tag)),
                ]),
                _vm.showAnswer || _vm.quData.quType !== "5"
                  ? _c("div", { staticClass: "content" }, [
                      _c("div", [_vm._v(_vm._s(item.content))]),
                      item.image
                        ? _c("div", [
                            _c("img", {
                              staticStyle: { "max-width": "40vw" },
                              attrs: { src: item.image },
                            }),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                item.isRight && _vm.showAnswer
                  ? _c("div", { staticClass: "right" }, [
                      _c("i", {
                        staticClass: "el-icon-success",
                        staticStyle: { color: "#1aac1a" },
                      }),
                      _vm._v(" 答案 "),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.showAnalysis &&
      ((_vm.mode !== 3 && _vm.quData.quType !== "99") || _vm.quData.analysis)
        ? _c("div", { staticClass: "as-box" }, [
            _vm.mode !== 3 && _vm.quData.quType !== "99"
              ? _c("div", [
                  _vm.quData.quType != "4" && _vm.quData.quType != "99"
                    ? _c("div", [
                        _c("div", { staticClass: "right-line" }, [
                          _c("i", {
                            class: _vm.quData.isRight
                              ? "right el-icon-success"
                              : "error el-icon-error",
                          }),
                          _vm.quData.isRight
                            ? _c("span", [_vm._v("答对了")])
                            : _c("span", [_vm._v("答错了")]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(" 学员得分： "),
                    _vm.quData.quType == "4" && _vm.paperState == 1
                      ? _c("span", [_vm._v(" 待阅卷后显示得分 ")])
                      : _c("span", [
                          _vm._v(" " + _vm._s(_vm.quData.actualScore) + " "),
                          !_vm.quData.isRight && _vm.quData.actualScore > 0
                            ? _c("span", [_vm._v("（部分得分）")])
                            : _vm._e(),
                        ]),
                  ]),
                  _vm.quData.quType == "1" ||
                  _vm.quData.quType == "2" ||
                  _vm.quData.quType == "3"
                    ? _c("div", [
                        _vm._v(" 学员答案： "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.userAnswer(_vm.quData.answerList))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.quData.quType == "4"
                    ? _c("div", [
                        _vm._v(" 学员答案："),
                        _c("span", {
                          staticClass: "asp",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.formatRichText(
                                _vm.quData.answer
                              )
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.quData.media != null && _vm.quData.media !== ""
                    ? _c("div", [
                        _c("audio", {
                          attrs: { src: _vm.quData.media, controls: "" },
                        }),
                      ])
                    : _vm._e(),
                  _vm.quData.quType == "5"
                    ? _c(
                        "div",
                        [
                          _vm._v(" 学员答案： "),
                          _vm._l(_vm.quData.answerList, function (a) {
                            return [
                              a.answer
                                ? _c("div", { key: a.id }, [
                                    _vm._v(_vm._s(a.answer)),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.quData.analysis
              ? _c("div", [
                  _vm.sub
                    ? _c("div", [_vm._v(" 题目解析： ")])
                    : _c("div", [_vm._v(" 大题解析： ")]),
                  _c("div", {
                    staticClass: "asp",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.formatRichText(_vm.quData.analysis)
                      ),
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }