<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="选择招聘项目"
    width="90%"
    @close="handleClose"
  >
    <div class="app-container">
      <data-table-job
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
        @data-changed="handleDataChange"
      >
        <template #filter-content>
          <el-input
            v-model="listQuery.filter.name"
            size="small"
            style="width: 200px"
            placeholder="岗位名称"
            class="filter-item"
          />
          <dic-list-select
            v-model="listQuery.filter.state"
            dic-code="user_state"
            class="filter-item"
            title="招聘项目"
            style="width: 150px; margin-bottom: 0px"
          />
          <depart-tree-select
            v-model="listQuery.filter.deptCode"
            class="filter-item"
            title="所属单位"
            style="width: 200px"
          />
          <dic-list-select
            v-model="listQuery.filter.state"
            dic-code="user_state"
            class="filter-item"
            title="状态"
            style="width: 150px; margin-bottom: 0px"
          />
          <dic-list-select
            v-model="listQuery.filter.time"
            dic-code="user_state"
            class="filter-item"
            title="发布时间"
            style="width: 150px; margin-bottom: 0px"
          />
          <el-button
            :disabled="!(selectedList && selectedList.length > 0)"
            :loading="loading"
            type="primary"
            class="filter-item"
            size="small"
            @click="handleConfirm"
          >
            {{ selectedLabel }}
          </el-button>
          <el-button
            :loading="loading"
            :disabled="!listData.totalLine || listData.totalLine === 0"
            type="primary"
            class="filter-item"
            size="small"
            @click="handleConfirm"
          >
            {{ `操作全部筛选岗位(${listData.totalLine || 0})` }}
          </el-button>
        </template>

        <template #data-columns>
          <el-table-column label="岗位名称" align="left" prop="name" />

          <el-table-column
            label="所属单位"
            align="left"
            prop="enterprise.name"
          />

          <el-table-column label="所属项目" align="left" prop="project.name" />

          <el-table-column
            label="候选人"
            align="right"
            width="80"
            prop="applyNumbers"
          />
          <el-table-column
            label="已导入"
            align="right"
            width="80"
            prop="applyNumbers"
          />
          <el-table-column
            label="招聘方式"
            align="center"
            width="100"
            prop="careerRecruitments"
          />

          <el-table-column
            width="80"
            label="状态"
            align="center"
            prop="status"
          />

          <el-table-column
            label="发布时间"
            width="150"
            align="center"
            prop="publishDate"
          />
        </template>
      </data-table-job>
    </div>
  </el-dialog>
</template>

<script>
import { importqxCareers } from '../../../../../../api/remoteTest/remoteTest'
import { modalTitle } from '../../../../../../data/const'
import { common } from './common'

export default {
  name: 'ProjectDialog',
  mixins: [common],
  props: {
    examId: String
  },
  data() {
    return {
      listData: {},
      selectedLabel: '请勾选岗位',
      listQuery: {
        view: 'openCareerWrapper',
        filter: {
          examId: this.examId,
          projectId: '',
          name: '',
          recruitmentId: '',
          status: '',
          publishDateStart: '',
          publishDateEnd: ''
          // excludedCareerIds: [
          //   'a70c794d-cee3-4635-8cca-d6345a93941c',
          //   'rtyl22008'
          // ]
        },
        page: {
          pageNo: 1,
          pageSize: 100
        }
      },
      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        keyId: 'id',
        // 列表请求URL
        listUrl: '/chr/openapi/recruitment/career/search'
      }
    }
  },

  watch: {
    selectedList: {
      handler(val) {
        this.selectedLabel = '操作选择结果(' + val.length + ')'
      }
    },
    examId: {
      handler(val) {
        this.listQuery.filter.examId = val
      },
      immediate: true
    }
  },

  methods: {
    // 列表数据改变回调
    handleDataChange(data) {
      // console.log('列表数据改变回调', data)
      this.listData = data
    },

    // 导入招聘系统职位
    importCareersInSys(qData) {
      this.loading = true
      importqxCareers(qData).then((res) => {
        // console.log('导入职位', res)
        this.loading = false
        if (res) {
          // console.log('导入职位sss', res)
          this.$notify({
            title: modalTitle,
            message: '导入职位成功！',
            type: 'success'
          })
        }

        // 关闭
        this.handleClose()
      })
    },

    // 操作选中的岗位
    handleConfirm() {
      if (this.selectedList === null || this.selectedList.length === 0) {
        this.$notify({
          title: modalTitle,
          message: '请至少勾选一个岗位！',
          type: 'warning'
        })
        return
      }

      const query = {
        batchType: 1,
        selected: this.selectedList,
        filter: {
          ...this.listQuery.filter
        }
      }

      this.importCareersInSys(query)

      this.$emit('select', this.selectedList, this.selectedObjs)
    },
    // 操作全部筛选的岗位
    handleWorkAll() {
      const query = {
        batchType: 2,
        filter: {
          ...this.listQuery.filter
        }
      }

      this.importCareersInSys(query)
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
}
</style>
