<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
    title="人脸认证"
    width="540px"
  >
    <div class="face-box">
      <div v-if="errorcurrent > 0 && errorcurrent < 3" class="errorTip">
        <div>1、请核对您的姓名、身份证号信息是否正确。</div>
        <div>2、确保您的面部被足够的光线照亮，面部清晰，以便系统进行识别。</div>
        <div>
          3、请确保您的面部无遮挡，以便准确识别。摘下帽子、眼镜或其他可能遮挡脸部的物品。
        </div>
        <div>4、请保持面部处于画面中央位置，且面部表情稳定，以便准确识别。</div>
        <div>5、请调整您的识别角度，尽量与摄像头保持平视。</div>
      </div>

      <div v-if="errorcurrent >= 3" class="errorTip">
        <div>
          很抱歉，您连续几次识别都未通过，请再次尝试。如果仍然失败，请联系管理员或技术支持，核对系统录入信息是否正确。
        </div>
      </div>
      <tracking-cam
        v-if="dialogVisible"
        ref="cam"
        :tracking="true"
        @tracking-error="showManual"
        @tracked="handleTracked"
      />

      <!-- <div v-if="faceInfo.faceToken!=null && faceInfo.faceToken!==''" class="scanTip">
        <div>姓名：{{ faceInfo.realName }}</div>
        <div>身份证号：{{ faceInfo.idCard }}</div>
        <div>识别成功，请继续下一步操作</div>
      </div> -->
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button
        v-if="manualVisible"
        :loading="isloading"
        type="primary"
        @click="handleManual"
      >拍照识别</el-button
      >
      <el-button type="danger" @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { post } from '@/utils/request'

export default {
  name: 'FaceCheckDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      faceInfo: {
        id: '',
        realName: '',
        idCard: ''
      },
      manualVisible: true,
      errorcurrent: 0,
      isloading: false
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {
    // 关闭窗口
    handleClose() {
      this.$message.info('窗口已关闭，若要重新识别请刷新页面！')
      this.$emit('update:visible', false)
    },

    // 人工拍照
    showManual() {
      this.manualVisible = true
    },

    // 进行手工拍照
    handleManual() {
      this.$refs.cam.handleCapture()
    },

    // 拍照
    handleTracked(base64) {
      this.isloading = true
      // 附加base64图片
      const params = this.params
      this.$set(params, 'base64', base64)
      // if (this.errorcurrent<2) {
      post(this.url, params)
        .then((res) => {
          this.isloading = false
          this.faceInfo = res.data
          if (this.faceInfo.pass) {
            this.successBack()
          } else {
            this.failBack(this.faceInfo.failMsg)
          }
        })
        .catch(this.failBack)
      // } else {
      //   this.isloading = false
      //   // this.$message.error('人脸比对失败超过三次！')
      //   this.$emit('error', '人脸比对失败超过三次！')
      // }
    },

    // 成功回调
    successBack() {
      this.$message.success('人脸比对成功！')
      this.$emit('success', this.faceInfo)
      setTimeout(() => {
        this.$emit('update:visible', false)
      }, 2000)
    },

    // 识别失败
    failBack(msg) {
      this.$emit('fail')
      this.$refs.cam.reTrack()
      this.errorcurrent += 1
      // 显示消息
      if (msg) {
        // this.$message.error(msg)
        this.$emit('error', msg)
      }
    }
  }
}
</script>

<style scoped>
.face-box {
  position: relative;
  width: 100%;
  height: auto;
}

.scanTip {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #1aac1a;
}

.errorTip {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #b9553f;
}
</style>
