<template>
  <div>
    <div class="container">
      <div class="header">{{ stat.title }}</div>
      <div class="controls-box">
        <el-row :gutter="10" style="width: 30%">
          <el-col :span="3">
            <div class="controls-left-tit">机位:</div>
          </el-col>
          <el-col :span="7">
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option v-for="item in options_1" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-input v-model="listQuery.params.code" placeholder="搜索编号" size="small" />
          </el-col>
        </el-row>
        <el-row :gutter="5" style="width: 60%;display:flex;justify-content: space-between;">
          <div style="display:flex;">
            <div style="line-height: 32px;margin-right:10px">
              <el-checkbox v-model="listQuery.params.online" size="small" >在线</el-checkbox>
            </div>
            <div style="line-height: 32px">
              <el-checkbox v-model="listQuery.params.offline" size="small" >离线</el-checkbox>
            </div>
          </div>
          <el-pagination :current-page="listQuery.current" :page-size="20" :total="total" layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" />
        </el-row>
      </div>
      <div class="supervisory-control-list">
        <el-card v-for="(item, index) in videoList" :key="index" :body-style="card_style_body" :style="card_style" shadow="always">
          <div class="supervisory-control-item">
            <div class="video-box">
              <el-row>
                <el-col :span="16">
                  <div class="video-1">
                    <video-viewer :height="150" :width="199" :online="item.online" :cam-type="'facade'" :paperid="item.id" />
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="video-2">
                    <video-viewer :height="150" :width="99" :online="item.online" :cam-type="'side'" :paperid="item.id" />
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="5" class="supervisory-control-item-footer">
              <el-col :span="4">
                <div class="supervisory-control-status">{{ item.realName }}</div>
              </el-col>
              <el-col :span="14">
                <div class="supervisory-control-tit">答题进度:{{ item.answerCount }}/{{ item.quCount }}</div>
              </el-col>
              <el-col :span="4">
                <div class="supervisory-control-btn">
                  <!-- <i v-show="!item.ismuted" class="el-icon-microphone" @click="changeMuted(index)" />
                  <i v-show="item.ismuted" class="el-icon-turn-off-microphone" @click="changeMuted(index)" /> -->
                  <el-popover placement="right" trigger="click">
                    <div class="forewarning-btn-box">
                      <!-- <el-button class="forewarning-btn" @click="handleWarning(data.id, 21)">确认作弊</el-button> -->
                      <el-button class="forewarning-btn" @click="handleWarning(item.id, 33)">疑似作弊</el-button>
                      <el-button class="forewarning-btn" @click="handleWarning(item.id, 32)">红牌警告</el-button>
                      <!-- <el-button class="forewarning-btn" @click="handleWarning(data.id, 32)">黄牌警告</el-button> -->
                    </div>

                    <i slot="reference" class="el-icon-warning"/>
                  </el-popover>
                  <i class="el-icon-full-screen" @click="watch(item)"/>

                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog :visible="warningInfoVisible" title="预警信息" width="50%">
      <el-descriptions :column="4" class="margin-top" title="" direction="vertical">
        <el-descriptions-item label="考生编号">{{
          warnInfo.studentCode
        }}</el-descriptions-item>
        <el-descriptions-item label="预警等级">
          <span v-show="warnInfo.alarmLevel == '31'">在线警示</span>
          <span v-show="warnInfo.alarmLevel == '32'">红牌警告</span>
          <span v-show="warnInfo.alarmLevel == '33'">疑似作弊</span>
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="发起时间">{{
          warnInfo.createTime
        }}</el-descriptions-item>
        <el-descriptions-item :span="4" label="描述">
          {{ warnInfo.msg.content }}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label="照片">
          <div v-show="warnInfo.pictureList&&warnInfo.pictureList.length>0" class="wranImg_list">
            <div v-for="(img, index) in warnInfo.pictureList" :key="index" class="wranImg_item">
              <el-image :src="img.facadeImageUrl" style="width: 100px; height: 100px" fit="fill" />
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="warningInfoVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="warningVisible" :close-on-click-modal="false" title="预警提示信息" width="500px" @close="handleWarningClose">
      <el-input v-model="warningmessage" type="textarea" />

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleWarningClose">取 消</el-button>
        <el-button :loading="warningLoading" type="primary" @click="forewarning">确 定</el-button>
      </div>
    </el-dialog>
    <monitoring-details :datainfo="detailInfo" :detail-visible="detailVisible" @detailClose="detailClose" @handleWarning="handleWarning" />
  </div>
</template>

<script>
import {
  batchMsg,
  singleMsg,
  httpwatchStat,
  chiefForceHand,
  forceHandAll,
  httppaperlarm,
  httpalarmpaging
} from '@/api/exam/watch'
import { postData } from '@/api/common'
export default {
  name: 'LargeScreen',
  components: {},
  data() {
    return {
      card_style: {
        width: ' 19%',
        margin: '5px',
        height: '20vh',
        backgroundColor: 'rgb(10, 25, 60)',
        border: '1px solid rgba(255, 255,255,.2)'
      },
      card_style_body: {
        backgroundColor: 'rgb(10, 25, 60)',
        padding: 0
      },
      options_1: [
        {
          value: '1',
          label: '双机位'
        }
      ],
      total: 0,
      currentPage: 1,
      examId: '',
      value: '',
      videoList: [],
      autoPage: false,
      refreshTime: 10,
      seconds: 10,
      paused: false,

      // 统计信息
      stat: {
        requireCount: 0,
        processCount: 0,
        handCount: 0,
        missCount: 0
      },
      listQuery: {
        current: 1,
        size: 20,
        params: {
          remoteExamId: this.examId,
          // invigilatorId: '1',
          handState: 0,
          online: false,
          offline: false
          // name: '',
          // code: '',
          // state: ''
        }
      },
      options: {
        lineCount: 8,
        // 列表请求URL
        // listUrl: '/api/monitor/paper/paging'
        listUrl: '/api/monitor/paper/watch-paging'
      },

      // 自动翻页
      pageTimer: null,
      showTimer: null,

      handVisible: false,
      handForm: {},
      handFormRules: {
        exState: [{ required: true, message: '考卷状态必须选择！' }],
        message: [{ required: true, message: '收卷理由必须填写！' }]
      },
      handLoading: false,
      warningVisible: false,
      warningmessage: null,
      warningLoading: false,
      warningStudentList: [],
      warningInfoVisible: false,
      warnInfo: {
        studentCode: '',
        alarmLevel: '',
        createTime: '',
        msg: {
          content: ''
        },
        pictureList: []
      },
      detailVisible: false,
      detailInfo: {},
      listLoading: false,
      timer: null
    }
  },
  watch: {
    examId: {
      handler(val) {
        this.listQuery.params.examId = val
        this.fetchStat()
        this.getAlarmPaging()
      }
    },

    // 自动翻页
    autoPage: {
      handler() {
        this.handleRefresh()
      }
    },
    refreshTime: {
      handler() {
        this.handleRefresh()
      }
    }
  },
  created() {
    // 获取考试信息
    this.examId = this.$route.query.examId
    this.listQuery.params.examId = this.examId
    this.listQuery.params.remoteExamId = this.examId

    // 获取统计信息
    this.fetchStat()
    // this.getAlarmPaging()
    this.getList()
    this.timer = setInterval(() => {
      this.getList()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.pageTimer)
    clearInterval(this.showTimer)
    clearInterval(this.timer)
  },
  mounted() {
    // this.$refs.video1.src = 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
    // this.$refs.video2.src = 'http://vjs.zencdn.net/v/oceans.mp4'
    // for (let i = 0; i < 20; i++) {
    //   const newObj = {
    //     v1_url: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
    //     v2_url: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
    //     ismuted: true,
    //     status: '考试中',
    //     name: `王${i}哈`,
    //     school: `第${i}大学`
    //   }
    //   this.videoList.push(newObj)
    // }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.listQuery.current = val
      this.getList()
    },
    changeMuted(index) {
      this.videoList[index].ismuted = !this.videoList[index].ismuted
    },
    // 获取预警列表
    async getAlarmPaging() {
      const subData = {
        current: 1,
        size: 100,
        params: {
          remoteExamId: this.examId
          // invigilatorId: '1',
          // handState: 0
          // name: '',
          // code: '',
          // state: ''
        }
      }
      const res = await httpalarmpaging(subData)
      if (res.success) {
        this.warningStudentList = res.data.records
      }
    },
    getList() {
      this.listLoading = true
      this.listQuery.t = new Date().getTime()
      postData(this.options.listUrl, this.listQuery).then(response => {
        this.videoList = response.data.records
        this.total = response.data.total
        this.listLoading = false
      })
    },
    // 获取统计信息
    fetchStat() {
      if (!this.examId) {
        return
      }
      httpwatchStat({ id: this.examId }).then((res) => {
        this.stat = res.data
        // 同步统计数据
        this.$emit('stat', this.stat)
        // 刷新列表
        this.handleRefresh()
      })
    },

    // 显示刷新
    showHandler() {
      if (this.showTimer) {
        clearInterval(this.showTimer)
      }

      this.showTimer = setInterval(() => {
        if (this.paused) {
          return
        }

        // 循环
        if (this.seconds <= 0) {
          this.seconds = this.refreshTime
        }

        this.seconds -= 1
      }, 1000)
    },

    // 自动翻页
    handleRefresh() {
      if (this.pageTimer) {
        clearInterval(this.pageTimer)
        this.seconds = this.refreshTime
      }
      // 自动翻页的
      if (this.autoPage) {
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()
          this.getAlarmPaging()

          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.nextPage()
          }
        }, this.refreshTime * 1000)
      } else {
        // 普通刷新
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()
          this.getAlarmPaging()
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
        }, this.refreshTime * 1000)
      }

      this.showHandler()
    },

    // 单人消息
    singleDialog(userId) {
      this.paused = true

      this.$prompt('输入提醒消息', '单人消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        singleMsg({ examId: this.examId, userId: userId, message: value }).then(
          () => {
            this.paused = false
          }
        )
      })
    },

    // 批量消息
    batchDialog() {
      this.paused = true

      this.$prompt('输入提醒消息', '批量消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        batchMsg({ examId: this.examId, message: value }).then(() => {
          this.paused = false
        })
      })
    },

    // 主考强制考生交卷
    chiefForceHandDialog(id) {
      this.paused = true

      this.$prompt('输入强制交卷理由', '强制交卷', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        chiefForceHand({ paperId: id, message: value }).then(() => {
          this.$message.success('操作成功！')
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
          this.fetchStat()
          this.getAlarmPaging()
          this.paused = false
        })
      })
    },

    // 继续考试 主考消除疑似作弊报警，允许考生继续考试
    chiefContinuingDialog(id) {
      this.paused = true

      this.$prompt('输入继续考试理由', '继续考试', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        chiefForceHand({ paperId: id, message: value }).then(() => {
          this.$message.success('操作成功！')
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
          this.fetchStat()
          this.getAlarmPaging()
          this.paused = false
        })
      })
    },

    // 批量消息
    showHandAll() {
      this.handVisible = true
      this.paused = true
    },

    doHandAll() {
      this.$refs.handForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.$confirm('交卷后考生将无法继续考试，确实要全部交卷吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const params = this.handForm
          params.examId = this.examId

          this.handLoading = true
          forceHandAll(params).then(() => {
            this.$message.success('操作成功！')
            this.fetchStat()
            this.getAlarmPaging()
            this.paused = false
            this.handLoading = false
            this.handVisible = false
          })
        })
      })
    },
    async forewarning() {
      if (this.warningmessage) {
        this.warningLoading = true
        const subData = {
          remoteExamId: this.examId,
          paperId: this.paperId,
          alarmLevel: this.warningLevel,
          msg: {
            content: this.warningmessage
          }
        }
        const res = await httppaperlarm(subData)
        this.warningLoading = false
        if (res.success) {
          this.warningVisible = false
          this.$message.success('操作成功！')
        }
      } else {
        this.$message.error('预警提示信息未填写！')
      }
    },
    handleWarning(id, level) {
      this.warningVisible = true
      this.paperId = id
      this.warningLevel = level
    },
    handleWarningClose() {
      this.warningVisible = false
      this.warningmessage = null
    },
    showDetail(info) {
      console.log(info)
      this.warnInfo = info
      if (this.warnInfo) {
        this.warningInfoVisible = true
      }
    },
    watch(item) {
      this.detailInfo = item
      console.log(item, 'item是什么')
      this.detailVisible = true
    },
    detailClose(f) {
      this.detailVisible = f
    }
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgb(10, 25, 60);
  color: white;
  overflow: hidden;
  padding: 10px;
}

.header {
  width: 100%;
  text-align: center;
  line-height: 32px;
  font-size: 20px;
}

.controls-box {
  width: 100%;
  background-color: rgb(30, 40, 75);
  padding: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.controls-left-tit {
  line-height: 32px;
  display: flex;
  text-align: center;
  width: 100%;
}

.supervisory-control-list {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.video-box {
  width: 100%;
  height: 15vh;
}

.supervisory-control-item-footer {
  line-height: 5vh;
  width: 100%;
  background-color: rgb(45, 70, 130);
}

.supervisory-control-status {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: rgb(175, 100, 110);
}

.supervisory-control-tit {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
}

.video-1,
.video-2 {
  position: relative;
  width: 99%;
  height: 14.5vh;
  border: 2px solid rgb(75, 135, 230);
  margin: 1px auto;
}

.video-1::before,
.video-2::before {
  position: absolute;
  content: "";
  top: -2px;
  bottom: -2px;
  left: 30px;
  width: 50%;
  border-top: 3px solid rgb(10, 25, 60);
  border-bottom: 3px solid rgb(10, 25, 60);
  z-index: 0;
}

.video-1::after,
.video-2::after {
  position: absolute;
  content: "";
  top: 30px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  height: 50%;
  border-right: 3px solid rgb(10, 25, 60);
  border-left: 3px solid rgb(10, 25, 60);
  z-index: 0;
}

.supervisory-control-btn {
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-around;
  /* line-height: 5vh; */
}

.supervisory-control-btn i {
  margin: auto 0;
  line-height: 5vh;
  display: block;
}
</style>
