var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("div", { staticClass: "header-tit1" }, [_vm._v("监考大屏")]),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "27vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                  _c("div", { staticClass: "baseinfo-box" }, [
                    _c("div", { staticClass: "baseinfo-item" }, [
                      _c("div", { staticClass: "quadrate" }),
                      _c("div", { staticClass: "baseinfo-tit" }, [
                        _vm._v("考试时间:"),
                      ]),
                      _c("div", { staticClass: "baseinfo-tip" }, [
                        _c("div", [_vm._v(_vm._s(_vm.examinfo.startTime))]),
                        _c("div", [_vm._v(_vm._s(_vm.examinfo.endTime))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "baseinfo-item" }, [
                      _c("div", { staticClass: "quadrate" }),
                      _c("div", { staticClass: "baseinfo-tit" }, [
                        _vm._v("考试方式:"),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.examinfo.participationType == "1",
                              expression: "examinfo.participationType == '1'",
                            },
                          ],
                          staticClass: "baseinfo-tip",
                        },
                        [_vm._v(" 在线-集中考试 ")]
                      ),
                    ]),
                    _c("div", { staticClass: "baseinfo-item" }, [
                      _c("div", { staticClass: "quadrate" }),
                      _c("div", { staticClass: "baseinfo-tit" }, [
                        _vm._v("考试人数:"),
                      ]),
                      _c("div", { staticClass: "baseinfo-tip" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.examinfo.stats &&
                                _vm.examinfo.stats.studentNum
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "baseinfo-item" }, [
                      _c("div", { staticClass: "quadrate" }),
                      _c("div", { staticClass: "baseinfo-tit" }, [
                        _vm._v("考试设置:"),
                      ]),
                      _c("div", { staticClass: "baseinfo-tip" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.examinfo.faceOn,
                                    expression: "examinfo.faceOn",
                                  },
                                ],
                                attrs: { size: "mini" },
                              },
                              [_vm._v("人脸识别")]
                            ),
                            _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("霸屏"),
                            ]),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.examinfo.examType == "2",
                                    expression: "examinfo.examType == '2'",
                                  },
                                ],
                                attrs: { size: "mini" },
                              },
                              [_vm._v("一机位")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.examinfo.examType == "2",
                                    expression: "examinfo.examType == '2'",
                                  },
                                ],
                                attrs: { size: "mini" },
                              },
                              [_vm._v("二机位")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.examinfo.examType == "2",
                                    expression: "examinfo.examType == '2'",
                                  },
                                ],
                                attrs: { size: "mini" },
                              },
                              [_vm._v("三机位")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "35vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("到场率 " + _vm._s(_vm.attendance) + "%"),
                  ]),
                  _c("v-chart", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.option_columns.series[0].data.length > 0,
                        expression: "option_columns.series[0].data.length > 0",
                      },
                    ],
                    staticStyle: { height: "30vh" },
                    attrs: { option: _vm.option_columns, autoresize: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "25vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("考生状态")]),
                  _c("v-chart", {
                    staticStyle: { height: "20vh" },
                    attrs: { option: _vm.yuanhuan_option, autoresize: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("div", { staticClass: "header-tit2" }, [
                _vm._v(_vm._s(_vm.examinfo.title)),
              ]),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "51.2vh", position: "relative" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        "z-index": "99",
                      },
                      attrs: { type: "success", size: "mini" },
                      on: { click: _vm.handleOpen },
                    },
                    [_vm._v("考试人数详情")]
                  ),
                  _c("v-chart", {
                    staticStyle: { height: "45vh" },
                    attrs: { option: _vm.map_option, autoresize: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "37vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("考生登录时间"),
                  ]),
                  _c("v-chart", {
                    staticStyle: { height: "30vh" },
                    attrs: { option: _vm.zhexian_option, autoresize: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("div", { staticClass: "header-tit3" }, [_vm._v("考试中")]),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "27vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("试卷信息")]),
                  _c(
                    "table",
                    { staticClass: "table-box" },
                    [
                      _c("tr", { staticClass: "table-tr" }, [
                        _c("td", [_vm._v("试卷名称")]),
                        _c("td", [_vm._v("总人数")]),
                        _c("td", [_vm._v("未到场")]),
                        _c("td", [_vm._v("考试中")]),
                        _c("td", [_vm._v("已交卷")]),
                      ]),
                      _vm._l(_vm.examlist, function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "table-tr" },
                          [
                            _c("td", [_vm._v(" " + _vm._s(item.title) + " ")]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.requireCount) + " "),
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.missCount) + " "),
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.processCount) + " "),
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.handCount) + " "),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "35vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("监控统计")]),
                  _c("v-chart", {
                    staticStyle: { height: "30vh" },
                    attrs: { option: _vm.option_columns_1, autoresize: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "card-box",
                  staticStyle: { height: "25vh" },
                  attrs: { "body-style": _vm.cardBody, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "card-tit" }, [_vm._v("监考情况")]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.examRooms)),
                            ]),
                            _vm._v("个"),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("虚拟考场"),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(
                                _vm._s(_vm.invigilateCounted.redCardPersons)
                              ),
                            ]),
                            _vm._v("人,"),
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.redCard)),
                            ]),
                            _vm._v("次 "),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("红牌警告"),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(
                                _vm._s(_vm.invigilateCounted.alarmPersons)
                              ),
                            ]),
                            _vm._v("人,"),
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.alarms)),
                            ]),
                            _vm._v("次 "),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("在线警示"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.forceHandIn)),
                            ]),
                            _vm._v("人"),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("强制交卷"),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(
                                _vm._s(_vm.invigilateCounted.yellowCardPersons)
                              ),
                            ]),
                            _vm._v("人,"),
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.yellowCard)),
                            ]),
                            _vm._v("次 "),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("跳出警示"),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "count-box" }, [
                          _c("div", { staticClass: "count-tit" }, [
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(_vm._s(_vm.invigilateCounted.cheats)),
                            ]),
                            _vm._v("确认，"),
                            _c("span", { staticClass: "count-num" }, [
                              _vm._v(
                                _vm._s(_vm.invigilateCounted.suspectCheats)
                              ),
                            ]),
                            _vm._v("疑似 "),
                          ]),
                          _c("div", { staticClass: "count-tip" }, [
                            _vm._v("标记作弊"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogTableVisible, title: "考试人数详情" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.mapData } },
            [
              _c("el-table-column", {
                attrs: { property: "name", label: "地址" },
              }),
              _c("el-table-column", {
                attrs: { property: "value", label: "数量", width: "200" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }