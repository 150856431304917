<template>
  <div class="container">
    <el-row :gutter="12">
      <el-col :span="8">
        <div class="header-tit1">监考大屏</div>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 27vh"
        >
          <div class="card-tit">基本信息</div>
          <div class="baseinfo-box">
            <div class="baseinfo-item">
              <div class="quadrate" />
              <div class="baseinfo-tit">考试时间:</div>
              <div class="baseinfo-tip">
                <div>{{ examinfo.startTime }}</div>
                <div>{{ examinfo.endTime }}</div>
              </div>
            </div>
            <div class="baseinfo-item">
              <div class="quadrate" />
              <div class="baseinfo-tit">考试方式:</div>
              <div
                v-show="examinfo.participationType == '1'"
                class="baseinfo-tip"
              >
                在线-集中考试
              </div>
            </div>
            <div class="baseinfo-item">
              <div class="quadrate" />
              <div class="baseinfo-tit">考试人数:</div>
              <div class="baseinfo-tip">
                {{ examinfo.stats && examinfo.stats.studentNum }}
              </div>
            </div>
            <div class="baseinfo-item">
              <div class="quadrate" />
              <div class="baseinfo-tit">考试设置:</div>
              <div class="baseinfo-tip">
                <div>
                  <el-button
                    v-show="examinfo.faceOn" size="mini"
                  >人脸识别</el-button
                  >
                  <el-button size="mini">霸屏</el-button>
                  <el-button
                    v-show="examinfo.examType == '2'" size="mini"
                  >一机位</el-button
                  >
                  <el-button
                    v-show="examinfo.examType == '2'" size="mini"
                  >二机位</el-button
                  >
                  <el-button
                    v-show="examinfo.examType == '2'" size="mini"
                  >三机位</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 35vh"
        >
          <div class="card-tit">到场率 {{ attendance }}%</div>
          <v-chart
            v-show="option_columns.series[0].data.length > 0"
            :option="option_columns"
            autoresize
            style="height: 30vh"
          />
        </el-card>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 25vh"
        >
          <div class="card-tit">考生状态</div>
          <v-chart :option="yuanhuan_option" autoresize style="height: 20vh" />
        </el-card>
      </el-col>
      <el-col :span="8">
        <div class="header-tit2">{{ examinfo.title }}</div>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 51.2vh;position: relative;"
        >
          <el-button type="success" size="mini" style="position: absolute;right: 10px;top: 10px;z-index: 99;" @click="handleOpen">考试人数详情</el-button>
          <v-chart :option="map_option" autoresize style="height: 45vh" />
        </el-card>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 37vh"
        >
          <div class="card-tit">考生登录时间</div>
          <v-chart :option="zhexian_option" autoresize style="height: 30vh" />
        </el-card>
      </el-col>
      <el-col :span="8">
        <div class="header-tit3">考试中</div>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 27vh"
        >
          <div class="card-tit">试卷信息</div>
          <table class="table-box">
            <tr class="table-tr">
              <td>试卷名称</td>
              <td>总人数</td>
              <td>未到场</td>
              <td>考试中</td>
              <td>已交卷</td>
            </tr>
            <tr v-for="(item, index) in examlist" :key="index" class="table-tr">
              <td>
                {{ item.title }}
              </td>
              <td>
                {{ item.requireCount }}
              </td>
              <td>
                {{ item.missCount }}
              </td>
              <td>
                {{ item.processCount }}
              </td>
              <td>
                {{ item.handCount }}
              </td>
            </tr>
          </table>
        </el-card>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 35vh"
        >
          <div class="card-tit">监控统计</div>
          <v-chart :option="option_columns_1" autoresize style="height: 30vh" />
        </el-card>
        <el-card
          :body-style="cardBody"
          shadow="hover"
          class="card-box"
          style="height: 25vh"
        >
          <div class="card-tit">监考情况</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit"><span class="count-num">{{ invigilateCounted.examRooms }}</span>个</div>
                <div class="count-tip">虚拟考场</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit">
                  <span class="count-num">{{ invigilateCounted.redCardPersons }}</span>人,<span
                    class="count-num"
                  >{{ invigilateCounted.redCard }}</span
                  >次
                </div>
                <div class="count-tip">红牌警告</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit">
                  <span class="count-num">{{ invigilateCounted.alarmPersons }}</span>人,<span
                    class="count-num"
                  >{{ invigilateCounted.alarms }}</span
                  >次
                </div>
                <div class="count-tip">在线警示</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit"><span class="count-num">{{ invigilateCounted.forceHandIn }}</span>人</div>
                <div class="count-tip">强制交卷</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit">
                  <span class="count-num">{{ invigilateCounted.yellowCardPersons }}</span>人,<span
                    class="count-num"
                  >{{ invigilateCounted.yellowCard }}</span
                  >次
                </div>
                <div class="count-tip">跳出警示</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="count-box">
                <div class="count-tit">
                  <span class="count-num">{{ invigilateCounted.cheats }}</span>确认，<span
                    class="count-num"
                  >{{ invigilateCounted.suspectCheats }}</span
                  >疑似
                </div>
                <div class="count-tip">标记作弊</div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogTableVisible" title="考试人数详情">
      <el-table :data="mapData">
        <el-table-column property="name" label="地址" />
        <el-table-column property="value" label="数量" width="200"/>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// import getData from '@/data/map'
import { registerMap } from 'echarts/core'
import chinaMap from './china.json'
import { httpimpl, httpstate, httplogin, httpmap, httpperson, httpinvigilate } from '@/api/exam/watch'
import { fetchRemoteexam } from '@/api/remoteTest/remoteTest'
export default {
  name: 'LargeScreen',
  components: {},
  data() {
    return {
      cardBody: {
        width: '100%',
        padding: '20px'
      },
      option_columns: {
        title: {
          text: ''
        },
        textStyle: {
          color: '#fff'
        },
        tooltip: {},
        legend: {
          data: ['数量'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          data: ['应到考生', '实到考生', '未到考生']
        },
        yAxis: {},
        series: [
          {
            name: '数量',
            type: 'bar',
            data: [0, 0, 0],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            }
          }
        ]
      },
      option_columns_1: {
        title: {
          text: ''
        },
        tooltip: {},
        legend: {
          data: ['数量'],
          textStyle: {
            color: '#fff'
          }
        },
        textStyle: {
          color: '#fff'
        },
        xAxis: {
          data: ['己人脸', '考生数', '答卷数量']
        },
        yAxis: {},
        series: [
          {
            name: '数量',
            type: 'bar',
            data: [0, 0, 0],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            }
          }
        ]
      },
      yuanhuan_option: {
        legend: {
          orient: 'vertical',
          x: 'left',
          data: ['考试中', '缺考', '已交卷', '已登录'],
          textStyle: {
            color: '#fff'
          }
        },
        textStyle: {
          color: '#fff'
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            },
            labelLine: {
              show: false,
              color: '#ffffff'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold',
                color: '#ffffff'
              }
            },
            data: [
              { value: 0, name: '考试中' },
              { value: 0, name: '缺考' },
              { value: 0, name: '已交卷' },
              { value: 0, name: '已登录' }
            ]
          }
        ]
      },
      zhexian_option: {
        textStyle: {
          color: '#fff'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {},
        series: [
          {
            data: [],
            type: 'line',
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            }
          }
        ]
      },
      map_option: null,
      examId: '',
      examinfo: {},
      examlist: [],
      studentState: {},
      attendance: 0,
      invigilateCounted: {},
      timer: null,
      mapData: [],
      geoCoordMap: {},
      dialogTableVisible: false
    }
  },
  mounted() {
    registerMap('china', chinaMap)
    const { examId } = this.$route.query
    this.examId = examId
    this.getInfo(examId)
    // 10秒定时更新
    this.timer = setInterval(() => {
      this.getInfo(examId)
    }, 10000)

    // console.log(getData(), examId, '@@@@@@@@@@@@@@@@@@@@@@@@@')
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleOpen() {
      this.dialogTableVisible = true
    },
    getInfo(examId) {
      this.getImpl(examId)
      this.getState(examId)
      this.fetchRemoteexam(examId)
      this.getloginState(examId)
      this.getmapState(examId)
      this.getpersonState(examId)
      this.getinvigilateState(examId)
    },
    async getImpl(id) {
      const res = await httpimpl({ id: id })
      if (res.success) {
        this.examlist = res.data
      }
    },
    // httpstate
    async getState(id) {
      const res = await httpstate({ id: id })
      if (res.success) {
        this.studentState = res.data
        this.yuanhuan_option.legend.data = []
        this.yuanhuan_option.legend.data[0] = `考试中 ${res.data.processCount}`
        this.yuanhuan_option.legend.data[1] = `缺考 ${res.data.missCount}`
        this.yuanhuan_option.legend.data[2] = `已交卷 ${res.data.handCount}`
        this.yuanhuan_option.legend.data[3] = `已登录 ${res.data.joinCount}`

        this.yuanhuan_option.series[0].data[0].name = `考试中 ${res.data.processCount}`
        this.yuanhuan_option.series[0].data[1].name = `缺考 ${res.data.missCount}`
        this.yuanhuan_option.series[0].data[2].name = `已交卷 ${res.data.handCount}`
        this.yuanhuan_option.series[0].data[3].name = `已登录 ${res.data.joinCount}`

        this.yuanhuan_option.series[0].data[0].value = res.data.processCount
        this.yuanhuan_option.series[0].data[1].value = res.data.missCount
        this.yuanhuan_option.series[0].data[2].value = res.data.handCount
        this.yuanhuan_option.series[0].data[3].value = res.data.joinCount

        this.option_columns.series[0].data = []

        this.option_columns.series[0].data[0] = res.data.requireCount
        this.option_columns.series[0].data[1] = res.data.joinCount
        this.option_columns.series[0].data[2] = res.data.missCount

        this.attendance = (res.data.joinCount / res.data.requireCount) * 100
        this.attendance = this.attendance.toFixed(2)
        // console.log(this.option_columns, this.attendance, '@@@@@@@@@@@@@@')
      }
    },
    async fetchRemoteexam(id) {
      const res = await fetchRemoteexam({ id: id })
      if (res.success) {
        this.examinfo = res.data
      }
    },
    // httplogin
    async getloginState(id) {
      const res = await httplogin({ id: id })
      if (res.success) {
        this.zhexian_option.series[0].data = []
        res.data.forEach((item, index) => {
          this.zhexian_option.xAxis.data[index] = item.loginTime
          this.zhexian_option.series[0].data[index] = item.num
        })
      }
    },
    // httpmap,
    async getmapState(id) {
      const res = await httpmap({ id: id })
      if (res.success) {
        this.mapData = []
        res.data.forEach(item => {
          const numData = {
            name: item.city || item.province,
            value: item.num
          }
          this.mapData.push(numData)
          this.geoCoordMap[item.city] = []
          this.geoCoordMap[item.city][0] = Number(item.longitude)
          this.geoCoordMap[item.city][1] = Number(item.latitude)
          console.log(item, this.geoCoordMap[item.city], '构建的地图经纬度数据')
        })
        console.log(this.mapData, 'kkkkk')
        this.map_option = this.getData()
        console.log(res, this.map_option, '地图数据是')
      }
    },
    //  httpperson
    async getpersonState(id) {
      const res = await httpperson({ id: id })
      if (res.success) {
        this.option_columns_1.series[0].data = []
        this.option_columns_1.series[0].data[0] = res.data.faceNum
        this.option_columns_1.series[0].data[1] = res.data.studentNum
        this.option_columns_1.series[0].data[2] = res.data.paperNum
      }
    },
    // httpinvigilate
    async getinvigilateState(id) {
      const res = await httpinvigilate({ id: id })
      if (res.success) {
        // console.log(res, '统计数据是')
        this.invigilateCounted = res.data
      }
    },
    convertData(data) {
      const res = []
      for (let i = 0; i < data.length; i++) {
        const geoCoord = this.geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    },
    getData() {
      return {
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
          fontWeight: 300,
          color: '#fff'
        },
        backgroundColor: '#404a59',
        title: {
          // text: 'Air quality of major cities in China',
          // subtext: 'data from PM25.in',
          text: '',
          subtext: '',
          sublink: '',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          y: 'bottom',
          x: 'right',
          data: ['数量'],
          textStyle: {
            color: '#fff'
          }
        },
        geo: {
          map: 'china',
          emphasis: {
            label: {
              show: false
            },
            itemStyle: {
              areaColor: '#2a333d'
            }
          },
          itemStyle: {
            areaColor: '#323c48',
            borderColor: '#111'
          }
        },
        series: [
          {
            name: '数量',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.convertData(this.mapData),
            symbolSize: (val) => val[2] / 10,
            tooltip: {
              formatter: function(val) {
                return val.name + ': ' + val.value[2]
              }
            },
            itemStyle: {
              color: '#ddb926'
            },
            label: {
              formatter: function(val) {
                return val.name + ': ' + val.value[2]
              },
              position: 'right',
              show: true
            },
            zlevel: 1
          }
          // {
          //   name: 'Top 5',
          //   type: 'effectScatter',
          //   coordinateSystem: 'geo',
          //   data: this.convertData(this.mapData.sort((a, b) => b.value - a.value).slice(0, 6)),
          //   symbolSize: (val) => val[2] / 10,
          //   showEffectOn: 'render',
          //   rippleEffect: {
          //     brushType: 'stroke'
          //   },
          //   emphasis: {
          //     scale: true
          //   },
          //   tooltip: {
          //     formatter: function(val) {
          //       return val.name + ': ' + val.value[2]
          //     }
          //   },
          //   label: {
          //     formatter: '{b}',
          //     position: 'right',
          //     show: true
          //   },
          //   itemStyle: {
          //     color: '#f4e925',
          //     shadowBlur: 10,
          //     shadowColor: '#333'
          //   },
          //   zlevel: 1
          // }
        ]
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 99%;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgb(10, 25, 60);
  color: white;
  overflow: hidden;
  padding: 5px;
}
.header-tit1,
.header-tit2,
.header-tit3 {
  width: 100%;
  line-height: 32px;
  padding: 5px;
}
.header-tit1,
.header-tit3 {
  color: rgb(85, 200, 210);
}
.header-tit2 {
  font-size: 20px;
  text-align: center;
}
.header-tit3 {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 40px;
}
.card-box {
  background-color: rgb(10, 25, 60);
  margin: 10px auto;
}
.card-tit {
  line-height: 32px;
  color: rgb(85, 200, 210);
}
.count-box,
.baseinfo-box {
  width: 100%;
  color: rgb(165, 185, 225);
}
.count-tit,
.count-tip {
  line-height: 32px;
  text-align: center;
}
.count-tit {
  font-size: 16px;
}
.count-tip {
  font-size: 12px;
}
.count-num {
  color: rgb(60, 125, 220);
  font-size: 20px;
}

.table-box {
  background-color: rgb(10, 25, 60);
  width: 100%;
  text-align: center;
  color: white;
}
.table-tr {
  line-height: 32px;
  font-size: 12px;
}
.table-tr:nth-child(2n + 1) {
  background-color: rgb(20, 40, 80);
}

.baseinfo-item {
  display: flex;
}
.baseinfo-tit,
.baseinfo-tip {
  line-height: 25px;
  font-size: 12px;
}
.baseinfo-tit {
  margin: 0 10px;
}
.quadrate {
  width: 10px;
  height: 10px;
  background-color: rgb(75, 210, 205);
  margin: 5px;
}
</style>
