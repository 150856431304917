<template>
  <div>
    <!-- <div v-if="breakShow" style="margin-bottom: 30px; cursor: pointer" @click="continueExam">
      <el-alert :closable="false" title="您有正在进行的考试，离线太久考试将被作废哦，点击此处可继续考试！" type="error" />
    </div> -->
    <web-table ref="pagingTable" :options="options" :list-query="listQuery">
      <template #filter-content>
        <el-input v-model="listQuery.params.title" class="filter-item" placeholder="搜索考试名称" size="small" prefix-icon="el-icon-search" style="width:200px" /> <el-date-picker v-model="listQuery.params.dateRange" class="filter-item" size="small" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </template>
      <template #data-columns>
        <el-table-column label="考试名称" show-overflow-tooltip>
          <template v-slot="scope">
            <detail-link :id="scope.row.id" :title="scope.row.title" to="CheckExam" />
          </template>
        </el-table-column> <el-table-column label="时长" align="center" prop="totalTime" width="120px" /> <el-table-column label="总分" align="center" prop="totalScore" width="120px" /> <el-table-column label="及格分" align="center" prop="qualifyScore" width="120px" /> <el-table-column label="考试时间" align="center" show-overflow-tooltip>
          <template v-slot="scope">
            {{ scope.row.startTime }} ~ {{ scope.row.endTime }}
          </template>
        </el-table-column> <el-table-column label="考试状态" align="center" width="120px">
          <template v-slot="scope">
            <el-tag v-if="scope.row.state===0" type="success">
              进行中
            </el-tag> <el-tag v-if="scope.row.state===1" type="danger" disabled>
              已禁用
            </el-tag> <el-tag v-if="scope.row.state===2" type="warning" disabled>
              未开始
            </el-tag> <el-tag v-if="scope.row.state===3" disabled>
              已结束
            </el-tag>
          </template>
        </el-table-column>
      </template>
    </web-table>
  </div>
</template>
<script>
import { checkProcess } from '@/api/paper/exam'
import { mapGetters } from 'vuex'

export default {
  name: 'OnlineList',
  components: { },
  data() {
    return {

      detailData: {},
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/exam/exam/online-paging'
      },

      postForm: {
        examId: '',
        password: ''
      },

      breakShow: false,
      breakId: '',
      examType: '',
      examId: '',
      checkToolShow: ''
    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  created() {
    checkProcess().then(res => {
      console.log(res, '数据是')
      if (res.data && res.data.id) {
        this.breakShow = true
        this.breakId = res.data.id
        this.examType = res.data.examType
        this.examId = res.data.examId
        this.checkToolShow = res.data.desktopToolOn
      }
    })
  },
  methods: {

    /**
     * 继续考试
     */
    continueExam() {
      if (this.examType == '2') {
        this.$router.push({ name: 'StartExam', params: { id: this.breakId }, query: { isvideo: true, examId: this.examId, checkToolShow: this.checkToolShow }})
      } else {
        this.$router.push({ name: 'StartExam', params: { id: this.breakId }})
      }
    }
  }
}
</script>
