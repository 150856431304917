<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="选择远程考试"
    width="80%"
    @close="handleClose"
  >
    <div class="app-container">
      <data-table
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
      >
        <template #filter-content>
          <dic-list-select
          v-model="listQuery.params.state"
          dic-code="remote_exam_status"
          title="考试项目状态"
        />
        <el-input
          v-model="listQuery.params.title"
          placeholder="搜索关键字"
          style="width: 200px"
          size="small"
          clearable
          class="filter-item"
        />

          <el-button
            v-if="!single"
            type="primary"
            class="filter-item"
            size="small"
            @click="handleConfirm"
          >{{ selectedLabel }}</el-button
          >
        </template>

        <template #data-columns>
          <el-table-column type="index" width="50" align="center" />

          <el-table-column
          label="项目名称"
          align="left"
          prop="title"
          show-overflow-tooltip
        >
          <!-- <template v-slot="scope">
            <detail-link
              :id="scope.row.id"
              :title="scope.row.title"
              to="UpdateRemoteTest"
              permission="remote:exam:manager"
            />
          </template> -->
        </el-table-column>
        <el-table-column
          label="所属单位"
          align="left"
          prop="enterpriseName"
          width="160px"
          show-overflow-tooltip
        />
        <el-table-column
          label="状态"
          align="center"
          width="80px"
          prop="state_dictText"
        />
        <el-table-column
          label="职位"
          prop="stats.careerNum"
          align="right"
          width="60px"
        >
          <template v-slot="scope">
            {{ (scope.row.stats && scope.row.stats.careerNum) || '0' }}
          </template>
        </el-table-column>
        <el-table-column
          label="考生"
          prop="stats.studentNum"
          align="right"
          width="60px"
        >
          <template v-slot="scope">
            {{ (scope.row.stats && scope.row.stats.studentNum) || '0' }}
          </template>
        </el-table-column>
        <el-table-column
          label="试卷"
          align="right"
          width="60px"
        >
          <template v-slot="scope">
            {{ (scope.row.stats && scope.row.stats.tmplNum) || '0' }}
          </template>
        </el-table-column>
        <el-table-column
          label="监考"
          prop="stats.invigilatorNum"
          align="right"
          width="60px"
        >
          <template v-slot="scope">
            {{
              (scope.row.stats && scope.row.stats.invigilatorNum) || '0'
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="阅卷"
          prop="stats.examinerNum"
          align="right"
          width="60px"
        >
          <template v-slot="scope">
            {{ (scope.row.stats && scope.row.stats.examinerNum) || '0' }}
          </template>
        </el-table-column>
        <el-table-column
          label="考试时间"
          align="center"
          width="160px"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            {{ scope.row.startTime }} 至 </br> {{ scope.row.endTime }}
          </template>
        </el-table-column>

          <el-table-column v-if="single" label="操作" align="center" width="80">
            <template v-slot="scope">
              <el-button type="text" @click="selectRow(scope.row)">
                选择
              </el-button>
            </template>
          </el-table-column>
        </template>
      </data-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'RemoteExamSelectDialog',
  props: {
    excludes: Array,
    excludeDeparts: Array,
    dialogShow: {
      type: Boolean,
      default: false
    },
    selections: Array,
    single: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      selectedLabel: '请选择远程考试',
      listQuery: {
        current: 1,
        size: 10,
        params: {
          excludes: []
        }
      },
      // 选定的数据列表
      selectedList: [],
      selectedObjs: [],
      options: {
        // 可批量操作
        multi: !this.single,
        multiShow: this.single,
        keyId: 'id',
        // 列表请求URL
        listUrl: '/api/remoteexam/remoteexam/paging'
      }
    }
  },

  watch: {
    // 检测查询变化
    dialogShow: {
      handler(val) {
        this.dialogVisible = this.dialogShow
        if (val && this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    excludeDeparts: {
      handler() {
        this.listQuery.params.excludeDeparts = this.excludeDeparts
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    selectedList: {
      handler(val) {
        this.selectedLabel = '确认' + val.length + '项'
      }
    }
  },

  created() {
    this.dialogVisible = this.dialogShow
    this.listQuery.params.excludes = this.excludes
    this.listQuery.params.excludeDeparts = this.excludeDeparts
  },

  methods: {
    // 选定回调
    handleSelected(data) {
      if (data) {
        this.selectedList = data.ids
        this.selectedObjs = data.objs
      }
    },
    // 选中一行
    selectRow(row) {
      if (row && row.id) {
        this.selectedList = [row.id]
        this.selectedObjs = [row]
      }
      this.handleConfirm()
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:dialogShow', false)
      this.$refs.pagingTable.clearSelection()
      this.selectedList = []
      this.selectedObjs = []
    },

    handleConfirm() {
      if (this.selectedList === null || this.selectedList.length === 0) {
        this.$message({
          type: 'error',
          message: '请至少勾选一个用户！!'
        })
        return
      }

      this.$emit('select', this.selectedList, this.selectedObjs)

      // 关闭
      this.handleClose()
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
}
</style>
