var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkData && _vm.checkData.id
    ? _c(
        "div",
        [
          _vm.disabled
            ? _c("el-alert", {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { title: _vm.checkMsg, type: "error" },
              })
            : _vm._e(),
          _c("div", [
            _vm.checkData.isBuy
              ? _c(
                  "div",
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, title: "考试信息", border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试名称" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.title) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "姓名" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.realName) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "身份证号" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.idCard) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试总分" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.totalScore) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "及格分数" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.checkData.qualifyScore) + "分 "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试时间" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.checkData.startTime) +
                                " ~ " +
                                _vm._s(_vm.checkData.endTime) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试时长" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.checkData.totalTime) + " 分钟 "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试类型" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.checkData.examType_dictText) +
                                " "
                            ),
                          ]
                        ),
                        _vm.checkData.desktopToolOn
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "辅助工具" } },
                              [
                                _c("div", [
                                  _c("ul", [
                                    _c("li", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _vm._v("防作弊检测软件 "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.checkToolDialog == 1,
                                                  expression:
                                                    "checkToolDialog == 1",
                                                },
                                              ],
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "#ff3333",
                                              },
                                            },
                                            [_vm._v("未启动")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.checkToolDialog == 3,
                                                  expression:
                                                    "checkToolDialog == 3",
                                                },
                                              ],
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "#ff3333",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "检查不通过,请按防作弊软件提示关闭程序"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.checkToolDialog == 2,
                                                  expression:
                                                    "checkToolDialog == 2",
                                                },
                                              ],
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "rgb(90, 225, 124)",
                                              },
                                            },
                                            [_vm._v("检查通过")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "li",
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "如果没有安装防作弊软件，请先下载安装。"
                                          ),
                                        ]),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/downtool/windows",
                                              tag: "a",
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v("点此下载")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("li", [
                                      _c("span", [
                                        _vm._v(
                                          "已下载，请先启动“考试系统防作弊检测工具。"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.checkData.content
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "注意事项" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#ff3333",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.checkData.content) + " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.checkData.openType === 9
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "输入密码" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "show-password": "",
                                    size: "mini",
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.postForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "password", $$v)
                                    },
                                    expression: "postForm.password",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkData.camOn && _vm.checkData.examType === "1"
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "设备调试" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.deviceDebug,
                                      callback: function ($$v) {
                                        _vm.deviceDebug = $$v
                                      },
                                      expression: "deviceDebug",
                                    },
                                  },
                                  [_vm._v(" 我已完成设备调试 ")]
                                ),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-camera",
                                    },
                                    on: { click: _vm.showDeviceTest },
                                  },
                                  [_vm._v(" 调试摄像头 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkData.examType === "2"
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "设备调试" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.deviceDebug,
                                      callback: function ($$v) {
                                        _vm.deviceDebug = $$v
                                      },
                                      expression: "deviceDebug",
                                    },
                                  },
                                  [_vm._v(" 我已完成设备调试 ")]
                                ),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-camera",
                                    },
                                    on: { click: _vm.showDeviceTest },
                                  },
                                  [_vm._v(" 调试主摄像头&副摄像头&屏幕录制 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c("el-button", { on: { click: _vm.goBack } }, [
                          _vm._v(" 返回 "),
                        ]),
                        _vm.checkData.examType === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.buttonNext(),
                                  type: "primary",
                                  icon: "el-icon-s-promotion",
                                },
                                on: { click: _vm.readyExam },
                              },
                              [_vm._v(" 开始考试 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.buttonNext(),
                                  type: "primary",
                                  icon: "el-icon-s-promotion",
                                },
                                on: { click: _vm.handleCreateBrfore },
                              },
                              [_vm._v(" 开始考试 ")]
                            ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", icon: "el-icon-help" },
                            on: { click: _vm.handleApply },
                          },
                          [_vm._v(" 异常申请 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "pay-box" }, [
                  _c("div", [_vm._v("此考试需要购买，请付费后再进入!")]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v("￥" + _vm._s(_vm.checkData.price)),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleBuy },
                        },
                        [_vm._v(" 购买此考试 ")]
                      ),
                    ],
                    1
                  ),
                ]),
          ]),
          !_vm.disabled
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.faceCheck,
                    "close-on-click-modal": false,
                    "close-on-press-escape": false,
                    "show-close": true,
                    title: "微信扫码，开启人脸识别",
                    width: "30%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.faceCheck = $event
                    },
                  },
                },
                [
                  _vm.faceCheck
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "space-around",
                            padding: "10px",
                          },
                        },
                        [
                          _c("div", {
                            ref: "qrcodediv",
                            attrs: { id: "qrcodediv" },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          !_vm.disabled
            ? _c("exam-apply-dialog", {
                attrs: {
                  "exam-id": _vm.postForm.examId,
                  visible: _vm.applyVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.applyVisible = $event
                  },
                },
              })
            : _vm._e(),
          _vm.checkData.examType === "1"
            ? _c("simple-device-test", {
                attrs: { visible: _vm.deviceVisible },
                on: {
                  "update:visible": function ($event) {
                    _vm.deviceVisible = $event
                  },
                },
              })
            : _vm._e(),
          _vm.checkData.examType === "2"
            ? _c("more-device-test", {
                attrs: {
                  visible: _vm.deviceVisible,
                  "check-data": _vm.checkData,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.deviceVisible = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }