<!-- 树状选择器 -->
<template>

  <div>
    <div style="margin-bottom: 10px; display: flex; align-items: center">
      <el-input
        v-model="filterText"
        style="flex-grow: 1"
        size="small"
        placeholder="输入关键字进行过滤"/>
      <el-button
        type="danger"
        style="margin-left: 10px"
        icon="el-icon-refresh-left"
        size="mini"
        circle
        @click="clearSelection"/>
    </div>

    <el-tree
      ref="tree"
      :data="treeData"
      :close-on-click-modal="false"
      :props="props"
      :filter-node-method="filterNode"
      default-expand-all
      node-key="id"
      highlight-current
      @node-click="onClickNode"
    />

  </div>

</template>

<script>
import { fetchTree } from '@/api/sys/dict/value'

export default {
  name: 'DicTree',
  props: {
    // 接收绑定参数
    value: String,
    dicCode: String,
    // 树节点配置选项
    props: {
      type: Object,
      required: false,
      default: () => ({
        parent: 'parentId',
        value: 'id',
        label: 'title',
        children: 'children'
      })
    }
  },
  data() {
    return {
      treeData: [],
      filterText: ''
    }
  },
  watch: {
    filterText: {
      handler(val) {
        this.filter(val)
      }
    }
  },
  created() {
    fetchTree({ dicCode: this.dicCode }).then(res => {
      this.treeData = res.data
    })
  },
  methods: {

    // 单击节点
    onClickNode(node) {
      this.$emit('input', node.id)
    },

    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },

    filter(val) {
      this.$refs.tree.filter(val)
    },

    clearSelection() {
      // 清空
      this.filterText = ''

      // 清除选择
      this.$emit('input', '')

      // 移除高亮效果
      document.querySelector('.is-current').className = 'el-tree-node is-expanded is-focusable'
    }
  }
}
</script>
