var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "300px" },
              attrs: {
                size: "small",
                placeholder: "请输入积分标题信息",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.refRemark,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "refRemark", $$v)
                },
                expression: "listQuery.params.refRemark",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: {
                label: "姓名",
                prop: "realName",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "证书名称",
                align: "center",
                prop: "name",
                width: "160px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "授予单位",
                align: "center",
                prop: "authority",
                width: "160px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "取得时间",
                align: "center",
                prop: "createTime",
                width: "160px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "到期时间",
                align: "center",
                prop: "endTime",
                width: "160px",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "操作项", align: "center", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: scope.row.certificateFileUrl,
                              target: "_black",
                            },
                          },
                          [
                            _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("下载"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }