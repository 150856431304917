var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c("el-page-header", {
        attrs: { content: "试题详情" },
        on: { back: _vm.$navBack },
      }),
      _c(
        "section",
        [
          _c("qu-item-show", {
            attrs: {
              value: _vm.quData,
              "show-analysis": true,
              index: 0,
              mode: 3,
              state: 1,
              "show-type": true,
            },
          }),
          _vm.quData.quType === "99"
            ? _c(
                "div",
                _vm._l(_vm.quData.subList, function (sub, jj) {
                  return _c(
                    "div",
                    { key: sub.id },
                    [
                      _c("qu-item-show", {
                        attrs: {
                          value: sub,
                          index: jj,
                          mode: 3,
                          state: 1,
                          "show-type": true,
                          sub: true,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }