// 考试模块
import WebLayout from '@/layout/WebLayout'

const examRouter = {
  path: '/',
  component: WebLayout,
  hidden: true,
  children: [
    {
      path: '/web/index',
      component: () => import('@/views/web/exam/list'),
      name: 'ExamOnline',
      meta: { title: '在线考试', noCache: true }
    },

    {
      path: '/web/exam/check/:id',
      component: () => import('@/views/web/exam/check'),
      name: 'CheckExam',
      meta: { title: '考试校验', noCache: true, activeMenu: '/web/index' }
    },

    {
      path: '/web/exam/result/:id',
      component: () => import('@/views/web/exam/result'),
      name: 'ShowExam',
      meta: { title: '考试结果', noCache: true, activeMenu: '/web/index' }
    },

    {
      path: '/web/qu/view/:id',
      component: () => import('@/views/web/qu/view'),
      name: 'ViewQu',
      meta: { title: '试题详情', noCache: true, activeMenu: '/web/paper' }
    },

    {
      path: '/web/paper',
      component: () => import('@/views/web/paper/total'),
      name: 'ListMyExam',
      meta: { title: '我的成绩', noCache: true, activeMenu: '/web/uc' }
    },

    {
      path: '/web/paper/list/:examId/:userId',
      component: () => import('@/views/web/paper/list'),
      name: 'WebPaperList',
      meta: { title: '考试记录', noCache: true, activeMenu: '/web/uc' }
    },

    {
      path: '/web/book/list/:examId',
      component: () => import('@/views/web/book'),
      name: 'BookList',
      meta: { title: '考试错题', noCache: true, activeMenu: '/web/uc' }
    },

    {
      path: '/web/repo',
      component: () => import('@/views/web/repo'),
      name: 'MyRepoList',
      meta: { title: '题库练习', noCache: true }
    },

    {
      path: '/web/repo/view/:repoId',
      component: () => import('@/views/web/repo/view'),
      name: 'MyRepoView',
      meta: { title: '题库详情', noCache: true, activeMenu: '/web/repo' }
    },

    {
      path: '/web/repo/training/:repoId/:mode',
      component: () => import('@/views/web/repo/training'),
      name: 'MyRepoTraining',
      meta: { title: '开始训练', noCache: true, activeMenu: '/web/repo' }
    },

    {
      path: '/web/repo/repo/:trainId',
      component: () => import('@/views/web/repo/result'),
      name: 'TrainResult',
      meta: { title: '训练结果', noCache: true, activeMenu: '/web/repo' }
    }
  ]
}
export default examRouter
