var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "postForm", attrs: { model: _vm.postForm, rules: _vm.rules } },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "captchaValue" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "输入验证码" },
              model: {
                value: _vm.postForm.captchaValue,
                callback: function ($$v) {
                  _vm.$set(_vm.postForm, "captchaValue", $$v)
                },
                expression: "postForm.captchaValue",
              },
            },
            [
              _c("template", { slot: "prepend" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeCode()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "30px", width: "auto" },
                      attrs: { src: _vm.keyUrl },
                    }),
                  ]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }