<template>
  <div class="cam-box">

    <div>
      <audio
        id="video" ref="video"
        :width="0" :height="0"
        :autoplay="true"
        :muted:="muted"
        style="background: #262626" />

      <canvas id="canvas" ref="canvas" :width="0" :height="0" />
    </div>

    <el-empty v-if="noDevice" description="摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！" />

  </div>
</template>

<script>
import kurentoUtils from 'kurento-utils'
import { httpuploadPicture, httpsnapuploadPicture } from '@/api/exam/watch'
import { Notification } from 'element-ui'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoCaller',
  props: {
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    },
    // 发起监控的方式
    camType: {
      type: String,
      default: 'facade'
    },
    paperid: {
      type: String,
      default: ''
    },
    deviceId: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: false
    },
    examId: {
      type: [Number, String],
      default: ''
    },
    captureP: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      source: null,
      canvas: null,
      context: null,
      camerasListEmitted: false,
      cameras: [],
      noDevice: false,
      // 警告定时器
      faceTimer: null,
      loading: false,

      // ice链接
      webRtcPeer: null,
      ws: null,
      timer: null

    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  watch: {

    // 检测查询变化
    isStop: {
      handler() {
        if (this.isStop) {
          this.stop()
        } else {
          this.start()
        }
      },
      deep: true
    },
    captureP: {
      handler() {
        // console.log(this.captureP, '是否监控到抓拍照片')
        if (this.captureP) {
          this.upLoadsnap()
        }
      }
    }

  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    // 关闭websocket
    if (this.ws) {
      this.ws.close()
    }
    this.stop()
  },
  methods: {
    init() {
      if (!this.webRtcPeer) {
        // 添加自己的中继服务器的配置，否则会默认指向谷歌的服务器
        const iceservers = {
          'iceServers': [
            {
              urls: `${process.env.VUE_APP_ICESERVER_STUN_URL}`
            },
            {
              urls: [`${process.env.VUE_APP_ICESERVER_TURN_URL}`],
              username: `${process.env.VUE_APP_ICESERVER_TURN_USERNAME}`,
              credential: `${process.env.VUE_APP_ICESERVER_TURN_CREDENTIAL}`
            }
          ]
        }
        const options = {
          localVideo: this.$refs.video,
          configuration: iceservers,
          onicecandidate: this.onIceCandidate,
          mediaConstraints: {
            audio:true,
            video:false
          }
        }
        this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
          (error) => {
            if (error) {
              Notification.error({
                title: '提示信息',
                message: '通讯设备出错，请检查通讯设备'
              })
              return console.log(error, '错误信息是')
            }
            this.webRtcPeer.generateOffer(this.onOfferPresenter)
          })
      }
      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        // console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'callResponse':
            this.presenterResponse(parsedMessage)
            break
          case 'presenterResponse':
            this.presenterResponse(parsedMessage)
            break
          case 'viewerResponse':
            this.viewerResponse(parsedMessage)
            break
          case 'iceCandidate':
            this.webRtcPeer.addIceCandidate(parsedMessage.candidate, function(error) {
              if (error) { return console.log('添加候选项错误', error) }
            })
            break
          case 'startCommunication':
            this.startCommunication(parsedMessage);
            break;
          case 'stopCommunication':
            this.dispose()
            break
          case 'interruptionNotify':
            this.errorResponse(parsedMessage)
            break
          case 'pantResponse':
            this.pantResponse(parsedMessage)
            break
          case 'finish':
            this.finishResponse(parsedMessage)
            break
          default:
            console.log('无法识别的信息', parsedMessage)
            // Notification.error({
            //   title: '提示信息',
            //   message: '系统出错请联系管理员'
            // })
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(`[websocket close]连接关闭, code=${event.code} reason=${event.reason},${event}`)
          this.dispose()
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          console.log('[websocket close] Connection died', event)
          // this.start()
        }
      }

      this.ws.onerror = (error) => {
        console.log(`[websocket error] ${error.message}`)
        // this.start()
      }
    },
    presenterResponse(message) {
      // console.log('我有进来么？',message)
      if (message.response == 'accepted') {
        this.$emit('connectSucess', 2)
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) { return console.log(error, '错误是') }
        })
      } else {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受', errorMsg)
        Notification.error({
          title: '提示信息',
          message: '对方已拒绝您的通话'
        })
        this.$emit('connectSucess', 0)
        this.dispose(true)
      }
    },
    startCommunication(message) {
      // console.log('开始语音通话')
      this.$emit('connectSucess', 2)
      this.webRtcPeer.processAnswer(message.sdpAnswer, function(error) {
        if (error)
          return console.error(error)
      })
    },
    viewerResponse(message) {
      if (message.response != 'accepted') {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受', errorMsg)
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) { return console.log(error, '错误是') }
        })
      }
    },
    finishResponse(message) {
      Notification.success({
        title: '提示信息',
        message: '考试已结束'
      })
      this.dispose()
    },
    errorResponse(message) {
      // console.log(message, '连接错误信息是什么')
      this.$emit('alterError', message)
    },
    pantResponse(message) {
      // console.log(message, 'pantResponse信息是什么')
      this.$emit('checkConnected', message)
    },
    onOfferPresenter(error, offerSdp) {
      // facade ： 正面视频
      // side ： 侧面视频
      // desktop ： 桌面视频

      if (error) { return console.log('生成offer错误') }
      const message = {
        id: 'call',
        sdpOffer: offerSdp,
        type: this.camType,
        name: this.paperid,
        caller: this.userId
        // kicked: true
      }
      // console.log('发送的消息是',message)
      // console.log(message, '视频发送')
      this.sendMessage(message)
    },
    onIceCandidate(candidate) {
      // console.log('当地的候选人', JSON.stringify(candidate))
      const message = {
        id: 'onIceCandidate',
        candidate: candidate,
        type: this.camType,
        name: this.paperid,
        caller: this.userId
      }
      this.sendMessage(message)
    },
    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },

    // fire 是否触发disconnected事件
    dispose(closeEmitter) {
      clearInterval(this.timer)
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose()
        this.webRtcPeer = null
      }
    },
    start() {
      // WebSocket.readyState
      // 返回当前 WebSocket 的链接状态，只读。
      // 语法
      // var readyState = WebSocket.readyState;
      // 值
      // 以下其中之一
      // 0 (WebSocket.CONNECTING)
      // 正在链接中
      // 1 (WebSocket.OPEN)
      // 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING)
      // 连接正在关闭
      // 3 (WebSocket.CLOSED)
      // 连接已关闭或者没有链接成功

      // 检查是否需要重新创建 websocket
      let toCreate = true
      if (this.ws && (this.ws.readyState == WebSocket.CONNECTING || this.ws.readyState == WebSocket.OPEN)) {
        // 正在链接中 || 已经链接并且可以通讯
        toCreate = false
      }

      if (toCreate) {
        try {
          const wsUrl = `${process.env.VUE_APP_SIGNAL_BASE_API}/signal/caller`
          // const wsUrl = 'wss://live.ntces.cn/tutorialcall/k'
          this.ws = new WebSocket(wsUrl)
          // console.log(this.ws, this.ws.readyState, WebSocket.CONNECTING, WebSocket.OPEN, 'websockt')

          let wsState = false

          this.ws.onopen = (e) => {
            wsState = true
            
            // console.log('Presenter Websocket已连接', this.camType, this.paperid, this.deviceId)
            this.init()
            this.peng()
          }
          setTimeout(() => {
            // console.log(wsState, '连接失败——————————————————————————————————————————')
            if (!wsState) {
              this.$emit('webjoin')
            }
          }, 3000)
        } catch (e) {
          this.$emit('webjoin')
        }
      }


      // if (this.ws) {
      //   this.ws.close()
      // }
    },
    stop() {
      // console.log('停止拍摄')
      const message = {
        id: 'stop',
        type: this.camType,
        name: this.paperid,
        caller:this.userId,
      }
      this.sendMessage(message)
      this.dispose()
      if (this.ws) {
        this.ws.close()
      }
    },
    interruption() {
      // console.log('中断')
      const message = {
        id: 'interruption',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        // console.log('心跳 presenter:', this.camType, this.paperid)
        const message = {
          id: 'pant',
          type: this.camType,
          name: this.paperid,
          caller:this.userId,
        }
        this.sendMessage(message)
      }, 3000)
    },
    // 静默截屏，截屏时候无感知
    capture() {
      // console.log('抓拍')
      const video = this.$refs.video
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.videoHeight
      canvas.width = video.videoWidth
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataImage = canvas.toDataURL(this.captureFormat)
      // 转换成base64去除头
      const base64 = dataImage.replace(/^data:image\/\w+;base64,/, '')
      // this.$emit('capture', base64)
      return base64
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
</style>
