var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "设备调试",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", [
        _vm.currentStep == 1
          ? _c("div", [
              _c(
                "div",
                { staticStyle: { height: "430px" } },
                [
                  _c("video-presenter", {
                    attrs: {
                      height: 430,
                      "cam-type": "facade",
                      paperid: _vm.checkData.studentVideoCheckName,
                      "is-stop": !_vm.dialogVisible,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tips-lg", on: { click: _vm.handleClose } },
                [_vm._v(" 能看到视频图像则表示摄像头正常，点此关闭 ")]
              ),
              _c("div", { staticClass: "tips-sm" }, [
                _c("div", [
                  _vm._v("1、网页必须基于https下才能调用摄像头和麦克风设备。"),
                ]),
                _c("div", [
                  _vm._v(
                    " 2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器、Safari浏览器、Edge浏览器。 "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    "3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。"
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.currentStep == 2
          ? _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "430px",
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "430px", width: "550px" } },
                    [
                      _c("video-viewer", {
                        attrs: {
                          height: 430,
                          width: 550,
                          "cam-type": "side",
                          online: true,
                          paperid: _vm.checkData.studentVideoCheckName,
                          "is-stop": !_vm.dialogVisible,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "qrcode-box" }, [
                    _c("div", { staticClass: "qrcode-tit" }, [
                      _vm._v("副摄像头二维码"),
                    ]),
                    _c("div", { staticClass: "qrcode-body" }, [
                      _c("div", {
                        ref: "qrcodediv",
                        attrs: { id: "qrcodediv" },
                      }),
                    ]),
                    _c("div", { staticClass: "qrcode-tip" }, [
                      _vm._v("请使用手机扫描上面二维码"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "tips-lg", on: { click: _vm.handleClose } },
                [_vm._v(" 能看到视频图像则表示摄像头正常，点此关闭 ")]
              ),
              _c("div", { staticClass: "tips-sm" }, [
                _c("div", [
                  _vm._v("1、调试成功后请退出手机端、正式考试需再次进行扫码。"),
                ]),
                _c("div", [
                  _vm._v(
                    " 2、扫码成功后请将摄像头放置于电脑斜45度，确保能看到完整的视角。 "
                  ),
                ]),
                _c("div", [
                  _vm._v("3、请保证摄像头开启直至考试结束、注意手机不要息屏。"),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.currentStep == 3
          ? _c("div", [
              _c(
                "div",
                { staticStyle: { height: "430px" } },
                [
                  _c("screen-sharing", {
                    attrs: {
                      paperid: _vm.checkData.studentVideoCheckName,
                      tracking: false,
                      height: 430,
                      "is-stop": !_vm.dialogVisible,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tips-lg", on: { click: _vm.handleClose } },
                [_vm._v(" 能看到视频图像则表示摄像头正常，点此关闭 ")]
              ),
              _c("div", { staticClass: "tips-sm" }, [
                _c("div", [
                  _vm._v(
                    "1、点击【整个屏幕】选项卡 > 选中屏幕图像 > 点击【分享】按钮"
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " 2、Mac电脑需要给浏览器授权才能进行屏幕录制，请在【隐私与安全】为浏览器开启录制权限。 "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "btn-box" },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "49%" },
                attrs: {
                  disabled: _vm.currentStep <= 1,
                  type: "primary",
                  icon: "el-icon-arrow-left",
                },
                on: {
                  click: function ($event) {
                    return _vm.next(-1)
                  },
                },
              },
              [_vm._v("上一步")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "49%" },
                attrs: { disabled: _vm.currentStep >= 3, type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.next(1)
                  },
                },
              },
              [
                _vm._v("下一步"),
                _c("i", { staticClass: "el-icon-arrow-right el-icon--right" }),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }