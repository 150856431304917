var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("dic-list-select", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { "dic-code": "exam_type", title: "选择考试类型" },
              model: {
                value: _vm.listQuery.params.examType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "examType", $$v)
                },
                expression: "listQuery.params.examType",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "搜索考试名称",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "380px" },
              attrs: {
                size: "small",
                type: "datetimerange",
                "value-format": "yyyy-MM-dd HH:mm:ss",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.listQuery.params.dateRange,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                },
                expression: "listQuery.params.dateRange",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "考试名称", "show-overflow-tooltip": "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("detail-link", {
                        attrs: {
                          params: {
                            examId: scope.row.examId,
                            userId: _vm.userId,
                          },
                          title: scope.row.title,
                          to: "WebPaperList",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "考试次数",
                align: "center",
                prop: "tryCount",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "最高得分",
                align: "center",
                prop: "maxScore",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "最近考试",
                align: "center",
                prop: "updateTime",
                width: "200px",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "是否通过", align: "center", width: "120px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        [
                          scope.row.passed
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "success" } },
                                [_vm._v("通过")]
                              )
                            : _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "danger" } },
                                [_vm._v("未通过")]
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作项", align: "center", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-circle-close",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handlerExamBook(scope.row.examId)
                            },
                          },
                        },
                        [_vm._v("考试错题")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }