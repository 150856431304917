<template>
  <div class="cam-box">
    <div>
      <video
        id="video"
        ref="video"
        :width="width"
        :height="height"
        :playsinline="true"
        :autoplay="true"
        muted
        style="background: #262626"
      />

      <canvas id="canvas" ref="canvas" :width="width" :height="height" />
    </div>

    <el-empty
      v-if="noDevice"
      description="摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！"
    />

    <el-dialog
      :visible.sync="videoListVisible"
      title="摄像头选择"
      width="50%"
      center
      append-to-body
    >
      <div>
        <div v-for="camera in cameras" :key="camera.deviceId">
          <el-row :gutter="16">
            <el-col
              :span="16"
            ><div class="grid-content bg-purple" />
              {{ camera.label }}
            </el-col>
            <el-col
              :span="8"
            ><div class="grid-content bg-purple" />
              <el-button
                :type="deviceId == camera.deviceId ? 'primary' : ''"
                @click="handleChooseCamera(camera.deviceId)"
              >选择</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="centerDialogVisible"
      title="消息通知"
      width="50%"
      center
    >
      <div class="notice-box">
        <span>
          {{ noticeMsg }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleOK">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import kurentoUtils from 'kurento-utils'
import { httpuploadPicture, httpsnapuploadPicture } from '@/api/exam/watch'
import { Notification } from 'element-ui'
import { number } from 'echarts'

export default {
  name: 'VideoPresenter',
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    },
    // 发起监控的方式
    camType: {
      type: String,
      default: 'facade'
    },
    paperid: {
      type: String,
      default: ''
    },
    chooseDeviceId: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: false
    },
    examId: {
      type: [Number, String],
      default: ''
    },
    captureP: {
      type: Boolean,
      default: false
    },
    isCheat: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    idealWidth: {
      type: Number,
      default: 0
    },
    idealHeight: {
      type: Number,
      default: 0
    },
    
  },
  data() {
    return {
      source: null,
      canvas: null,
      context: null,
      camerasListEmitted: false,
      cameras: [],
      noDevice: false,
      // 警告定时器
      faceTimer: null,
      loading: false,

      // ice链接
      webRtcPeer: null,
      ws: null,
      timer: null,
      from: null,
      ismuted: true,
      centerDialogVisible: false,
      noticeMsg: '',
      videoListVisible: false,
      deviceId: ''
    }
  },
  watch: {
    // 检测查询变化
    isStop: {
      handler() {
        if (this.isStop) {
          this.stop()
        } else {
          this.start()
        }
      },
      deep: true
    },
    captureP: {
      handler() {
        console.log(this.captureP, '是否监控到抓拍照片')
        if (this.captureP) {
          this.upLoadsnap()
        }
      }
    },
    isCheat() {
      if (this.isCheat) {
        this.unfocusAlarm()
      }
    }
  },
  mounted() {
    console.log(this.chooseDeviceId, 'this.chooseDeviceId是什么')
    // 检查摄像头
    if (this.chooseDeviceId) {
      this.deviceId = this.chooseDeviceId
      this.start()
    } else {
      this.loadCameras()
    }
  },
  beforeDestroy() {
    // 关闭websocket
    if (this.ws) {
      this.ws.close()
    }
    this.stop()
  },
  methods: {
    loadCameras() {
      if (navigator.mediaDevices || navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {
            navigator.mediaDevices
              .enumerateDevices()
              .then((deviceInfos) => {
                // console.log(deviceInfos, '@@@@')
                for (let i = 0; i !== deviceInfos.length; ++i) {
                  const deviceInfo = deviceInfos[i]
                  if (deviceInfo.kind === 'videoinput') {
                    this.cameras.push(deviceInfo)
                  }
                }

                if (this.cameras.length === 0) {
                  this.noDevice = true
                }
              })
              .then(() => {
                if (!this.camerasListEmitted) {
                  if (this.cameras.length === 1) {
                    this.deviceId = this.cameras[0].deviceId
                    this.start()
                  } else if (this.cameras.length > 1) {
                    this.deviceId = this.cameras[0].deviceId
                    this.videoListVisible = true
                  } else {
                    this.noDevice = true
                  }
                  // this.$emit('cameras', this.cameras)
                  this.camerasListEmitted = true
                }
              })
              .catch((error) => this.$emit('notsupported', error))
          })
          .catch((e) => {
            console.error(e)
            Notification.error({
              title: '提示信息',
              message: '请检查您的音频，视频设备是否正常，浏览器是否允许访问音频，视频设备！'
            })
          })
      } else {
        Notification.error({
          title: '提示信息',
          message: '浏览器不支持视频和音频，请更换浏览器'
        })
      }
    },
    // 建立本地与信令服务器的连接
    initDevice() {
      if (!this.webRtcPeer) {
        const constraints = {
          audio: true,
          video: {
            // width: { min: 540 },
            // height: { min: 360 },
            deviceId: { exact: this.deviceId },
            facingMode: 'user'
          }
        }
        if(this.minWidth&&this.minWidth>0){
          constraints.video.width = { min:0}
          constraints.video.width.min = this.minWidth
        }
        if(this.minHeight&&this.minHeight>0){
          constraints.video.height = { min:0}
          constraints.video.height.min = this.minHeight
        }
        if(this.idealWidth&&this.idealWidth>0){
          constraints.video.width = { ideal:0}
          constraints.video.width.ideal = this.idealWidth
        }
        if(this.idealHeight&&this.idealHeight>0){
          constraints.video.height = { ideal:0}
          constraints.video.height.ideal = this.idealHeight
        }
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            // 添加自己的中继服务器的配置，否则会默认指向谷歌的服务器
            const iceservers = {
              iceServers: [
                {
                  urls: `${process.env.VUE_APP_ICESERVER_STUN_URL}`
                },
                {
                  urls: [`${process.env.VUE_APP_ICESERVER_TURN_URL}`],
                  username: `${process.env.VUE_APP_ICESERVER_TURN_USERNAME}`,
                  credential: `${process.env.VUE_APP_ICESERVER_TURN_CREDENTIAL}`
                }
              ]
            }

            const options = {
              localVideo: this.$refs.video,
              configuration: iceservers,
              onicecandidate: this.onIceCandidate,
              videoStream: stream
            }
            this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
              options,
              (error) => {
                if (error) {
                  return console.log(error, '错误信息是')
                }
                this.webRtcPeer.generateOffer(this.onOfferPresenter)
              }
            )
          })
          .catch((error) => this.$emit('error', error))
      }
      this.ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)
        // console.log('收到的消息', message.data)
        switch (parsedMessage.id) {
          case 'presenterResponse':
            this.presenterResponse(parsedMessage)
            break
          case 'viewerResponse':
            this.viewerResponse(parsedMessage)
            break
          case 'iceCandidate':
            this.webRtcPeer.addIceCandidate(
              parsedMessage.candidate,
              function(error) {
                if (error) {
                  return console.log('添加候选项错误', error)
                }
              }
            )
            break
          case 'incomingCall':
            this.incomingCall(parsedMessage)
            break
          case 'stopCommunication':
            this.dispose()
            break
          case 'massNotification':
            this.massNotification(parsedMessage)
            break
          case 'alarmNotification':
            this.alarmNotification(parsedMessage)
            break
          case 'alarm':
            this.alarm(parsedMessage)
            break
          case 'interruptionNotify':
            this.errorResponse(parsedMessage)
            break
          case 'recording':
            console.log('视频开始录制', parsedMessage)
            break
          case 'stopped':
            console.log('视频终止录制', parsedMessage)
            break
          case 'paused':
            console.log('视频暂停录制', parsedMessage)
            break
          case 'pantResponse':
            this.pantResponse(parsedMessage)
            break
          case 'finish':
            this.finishResponse(parsedMessage)
            break
          default:
            console.log('无法识别的信息', parsedMessage)
          // Notification.error({
          //   title: '提示信息',
          //   message: '系统出错请联系管理员'
          // })
        }
      }
      this.ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(
            `[websocket close]连接关闭, code=${event.code} reason=${event.reason}`
          )
          this.dispose()
        } else {
          // 例如服务器进程被杀死或网络中断
          // 在这种情况下，event.code 通常为 1006
          console.log('[websocket close] Connection died')
          // this.start()
        }
      }

      this.ws.onerror = (error) => {
        // this.start()
        console.log(`[websocket error] ${error.message}`)
      }
    },
    presenterResponse(message) {
      if (message.response == 'accepted') {
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) {
            return console.log(error, '错误是')
          }
        })
      } else {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受', errorMsg)

        this.dispose(true)
      }
    },
    viewerResponse(message) {
      if (message.response != 'accepted') {
        const errorMsg = message.message ? message.message : 'Unknow error'
        console.log('由于以下原因不被接受', errorMsg)
        this.dispose()
      } else {
        this.webRtcPeer.processAnswer(message.sdpAnswer, (error) => {
          if (error) {
            return console.log(error, '错误是')
          }
        })
      }
    },
    incomingCall(message) {
      console.log('incoming call from ', message)
      const subdata = {
        ...message,
        isReady: true
      }
      // 默认直接接通电话

      this.$alert('监考老师正在给你打电话', '温馨提示', {
        confirmButtonText: '知道了',
        callback: (action) => {
          console.log('准备建立语音通道', subdata)
          this.$emit('readyCall', subdata)
        }
      })
    },
    massNotification(message) {
      this.centerDialogVisible = true
      this.noticeMsg = message.message
      console.log(message, 'message是什么')
    },
    alarmNotification(message) {
      Notification.error({
        title: '提示信息',
        message: message.message
          ? message.message
          : '请检查您的手机，是否处于监考页面'
      })
    },
    finishResponse(message) {
      Notification.success({
        title: '提示信息',
        message: '考试已结束'
      })
      this.dispose()
    },
    alarm(message) {
      // console.log(message, '警告信息是什么')
      if (message.snap) {
        const newArr = []
        for (let i = 0; i < message.snapNum; i++) {
          const newObj = {
            sort: i + 1,
            imageData: this.capture()
          }
          newArr.push(newObj)
        }
        this.upLoadWarning(message.inspectAlarmId, newArr)
        // this.$emit('alarm',message)
        if (this.camType === 'facade') {
          this.$alert(message.message, '警告', {
            confirmButtonText: '知道了',
            callback: (action) => {
              console.log('知道了')
            }
          })
        }
      }
    },
    errorResponse(message) {
      // console.log(message, '连接错误信息是什么')
      this.$emit('alterError', message)
    },
    pantResponse(message) {
      // console.log(message, 'pantResponse信息是什么')
      this.$emit('checkConnected', message)
    },
    onOfferPresenter(error, offerSdp) {
      // facade ： 正面视频
      // side ： 侧面视频
      // desktop ： 桌面视频

      if (error) {
        return console.log('生成offer错误')
      }
      const message = {
        id: 'presenter',
        sdpOffer: offerSdp,
        type: this.camType,
        name: this.paperid,
        kicked: true
      }
      if (this.camType != 'facade') {
        message.mode = 'video-only'
      } else {
        message.mode = ''
      }
      // console.log(message, '视频发送')
      this.sendMessage(message)
    },
    onIceCandidate(candidate) {
      // console.log('当地的候选人', JSON.stringify(candidate))
      const message = {
        id: 'onIceCandidate',
        candidate: candidate,
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
    },
    sendMessage(message) {
      const jsonMessage = JSON.stringify(message)
      this.ws.send(jsonMessage)
    },

    // fire 是否触发disconnected事件
    dispose(closeEmitter) {
      clearInterval(this.timer)
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose()
        this.webRtcPeer = null
      }
      if (this.webRtcPeer2) {
        this.webRtcPeer2.dispose()
        this.webRtcPeer2 = null
      }
    },
    start() {
      // WebSocket.readyState
      // 返回当前 WebSocket 的链接状态，只读。
      // 语法
      // var readyState = WebSocket.readyState;
      // 值
      // 以下其中之一
      // 0 (WebSocket.CONNECTING)
      // 正在链接中
      // 1 (WebSocket.OPEN)
      // 已经链接并且可以通讯
      // 2 (WebSocket.CLOSING)
      // 连接正在关闭
      // 3 (WebSocket.CLOSED)
      // 连接已关闭或者没有链接成功

      // 检查是否需要重新创建 websocket
      let toCreate = true
      if (
        this.ws &&
        (this.ws.readyState == WebSocket.CONNECTING ||
          this.ws.readyState == WebSocket.OPEN)
      ) {
        // 正在链接中 || 已经链接并且可以通讯
        toCreate = false
      }

      if (toCreate) {
        try {
          const wsUrl = `${process.env.VUE_APP_SIGNAL_BASE_API}/signal/invigilation`
          // const wsUrl = 'wss://live.ntces.cn/tutorialcall/k'
          this.ws = new WebSocket(wsUrl)
          // console.log(this.ws, this.ws.readyState, WebSocket.CONNECTING, WebSocket.OPEN, 'websockt')

          let wsState = false

          this.ws.onopen = (e) => {
            wsState = true
            if (this.deviceId) {
              this.initDevice()
            } else {
              Notification.error({
                title: '提示信息',
                message: '没有可用的摄像头，请检查设备'
              })
            }

            this.peng()
          }
          setTimeout(() => {
            // console.log(wsState, '连接失败——————————————————————————————————————————')
            if (!wsState) {
              this.$emit('webjoin')
            }
          }, 3000)
        } catch (e) {
          this.$emit('webjoin')
        }
      }

      // if (this.ws) {
      //   this.ws.close()
      // }
    },
    stop() {
      // console.log('停止拍摄')
      const message = {
        id: 'stop',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
      this.dispose()
      if (this.ws) {
        this.ws.close()
      }
    },
    unfocusAlarm() {
      const message = {
        id: 'unfocusAlarm',
        type: this.camType,
        name: this.paperid,
        message: ''
      }
      this.sendMessage(message)
      this.$emit('unfocusWarning')
    },
    interruption() {
      // console.log('中断')
      const message = {
        id: 'interruption',
        type: this.camType,
        name: this.paperid
      }
      this.sendMessage(message)
    },
    peng() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        // console.log('心跳 presenter:', this.camType, this.paperid)
        const message = {
          id: 'pant',
          type: this.camType,
          name: this.paperid
        }
        this.sendMessage(message)
      }, 3000)
    },
    async upLoadWarning(inspectAlarmId, pictureList) {
      const subData = {
        inspectAlarmId: inspectAlarmId,
        type: this.camType,
        pictureList: pictureList
      }
      const res = await httpuploadPicture(subData)
      if (res.sucess) {
        console.log('抓拍成功')
      }
    },
    // httpsnapuploadPicture
    async upLoadsnap() {
      console.log('开始抓拍比对')
      const subData = {
        examId: this.examId,
        paperId: this.paperid,
        imageData: this.capture()
      }
      const res = await httpsnapuploadPicture(subData)
      if (res.success) {
        console.log('抓拍对比成功')
        this.$emit('captureSucess', true)
      } else {
        this.$emit('captureSucess', false)
        // Notification.error({
        //   title: '提示信息',
        //   message: res.msg
        // })
      }
    },
    // 静默截屏，截屏时候无感知
    capture() {
      // console.log('抓拍')
      const video = this.$refs.video
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.videoHeight
      canvas.width = video.videoWidth
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataImage = canvas.toDataURL('image/jpeg')
      // 转换成base64去除头
      const base64 = dataImage.replace(/^data:image\/\w+;base64,/, '')
      // this.$emit('capture', base64)
      return base64
    },
    handleOK() {
      this.centerDialogVisible = false
    },
    handleChooseCamera(id) {
      this.deviceId = id
      this.videoListVisible = false
      this.start()
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
.notice-box span {
  line-height: 32px;
  display: block;
}
.grid-content {
  margin: 5px 0;
}
</style>
