import { post } from '@/utils/request'

export function fetchDetail(id) {
  return post('/api/sys/user/msg/detail-for-read', { id: id })
}

export function fetchUnread() {
  return post('/api/sys/user/msg/count-unread', { })
}

export function markAsRead(data) {
  return post('/api/sys/user/msg/mark-read', data)
}

export function userDelete(data) {
  return post('/api/sys/user/msg/user-delete', data)
}
