var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_c("user-banner")], 1),
    _c("div", { staticStyle: { display: "flex", "margin-top": "50px" } }, [
      _c(
        "div",
        { staticStyle: { width: "200px", background: "#f8f8f8" } },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              staticStyle: { width: "200px" },
              attrs: {
                "default-active": _vm.active,
                "background-color": "#f8f8f8",
              },
              on: { select: _vm.menuSelect },
            },
            [
              _c(
                "el-submenu",
                {
                  attrs: { index: "settings" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("用户设置")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-menu-item", { attrs: { index: "user" } }, [
                    _vm._v(" 个人资料 "),
                  ]),
                  _c("el-menu-item", { attrs: { index: "pass" } }, [
                    _vm._v(" 密码安全 "),
                  ]),
                  _c("el-menu-item", { attrs: { index: "connect" } }, [
                    _vm._v(" 账号绑定 "),
                  ]),
                  _vm.siteData.props.faceLogin
                    ? _c("el-menu-item", { attrs: { index: "real-person" } }, [
                        _vm._v(" 实名认证 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.siteData.props.moduleExam
                ? _c(
                    "el-menu-item",
                    { staticClass: "module-exam", attrs: { index: "exam" } },
                    [
                      _c("i", { staticClass: "el-icon-document" }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("考试成绩"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.siteData.props.moduleExam
                ? _c(
                    "el-menu-item",
                    { staticClass: "module-exam", attrs: { index: "train" } },
                    [
                      _c("i", { staticClass: "el-icon-data-line" }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("训练记录"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("el-menu-item", { attrs: { index: "msg" } }, [
                _c("i", { staticClass: "el-icon-chat-round" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("站内信息"),
                ]),
              ]),
              _c("el-menu-item", { attrs: { index: "points" } }, [
                _c("i", { staticClass: "el-icon-coin" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("积分日志"),
                ]),
              ]),
              _c("el-menu-item", { attrs: { index: "myCertificate" } }, [
                _c("i", { staticClass: "el-icon-coin" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的证书"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "flex-grow": "1",
            border: "#f1f1f1 1px solid",
            padding: "30px",
          },
        },
        [
          _vm.active === "user" ? _c("update-info") : _vm._e(),
          _vm.active === "pass" ? _c("update-pass") : _vm._e(),
          _vm.active === "connect" ? _c("connect-bind") : _vm._e(),
          _vm.active === "real-person" ? _c("real-person") : _vm._e(),
          _vm.active === "exam" ? _c("my-paper") : _vm._e(),
          _vm.active === "train" ? _c("train-history") : _vm._e(),
          _vm.active === "msg" ? _c("msg") : _vm._e(),
          _vm.active === "points" ? _c("points-log") : _vm._e(),
          _vm.active === "myCertificate" ? _c("MyCertificate") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }