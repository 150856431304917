<template>
  <div class="wrap">
    <el-descriptions :colon="false" title="基本信息" border>
      <el-descriptions-item label="版本"> V1.0.0 </el-descriptions-item>
      <el-descriptions-item label="软件语言"> 简体中文 </el-descriptions-item>
      <el-descriptions-item label="系统兼容">
        支持 64位的 Win10 / Win11 操作系统
      </el-descriptions-item>
      <el-descriptions-item label="大小"> 185 KB </el-descriptions-item>

      <el-descriptions-item label="更新时间"> 2024/06/25 </el-descriptions-item>

      <el-descriptions-item label="其他">
        <el-row :span="20">
          <el-tag class="right_10">安全无毒</el-tag>
          <el-tag type="success">无插件</el-tag>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>

    <div class="content_wrap">
      <a
        href="https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip"
        target="_blank"
      >
        <el-button type="primary">点此下载</el-button>
      </a>
    </div>

    <div class="content_wrap">
      <div class="content_tit">安装说明</div>
      <div class="content_item">
        <div class="content_item_tit">
          1.
          将压缩包下载到本地解压后，鼠标双击解压后的文件，即可完成安装并启动。
        </div>
        <div class="content_item_box">
          <el-image :src="caozuoImg.az_1" :fit="'fill'" style="width: 48%;"/>
        </div>
      </div>
      <div class="content_item">
        <div class="content_item_tit">
          2.
          部分用户在安装后运行应用程序时可能会出现如下图所示的无法打开情况。这是windows系统启用了安全机制
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_2" :fit="'fill'" style="width: 48%;"/>
        </div>
        <div class="content_item_tit">
          您可以通过允许安装进行继续操作：更多信息 -> 仍要运行 如下图：
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_3" :fit="'fill'" style="width: 48%;"/>
        </div>
        <div class="content_item_tit">
          3. 根据安装向导提示完成安装选项、安装位置的设置，点击 完成 即可完成安装并运行考试客户端程序。
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_4" :fit="'fill'" style="width: 48%;"/>
          <el-image :src="caozuoImg.az_5" :fit="'fill'" style="width: 48%;"/>
        </div>
        <div class="content_item_tit">
          注：软件安装时，若弹出如下提示，是由于电脑系统版本缺少必备文件导致，请下载vc runtime安装包（注：安装完成后需要重启电脑），下载地址：
        </div>
        <div>
          <a style="color: blue" href="https://learn.microsoft.com/zh-cn/cpp/windows/latest-supported-vc-redist?view=msvc-170">
            https://learn.microsoft.com/zh-cn/cpp/windows/latest-supported-vc-redist?view=msvc-170
          </a>
        </div>
        <div class="content_item_box_2">
          <el-image :src="caozuoImg.az_6" :fit="'fill'" style="width: 48%;"/>
          <el-image :src="caozuoImg.az_7" :fit="'fill'" style="width: 48%;"/>
        </div>
      </div>
    </div>
    <div class="footer"/>
  </div>
</template>

<script>
import az_1 from '@/assets/web/downWin_1.jpg'
import az_2 from '@/assets/web/downWin_2.png'
import az_3 from '@/assets/web/downWin_3.png'
import az_4 from '@/assets/web/downWin_4.png'
import az_5 from '@/assets/web/downWin_5.png'
import dllError from '@/assets/web/dllError.png'
import vcAz from '@/assets/web/vcAz.png'
export default {
  name: 'DownWin',
  data() {
    return {
      activeName: 'win',
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      caozuoImg: {
        az_1: az_1,
        az_2: az_2,
        az_3: az_3,
        az_4: az_4,
        az_5: az_5,
        az_6: dllError,
        az_7: vcAz
      }
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style scoped>
.wrap {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
}
.hader_tit {
  text-align: center;
}

.content_wrap {
  margin-top: 30px;
}
.content_tit {
  line-height: 40px;
  font-weight: bold;
}
.content_item {
  margin: 20px auto;
}
.content_item_tit {
  line-height: 32px;
}
.content_item_box_2 {
  display: flex;
  justify-content: space-between;
}
.footer {
  width: 100%;
  height: 5px;
}
.flex {
  display: flex;
}
.right_10 {
  margin-right: 10px;
}
.down_tool_wrap {
  width: 100%;
  padding: 10px;
}
.down_tool_item {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.down_tool_btn {
  width: 150px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #000;
}
.iconfont {
  font-size: 25px;
}
</style>
