<template>
  <div>
    <div v-if="filled && value">
      <audio
        :src="value"
        controls="controls"
        controlslist="nodownload"
        oncontextmenu="return false"
      />
      <div>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="clearFilled"
        >重录</el-button
        >
      </div>
    </div>

    <div v-else class="record-box">
      <div style="display: flex; width: 100%; align-items: center">
        <div v-if="state === 0 || state === 1 || playing" class="line-box">
          <div :style="`height: ${line[0]}%`" class="line-item" />
          <div :style="`height: ${line[1]}%`" class="line-item" />
          <div :style="`height: ${line[2]}%`" class="line-item" />
        </div>

        <div v-if="state === 2 && !playing" class="line-box">
          <i class="el-icon-success" style="color: #1aac1a; font-size: 20px" />
        </div>

        <div class="title-box">
          <span>{{ msg }}</span>
        </div>
      </div>
      <div style="font-size: 24px">{{ formatSec() }}</div>
      <div style="margin-top: 15px">
        <el-button
          :disabled="state === 2"
          :type="state === 1 ? 'danger' : 'primary'"
          size="large"
          icon="el-icon-mic"
          style="font-size: 32px"
          circle
          @click="startRecord"
        />
      </div>
      <div class="opt-box">
        <el-button
          :disabled="state !== 2"
          :type="state === 2 ? 'danger' : 'default'"
          plain
          icon="el-icon-delete-solid"
          circle
          @click="clearRecord"
        />
        <el-button
          :disabled="state !== 2"
          :type="state === 2 ? 'primary' : 'default'"
          :icon="playing ? 'el-icon-video-pause' : 'el-icon-caret-right'"
          plain
          circle
          @click="playRecord"
        />
        <el-button
          :disabled="state !== 2"
          :loading="loading"
          :type="state === 2 ? 'success' : 'default'"
          plain
          icon="el-icon-upload"
          circle
          @click="saveRecord"
        />
      </div>

      <div v-if="tips" style="font-size: 12px; padding-top: 20px">
        {{ tips }}
      </div>
    </div>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
// import { uploadFile } from '@/utils/upload'
import { defaultUploadFile } from '@/utils/upload'

const recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1 // 声道，支持 1 或 2， 默认是1
})

export default {
  name: 'Recorder',
  props: {
    value: String,
    tips: String
  },
  data() {
    return {
      filled: false,
      line: [30, 65, 100],
      msg: '准备录音..',
      state: 0, // 0准备就绪，1录音中，2录音完成
      loading: false,
      seconds: 0,
      fileObj: null,
      playing: false
    }
  },

  beforeDestroy() {
    recorder.destroy()
  },

  mounted() {
    // 播放
    recorder.onplay = () => {
      this.playing = true
      this.msg = '录音播放中..'
    }

    // 停止播放
    recorder.onstopplay = () => {
      this.playing = false
      this.msg = this.state === 0 ? '准备录音..' : '录音已完成..'
    }

    // 播放结束
    recorder.onplayend = () => {
      this.playing = false
      this.msg = this.state === 0 ? '准备录音..' : '录音已完成..'
    }

    // 正在录音
    recorder.onprogress = ({ duration }) => {
      this.seconds = duration.toFixed(0)
      this.playRun()
    }
  },

  created() {
    // 检查录音权限
    this.getPermission()
    // 填充
    this.fillValue()
  },

  methods: {
    // 回填录音
    fillValue() {
      if (this.value) {
        this.filled = true
      }
    },

    // 重新录制
    clearFilled() {
      this.filled = false
    },

    // 检查权限
    getPermission() {
      Recorder.getPermission().then(
        () => {
          console.log('record ready')
        },
        () => {
          this.$message.error('操作失败，请检查设备是否具备录音功能！')
        }
      )
    },

    // 格式显示
    formatSec() {
      let min = this.seconds / 60
      let sec = this.seconds % 60
      min = Math.floor(min)
      sec = Math.floor(sec)
      return (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec)
    },

    // 单纯为了显示一个图标效果
    playRun() {
      for (let i = 0; i < this.line.length; i++) {
        const v = this.line[i]
        if (v < 100) {
          this.line[i] = v + 5
        } else {
          this.line[i] = 0
        }
      }
      this.$forceUpdate()
    },

    // 清理数据
    clearRecord() {
      // 就绪
      this.state = 0
      this.seconds = 0
      this.fileObj = null
      this.msg = '准备录音..'
      if (this.playing) {
        recorder.stopPlay()
      }

      this.ready = false
    },

    // 播放或暂停录音
    playRecord() {
      if (this.state === 2) {
        if (!this.playing) {
          recorder.play()
        } else {
          recorder.stopPlay()
        }
      }
    },

    // 开始录音
    startRecord() {
      // 正在录音则停止
      if (this.state === 1) {
        this.stopRecord()
        return
      }

      recorder.start().then(
        () => {
          this.msg = '正在录音中..'
          this.state = 1
        },
        (error) => {
          this.$message.error(`${error.name} : ${error.message}`)
        }
      )
    },

    // 录音结束
    stopRecord() {
      recorder.stop()
      // 获得录音文件
      this.fileObj = recorder.getWAVBlob()
      // 录音完成
      this.msg = '录音已完成..'
      this.state = 2
    },

    // 上传和保存录音文件
    saveRecord() {
      this.loading = true
      const file = new window.File([this.fileObj], 'record.wav', {
        type: 'audio/wav'
      })
      this.msg = '录音上传中..'
      // 上传文件
      defaultUploadFile(file).then(
        (res) => {
          this.loading = false
          this.$emit('input', res)
          this.$emit('success', res)
          this.msg = '录音上传成功！'
          this.$message.success('录音上传成功！')
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }
}
</script>

<style scoped>
.record-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 460px;
  height: auto;
  padding: 20px;
}

.title-box {
  display: flex;
  width: 100%;
  align-items: center;
  height: 20px;
  padding-left: 5px;
}

.line-box {
  height: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 19px;
}

.line-item {
  background: #0a84ff;
  width: 5px;
}

.opt-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-top: 20px;
}

.opt-box button {
  font-size: 18px;
}
</style>
