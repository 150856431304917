var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": false,
        title: "人脸认证",
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "face-box" },
        [
          _vm.errorcurrent > 0 && _vm.errorcurrent < 3
            ? _c("div", { staticClass: "errorTip" }, [
                _c("div", [
                  _vm._v("1、请核对您的姓名、身份证号信息是否正确。"),
                ]),
                _c("div", [
                  _vm._v(
                    "2、确保您的面部被足够的光线照亮，面部清晰，以便系统进行识别。"
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " 3、请确保您的面部无遮挡，以便准确识别。摘下帽子、眼镜或其他可能遮挡脸部的物品。 "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    "4、请保持面部处于画面中央位置，且面部表情稳定，以便准确识别。"
                  ),
                ]),
                _c("div", [
                  _vm._v("5、请调整您的识别角度，尽量与摄像头保持平视。"),
                ]),
              ])
            : _vm._e(),
          _vm.errorcurrent >= 3
            ? _c("div", { staticClass: "errorTip" }, [
                _c("div", [
                  _vm._v(
                    " 很抱歉，您连续几次识别都未通过，请再次尝试。如果仍然失败，请联系管理员或技术支持，核对系统录入信息是否正确。 "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.dialogVisible
            ? _c("tracking-cam", {
                ref: "cam",
                attrs: { tracking: true },
                on: {
                  "tracking-error": _vm.showManual,
                  tracked: _vm.handleTracked,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.manualVisible
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.isloading, type: "primary" },
                  on: { click: _vm.handleManual },
                },
                [_vm._v("拍照识别")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }