var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("h2", { staticClass: "title" }, [
        _vm._v(
          "试卷名称 ：" +
            _vm._s(_vm.examInfo.data.title) +
            " " +
            _vm._s(_vm.current)
        ),
      ]),
      _vm._l(_vm.examList, function (item, index) {
        return _c(
          "el-row",
          { key: item.id, staticClass: "grid-item", attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v(_vm._s(item.title)),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v(
                  "总" +
                    _vm._s(item.quCount) +
                    "题/共" +
                    _vm._s(item.totalScore) +
                    "分"
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.current < index,
                        type: _vm.current == index ? "primary" : "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.startExam(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.current > index ? "修改试题" : "开始答题")
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      }),
      _c(
        "h2",
        { staticClass: "title" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: _vm.current == _vm.examList.length ? "primary" : "",
              },
              on: { click: _vm.handDirect },
            },
            [_vm._v("立即交卷")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }