var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("h2", { staticClass: "hader_tit" }, [_vm._v("在线考试客户端")]),
      _c("div", { staticClass: "down_tool_wrap" }, [
        _c("div", { staticClass: "down_tool_item" }, [
          _c(
            "div",
            {
              class:
                _vm.active == "win" ? "down_tool_btn_active" : "down_tool_btn",
              on: { click: _vm.goWin },
            },
            [
              _c("i", { staticClass: "iconfont icon-windows" }),
              _c("span", [_vm._v("Windows系统")]),
            ]
          ),
          _c(
            "div",
            {
              class:
                _vm.active == "mac" ? "down_tool_btn_active" : "down_tool_btn",
              on: { click: _vm.goMac },
            },
            [
              _c("i", { staticClass: "iconfont icon-macos" }),
              _c("span", [_vm._v("Mac OS 系统")]),
            ]
          ),
        ]),
      ]),
      _c("router-view"),
      _c("div", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }