var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "left hidden-sm-and-down",
              attrs: { lg: 16, md: 10 },
            },
            [
              _c("img", {
                staticStyle: { height: "45vh" },
                attrs: { src: require("@/assets/web/images/login2.png") },
              }),
            ]
          ),
          _c("el-col", { staticClass: "right", attrs: { lg: 8, md: 14 } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "账号登录", name: "account" } },
                      [
                        _vm.activeName === "account"
                          ? _c(
                              "el-form",
                              {
                                ref: "postForm",
                                attrs: {
                                  model: _vm.postForm,
                                  rules: _vm.loginRules,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "username" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "用户名",
                                        "prefix-icon": "el-icon-user",
                                      },
                                      model: {
                                        value: _vm.postForm.username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm,
                                            "username",
                                            $$v
                                          )
                                        },
                                        expression: "postForm.username",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "password" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "密码",
                                        type: "password",
                                        "prefix-icon": "el-icon-lock",
                                        "show-password": "",
                                      },
                                      model: {
                                        value: _vm.postForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm,
                                            "password",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "postForm.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.captcha
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { prop: "captchaValue" } },
                                      [
                                        _c("qx-captcha", {
                                          ref: "captcha",
                                          attrs: { "submit-form": _vm.keyDown },
                                          model: {
                                            value: _vm.postForm,
                                            callback: function ($$v) {
                                              _vm.postForm = $$v
                                            },
                                            expression: "postForm",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          loading: _vm.loading,
                                          type: "primary",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.accountLogin($event)
                                          },
                                        },
                                      },
                                      [_vm._v("登录")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "line-height": "10px",
                    },
                  },
                  [
                    _vm.siteData.props.userReg
                      ? _c("el-link", { attrs: { href: "/register" } }, [
                          _vm._v("立即注册"),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.mobileLogin
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { href: "/forgot" },
                          },
                          [_vm._v("忘记密码？")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isDemo ? _c("demo-account") : _vm._e(),
                _c("div", { staticClass: "version" }, [
                  _vm._v(_vm._s(_vm.version)),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.h5Visible, width: "340px" },
          on: {
            "update:visible": function ($event) {
              _vm.h5Visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "code-tips" }, [_vm._v("扫码进入H5学员端")]),
          _c(
            "div",
            { staticStyle: { width: "300px", border: "#ddd 1px solid" } },
            [
              _c("vue-qr", {
                attrs: {
                  size: 298,
                  "logo-src": _vm.siteData.backLogoUrl,
                  "logo-scale": 0.2,
                  text: _vm.siteData.h5Host,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.mpVisible, width: "340px" },
          on: {
            "update:visible": function ($event) {
              _vm.mpVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "code-tips" }, [
            _vm._v("扫码进入小程序学员端"),
          ]),
          _c("img", {
            staticStyle: { width: "300px", border: "#ddd 1px solid" },
            attrs: { src: _vm.siteData.mpCode },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }