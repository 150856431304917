var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "watch-box" }, [
        _c(
          "div",
          { staticClass: "watch-left" },
          [
            _c(
              "el-card",
              { staticStyle: { width: "100%", height: "calc(100vh - 80px)" } },
              [
                _vm._v(" 自动翻页： "),
                _c(
                  "div",
                  [
                    _c("el-switch", {
                      attrs: { "active-text": "开", "inactive-text": "关" },
                      model: {
                        value: _vm.autoPage,
                        callback: function ($$v) {
                          _vm.autoPage = $$v
                        },
                        expression: "autoPage",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [_vm._v("刷新时间：")]),
                _c(
                  "div",
                  [
                    _c("el-input-number", {
                      attrs: { min: 5, max: 999, size: "mini" },
                      model: {
                        value: _vm.refreshTime,
                        callback: function ($$v) {
                          _vm.refreshTime = $$v
                        },
                        expression: "refreshTime",
                      },
                    }),
                    _vm._v(" 秒 "),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "font-size": "12px", color: "#0a84ff" } },
                  [_vm._v(" 即将在" + _vm._s(_vm.seconds) + "秒后刷新 ")]
                ),
                _c("el-divider"),
                _c(
                  "div",
                  { staticStyle: { color: "#555", "line-height": "30px" } },
                  [
                    _c("div", [
                      _vm._v(
                        "应考人数：" + _vm._s(_vm.stat.requireCount) + "人"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "在考人数：" + _vm._s(_vm.stat.processCount) + "人"
                      ),
                    ]),
                    _c("div", [
                      _vm._v("交卷人数：" + _vm._s(_vm.stat.handCount) + "人"),
                    ]),
                    _c("div", [
                      _vm._v("缺考人数：" + _vm._s(_vm.stat.missCount) + "人"),
                    ]),
                  ]
                ),
                _c("el-divider"),
                _vm.stat.processCount > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.batchDialog },
                          },
                          [_vm._v("群发消息")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "watch-right" },
          [
            _c("data-grid", {
              ref: "dataGrid",
              attrs: { options: _vm.options, "list-query": _vm.listQuery },
              scopedSlots: _vm._u([
                {
                  key: "filter-content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0px 0px 10px 0px",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-left": "10px",
                            },
                            attrs: { placeholder: "搜索编号", size: "small" },
                            model: {
                              value: _vm.listQuery.params.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery.params, "code", $$v)
                              },
                              expression: "listQuery.params.code",
                            },
                          }),
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { placeholder: "请选择机位" },
                              model: {
                                value: _vm.jiweiValue,
                                callback: function ($$v) {
                                  _vm.jiweiValue = $$v
                                },
                                expression: "jiweiValue",
                              },
                            },
                            _vm._l(_vm.jiweiOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { label: "" },
                              model: {
                                value: _vm.listQuery.params.online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery.params, "online", $$v)
                                },
                                expression: "listQuery.params.online",
                              },
                            },
                            [_vm._v("全部 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "false" },
                              model: {
                                value: _vm.listQuery.params.online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery.params, "online", $$v)
                                },
                                expression: "listQuery.params.online",
                              },
                            },
                            [_vm._v("离线")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "true" },
                              model: {
                                value: _vm.listQuery.params.online,
                                callback: function ($$v) {
                                  _vm.$set(_vm.listQuery.params, "online", $$v)
                                },
                                expression: "listQuery.params.online",
                              },
                            },
                            [_vm._v("在线")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { label: "" },
                              model: {
                                value: _vm.listQuery.params.answerStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery.params,
                                    "answerStatus",
                                    $$v
                                  )
                                },
                                expression: "listQuery.params.answerStatus",
                              },
                            },
                            [_vm._v("全部 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.listQuery.params.answerStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery.params,
                                    "answerStatus",
                                    $$v
                                  )
                                },
                                expression: "listQuery.params.answerStatus",
                              },
                            },
                            [_vm._v("未答卷")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.listQuery.params.answerStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery.params,
                                    "answerStatus",
                                    $$v
                                  )
                                },
                                expression: "listQuery.params.answerStatus",
                              },
                            },
                            [_vm._v("考试中")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              model: {
                                value: _vm.listQuery.params.answerStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery.params,
                                    "answerStatus",
                                    $$v
                                  )
                                },
                                expression: "listQuery.params.answerStatus",
                              },
                            },
                            [_vm._v("已交卷")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "data-item",
                  fn: function (ref) {
                    var data = ref.data
                    return [
                      _c("el-card", { staticClass: "user-card" }, [
                        _c("div", { staticClass: "video-box" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.jiweiValue == "2",
                                  expression: "jiweiValue == '2'",
                                },
                              ],
                              staticClass: "main-video",
                            },
                            [
                              _c("video-viewer", {
                                attrs: {
                                  height: 100,
                                  "cam-type": "desktop",
                                  online: data.online,
                                  paperid: data.id,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "sub-video" }, [
                            _c(
                              "div",
                              { staticClass: "second-video" },
                              [
                                _c("video-viewer", {
                                  attrs: {
                                    height: 100,
                                    "cam-type": "side",
                                    online: data.online,
                                    paperid: data.id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "third-video" },
                              [
                                _c("video-viewer", {
                                  attrs: {
                                    height: 100,
                                    online: data.online,
                                    "cam-type": "facade",
                                    paperid: data.id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "user-name",
                                on: {
                                  click: function ($event) {
                                    return _vm.goDetail(data.studentId)
                                  },
                                },
                              },
                              [_c("a", [_vm._v(_vm._s(data.realName))])]
                            ),
                            _c("div", [
                              data.online
                                ? _c("span", { staticClass: "online" }, [
                                    _vm._v(" 在线 "),
                                  ])
                                : _c("span", { staticClass: "offline" }, [
                                    _vm._v(" 离线 "),
                                  ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "120px",
                                  "text-align": "right",
                                },
                              },
                              [
                                data.paperState >= 0
                                  ? _c(
                                      "span",
                                      {
                                        class: {
                                          online: data.paperState == 0,
                                          offwarn: data.paperState > 0,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.paperState_dictText) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("span", { staticClass: "offline" }, [
                                      _vm._v(" 未考试 "),
                                    ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              width: "100%",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#888",
                                },
                              },
                              [
                                _vm._v(
                                  " 答题进度:" +
                                    _vm._s(data.answerCount) +
                                    "/" +
                                    _vm._s(data.quCount) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _c("a", [
                                _c("i", {
                                  staticClass: "el-icon-service",
                                  staticStyle: { "margin-right": "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleConsultModal(data)
                                    },
                                  },
                                }),
                              ]),
                              _c("a", [
                                _c("i", {
                                  staticClass: "el-icon-view",
                                  on: {
                                    click: function ($event) {
                                      return _vm.watch(data)
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              width: "100%",
                              "justify-content": "space-around",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: { placement: "right", trigger: "click" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "forewarning-btn-box" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "forewarning-btn",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleWarning(
                                              data.id,
                                              33
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("疑似作弊")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "forewarning-btn",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleWarning(
                                              data.id,
                                              32
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("红牌警告")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { slot: "reference", size: "mini" },
                                    slot: "reference",
                                  },
                                  [_vm._v("预警")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !data.attentionId && data.id,
                                    expression: "!data.attentionId && data.id",
                                  },
                                ],
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.follow(data.id)
                                  },
                                },
                              },
                              [_vm._v("关注")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: data.attentionId,
                                    expression: "data.attentionId",
                                  },
                                ],
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelfollow(data.attentionId)
                                  },
                                },
                              },
                              [_vm._v("取消关注")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.chiefForceHandDialog(data.id)
                                  },
                                },
                              },
                              [_vm._v("强制交卷")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.handVisible,
            "close-on-click-modal": false,
            title: "全部收卷",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.handVisible = $event
            },
            close: function ($event) {
              _vm.handVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "handForm",
              attrs: {
                model: _vm.handForm,
                rules: _vm.handFormRules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "考卷状态", prop: "exState" } },
                [
                  _c("dic-list-select", {
                    staticStyle: { width: "100%" },
                    attrs: { "dic-code": "paper_ex_state" },
                    model: {
                      value: _vm.handForm.exState,
                      callback: function ($$v) {
                        _vm.$set(_vm.handForm, "exState", $$v)
                      },
                      expression: "handForm.exState",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收卷理由", prop: "message" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.handForm.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.handForm, "message", $$v)
                      },
                      expression: "handForm.message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.handVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.handLoading, type: "primary" },
                  on: { click: _vm.doHandAll },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.warningVisible,
            "close-on-click-modal": false,
            title: "预警提示信息",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.warningVisible = $event
            },
            close: _vm.handleWarningClose,
          },
        },
        [
          _c("el-input", {
            attrs: { type: "textarea" },
            model: {
              value: _vm.warningmessage,
              callback: function ($$v) {
                _vm.warningmessage = $$v
              },
              expression: "warningmessage",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleWarningClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.warningLoading, type: "primary" },
                  on: { click: _vm.forewarning },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("monitoring-details", {
        attrs: {
          "is-chief": false,
          datainfo: _vm.detailInfo,
          "detail-visible": _vm.detailVisible,
        },
        on: { detailClose: _vm.detailClose, handleWarning: _vm.handleWarning },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            title: _vm.chatName,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "chat-wrap" }, [
            _c(
              "div",
              { staticClass: "chat-box" },
              _vm._l(_vm.news, function (item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.type == 1,
                          expression: "item.type == 1",
                        },
                      ],
                      staticClass: "chat-left",
                    },
                    [
                      _c(
                        "el-avatar",
                        { staticStyle: { "margin-right": "10px" } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      _c("div", { staticClass: "chat-content" }, [
                        _vm._v(_vm._s(item.news)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.type == 2,
                          expression: "item.type == 2",
                        },
                      ],
                      staticClass: "chat-right",
                    },
                    [
                      _c(
                        "el-avatar",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v(" 我 ")]
                      ),
                      _c("div", { staticClass: "chat-content" }, [
                        _vm._v(_vm._s(item.news)),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "chat-Controls" }, [
              _c(
                "div",
                { staticClass: "chat-input" },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 4,
                      type: "textarea",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "chat-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.sendMsg },
                    },
                    [_vm._v("发送")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }