import { post } from '@/utils/request'
import { postJob } from '../../utils/request-job'

/**
 * 保存远程考试基本信息
 * @param data
 */
export function saveData(data) {
  return post('/api/remoteexam/remoteexam/save', data)
}

/**
 * 导入招聘系统职位
 * @param data
 */
export function importqxCareers(data) {
  return post('/api/remoteexam/career/importqxcareers', data)
}

/**
 * 导入招聘系统考生
 * @param data
 */
export function importqxresumes(data) {
  return post('/api/remoteexam/career/importqxresumes', data)
}

/**
 * 设定远程考试岗位的试卷
 * @param id
 * @returns {*}
 */
export function setup(data) {
  return post('/api/remoteexam/career/setup', data)
}

/**
 * 删除阅卷设置
 * @param id
 * @returns {Promise}
 */
export function deleteMarkerRule(data) {
  return post('/api/remoteexam/letter/cleanmakerrule', data)
}

/**
 * 查看远程考试
 * @param data
 * @returns {Promise}
 */
export function fetchRemoteexam(data) {
  return post('/api/remoteexam/remoteexam/update', data)
}

/**
 * 删除远程考试岗位
 * @param id
 * @returns {Promise}
 */
export function deleteCareer(data) {
  return post('/api/remoteexam/career/delete', data)
}

/**
 * 招聘项目
 * @param id
 * @returns {Promise}
 */
export function fetchStudentList(data) {
  return post('/api/remoteexam/student/paging', data)
}

/**
 * 招聘项目
 * @param id
 * @returns {Promise}
 */
export function fetchProject(data) {
  return postJob('/chr/openapi/recruitment/project/search', data)
}

/**
 * 招聘方式
 * @param id
 * @returns {Promise}
 */
export function fetchRecruitmentType(data) {
  return postJob('/chr/openapi/recruitment/recruitment/search', data)
}

/**
 * 租户列表
 * @param id
 * @returns {Promise}
 */
export function fetchTenancy(data) {
  return postJob('/chr/openapi/recruitment/tenancy/search', data)
}

/**
 * 删除监考老师
 * @param id
 * @returns {Promise}
 */
export function deleteInvigilator(data) {
  return post('/api/remoteexam/invigilator/delete', data)
}

/**
 * 一键分配
 * @param id
 * @returns {Promise}
 */
export function remoteexamAutoassign(data) {
  return post('/api/remoteexam/remoteexam/autoassign', data)
}

/**
 * 导入监考
 * @param id
 * @returns {Promise}
 */
export function remoteInvigilatorImportSysuser(data) {
  return post('/api/remoteexam/invigilator/importsysuser', data)
}

/**
 * 查看远程考试统计信息
 * @param id
 * @returns {Promise}
 */
export function remoteexamStatsUpdate(data) {
  return post('/api/remoteexam/stats/update', data)
}

/**
 * 阅卷设置
 * @param id
 * @returns {Promise}
 */
export function remoteexamLetterSetupMarker(data) {
  return post('/api/remoteexam/letter/setupMarker', data)
}

/**
 * 启用考试
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamPublish(data) {
  return post('/api/remoteexam/remoteexam/publish', data)
}

/**
 * 禁用考试
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamForbidden(data) {
  return post('/api/remoteexam/remoteexam/forbidden', data)
}

/**
 * 提交考试
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamPrepare(data) {
  return post('/api/remoteexam/remoteexam/preparemulti', data)
}

/**
 * 同步考试
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamSync(data) {
  return post('/api/remoteexam/remoteexam/sync', data)
}

/**
 * 一键分配试卷
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamAutotmpl(data) {
  return post('/api/remoteexam/remoteexam/autotmpl', data)
}
/**
 * 取消掉分配试卷
 * @param id
 * @returns {Promise}
 */
export function remoteexamRemoteexamDelettmpl(data) {
  return post('/api/remoteexam/remoteexam/deletetmpl', data)
}
/**
 * 删除阅卷组
 * @param id
 * @returns {Promise}
 */
export function delRule(data) {
  return post('/api/remoteexam/rule/delete', data)
}

/**
 * 查看阅卷设置时的题目列表
 * @returns {Promise}
 */
export function remoteexamLetterQulist(data) {
  return post('/api/remoteexam/letter/qulist', data)
}

/**
 * 完成考试编辑向导
 * @returns {Promise}
 */
export function remoteexamPrepare(data) {
  return post('/api/remoteexam/remoteexam/prepare', data)
}

/**
 * 删除考生
 * @returns {Promise}
 */
export function remoteexamStudentDelete(data) {
  return post('/api/remoteexam/student/delete', data)
}

/**
 * 新增考生
 * @returns {Promise}
 */
export function remoteexamStudentSave(data) {
  return post('/api/remoteexam/student/save', data)
}

/**
 * 分配监控老师
 * @returns {Promise}
 */
export function remoteexamStudentAssign(data) {
  return post('/api/remoteexam/student/assign', data)
}

/**
 * 发送通知
 * @returns {Promise}
 */
export function remoteexamStudentNotification(data) {
  return post('/api/remoteexam/student/notification', data)
}
/**
 * 获取通知模板
 * @returns {Promise}
 */
export function getNotificationtmplPaging(data) {
  return post('/api/notificationtmpl/paging', data)
}

/**
 * 远程考试岗位列表
 * @returns {Promise}
 */
export function remoteexamCareerList(data) {
  return post('/api/remoteexam/career/paging', data)
}

/**
 * 远程考试监考老师
 * @returns {Promise}
 */
export function remoteexamInvigilatorList(data) {
  return post('/api/remoteexam/invigilator/paging', data)
}

/**
 * 消息模板信息查询
 * @returns {Promise}
 */
export function remoteexamNotificationTmplUpdate(data) {
  return post('/api/notificationtmpl/update', data)
}

/**
 * 消息模板信息保存
 * @returns {Promise}
 */
export function remoteexamNotificationTmplSave(data) {
  return post('/api/notificationtmpl/save', data)
}

/**
 * 查询字典
 * @returns {Promise}
 */
export function sysDicValueTree(data) {
  return post('/api/sys/dic/value/tree', data)
}
/**
 * 清除分配
 * @returns {Promise}
 */
export function deleteAssign(data) {
  return post('/api/remoteexam/remoteexam/deleteAssign', data)
}
/**
 * 通知详情
 * @returns {Promise}
 */
export function notificationUpdate(data) {
  return post('/api/remoteexam/notification/update', data)
}

/**
 * 远程考试列表
 * @returns {Promise}
 */
export function remoteexamList(data) {
  return post('/api/remoteexam/remoteexam/paging', data)
}
