var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "left-content", attrs: { lg: 16, md: 10 } },
            [
              _c("div", { staticClass: "title-row" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.examInfo.title) + " 在线考试"),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " 考试时间: " +
                      _vm._s(_vm.examInfo.startTime) +
                      " - " +
                      _vm._s(_vm.examInfo.endTime) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v("欢迎你参加在线考试！"),
                    ]),
                    _c("div", { staticClass: "info-content" }, [
                      _vm._v(
                        " 请使用最新版Chrome谷歌浏览器、Firefox火狐浏览器、Safari浏览器、Edge浏览器进行考试;"
                      ),
                      _c("br"),
                      _vm._v("提前登录系统，检测调试电脑及手机设备;"),
                      _c("br"),
                      _vm._v("请关闭QQ微信等软件，不要跳出考试页面;"),
                      _c("br"),
                      _vm._v("注意考试纪律，诚信作答;"),
                      _c("br"),
                      _vm._v("网络故障中断考试，重新登录继续作答即可. "),
                    ]),
                    _c("div", { staticClass: "count-down" }, [
                      _vm._v(" 距离开考还有: "),
                      _c("div", { staticClass: "time-value" }, [
                        _vm._v(_vm._s(_vm.remainingTime)),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c("img", {
                      staticStyle: { height: "45vh" },
                      attrs: { src: require("@/assets/web/images/login2.png") },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { staticClass: "right", attrs: { lg: 8, md: 14 } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "用户登录", name: "account" } },
                      [
                        _vm.activeName === "account"
                          ? _c(
                              "el-form",
                              {
                                ref: "postForm",
                                attrs: {
                                  model: _vm.postForm,
                                  rules: _vm.loginRules,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "username" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "用户名",
                                        "prefix-icon": "el-icon-user",
                                      },
                                      model: {
                                        value: _vm.postForm.username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm,
                                            "username",
                                            $$v
                                          )
                                        },
                                        expression: "postForm.username",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "password" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "密码",
                                        type: "password",
                                        "prefix-icon": "el-icon-lock",
                                        "show-password": "",
                                      },
                                      model: {
                                        value: _vm.postForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm,
                                            "password",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "postForm.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "captchaValue" } },
                                  [
                                    _c("qx-captcha", {
                                      ref: "captcha",
                                      attrs: { "submit-form": _vm.keyDown },
                                      model: {
                                        value: _vm.postForm,
                                        callback: function ($$v) {
                                          _vm.postForm = $$v
                                        },
                                        expression: "postForm",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          loading: _vm.loading,
                                          type: "primary",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.accountLogin($event)
                                          },
                                        },
                                      },
                                      [_vm._v(" 登录 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isDemo ? _c("demo-account") : _vm._e(),
                _c("div", { staticClass: "version" }, [
                  _vm._v(_vm._s(_vm.version)),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.h5Visible, width: "340px" },
          on: {
            "update:visible": function ($event) {
              _vm.h5Visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "code-tips" }, [_vm._v("扫码进入H5学员端")]),
          _c(
            "div",
            { staticStyle: { width: "300px", border: "#ddd 1px solid" } },
            [
              _c("vue-qr", {
                attrs: {
                  size: 298,
                  "logo-src": _vm.siteData.backLogoUrl,
                  "logo-scale": 0.2,
                  text: _vm.siteData.h5Host,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.mpVisible, width: "340px" },
          on: {
            "update:visible": function ($event) {
              _vm.mpVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "code-tips" }, [
            _vm._v("扫码进入小程序学员端"),
          ]),
          _c("img", {
            staticStyle: { width: "300px", border: "#ddd 1px solid" },
            attrs: { src: _vm.siteData.mpCode },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }