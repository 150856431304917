var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-alert", {
        attrs: {
          type: "info",
          title:
            "未认证或认证失败的用户，可手动指定用于人脸识别的用户照片记录！",
        },
      }),
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "供应商",
                    prop: "providerName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "调用接口",
                    prop: "interfaceName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "认证结果",
                    prop: "responseSuccess",
                    width: "100px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.responseSuccess
                            ? _c("span", [_vm._v("成功")])
                            : _c("span", [_vm._v("失败")]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "接口调用时间",
                    prop: "createTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "人脸图片", align: "center", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-image", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.faceImage,
                                expression: "scope.row.faceImage",
                              },
                            ],
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: {
                              src:
                                "data:image/png;base64," + scope.row.faceImage,
                              "preview-src-list": [
                                "data:image/png;base64," + scope.row.faceImage,
                              ],
                              fit: "fill",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "操作",
                    prop: "points",
                    width: "100px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["sys:user:update"],
                                  expression: "['sys:user:update']",
                                },
                              ],
                              attrs: {
                                type: "primary",
                                icon: "el-icon-document-checked",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUnbind(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("指定")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }