var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cam-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.videoLock,
            expression: "!videoLock",
          },
        ],
      },
      [
        _c("video", {
          ref: "video",
          staticStyle: { background: "#262626" },
          attrs: {
            id: "video",
            width: _vm.width,
            height: _vm.height,
            playsinline: true,
            autoplay: true,
          },
        }),
        _c("canvas", {
          ref: "canvas",
          attrs: { id: "canvas", width: _vm.width, height: _vm.height },
        }),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.videoLock,
            expression: "videoLock",
          },
        ],
      },
      [_c("img", { attrs: { src: _vm.captureImage } })]
    ),
    _vm.paddingTop > 0
      ? _c(
          "div",
          { style: "padding-top:" + _vm.paddingTop + "px" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.deviceId,
                  callback: function ($$v) {
                    _vm.deviceId = $$v
                  },
                  expression: "deviceId",
                },
              },
              _vm._l(_vm.cameras, function (item) {
                return _c("el-option", {
                  key: item.deviceId,
                  attrs: { value: item.deviceId, label: item.label },
                })
              }),
              1
            ),
            _vm.tracking && _vm.showTrackTips && _vm.trackingMsg
              ? _c("div", { staticClass: "tips" }, [
                  _vm._v(" " + _vm._s(_vm.trackingMsg) + " "),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }