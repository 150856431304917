<template>
  <el-select
    v-model="currentValue"
    :disabled="disabled"
    :placeholder="title"
    :remote-method="fetchData"
    :filterable="true"
    size="small"
    remote
    reserve-keyword
    clearable
    automatic-dropdown
    class="filter-item"
    @change="handlerChange"
  >
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: 'DicListSelectProjectCommon',
  props: {
    title: String,
    value: {
      type: String | Number
    },
    disabled: Boolean,
    excludes: Array,
    api: Function,
    otherParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value + ''
      }
    },
    otherParams: {
      handler(val) {
        if (val) {
          this.fetchData()
        }
      },
      deep: true
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData()
  },
  methods: {
    fetchData(name) {
      const q = {
        view: 'openProjectList',
        filter: {},
        page: {
          pageNo: 1,
          pageSize: 10000
        }
      }
      if (name) {
        // q.filter.searchName = name
        q.filter.name = name
      }

      // 其他查询参数
      if (this.otherParams) {
        q.filter = { ...q.filter, ...this.otherParams }
      }

      this.api(q).then((response) => {
        let list = []

        if (
          response &&
          response.data &&
          response.data.items &&
          response.data.items.length > 0
        ) {
          list = response.data.items
        }

        this.dataList = list
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>
::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}
</style>
