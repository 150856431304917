<template>
  <div>
    <div style="height: 500px;margin: '0 auto';">
      v1
      <video-viewer :height="430" :width="550" :cam-type="'facade'" :paperid="'1549684036545978370'" />
    </div>
    <!-- <div style="height: 500px;margin: '0 auto';">
      v2
      <video-viewer :height="430" :width="550" :cam-type="'side'" :paperid="'1549684036545978370'" />
    </div>
    <div style="height: 500px;margin: '0 auto';">
      v3
      <video-viewer :height="430" :width="550" :cam-type="'desktop'" :paperid="'1549684036545978370'" />
    </div>
    <div id="qrcodediv" ref="qrcodediv" /> -->
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'TestViewer',
  data() {
    return {
      qrCode: null
    }
  },

  mounted() {
    this.createdQrCode()
  },

  methods: {
    createdQrCode() {
      console.log(this.$refs.qrcodediv, '@@@@@@@@@@@@@@')
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      this.qrCode = new QRCode(this.$refs.qrcodediv, {
        width: 180,
        height: 180,
        text: 'https://zpuat-exam.crtc-hr.com:8090/across/selectcamera?name=12201010101&type=side'
      })
      console.log(this.qrCode, '@@@@@@@@@@@@@@')
    }
  }
}
</script>


