var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "header" }, [_vm._v(_vm._s(_vm.stat.title))]),
        _c(
          "div",
          { staticClass: "controls-box" },
          [
            _c(
              "el-row",
              { staticStyle: { width: "30%" }, attrs: { gutter: 10 } },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("div", { staticClass: "controls-left-tit" }, [
                    _vm._v("机位:"),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 7 } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", size: "small" },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      },
                      _vm._l(_vm.options_1, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 7 } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "搜索编号", size: "small" },
                      model: {
                        value: _vm.listQuery.params.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "code", $$v)
                        },
                        expression: "listQuery.params.code",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              {
                staticStyle: {
                  width: "60%",
                  display: "flex",
                  "justify-content": "space-between",
                },
                attrs: { gutter: 5 },
              },
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "line-height": "32px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.listQuery.params.online,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery.params, "online", $$v)
                            },
                            expression: "listQuery.params.online",
                          },
                        },
                        [_vm._v("在线")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "line-height": "32px" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.listQuery.params.offline,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery.params, "offline", $$v)
                            },
                            expression: "listQuery.params.offline",
                          },
                        },
                        [_vm._v("离线")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.listQuery.current,
                    "page-size": 20,
                    total: _vm.total,
                    layout: "total, prev, pager, next, jumper",
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "supervisory-control-list" },
          _vm._l(_vm.videoList, function (item, index) {
            return _c(
              "el-card",
              {
                key: index,
                style: _vm.card_style,
                attrs: { "body-style": _vm.card_style_body, shadow: "always" },
              },
              [
                _c(
                  "div",
                  { staticClass: "supervisory-control-item" },
                  [
                    _c(
                      "div",
                      { staticClass: "video-box" },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticClass: "video-1" },
                                [
                                  _c("video-viewer", {
                                    attrs: {
                                      height: 150,
                                      width: 199,
                                      online: item.online,
                                      "cam-type": "facade",
                                      paperid: item.id,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticClass: "video-2" },
                                [
                                  _c("video-viewer", {
                                    attrs: {
                                      height: 150,
                                      width: 99,
                                      online: item.online,
                                      "cam-type": "side",
                                      paperid: item.id,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "supervisory-control-item-footer",
                        attrs: { gutter: 5 },
                      },
                      [
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c(
                            "div",
                            { staticClass: "supervisory-control-status" },
                            [_vm._v(_vm._s(item.realName))]
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 14 } }, [
                          _c(
                            "div",
                            { staticClass: "supervisory-control-tit" },
                            [
                              _vm._v(
                                "答题进度:" +
                                  _vm._s(item.answerCount) +
                                  "/" +
                                  _vm._s(item.quCount)
                              ),
                            ]
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c(
                            "div",
                            { staticClass: "supervisory-control-btn" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "forewarning-btn-box" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "forewarning-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleWarning(
                                                item.id,
                                                33
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("疑似作弊")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "forewarning-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleWarning(
                                                item.id,
                                                32
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("红牌警告")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-warning",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              ),
                              _c("i", {
                                staticClass: "el-icon-full-screen",
                                on: {
                                  click: function ($event) {
                                    return _vm.watch(item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.warningInfoVisible,
            title: "预警信息",
            width: "50%",
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { column: 4, title: "", direction: "vertical" },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "考生编号" } }, [
                _vm._v(_vm._s(_vm.warnInfo.studentCode)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "预警等级" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.warnInfo.alarmLevel == "31",
                        expression: "warnInfo.alarmLevel == '31'",
                      },
                    ],
                  },
                  [_vm._v("在线警示")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.warnInfo.alarmLevel == "32",
                        expression: "warnInfo.alarmLevel == '32'",
                      },
                    ],
                  },
                  [_vm._v("红牌警告")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.warnInfo.alarmLevel == "33",
                        expression: "warnInfo.alarmLevel == '33'",
                      },
                    ],
                  },
                  [_vm._v("疑似作弊")]
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { span: 2, label: "发起时间" } },
                [_vm._v(_vm._s(_vm.warnInfo.createTime))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { span: 4, label: "描述" } },
                [_vm._v(" " + _vm._s(_vm.warnInfo.msg.content) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { span: 4, label: "照片" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.warnInfo.pictureList &&
                            _vm.warnInfo.pictureList.length > 0,
                          expression:
                            "warnInfo.pictureList&&warnInfo.pictureList.length>0",
                        },
                      ],
                      staticClass: "wranImg_list",
                    },
                    _vm._l(_vm.warnInfo.pictureList, function (img, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "wranImg_item" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: img.facadeImageUrl, fit: "fill" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.warningInfoVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.warningVisible,
            "close-on-click-modal": false,
            title: "预警提示信息",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.warningVisible = $event
            },
            close: _vm.handleWarningClose,
          },
        },
        [
          _c("el-input", {
            attrs: { type: "textarea" },
            model: {
              value: _vm.warningmessage,
              callback: function ($$v) {
                _vm.warningmessage = $$v
              },
              expression: "warningmessage",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleWarningClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.warningLoading, type: "primary" },
                  on: { click: _vm.forewarning },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("monitoring-details", {
        attrs: {
          datainfo: _vm.detailInfo,
          "detail-visible": _vm.detailVisible,
        },
        on: { detailClose: _vm.detailClose, handleWarning: _vm.handleWarning },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }