var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cam-box" },
    [
      _c("div", [
        _c("video", {
          ref: "video",
          staticStyle: { background: "#262626" },
          attrs: {
            id: "video",
            width: _vm.width,
            height: _vm.height,
            playsinline: true,
            autoplay: true,
            muted: "",
          },
          domProps: { muted: true },
        }),
        _c("canvas", {
          ref: "canvas",
          attrs: { id: "canvas", width: _vm.width, height: _vm.height },
        }),
      ]),
      _vm.noDevice
        ? _c("el-empty", {
            attrs: {
              description:
                "摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！",
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoListVisible,
            title: "摄像头选择",
            width: "50%",
            center: "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoListVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.cameras, function (camera) {
              return _c(
                "div",
                { key: camera.deviceId },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }),
                        _vm._v(" " + _vm._s(camera.label) + " "),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "grid-content bg-purple" }),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type:
                                  _vm.deviceId == camera.deviceId
                                    ? "primary"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleChooseCamera(camera.deviceId)
                                },
                              },
                            },
                            [_vm._v("选择")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.centerDialogVisible,
            title: "消息通知",
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "notice-box" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.noticeMsg) + " ")]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOK } },
                [_vm._v("我知道了")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }