<template>
  <div>
    <div v-if="checkToolDialog==2||checkToolDialog==0" style="height: 150px">
      <video-presenter
        :height="150"
        :cam-type="'facade'"
        :paperid="paperId"
        :exam-id="examId"
        :capture-p="captureP"
        @alterError="alterError"
        @webjoin="nojion"
        @checkConnected="checkConnected"
        @captureSucess="captureSucess"
        @readyCall="readyCall"
      />
      <VideoCallerViewer
        v-if="isShowCall"
        :cam-type="'facade'"
        :muted="!isShowCall"
        :caller-info="callerInfo"
        :paperid="paperId"
      />
    </div>
    <div v-else style="height: 150px; background: #262626" />
    <!-- <video-viewer :height="100" :width="100" :cam-type="'facade'" :muted="true" :paperid="paperId" /> -->
    <div>
      <!-- 没有启动，步骤需要大于1；已启动，desktop中断了  -->
      <screen-sharing
        v-if="(!started && videoCurrentStep >= 3) || started"
        :paperid="paperId"
        :tracking="false"
        :height="0"
        @checkConnected="checkConnected"
      />
    </div>

    <el-dialog
      :visible="!started && videoCurrentStep == 1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="微信扫码，开启手机监控"
      width="50%"
      center
    >
      <div
        style="
          width: 100%;
          /* display: flex;
          justify-content: space-around; */
          padding: 10px;
        "
      >
        <mobile-step :paper-id="paperId" />
      </div>
    </el-dialog>
    <el-dialog
      :visible="!started && videoCurrentStep == 2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="屏幕共享桌面提示"
      width="50%"
      center
    >
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 10px;
        "
      >
        <el-image :src="url" fit="fill" style="width: 500px; height: 400px" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="startDesk">开启桌面共享</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="mobileVisible && started"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="请检查摄像头"
      width="30%"
    >
      <el-result :sub-title="errMsg" title="手机监控失效" icon="error">
        <template v-if="mobileVisible" slot="icon">
          <div
            v-if="mobileVisible"
            style="
              width: 100%;
              display: flex;
              justify-content: space-around;
              padding: 10px;
            "
          >
            <div id="qrcodediv" ref="qrcodediv" />
          </div>
        </template>
      </el-result>
    </el-dialog>
    <el-dialog
      :visible="videoVisible && started"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="请检查摄像头"
      width="30%"
    >
      <el-result
        v-if="videoVisible"
        icon="error"
        sub-title="请刷新页面，并重新检查视频或桌面监控失效"
        title="监控失效"
      />
    </el-dialog>
    <el-dialog
      :visible="checkToolDialog==3||checkToolDialog==1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title=""
      width="30%"
    >
      <el-result :sub-title="checkErrMsg" icon="error" title="温馨提示">
        <template v-if="checkToolDialog==1" slot="extra">
          <!-- <router-link to="/downtool/windows" tag="a" target="_blank">点此下载</router-link> -->
          <el-button @click="goBack">点此退出考试</el-button>
          <!-- <a
            href="https://exam-prod.obs.cn-north-4.myhuaweicloud.com/soft/anti_cheating_1.0.zip" target="_blank"
          >点此下载</a
          > -->
          <!-- <el-button type="primary" size="medium">返回</el-button> -->
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>

<script>
// import kurentoUtils from 'kurento-utils'
import QRCode from 'qrcodejs2'
import d1 from '@/assets/images/d1.jpg'
import VideoCallerViewer from '@/components/VideoCallerViewer'
import { checkTool } from '../../mixin.js'
export default {
  name: 'ExamCam',
  components: { VideoCallerViewer },
  mixins: [checkTool],
  props: {
    paperId: {
      type: [Number, String],
      default: ''
    },
    examId: {
      type: [Number, String],
      default: ''
    },
    captureP: {
      type: Boolean,
      default: false
    },
    checkToolShow: {
      type: String,
      default: 'false'
    }
  },
  data() {
    return {
      videoCurrentStep: 0,
      videoVisible: false,
      mobileVisible: false,
      qrCode: null,
      // 是否已成功打开
      started: false,
      onlyOne: false,
      url: d1,
      errMsg: '',
      isShowCall: false,
      callerInfo: {}
    }
  },
  watch: {
    // 检测查询变化
    // mobileVisible: {
    //   handler() {
    //     if (this.mobileVisible) {
    //       const timer = setInterval(() => {
    //         this.createdQrCode()
    //         if (this.$refs.qrcodediv) {
    //           clearInterval(timer)
    //         }
    //       }, 500)
    //     }
    //   },
    //   deep: true
    // }
  },

  methods: {
    showQrCode() {
      const timer = setInterval(() => {
        if (this.$refs.qrcodediv) {
          this.createdQrCode()
          clearInterval(timer)
        }
      }, 500)
    },
    goBack() {
      this.$router.go(-1)
    },
    createdQrCode() {
      console.log(this.qrCode, 'lllll')
      if (this.qrCode != null) {
        this.qrCode.clear()
      }
      const text = `${process.env.VUE_APP_URL_PREFIX}/across/selectcamera?name=${this.paperId}&type=side`
      this.qrCode = new QRCode(this.$refs.qrcodediv, {
        width: 200,
        height: 200,
        text: text
      })
      // console.log('二维码地址', text)
    },
    checkConnected(e) {
      if (e.response == 'accepted') {
        if (e.facade) {
          if (this.videoCurrentStep < 1) {
            this.videoCurrentStep = 1
          }

          if (e.side) {
            if (this.videoCurrentStep < 2) {
              this.videoCurrentStep = 2
            }
            this.mobileVisible = false
            if (e.desktop) {
              if (this.videoCurrentStep < 4) {
                this.videoCurrentStep = 4
                this.started = true
                this.videoVisible = false
              }
            }
          }
        }
        if (this.started && !this.onlyOne) {
          console.log(this.started, 'this.started')
          this.onlyOne = true
          // 已成功启动，触发消息 connected
          const e = {
            id: 'connected',
            started: this.started,
            paperId: this.paperid,
            deviceId: this.deviceId
          }
          this.$emit('started', e)
        }

        this.$emit('checkReady', e)
      }
    },
    alterError(message) {
      const err = {
        type: message.type,
        status: 2
      }
      this.$emit('checkReady', err)
      if (message.type == 'side') {
        this.mobileVisible = true
        this.qrCode = null
        this.errMsg =
          '手机监控失败，请检查手机摄像头是否正常连接，或者微信重新扫码登录'
        if (!this.qrCode) {
          this.showQrCode()
        }
      } else {
        this.errMsg =
          '摄像头或屏幕共享载入失败，请刷新页面或者关闭考试页面重新进入考试！'
        this.videoVisible = true
      }
    },
    captureSucess(flag) {
      this.$emit('captureSucess', flag)
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    nojion() {
      this.$alert('网络连接失败，请联系监考人员进行异常反馈', '温馨提示', {
        confirmButtonText: '知道了',
        callback: (action) => {
          this.$router.go(-1)
        }
      })
    },
    startDesk() {
      this.videoCurrentStep = 3
    },
    readyCall(state) {
      this.isShowCall = state.isReady
      this.callerInfo = state
    }
  }
}
</script>

<style scoped>
.cam-box {
  position: relative;
  width: 100%;
  height: auto;
}

video,
canvas,
img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
.err-tit {
  width: 100%;
  margin: 5px 0;
  line-height: 20px;
}
</style>
