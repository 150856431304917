var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "top" }, [
      _vm.siteData.frontLogoUrl
        ? _c("div", [
            _c("img", { attrs: { src: _vm.siteData.frontLogoUrl } }),
            _c(
              "span",
              {
                staticStyle: {
                  "margin-left": "10px",
                  height: "100%",
                  "vertical-align": "middle",
                  color: "white",
                },
              },
              [_vm._v(" " + _vm._s(_vm.siteData.verName))]
            ),
          ])
        : _c("div", { staticClass: "site-tt" }, [
            _vm._v(" " + _vm._s(_vm.siteData.siteName) + " "),
          ]),
    ]),
    _c("div", { staticClass: "content" }, [_c("app-main")], 1),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "footer-inner" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }