var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-infocon" }, [
    _c("div", { staticClass: "bg" }, [
      _c(
        "div",
        {
          staticStyle: { cursor: "pointer", position: "relative" },
          on: {
            click: function ($event) {
              _vm.uploadDialog = true
            },
          },
        },
        [
          _c(
            "el-image",
            { staticClass: "user-img", attrs: { src: _vm.userData.avatarUrl } },
            [
              _c(
                "div",
                { attrs: { slot: "error" }, slot: "error" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "user-img",
                      attrs: { src: _vm.userData.faceUrl },
                    },
                    [
                      _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                        _c("img", {
                          attrs: { src: require("@/assets/web/avatar.png") },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "infor",
          staticStyle: { "line-height": "15px", "margin-top": "0px" },
        },
        [
          _c("p", { staticClass: "name" }, [
            _vm._v(
              _vm._s(_vm.userData.realName) +
                " （ID：" +
                _vm._s(_vm.userData.id) +
                "）"
            ),
          ]),
          _c("p", { staticClass: "class" }, [
            _c("span", [
              _c("i", { staticClass: "el-icon-s-custom" }),
              _vm._v(" 部门：" + _vm._s(_vm.userData.deptCode_dictText)),
            ]),
          ]),
          _c("p", { staticClass: "class" }, [
            _c("span", [
              _c("i", { staticClass: "el-icon-s-finance" }),
              _vm._v(" 积分：" + _vm._s(_vm.userData.points)),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }