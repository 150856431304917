<template>
  <div class="watch-out-box">
    <div class="watch-out-header">
      <div class="watch-out-title">{{ stat.title }}</div>
      <el-badge :value="alarm.unRead" :hidden="alarm.unRead <= 0" style="margin-right: 50px;">
        <div>
          <el-radio-group v-model="activeName" size="mini">
            <el-radio-button
              label="process"
            >监考 ({{ stat.processCount }})</el-radio-button
            >
            <el-radio-button
              label="finished"
            >已交卷 ({{ stat.handCount }})</el-radio-button
            >
            <el-radio-button
              label="apply"
            >预警列表({{ alarm.total }})</el-radio-button
            >
          </el-radio-group>
        </div>
      </el-badge>
    </div>
    <inspector-process
      v-if="activeName === 'process'"
      :exam-id="examId"
      :news-list="newsList"
      :send-message="sendMsg"
      @stat="syncStat"
    />
    <inspector-hand v-if="activeName === 'finished'" :exam-id="examId" />
    <inspector-warning-list v-if="activeName === 'apply'" :exam-id="examId" @refreshCount ="refreshCount"/>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { getToken } from '@/utils/auth'

import InspectorWarningList from '@/views/admin/remoteTest/inspector/components/InspectorWarningList'
import InspectorProcess from '@/views/admin/remoteTest/inspector/components/InspectorProcess'
import InspectorHand from '@/views/admin/remoteTest/inspector/components/InspectorHand'
import examinationProcess from '@/views/admin/remoteTest/examination/components/examinationProcess'
import examinationHand from '@/views/admin/remoteTest/examination/components/examinationHand'
import { httpwatchStat, httpalarmcount } from '@/api/exam/watch'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectorProcess,
    InspectorHand,
    examinationProcess,
    examinationHand,
    InspectorWarningList
  },
  data() {
    return {
      examId: '',
      cacheKey: 'inspector-tab-active',
      activeName: 'process',
      stat: {
        requireCount: 0,
        processCount: 0,
        handCount: 0,
        missCount: 0,
        applyCount: 0
      },
      statTimer: null,
      isMain: false,
      newsList: [],
      alarm: {
        total: 0,
        unRead: 0
      }
    }
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'roles'])
  },
  watch: {
    activeName: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)

        // 统计数据从里面传出
        if (val === 'process') {
          this.clearTimer()
        } else {
          // 初始化统计数据
          this.initTimer()
        }
      }
    }
  },

  created() {
    console.log('@@@@@@@@@@@@', this.roles)
    this.roles.forEach((role) => {
      if (role == 'invigilatorChief') {
        this.isMain = true
      }
    })
    // 获取考试信息
    this.examId = this.$route.params.examId

    // 获取缓存
    const active = localStorage.getItem(this.cacheKey)
    if (active) {
      this.activeName = active
    }
    // 启动聊天室
    this.startChat()
    // 获取初始化预警数量
    this.getAlarmCount()
  },
  beforeDestroy() {
    this.clearTimer()
    this.stopChat()
  },
  methods: {
    // 获取统计信息
    fetchStat() {
      if (!this.examId) {
        return
      }
      httpwatchStat({ id: this.examId }).then((res) => {
        this.stat = res.data
      })
    },

    initTimer() {
      this.clearTimer()
      // 10秒获取一次
      this.statTimer = setInterval(this.fetchStat, 10000)
      // 获取一次信息
      this.fetchStat()
    },

    clearTimer() {
      if (this.statTimer) {
        clearInterval(this.statTimer)
      }
    },

    // 同步统计状态
    syncStat(data) {
      this.stat = data
    },

    sendMsg(content, currentStudent) {
      if (content) {
        const { studentId } = currentStudent
        if (this.newsList.length>0) {
          this.newsList.forEach((item) => {
            if (item.id != studentId) {
              this.newsList.push({
                id: studentId,
                news: [
                  {
                    type: 2,
                    news: content
                  }
                ]
              })
            } else {
              item.news.push({
                type: 2,
                news: content
              })
            }
          })
        } else {
          this.newsList.push({
            id: studentId,
            news: [
              {
                type: 2,
                news: content
              }
            ]
          })
        }
        const message = {
          content: content,
          // examId: this.examId,
          // paperId: this.paperId,
          receiver: studentId,
          type: 'CHAT'
        }
        this.stompClient.send(
          '/app/chat.send.to.student',
          {},
          JSON.stringify(message)
        )
      }
    },
    onMessageReceived(message) {
      const { body } = message
      if (body) {
        const msg = JSON.parse(body)
        console.log('收到了消息了', msg)
        if (this.newsList.length>0) {
          this.newsList.forEach((item) => {
            console.log(item, 'item是什么')
            if (item.id != msg.studentId) {
              console.log('进来了么', item.id, msg)
              this.newsList.push({
                id: msg.studentId,
                news: [
                  {
                    type: 1,
                    news: msg.content,
                    name: msg.senderName
                  }
                ]
              })
            } else {
              item.news.push({
                type: 1,
                news: msg.content,
                name: msg.senderName
              })
              console.log(this.newsList, '新的数据是什么')
            }
          })
        } else {
          this.newsList.push({
            id: msg.studentId,
            news: [
              {
                type: 1,
                news: msg.content,
                name: msg.senderName
              }
            ]
          })
          console.log(this.newsList, 'newsList有值么')
        }
      }
    },

    onAlarmMessageReceiveid(message) {
      const { body } = message
      if (body) {
        const msg = JSON.parse(body)
        const { total, unRead, remoteExamId } = msg

        if (this.examId === remoteExamId) {
          this.alarm = {
            total,
            unRead
          }
        } else {
          console.log('非本考试警告消息', msg, this.examId)
        }
        console.log('收到了警告消息了', msg)
      }
    },

    onChatConnected(frame) {
      console.log('>>> chat connected: ', frame)
      this.subscription = this.stompClient.subscribe(
        '/user/topic/msg',
        this.onMessageReceived,
        (error) => {
          console.log(error)
        }
      )
      this.alarmSubscription = this.stompClient.subscribe(
        '/user/topic/alarm',
        this.onAlarmMessageReceiveid,
        (error) => {
          console.log(error)
        }
      )
    },

    startChat() {
      const wsUrl = `${process.env.VUE_APP_CHAT_BASE_API}/api/chat`
      // const wsUrl = `ws://localhost:9203/chat`;
      this.socket = new SockJS(wsUrl)
      // this.socket = new WebSocket(wsUrl);
      this.stompClient = Stomp.over(this.socket)

      const token = getToken()
      this.stompClient.connect({ token }, this.onChatConnected, (err) => {
        // 连接失败
        console.log(err)
      })
    },
    stopChat() {
      if (this.alarmSubscription) {
        this.alarmSubscription.unsubscribe()
      }
      if (this.subscription) {
        this.subscription.unsubscribe()
      }
      if (this.stompClient) {
        this.stompClient.disconnect()
      }
    },
    async getAlarmCount() {
      const subdata = {
        id: this.examId
      }
      const res = await httpalarmcount(subdata)
      const { total, unRead } = res.data
      this.alarm = {
        total,
        unRead
      }
    },
    refreshCount() {
      this.getAlarmCount()
    }

  }
}
</script>

<style scoped>
.watch-out-box {
  background: #1e2c3c;
  padding: 10px;
  height: 100vh;
  border-radius: 5px;
  overflow: auto;
  margin: -10px;
}

.watch-out-header {
  display: flex;
  align-items: center;
  color: #fff;
  border-bottom: #888 1px solid;
  margin-bottom: 10px;
  height: 50px;
}

.watch-out-title {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
}
</style>
