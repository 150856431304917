export const common = {
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      // 选定的数据列表
      selectedList: [],
      selectedObjs: []
    }
  },
  watch: {
    // 检测查询变化
    dialogShow: {
      handler() {
        this.dialogVisible = this.dialogShow
      },
      deep: true
    }
  },
  created() {
    this.dialogVisible = this.dialogShow
  },
  methods: {
    // 选定回调
    handleSelected(data) {
      if (data) {
        this.selectedList = data.ids
        this.selectedObjs = data.objs
      }
    },
    // 关闭选择框
    handleClose() {
      this.$emit('update:dialogShow', false)
    }
  }
}
