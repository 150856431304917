var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "video-box" },
      [
        _vm.deviceId
          ? _c("video-presenter", {
              attrs: {
                height: 600,
                "cam-type": "side",
                paperid: _vm.paperid,
                "choose-device-id": _vm.deviceId,
                "is-cheat": _vm.isCheat,
              },
              on: { unfocusWarning: _vm.unfocusWarning },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("el-card", { staticClass: "box-card" }, [
          _vm._v(
            " 注意: 某些型号的手机上不显示画面，只要在电脑上能看到手机画面即可 "
          ),
        ]),
        _c("div", { staticClass: "tip-status" }, [_vm._v("正在拍摄中..")]),
        _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tipMsg))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }