import { post } from '@/utils/request'
import { postJob } from '@/utils/request-job'

/**
 * 通用请求类
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postData(url, data) {
  return post(url, data)
}

/**
 * 通用请求类
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postJobData(url, data) {
  return postJob(url, data)
}
