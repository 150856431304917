import axios from 'axios'
import { Notification, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// 请求实例
const instance = axios.create({
  // baseURL: process.env.Img_SYS_API,
  baseURL: `${process.env.VUE_APP_SIGNAL_BASE_API}`,
  timeout: 60000
})

// 请求前置过滤器
instance.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers['token'] = getToken()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应数据拦截并做通用处理
instance.interceptors.response.use(
  (response) => {
    const res = response.data

    // 非正常标准的数据，可能是第三方接口，直接返回响应
    if (res.success == null) {
      return response
    }

    // 0为正确响应码
    if (res.success !== true) {
      Notification.error({
        title: '提示信息',
        message: res.msg || '服务器开小差了呢！'
      })

      // 登录超时响应码
      if (res.status === 10010002) {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }

      // 业务错误，返回错误码
      return Promise.reject(new Error(res.code))
    } else {
      return res
    }
  },
  (error) => {
    // 错误响应
    const response = error.response

    if (response && response.status) {
      const msg = codeMessage[response.status]
      Notification.error({
        title: '提示信息',
        message: msg
      })
    }

    return Promise.reject(error)
  }
)

/**
 * 封装post请求
 * @param url
 * @param data
 * @param options
 * @returns {Promise}
 */
export function postImg(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, options).then(
      (response) => {
        resolve(response)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param options
 * @returns {Promise}
 */
export function getImg(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, data, options).then(
      (response) => {
        resolve(response)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。', // token 过期
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  412: '令牌失效，请重新请求令牌。', // token 过期
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}
