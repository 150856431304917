var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quData && _vm.quData.id
    ? _c(
        "div",
        {
          staticClass: "qu-box",
          class: { "sub-box": _vm.sub },
          attrs: { id: "qu-" + _vm.quData.quId },
        },
        [
          _c(
            "div",
            {
              ref: "quItem",
              class: { "pin-class": _vm.pin && _vm.quData.quType === "99" },
            },
            [
              _vm.showType
                ? _c("div", { staticClass: "score-bar" }, [
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.quData.quType_dictText) +
                          " （本题分值：" +
                          _vm._s(_vm.quData.score) +
                          "分） "
                      ),
                    ]),
                    _vm.quData.quType !== "99"
                      ? _c(
                          "div",
                          { staticClass: "mark" },
                          [
                            _vm.mark
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.quData.mark
                                        ? "取消本题的疑难标记"
                                        : "为本题添加疑难标记",
                                      effect: "dark",
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      class: {
                                        "el-icon-s-flag": true,
                                        active: _vm.quData.mark,
                                      },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.markQu()
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.pin,
                                    expression: "!pin",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doPin()
                                  },
                                },
                              },
                              [_vm._v("固定题目")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.pin,
                                    expression: "pin",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doPin()
                                  },
                                },
                              },
                              [_vm._v("取消固定")]
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "qu-item" }, [
                _c("div", { class: _vm.sub ? "sub" : "num" }, [
                  _vm.sort
                    ? _c("span", [_vm._v(_vm._s(_vm.sort) + ".")])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.quData.sort) + " "),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.formatRichText(_vm.quData.content)
                      ),
                    },
                  }),
                ]),
              ]),
              _vm.quData.quType === "1" ||
              _vm.quData.quType === "2" ||
              _vm.quData.quType === "3"
                ? _c(
                    "div",
                    { staticClass: "qu-answer" },
                    _vm._l(_vm.quData.answerList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "item",
                          class: { active: item.checked },
                          on: {
                            click: function ($event) {
                              return _vm.handleItemClick(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "tag" }, [
                            _vm._v(_vm._s(item.abc)),
                          ]),
                          _c("div", { staticClass: "content" }, [
                            _c("div", [_vm._v(_vm._s(item.content))]),
                            item.image
                              ? _c("div", [
                                  _c("img", {
                                    staticStyle: { "max-width": "90%" },
                                    attrs: { src: item.image },
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.quData.quType === "4"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "as-box" },
                      [
                        _c("Tinymce", {
                          ref: "tinymce",
                          staticStyle: { width: "100%" },
                          attrs: {
                            paste: false,
                            id: _vm.quData.id,
                            "show-upload": false,
                          },
                          model: {
                            value: _vm.quData.answer,
                            callback: function ($$v) {
                              _vm.$set(_vm.quData, "answer", $$v)
                            },
                            expression: "quData.answer",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.quData.quType === "5"
                ? _c(
                    "div",
                    { staticClass: "qu-answer" },
                    _vm._l(_vm.quData.answerList, function (item) {
                      return _c("div", { key: item.id, staticClass: "item" }, [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("el-input", {
                              model: {
                                value: item.answer,
                                callback: function ($$v) {
                                  _vm.$set(item, "answer", $$v)
                                },
                                expression: "item.answer",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.sub ? _c("div", { staticClass: "split" }) : _vm._e(),
            ]
          ),
          _vm.quData.quType === "99"
            ? _c(
                "div",
                { style: "margin-top: " + _vm.subMargin + "px" },
                _vm._l(_vm.quData.subList, function (sub) {
                  return _c(
                    "div",
                    { key: sub.id },
                    [
                      _c("qu-item-exam", {
                        key: sub.id,
                        attrs: {
                          "show-type": _vm.showType,
                          sort: _vm.quData.sort,
                          value: sub,
                          sub: true,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }