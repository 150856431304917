var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { placeholder: "输入关键字进行过滤", size: "small" },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.treeLoading,
            expression: "treeLoading",
          },
        ],
        ref: "tree",
        staticStyle: { "margin-top": "10px" },
        attrs: {
          data: _vm.treeData,
          "check-strictly": true,
          "default-checked-keys": _vm.deptCodes,
          props: _vm.defaultProps,
          "filter-node-method": _vm.filterNode,
          "empty-text": " ",
          "default-expand-all": "",
          "show-checkbox": "",
          "node-key": "deptCode",
        },
        on: { "check-change": _vm.handleCheckChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }