import { post } from '@/utils/request'

/**
 * 发送单人消息
 * @param data
 */
export function singleMsg(data) {
  return post('/api/exam/watch/single-msg', data)
}

/**
 * 发送批量消息
 * @param data
 */
export function batchMsg(data) {
  return post('/api/exam/watch/batch-msg', data)
}

export function httpbatchMsg(data) {
  return post('/api/monitor/paper/mass-notification', data)
}

/**
 * 监考统计信息
 * @param data
 */
export function watchStat(data) {
  return post('/api/exam/watch/watch-stat', data)
}

export function httpwatchStat(data) {
  return post('/api/monitor/paper/watch-stat', data)
}
// /api/chiefmonitor/paper/watch-stat
export function httpchiefwatchStat(data) {
  return post('/api/chiefmonitor/paper/watch-stat', data)
}
// 大屏试卷  /api/monitor/dashboard/impl-stat
export function httpimpl(data) {
  return post('/api/monitor/dashboard/impl-stat', data)
}
// 大屏状态  /api/monitor/dashboard/state-stat
export function httpstate(data) {
  return post('/api/monitor/dashboard/state-stat', data)
}
// 大屏登录  /api/monitor/dashboard/state-stat
export function httplogin(data) {
  return post('/api/monitor/dashboard/login-stat', data)
}
// 大屏地图
export function httpmap(data) {
  return post('/api/monitor/dashboard/map', data)
}
// 大屏监控统计
export function httpperson(data) {
  return post('/api/monitor/dashboard/person-stat', data)
}
// 大屏监考情况统计
export function httpinvigilate(data) {
  return post('/api/monitor/dashboard/invigilate-stat', data)
}
export function httpWatchpre(data) {
  return post('/api/monitor/paper/watch-pre', data)
}

// 监控列表
export function httpalarmpaging(data) {
  return post('/api/monitor/alarm/records-paging', data)
}
// 主考预警列表
export function httpchiefalarmpaging(data) {
  return post('/api/chiefmonitor/paper/alarm-paging', data)
}
// 主考待处理预警列表
export function httpchiefmonitoralarmpaging(data) {
  return post('/api/chiefmonitor/paper/processing-paging', data)
}
// 监控列表
export function httppaperlarm(data) {
  return post('/api/monitor/paper/alarm', data)
}
// 关注
export function httpfollow(data) {
  return post('/api/monitor/paper/follow', data)
}
// 取消关注
export function httpcancelfollow(data) {
  return post('/api/monitor/paper/cancelfollow', data)
}
// 报警详情
export function httpalarmview(data) {
  return post('/api/monitor/alarm/view', data)
}
// 报警数量
export function httpalarmcount(data) {
  return post('/api/monitor/alarm/alarm-count', data)
}
// 监控列表(主考)
export function httpchiefpaperlarm(data) {
  return post('/api/chiefmonitor/paper/alarm', data)
}
// 上传图片
export function httpuploadPicture(data) {
  return post('/api/monitor/alarm/uploadPicture', data)
}
// 抓拍对比图片
export function httpsnapuploadPicture(data) {
  return post('/api/monitor/snap/uploadPicture', data)
}
// 主考强制考生交卷
export function chiefForceHand(data) {
  return post('/api/monitor/paper/force-hand', data)
}

// 继续考试 主考消除疑似作弊报警，允许考生继续考试
export function chiefContinuing(data) {
  return post('/api/monitor/paper/continuing', data)
}

// 主考强制考生交卷
export function chiefmonitorForceHand(data) {
  return post('/api/chiefmonitor/paper/force-hand', data)
}

// 继续考试 主考消除疑似作弊报警，允许考生继续考试
export function chiefMonitorContinuing(data) {
  return post('/api/chiefmonitor/paper/continuing', data)
}

/**
 * 强制交卷
 * @param data
 */
export function forceHand(data) {
  return post('/api/exam/watch/force-hand', data)
}

/**
 * 全部强制交卷
 * @param data
 */
export function forceHandAll(data) {
  return post('/api/exam/watch/force-hand-all', data)
}

// 考生详情
export function httpstudentDeatils(data) {
  return post('/api/monitor/student/detail', data)
}
// 告警图片列表
export function httpalarmpicture(data) {
  return post('/api/monitor/student/alarm-picture-paging', data)
}
// 人脸对比抓拍图片列表
export function httpsnappicture(data) {
  return post('/api/monitor/student/snap-picture-paging', data)
}
