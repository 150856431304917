<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    :destory-on-close="true"
    title="Excel 模板导入"
    width="60%"
    @close="handleClose"
  >
    <div v-if="dialogVisible" class="app-container">
      <el-alert
        :closable="false"
        title="从Excel 模板导入的岗位将覆盖相同的岗位"
        type="info"
        class="alert-body"
        show-icon
      >
        <div slob="description">
          <span
            style="display: inline"
          >只能上传 xls / xlsx 文件，且不超过 5MB</span
          >
          <span class="link-text">
            模板下载地址：<el-button style="padding: 0" type="text">
              <a href="/template/岗位导入模板.xlsx" target="_black">
                《 岗位导入模板.xlsx 》
              </a>
            </el-button>
          </span>
        </div>
      </el-alert>

      <el-upload
        ref="upload"
        :action="actionUrl"
        :auto-upload="false"
        :limit="10"
        :data="uploadData"
        :headers="header"
        :on-exceed="onExceed"
        :before-upload="onBeforeUpload"
        :on-success="onUploadSuccess"
        :on-error="onUploadError"
        multiple
        class="upload-demo"
        accept=".xls,.xlsx"
        drag
      >
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmToUpload">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { modalConfig, modalTitle } from '../../../../../../data/const'
import { common } from './common'

import { getToken } from '@/utils/auth'

export default {
  name: 'UpLoadDialog',
  mixins: [common],
  props: {
    examId: String,
    isUpload: Boolean
  },
  data() {
    return {
      // 上传头
      header: {},
      actionUrl: '',
      uploadData: {}
    }
  },

  created() {
    this.header = { token: getToken() }
    this.uploadData = { examId: this.examId }
    this.actionUrl = process.env.VUE_APP_BASE_API + '/api/excel/importCareer'
  },
  methods: {
    confirmToUpload() {
      this.$confirm('请确定是否导入模板中的岗位？', modalTitle, {
        ...modalConfig,
        type: 'warning'
      }).then(() => {
        this.submitCareer()
      })
    },
    // 提交模板中的岗位
    submitCareer() {
      this.$refs.upload.submit()
    },
    onUploadSuccess(res) {
      if (res && res.success) {
        this.$notify({
          title: modalTitle,
          message: res.msg || '考生导入成功!',
          type: 'success'
        })
      } else {
        this.$notify({
          title: modalTitle,
          message: res.msg || '考生导入失败!',
          type: 'error'
        })
      }

      this.$emit('update:isUpload', true)
      this.handleClose()
    },
    onUploadError(err) {
      this.$notify({
        title: modalTitle,
        message: err.msg || '岗位导入失败!',
        type: 'error'
      })
    },
    onBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      // const fileType = file.name.substring(file.name.lastIndexOf('.')+1)
      // 判断图片的类型
      const isJpg =
        file.type == 'application/vnd.ms-excel' ||
        file.type ==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      if (!isJpg) {
        this.$notify({
          title: modalTitle,
          message: '只能上传 xls, xlsx 格式的图片！',
          type: 'warning'
        })
        return false
      }
      if (!isLt2M) {
        this.$notify.error({
          title: modalTitle,
          message: '上传图片大小不能超过 5MB!',
          type: 'warning'
        })
        return false
      }
      return true
    },
    // 文件超出个数限制时
    onExceed(files, fileList) {
      this.$notify({
        title: modalTitle,
        message: '一次最多上传十个文件!',
        type: 'warning'
      })
      // console.log('fileList', this.fileList)
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
}

.upload-demo {
  width: 100%;
}

.alert-body {
  margin-top: -10px;
  margin-bottom: 10px;
}

::v-deep .el-alert__content {
  width: 100%;
}
.link-text {
  float: right;
}

::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
}
</style>
