import Vue from 'vue'
import Router from 'vue-router'
import LoginLayout from '@/views/login/layout/LoginLayout'
Vue.use(Router)
import Layout from '@/layout'

// 静态通用路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '#',
    component: LoginLayout,
    redirect: '/login',
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index'),
        meta: {
          title: '用户登录'
        }
      },
      {
        path: '/login/:id',
        name: 'LoginExam',
        component: () => import('@/views/login/preciseexam'),
        meta: {
          title: '用户登录'
        }
      },
      {
        path: '/register',
        name: 'Register',
        layout: LoginLayout,
        component: () => import('@/views/login/register'),
        meta: {
          title: '学员注册'
        }
      },
      {
        path: '/forgot',
        name: 'Forgot',
        layout: LoginLayout,
        component: () => import('@/views/login/forgot'),
        meta: {
          title: '忘记密码'
        }
      }
    ]
  },
  {
    path: '/devicecheck/:id',
    name: 'DeviceCheck',
    component: () => import('@/views/web/devicecheck'),
    hidden: true,
    meta: { title: '设备检测', noCache: true }
  },
  {
    path: '/examineeGuide/:id',
    name: 'ExamineeGuide',
    component: () => import('@/views/web/guide'),
    hidden: true,
    meta: { title: '考生引导', noCache: true }
  },
  {
    path: '/examineeconfirm/:id',
    name: 'ExamineeConfirm',
    component: () => import('@/views/phone/examineeconfirm'),
    hidden: true,
    meta: { title: '考生邀请', noCache: true }
  },
  {
    path: '/ack/:id',
    name: 'ack',
    component: () => import('@/views/phone/examineeconfirm'),
    hidden: true,
    meta: { title: '考生邀请', noCache: true }
  },
  {
    path: '/largescreen',
    name: 'LargeScreen',
    component: () => import('@/views/largescreen'),
    hidden: true,
    meta: { title: '数据大屏', noCache: true }
  },
  {
    path: '/remote-test/chiefinspector/:examId',
    name: 'ChiefWatch',
    component: () => import('@/views/admin/remoteTest/examination'),
    hidden: true,
    meta: { title: '主考官', noCache: true }
  },
  {
    path: '/remote-test/inspector/:examId',
    name: 'WebWatch',
    component: () => import('@/views/admin/remoteTest/inspector'),
    hidden: true,
    meta: { title: '监考老师', noCache: true }
  },
  {
    path: '/testpresenter',
    name: 'TestPresenter',
    component: () => import('@/views/testpresenter.vue'),
    hidden: true,
    meta: { title: '测试视频发起', noCache: true }
  },
  {
    path: '/testviewer',
    name: 'Test',
    component: () => import('@/views/testviewer.vue'),
    hidden: true,
    meta: { title: '测试视频接收', noCache: true }
  },
  {
    path: '/web/answer/section/:id',
    component: () => import('@/views/web/exam/components/AnswerBySection'),
    name: 'answerbysection',
    hidden: true,
    meta: { title: '章节训练', noCache: true }
  },
  {
    path: '/web/user/info',
    component: () => import('@/views/admin/remoteTest/records/videoRecords'),
    name: 'info',
    hidden: true,
    meta: { title: '章节训练', noCache: true }
  },
  {
    path: '/across/mobileface',
    name: 'MobileFace',
    component: () => import('@/views/mobileface'),
    hidden: true,
    meta: { title: '人脸识别', noCache: true }
  },
  {
    path: '/across/mobilecamera',
    name: 'MobileCamera',
    component: () => import('@/views/mobilecamera'),
    hidden: true,
    meta: { title: '视频监考', noCache: true }
  },
  {
    path: '/across/selectcamera',
    name: 'SelectCamera',
    component: () => import('@/views/mobilecamera/selectCamera.vue'),
    hidden: true,
    meta: { title: '视频监考', noCache: true }
  },
  {
    path: '/monitoringscreen',
    name: 'MonitoringScreen',
    component: () => import('@/views/monitoringscreen'),
    hidden: true,
    meta: { title: '监控大屏', noCache: true }
  },
  {
    path: '/downtool',
    name: 'downtool',
    component: () => import('@/views/web/exam/downTool.vue'),
    hidden: true,
    children: [
      {
        path: 'windows',
        name: 'downWin',
        component: () => import('@/views/web/exam/down/widnows.vue'),
        hidden: true,
        meta: { title: '软件下载', noCache: true }
      },
      {
        path: 'mac',
        name: 'downMac',
        component: () => import('@/views/web/exam/down/mac.vue'),
        hidden: true,
        meta: { title: '软件下载', noCache: true }
      },
  ]

  },
  {
    path: '/sync',
    name: 'LoginSync',
    component: () => import('@/views/login/sync'),
    hidden: true,
    meta: { title: '登录成功', noCache: true }
  },

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },

  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },

  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: 'Profile',
        meta: { title: '个人资料', icon: 'user', noCache: true }
      }
    ]
  }
]

// 导入目录下的子路由
const subRouters = require.context('./modules', true, /\.js$/)
subRouters.keys().reduce((modules, modulePath) => {
  const value = subRouters(modulePath)
  constantRoutes.push(value.default)
}, {})

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
