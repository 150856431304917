export const deviceCheck = {
  data() {
    return {
      deviceFlag: false
    }
  },
  created() {
    this.checkDevice()
  },
  methods: {
    checkDevice() {
      const userAgent = window.navigator.userAgent
      console.log(userAgent)
      // 谷歌或火狐
      if (
        (userAgent.indexOf('Chrome') !== -1 &&
          userAgent.indexOf('Safari') !== -1 &&
          userAgent.indexOf('Mozilla') !== -1) ||
        userAgent.indexOf('Firefox') !== -1 ||
        userAgent.indexOf('Edg') !== -1 ||
        (userAgent.indexOf('Safari') !== -1 &&
          userAgent.indexOf('Chrome') === -1)
      ) {
        // console.log('谷歌浏览器或火狐浏览器或Edge浏览器或Safari浏览器')
        this.deviceFlag = true
      }
      // 是Edg
      // if (userAgent.indexOf('Edg') !== -1) {
      //   this.deviceFlag = false
      // }

      // 是搜狗
      if (userAgent.indexOf('SE 2.X MetaSr') !== -1) {
        this.deviceFlag = false
      }
      // 是360
      if (userAgent.indexOf('QIHU 360SE') !== -1) {
        this.deviceFlag = false
      }
      // 是支付宝
      if (userAgent.indexOf('AlipayClient') !== -1) {
        this.deviceFlag = false
      }
      // 是抖音
      if (userAgent.indexOf('DiDiPhone') !== -1) {
        this.deviceFlag = false
      }
      // 是QQ
      if (userAgent.indexOf('QQ') !== -1) {
        this.deviceFlag = false
      }
      // 是微信
      if (
        userAgent.indexOf('WindowsWechat') !== -1 ||
        userAgent.indexOf('MacWechat') !== -1 ||
        userAgent.indexOf('Weixin') !== -1 ||
        userAgent.indexOf('MicroMessenger') !== -1
      ) {
        this.deviceFlag = false
      }

      if (!this.deviceFlag) {
        this.$alert(
          `当前浏览器暂不支持，请更换为Chrome浏览器( https://www.google.cn/intl/zh-CN/chrome )或者Firefox浏览器( https://www.firefox.com.cn/ )或者Edge浏览器( https://www.microsoft.com/zh-cn/edge/download )或者Safari浏览器`,
          {
            confirmButtonText: '知道了',
            callback: (action) => {
              // this.$router.go(-1)
            }
          }
        )
      }
    }
  }
}
