var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "content",
    },
    [
      !_vm.isError
        ? _c(
            "div",
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("【" + _vm._s(_vm.exam.title) + "】 在线考试邀请"),
              ]),
              _vm._m(0),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-bottom": "0.35rem" },
                  attrs: {
                    column: 1,
                    "label-style": {
                      "text-align": "center",
                      width: "2rem",
                      border: "5px solid #fff",
                    },
                    "content-style": {
                      width: "4rem",
                      border: "5px solid #fff",
                      "background-color": "#fafafa",
                    },
                    border: "",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                    _c("b", [_vm._v(" " + _vm._s(_vm.info.name || "-") + " ")]),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                    _vm._v(" " + _vm._s(_vm.info.mobile || "-") + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "E-mail" } }, [
                    _vm._v(" " + _vm._s(_vm.info.email || "-") + " "),
                  ]),
                ],
                1
              ),
              _vm._m(1),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-bottom": "0.35rem" },
                  attrs: {
                    column: 1,
                    "label-style": {
                      "text-align": "center",
                      width: "2rem",
                      border: "5px solid #fff",
                    },
                    "content-style": {
                      width: "4rem",
                      border: "5px solid #fff",
                      "background-color": "#fafafa",
                    },
                    border: "",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "开始时间" } }, [
                    _c("b", [
                      _vm._v(" " + _vm._s(_vm.exam.startTime || "-") + " "),
                    ]),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "引导考试入口" } },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "#cd2b1e",
                            "text-decoration": "underline",
                          },
                          attrs: {
                            href: _vm.info.examEnterUrl,
                            target: "_black",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.info.examEnterUrl || "-") + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            icon: "el-icon-document-copy",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy($event, _vm.info.examEnterUrl)
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                      _c("br"),
                      _vm._v("（请复制链接到电脑浏览器进行打开） "),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "考试入口" } },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "#cd2b1e",
                            "text-decoration": "underline",
                          },
                          attrs: { href: _vm.baseUrl, target: "_black" },
                        },
                        [_vm._v(" " + _vm._s(_vm.baseUrl) + " ")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            icon: "el-icon-document-copy",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy($event, _vm.baseUrl)
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                      _c("br"),
                      _vm._v("（请复制链接到电脑浏览器进行打开） "),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "登录账号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.info.username || "-") + " "),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            icon: "el-icon-document-copy",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy($event, _vm.info.username)
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "登录密码" } },
                    [
                      _vm._v(" " + _vm._s(_vm.info.passwd || "-") + " "),
                      _c(
                        "el-link",
                        {
                          staticClass: "color:blue",
                          attrs: {
                            icon: "el-icon-document-copy",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy($event, _vm.info.passwd)
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                    ],
                    1
                  ),
                  _c("el-descriptions-item", { attrs: { label: "确认情况" } }, [
                    _vm.info.state === 1
                      ? _c("span", { staticClass: "red-text" }, [
                          _vm._v("（未确认）"),
                        ])
                      : _vm._e(),
                    _vm.info.state === 2
                      ? _c("span", { staticClass: "blue-text" }, [
                          _vm._v("（确认参加）"),
                        ])
                      : _vm._e(),
                    _vm.info.state === 3
                      ? _c("span", [
                          _vm._v("（" + _vm._s(_vm.info.state_dictText) + "）"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._m(2),
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "box-header" }, [
                    _vm._v("考试须知："),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "indent-text" }, [_vm._v("您好！")]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 现邀请您参加 "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.info.careerEnterpriseName) +
                          _vm._s(_vm.exam.title)
                      ),
                    ]),
                    _vm._v(" ，请您在收到通知信后仔细阅读考试须知， 请您于 "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(" " + _vm._s(_vm.confirmEndTimeStr || "截止时间")),
                    ]),
                    _vm._v(
                      " 前登录考试系统进行设备测试，并完成人脸身份验证。 "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 本场考试要求必须开启电脑摄像头、手机视频监控及电脑桌面录屏监控，因此请确保设备正常运作并保障网络稳定。如未提前登录检测设备，出现设备故障、监控设备不能开启等问题，将会影响考生成绩。 "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 请您按通知时间准时登录进行作答！考试注意如下: "),
                  ]),
                ]),
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "box-header" }, [
                    _vm._v("考试要求："),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 1. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "设备要求：请自备电脑（建议用笔记本电脑）、高清摄像头（电脑自带摄像头即可），监控用手机（安装微信软件），电脑安装Chrome浏览器（版本80以上）"
                      ),
                    ]),
                    _vm._v(
                      "。禁止使用手机或平板电脑（如iPad、安卓系统平板等）作答。所有设备请确保电量充足，上网流量充足； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 2. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "网速要求：作答网络的带宽需高于5M，或使用4G/5G手机分享热点上网"
                      ),
                    ]),
                    _vm._v(
                      "。关闭360等电脑管理软件，关闭下载软件，确保网速流畅； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 3. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "环境要求：请选择独立、安静、封闭、光线明亮、不受打扰的空间独自参加考试"
                      ),
                    ]),
                    _vm._v(
                      "，例如在家、寝室、会议室均可，不允许在网吧、咖啡厅、教室等公共场所作答； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 4. 迟到5分钟后，将不能再登录系统作答，请提前登录考试系统； "
                    ),
                  ]),
                  _c(
                    "p",
                    { staticClass: "indent-text" },
                    [
                      _vm._v(
                        " 5、防作弊检测软件下载：本次考试严格要求考试期间电脑设备禁止开启与考试无关软件或程序，考生需要下载防作弊检测软件才可允许考试，软件下载: "
                      ),
                      _c(
                        "router-link",
                        {
                          staticStyle: {
                            color: "#cd2b1e",
                            "text-decoration": "underline",
                          },
                          attrs: {
                            to: "/downtool/windows",
                            tag: "a",
                            target: "_blank",
                          },
                        },
                        [_vm._v("点此下载")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "box-header" }, [
                    _vm._v("考试监控："),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v("1. 手机监控："),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 本次考试将开启手机视频监控，考生进入考试系统后使用微信扫码进入手机视频监控模式； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        " 请将手机放置于考生座位电脑正侧方（不要放后方或前方），间隔1.5米以上距离，架高手机，俯拍桌面；手机监控画面请包含完整的电脑屏幕、键盘、考生侧脸、上半身，双手处于监控范围内；手机请不要横着摆放，保持桌面整洁，不要有杂物，参考下图： "
                      ),
                    ]),
                  ]),
                  _c("img", {
                    staticStyle: { width: "6.8rem" },
                    attrs: { src: require("@/assets/web/images/phone1.png") },
                  }),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v("2. 电脑视频监控："),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 考试将开启电脑摄像头视频监控，请按系统要求打开电脑摄像头，确保在答题页面右上方可以清晰看到本人的上半身头像。 "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v("3. 电脑桌面监控："),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 考试将开启答题操作电脑录屏监控，正式考试时系统会要求共享屏幕，选择“您的整个屏幕”，点击“分享”即可开始录制，考试全程请不要停止共享。 "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 在正式考试前，您可以随时登录系统检测以上监控设备，体验操作，"
                    ),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "请确保正式开考后，所有监控设备正常开启，否则会影响您的考试成绩"
                      ),
                    ]),
                    _vm._v("。 "),
                  ]),
                ]),
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "box-header" }, [
                    _vm._v("考试纪律："),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 1. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v("监考官会通过监控大屏全程观察每位考生的举止行为"),
                    ]),
                    _vm._v(
                      "，请严格遵守考场纪律，诚实作答，任意作弊行为一经核实，则考试成绩按0分计，并记录在融通人力诚信档案中； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 2. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "考试中使用手机或平板电脑查阅信息，翻阅辅导书籍材料，查看小抄，接打电话，左顾右盼、外接显示器等行为，都将被认定为作弊"
                      ),
                    ]),
                    _vm._v("； "),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 3. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "考试中严禁有其他人员陪同，例如家人、同事、朋友等，如有他人辅助作答，将被认定为作弊"
                      ),
                    ]),
                    _vm._v("； "),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 4. 未按要求开启电脑摄像头监控、手机视频监控、电脑桌面监控，监控手机未按要求摆放，将被认定为违规； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 5. 考试中禁止跳出考试页面，不要佩戴耳机、帽子，口罩，头发不能遮盖耳朵，不要遮挡电脑和手机摄像头，关闭有自动消息弹窗的软件，衣着要得体，作答期间不允许离开座位； "
                    ),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(" 6. "),
                    _c("span", { staticClass: "red-text" }, [
                      _vm._v(
                        "考中考官会随机抽查考生座位周围环境，请配合考官操作；"
                      ),
                    ]),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v("7. 可以使用草稿纸、笔，禁止使用电子计算器；"),
                  ]),
                  _c("p", { staticClass: "indent-text" }, [
                    _vm._v(
                      " 8. 举办方拥有全部试题的内容版权，请不要对试题内容做任何拍照、复制、传播。 "
                    ),
                  ]),
                ]),
              ]),
              _vm.info
                ? _c(
                    "div",
                    { staticClass: "work-btns" },
                    [
                      _c(
                        "el-button",
                        {
                          class:
                            _vm.info.state === 2
                              ? "work-btn-disabled"
                              : "work-btn",
                          attrs: {
                            disabled: _vm.info.state === 2,
                            loading: _vm.loading,
                            type: "primary",
                          },
                          on: { click: _vm.joinTest },
                        },
                        [_vm._v(" 确认参加 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.info.state === 3,
                            loading: _vm.loading,
                            type: "default",
                          },
                          on: { click: _vm.rejectTest },
                        },
                        [_vm._v(" 放弃参加 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.btnIsDisabled,
                            type: "default",
                          },
                          on: { click: _vm.later },
                        },
                        [_vm._v(" 稍后确认 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", [
            _vm._m(3),
            _c("h4", { staticClass: "err-notice" }, [
              _vm._v(
                " 信息错误，请确认当前页面地址是否和通知中的链接地址一致，并稍后重试。 "
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sub-title" }, [
      _c("div", { staticClass: "icon-row" }, [
        _c("div", { staticClass: "icon-content" }, [
          _c("i", { staticClass: "el-icon-user-solid" }),
        ]),
        _c("span", [_vm._v("考生信息")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sub-title" }, [
      _c("div", { staticClass: "icon-row" }, [
        _c("div", { staticClass: "icon-content" }, [
          _c("i", { staticClass: "el-icon-s-order" }),
        ]),
        _c("span", [_vm._v("考试账号")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sub-title", attrs: { id: "introduce" } }, [
      _c("div", { staticClass: "icon-row" }, [
        _c("div", { staticClass: "icon-content" }, [
          _c("i", { staticClass: "el-icon-document" }),
        ]),
        _c("span", [_vm._v("考试须知")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBody" }, [
      _c("img", {
        staticClass: "img404",
        attrs: { src: require("@/assets/404_images/404.png"), alt: "404" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }