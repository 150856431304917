<template>
  <div>
    <el-form
      ref="postForm"
      :model="postForm"
      :rules="rules"
      label-position="left"
      label-width="100px"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="登录账号" prop="userName">
            <el-input v-model="postForm.userName" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="用户角色" prop="roles">
            <role-select v-model="postForm.roles" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属部门" prop="deptCode">
            <depart-tree-select v-model="postForm.deptCode" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登录密码" prop="password">
            <el-input
              v-model="postForm.password"
              size="small"
              placeholder="不修改请留空"
              type="password"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item v-if="!postForm.id" label="手机号" prop="mobile">
            <el-input v-model="postForm.mobile" size="small" type="text" />
          </el-form-item>
          <el-form-item v-else label="手机绑定">
            <mobile-binder :user-id="postForm.id" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="电子邮箱">
            <el-input v-model="postForm.email" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="用户头像">
            <file-upload
              v-model="postForm.avatar"
              :show-url.sync="postForm.avatarUrl"
              :limit="1"
              :compress="3000"
              list-type="picture-card"
              tips="仅用于学员端展示"
              accept=".jpg,.png,.jpeg"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="postForm.realName" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="身份证号">
            <el-input v-model="postForm.idCard" size="small" />
          </el-form-item>
        </el-col>

        <el-col v-if="siteData.faceLogin" :span="24">
          <el-form-item label="人脸照片">
            <file-upload
              v-model="postForm.face"
              :show-url.sync="postForm.faceUrl"
              :limit="1"
              :compress="3000"
              list-type="picture-card"
              tips="用于人脸登录，需要保证正脸位于图片中央，图片质量会影响识别率"
              accept=".jpg,.png,.jpeg"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      style="border-top: #eee 1px solid; padding-top: 20px; text-align: right"
    >
      <el-button @click="handleCancel">取 消</el-button>
      <el-button
        :loading="loading" type="primary"
        @click="handleSave"
      >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import { saveData, fetchDetail } from '@/api/sys/user/user'
import MobileBinder from '@/views/admin/sys/user/components/MobileBinder.vue'
import { isMobileIgnoresNull } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
  name: 'UserUpdateBase',
  components: { MobileBinder },
  props: {
    userId: String,
    departId: String
  },

  data() {
    return {
      loading: false,
      postForm: {},
      rules: {
        userName: [{ required: true, message: '用户不能为空！' }],
        realName: [{ required: true, message: '姓名不能为空！' }],
        password: [{ required: true, message: '密码不能为空！' }],
        deptCode: [{ required: true, message: '部门不能为空！' }],
        mobile: [{ required: false, validator: isMobileIgnoresNull }],
        roles: [{ required: true, message: '必须选择一个角色！' }]
      }
    }
  },

  computed: {
    ...mapGetters(['siteData'])
  },

  watch: {
    userId: {
      handler(val) {
        this.showInfo(val)
      }
    },

    departId: {
      handler(val) {
        if (val) {
          this.postForm.departId = val
        }
      }
    }
  },

  created() {
    this.showInfo(this.userId)
  },

  methods: {
    // 显示信息用于编辑
    showInfo(id) {
      // 添加模式
      if (!id) {
        this.postForm = {}
        this.rules.password = [{ required: true, message: '密码不能为空！' }]
        return
      }
      fetchDetail(id).then((res) => {
        this.postForm = res.data
        this.postForm.password = null
        this.rules.password = []
      })
    },

    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        saveData(this.postForm)
          .then(() => {
            this.$message({
              type: 'success',
              message: '用户保存成功!'
            })
            this.loading = false
            this.handleCancel()
          })
          .catch(() => {
            this.loading = false
          })
      })
    },

    handleCancel() {
      this.$emit('close')
    }
  }
}
</script>
