var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "方案类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "dahansantong" } }, [
                    _vm._v("大汉三通"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "dahansantong"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "接口地址", prop: "data.server" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "接口地址" },
                        model: {
                          value: _vm.postForm.data.server,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "server", $$v)
                          },
                          expression: "postForm.data.server",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "data.username" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "用户名" },
                        model: {
                          value: _vm.postForm.data.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "username", $$v)
                          },
                          expression: "postForm.data.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "data.password" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "密码" },
                        model: {
                          value: _vm.postForm.data.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "password", $$v)
                          },
                          expression: "postForm.data.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签名", prop: "data.sign" } },
                    [
                      _c("el-input", {
                        attrs: { size: "sign", placeholder: "签名" },
                        model: {
                          value: _vm.postForm.data.sign,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "sign", $$v)
                          },
                          expression: "postForm.data.sign",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-divider"),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-refresh",
                    type: "warning",
                    size: "small",
                  },
                  on: { click: _vm.changProvider },
                },
                [_vm._v("使用当前方案")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-folder",
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }