const getBase64Img = function(img) {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  const base64 = canvas.toDataURL('image/png')
  // 去除文件头
  return base64.replace(/^data:image\/\w+;base64,/, '')
}

/**
 * 本地图像base64
 * @param file
 * @returns {string}
 */
export function localToBase64(file) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = file
    image.onload = function() {
      const base64 = getBase64Img(image)
      resolve(base64)
    }
    image.onerror = function() {
      reject('')
    }
  })
}
