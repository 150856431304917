import moment from 'moment'
import axios from 'axios'

export const guideStepMixin = {
  props: {
    goLastStepHandler: { type: Function },
    goNextStepHandler: { type: Function },
    info: {
      type: Object,
      default: () => {
        return {
          exam: {}
        }
      }
    },
    examInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      exam: {},
      // remainingTime: '',
      isPractice: false,
      timestamp: '' // 倒计时检查考试是否开始
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.exam) {
          this.exam = val.exam
        }
      },
      deep: true,
      immediate: true
    },
    exam: {
      handler(val) {
        let res = ''
        if (val) {
          const now = moment()
          let startTime = ''
          let endTime = ''

          // console.log('exam 改变', this.exam)

          if (val && val.startTime) {
            startTime = moment(val.startTime, 'YYYY-MM-DD HH:mm')
            endTime = moment(val.endTime, 'YYYY-MM-DD HH:mm')

            // console.log('开始时间和结束时间', startTime, endTime)

            this.isPractice = false
            if (startTime > now) {
              this.isPractice = true

              this.timestamp = setInterval(() => {
                const current = moment()
                if (startTime <= current) {
                  this.isPractice = false
                  clearInterval(this.timestamp)
                }
              }, 1000)

              let temp = moment(startTime).diff(now, 'minutes')
              if (temp > 60) {
                temp = moment(startTime).diff(now, 'hours')
                if (temp > 24) {
                  temp = moment(startTime).diff(now, 'days')
                  res = temp + '天'
                } else {
                  res = temp + '小时'
                }
              } else {
                res = temp + '分钟'
              }
              // console.log('剩余时间', temp)
            } else if (endTime < now) {
              res = '考试已结束'
            } else {
              res = '考试已开始'
            }
          }
        }
        // console.log('时间计算后：', res)
        // this.remainingTime = res
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    next() {
      this.goNextStepHandler()
    },
    last() {
      this.goLastStepHandler()
    }
  },
  beforeDestroy() {
    if (this.timestamp) {
      clearInterval(this.timestamp)
    }
  }
}

export const checkTool = {
  data() {
    return {
      checkToolDialog: false,
      checkErrMsg: '没有检测到防作弊检测工具，请下载并运行',
      noTool: false
    }
  },
  created() {
    // console.log('检测工具', this.checkToolShow)
    // 判断系统是否是windows
    const agent = navigator.userAgent.toLowerCase()
    const isWin = agent.indexOf('win') > -1 || agent.indexOf('wow') > -1
    const userAgent = navigator.userAgent
    const isMac = /Mac/.test(userAgent)
    if (this.checkToolShow != 'false') {
      this.checkDetectionTool()
    }
  },
  mounted() {
    // console.log('检测工具', this.checkToolShow)
    // 判断系统是否是windows
    const agent = navigator.userAgent.toLowerCase()
    const isWin = agent.indexOf('win') > -1 || agent.indexOf('wow') > -1
    const userAgent = navigator.userAgent
    const isMac = /Mac/.test(userAgent)
    if (this.checkToolShow != 'false') {
      this.timer2 = setInterval(() => {
        this.checkDetectionTool()
      }, 10000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer2)
  },
  methods: {
    checkDetectionTool() {
      axios({
        baseURL: 'http://localhost:12622/loc',
        timeout: 1000,
        method: 'GET'
        // headers: {
        //   'Access-Control-Allow-Orgin': '*',
        //   'Content-Type': 'application/json;charset=utf-8'
        // }
      })
        .then((res) => {
          // console.log(res.data.split(',')[0].indexOf('false'), '结果是')
          // if (res.data.split(',')[0].indexOf('false') > -1) {
          const { success } = res.data
          console.log('结果是', success)
          if (!success) {
            this.checkToolDialog = true
            this.noTool = false
            this.checkErrMsg = '请到到防作弊检测软件里面去检查错误'
          } else {
            this.checkToolDialog = false
            this.noTool = false
          }
        })
        .catch((err) => {
          this.checkToolDialog = true
          this.noTool = true
          this.checkErrMsg = '没有检测到防作弊检测工具，请下载并运行'
          console.log(err, '错误是')
        })
    }
  }
}
