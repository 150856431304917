var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticStyle: { height: "360px" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                color: "#000",
                "font-weight": "700",
                "font-size": "16px",
                "margin-bottom": "10px",
              },
            },
            [_vm._v("进行中的考试")]
          ),
          _vm.tableData.length > 0
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "header-cell-style": {
                      background: "#f2f7fb",
                      color: "#000",
                      "font-weight": "700",
                      "font-size": "14px",
                      "line-height": "32px",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "考试名称",
                      prop: "title",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("detail-link", {
                                attrs: {
                                  params: { examId: scope.row.id },
                                  title: scope.row.title,
                                  to: "ExamWatch",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3397613159
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "正在考试",
                      align: "center",
                      prop: "procCount",
                      width: "100px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "考完人数",
                      align: "center",
                      width: "100px",
                      prop: "handCount",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "t-empty" },
                [
                  _c("el-empty", {
                    attrs: { description: "啊哦，暂无正在进行中的考试！" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }