<template>

  <div>

    <el-row :gutter="25">
      <el-col :span="18">

        <el-card>

          <el-page-header content="题库训练" @back="$navBack" />

          <div style="margin-top: -25px; height: 35px">
            <el-button type="danger" style="float: right" @click="handleFinish">结束训练</el-button>
          </div>

          <el-row v-if="mode!==13" style="margin: 20px 0px 20px 0px">

            <el-col :span="6" class="guide-item">
              <div>答对</div>
              <div>{{ trueCount }}题</div>
            </el-col>
            <el-col :span="6" class="guide-item">
              <div>答错</div>
              <div>{{ wrongCount }}题</div>
            </el-col>
            <el-col :span="6" class="guide-item">
              <div>正确率</div>
              <div>{{ rightRate }}%</div>
            </el-col>
            <el-col :span="6" class="guide-item" style="display: flex; justify-content: center; align-content: center; align-items: center">
              <div>
                <el-checkbox v-model="nextIfTrue">答对继续下一题</el-checkbox>
              </div>
            </el-col>
          </el-row>

          <el-row class="lky-exam">
            <el-col>
              <qu-item-train v-model="quData" :card="cardItem" :sub="false" :mode="mode" @rest="handleRest" />
            </el-col>

            <el-col>
              <el-button :disabled="currentSort <= 1" icon="el-icon-back" @click="fetchPrevious">上一题</el-button>
              <el-button :disabled="currentSort >= maxSort" style="float: right" type="primary" @click="fetchNext">下一题<i class="el-icon-right el-icon--right" /></el-button>
            </el-col>

          </el-row>

        </el-card>

      </el-col>
      <el-col :span="6">

        <el-card>

          <div style="font-weight: 700; font-size: 16px">答题卡</div>
          <el-divider />

          <div style="display: flex; flex-wrap: wrap; max-height: 600px; overflow-y: scroll">

            <el-tag
              v-for="item in cardList"
              :key="item.quId"
              :type="itemClass(item.quId, item.isRight, item.answered)"
              st
              style="margin: 5px;font-size: 10px; cursor: pointer"
              @click="switchQu(item)"
            >
              {{ item.sort }}
            </el-tag>

          </div>

        </el-card>

      </el-col>

    </el-row>

  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

import { finishTrain, startTrain, listCard, quDetail } from '@/api/repo/train'
import { Loading } from 'element-ui'
import { mapGetters } from 'vuex'

export default {
  components: { },
  data() {
    return {
      trainId: '',
      cardShow: false,
      nextIfTrue: false,
      currentSort: 1,
      maxSort: 100,
      trueCount: 0,
      wrongCount: 0,
      totalCount: 0,
      repoId: '',
      quId: '',
      mode: 0,
      cardList: [],
      cardItem: {},
      quData: {
        quType: '1'
      }

    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ]),

    rightRate: function() {
      if (this.trueCount === 0 || this.totalCount === 0) {
        return 0
      }

      return parseInt(this.trueCount * 100 / this.totalCount)
    }

  },
  created() {
    this.repoId = this.$route.params.repoId
    this.mode = parseInt(this.$route.params.mode)
    this.handlerStart(true)
  },
  methods: {

    handleRest(data) {
      const that = this

      this.cardItem.isRight = data.isRight
      this.cardItem.answers = data.answers
      this.cardItem.answered = true
      console.log('rest', data)
      if (data.isRight) {
        this.trueCount += 1

        // 继续下一题
        if (this.nextIfTrue) {
          this.$message.success('回答正确，继续下一题..')

          setTimeout(function() {
            that.fetchNext()
          }, 1500)
        }
      } else {
        this.wrongCount += 1
      }
    },

    handlerStart(clear) {
      // 打开
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const that = this
      // 开始训练
      startTrain(this.mode, this.repoId, this.userId, clear).then(res => {
        that.trainId = res.data.id

        // 查找答题卡
        listCard(that.trainId).then(res => {
          this.cardList = res.data
          this.totalCount = res.data.length
          // 最后一个
          this.maxSort = this.cardList[this.cardList.length - 1].sort
          // 正确树立
          this.cardList.forEach(function(item) {
            if (item.isRight) {
              that.trueCount += 1
            }

            if (!item.isRight && item.answered) {
              that.wrongCount += 1
            }
          })

          // 查找第一题
          this.switchQu(this.cardList[0])

          // 关闭
          loading.close()
        })
      }).catch(() => {
        // 错题训练。题库=考试
        this.handleBack()

        // 关闭
        loading.close()
      })
    },

    handleBack() {
      // 错题训练。题库=考试
      if (this.mode == 0) {
        this.$router.push({ name: 'BookList', params: { examId: this.repoId }})
      } else {
        // 跳回题库详情
        this.$router.push({ name: 'MyRepoView', params: { repoId: this.repoId }})
      }
    },

    // 切换试题
    switchQu(item) {
      this.quId = item.quId
      this.currentSort = item.sort
      this.cardItem = item
      this.fetchQuDetail()
    },

    // 查找试题详细
    fetchQuDetail() {
      // 打开
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      quDetail(this.trainId, this.quId, this.mode).then(response => {
        // 试题信息
        this.quData = response.data

        // 背题模式
        if (this.mode === 13) {
          this.cardItem.answered = true
          this.cardItem.isRight = true
        }
        loading.close()

        // 还原滚动位置
        scrollTo(0, 800)
      })
    },

    // 上一题
    fetchNext() {
      const index = this.currentSort
      const item = this.cardList[index]

      this.switchQu(item)
    },

    // 下一题
    fetchPrevious() {
      const index = this.currentSort - 2
      const item = this.cardList[index]
      this.switchQu(item)
    },

    handleFinish() {
      const that = this

      this.$confirm('确实要结束本次训练吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        finishTrain(that.trainId).then(() => {
          this.$router.replace({ name: 'TrainResult', params: { trainId: this.trainId }})
        })
      })
    },

    // 答题卡样式
    itemClass(quId, isRight, answered) {
      if (quId === this.quData.quId) {
        return 'warning'
      }

      if (isRight) {
        return 'success'
      } else if (answered) {
        return 'danger'
      } else {
        return 'info'
      }
    }

  }
}
</script>

<style scoped>

  .guide-item{
    background: #efefef;
    text-align: center;
    line-height: 24px;
    border-right: #e1e1e1 1px solid;
    padding: 5px 0px 5px 0px;
    height: 55px;
  }

  .guide-item :first-child{
    color: #0d5ea5;
    font-weight: 700;
  }
  .guide-item :last-child{
    color: #666;
    font-size: 14px;
  }
</style>
