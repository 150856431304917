<template>
  <div
    v-if="quData && quData.id"
    :class="{ 'sub-box': sub }"
    :id="`qu-${quData.quId}`"
    class="qu-box"
  >
    <div ref="quItem" :class="{ 'pin-class': pin && quData.quType === '99' }">
      <div v-if="showType" class="score-bar">
        <div style="flex-grow: 1">
          {{ quData.quType_dictText }} （本题分值：{{ quData.score }}分）
        </div>

        <div v-if="quData.quType !== '99'" class="mark">
          <el-tooltip
            v-if="mark"
            :content="quData.mark ? '取消本题的疑难标记' : '为本题添加疑难标记'"
            effect="dark"
            placement="bottom"
          >
            <i
              :class="{ 'el-icon-s-flag': true, active: quData.mark }"
              style="cursor: pointer"
              @click="markQu()"
            />
          </el-tooltip>
        </div>
        <div v-else>
          <el-button
            v-show="!pin" type="text"
            @click="doPin()"
          >固定题目</el-button
          >
          <el-button
            v-show="pin" type="text"
            @click="doPin()"
          >取消固定</el-button
          >
          <!-- <i :class="{ 'el-icon-paperclip': true, 'pin-btn': true, 'is-pin': pin}" style="cursor: pointer" @click="doPin()" /> -->
        </div>
      </div>

      <div class="qu-item">
        <div :class="sub ? 'sub' : 'num'">
          <span v-if="sort">{{ sort }}.</span>
          {{ quData.sort }}
        </div>
        <div class="content">
          <div v-html="$options.filters.formatRichText(quData.content)" />
        </div>
      </div>

      <!-- 单选多选判断 -->
      <div
        v-if="
          quData.quType === '1' ||
            quData.quType === '2' ||
            quData.quType === '3'
        "
        class="qu-answer"
      >
        <div
          v-for="item in quData.answerList"
          :key="item.id"
          :class="{ active: item.checked }"
          class="item"
          @click="handleItemClick(item)"
        >
          <div class="tag">{{ item.abc }}</div>
          <div class="content">
            <div>{{ item.content }}</div>
            <div v-if="item.image">
              <img :src="item.image" style="max-width: 90%" >
            </div>
          </div>
        </div>
      </div>

      <!-- 主观题 -->
      <div v-if="quData.quType === '4'">
        <div class="as-box">
          <Tinymce
            ref="tinymce"
            :paste="false"
            :id="quData.id"
            v-model="quData.answer"
            :show-upload="false"
            style="width: 100%"
          />
        </div>
      </div>

      <!-- 填空题 -->
      <div v-if="quData.quType === '5'" class="qu-answer">
        <div v-for="item in quData.answerList" :key="item.id" class="item">
          <div class="content">
            <el-input v-model="item.answer" />
          </div>
        </div>
      </div>

      <div v-if="sub" class="split" />
    </div>

    <!-- 组合题引用自己 -->
    <div v-if="quData.quType === '99'" :style="`margin-top: ${subMargin}px`">
      <div v-for="sub in quData.subList" :key="sub.id">
        <qu-item-exam
          :key="sub.id"
          :show-type="showType"
          :sort="quData.sort"
          :value="sub"
          :sub="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuItemExam',
  components: {},
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    showType: {
      type: Boolean,
      default: function() {
        return true
      }
    },
    mark: {
      type: Boolean,
      default: function() {
        return true
      }
    },
    sub: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    sort: {
      type: Number,
      default: function() {
        return null
      }
    }
  },

  data() {
    return {
      init: false,
      // 当前试题内容
      quData: {
        quType: '',
        answer: '',
        answerList: []
      },
      pin: false,
      subMargin: 0
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.fillData(val)
      }
    },
    quData: {
      handler(val) {
        if (val && this.init) {
          this.handleFill()
        }
      },
      deep: true
    }
  },

  created() {
    this.fillData(this.value)
  },

  methods: {
    // 填充数据
    fillData(data) {
      if (!data) {
        return
      }

      // 断开引用
      if (!this.sub) {
        this.quData = JSON.parse(JSON.stringify(data))
      } else {
        this.quData = data
      }

      if (this.quData.quType === '4' && this.$refs.tinymce) {
        this.$refs.tinymce.setContent(this.quData.answer || '')
      }

      this.$nextTick(() => {
        this.init = true
      })
    },

    handleFill() {
      this.$emit('fill', this.quData)
    },

    // 选定值
    handleItemClick(item) {
      item.checked = !item.checked

      // 单选要清理其它数据
      if (this.quData.quType === '1' || this.quData.quType === '3') {
        this.quData.answerList.forEach(function(qu) {
          console.log(qu)
          if (item.id !== qu.id) {
            qu.checked = false
          }
        })
      }
    },

    // 处理
    markQu() {
      this.quData.mark = !this.quData.mark
    },

    doPin() {
      this.pin = !this.pin
      if (this.pin) {
        this.subMargin = this.$refs.quItem.offsetHeight + 80
      } else {
        this.subMargin = 0
      }
    },
    change() {
      console.log('change....')
    }
  }
}
</script>

<style>
.pin-class {
  position: fixed;
  top: 40px;
  background: #fff;
  border: #5794f7 2px dashed;
  width: calc(70vw - 60px);
  z-index: 99999;
}

.pin-btn {
  color: #555;
  font-weight: 700;
  font-size: 18px;
}

.is-pin {
  color: #5794f7;
}
</style>
