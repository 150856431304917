import { post } from '@/utils/request'
import { postImg, getImg } from '@/utils/request-img'

/**
 * 题库详情
 * @param data
 */
export function fetchDetail(id) {
  return post('/api/exam/exam/detail', { id: id })
}

/**
 * 简略详情
 * @param data
 */
export function simpleDetail(id) {
  return post('/api/exam/exam/simple-detail', { id: id })
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/exam/exam/save', data)
}

/**
 * 题库详情
 * @param data
 */
export function fetchList(data) {
  return post('/api/exam/exam/paging', data)
}

/**
 * 校验考试信息
 * @param id
 * @returns {*}
 */
export function checkInfo(id) {
  return post('/api/exam/exam/check-info', { id: id })
}

/**
 * 发送考试安排
 * @param data
 * @returns {Promise}
 */
export function prepareNotify(data) {
  return post('/api/exam/exam/prepare-notify', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(examId) {
  return post('/api/exam/buy/init', { examId: examId })
}

export function getqrcode(data) {
  return getImg(`/api/presenter/qrcode/create?name=${data}`)
}
// 预先获取试卷ID
export function httpReadyParper(data) {
  // return post('/api/exam/exam/paperid', data)
  return post('/api/paper/paper/paperid', data)
}
// 创建试卷（已有试卷ID）
export function httpCreateById(data) {
  return post('/api/paper/paper/idcreate', data)
}

// 常见问题
export function httpQuestion(data) {
  return post('/api/question/paging', data)
}

